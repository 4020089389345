import InfoModal from '../../../shared/InfoModal';
import { Formik } from 'formik';
import styled from 'styled-components';
import Input from '../../../shared/Input';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

let initialValues = { key: '' };

const AddApiKeyModal = ({ modalState, setModalState, editUser, userId }) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    key: Yup.string().required(t('addApiKeyModal.keyRequired')),
  });
  const { enqueueSnackbar } = useSnackbar();
  const submitHandler = async ({ key }, { resetForm }) => {
    const res = await editUser(userId, { [modalState.target]: key });
    if (res.status === 200) {
      setModalState({ shown: false, target: null });
      enqueueSnackbar(t('addApiKeyModal.successTooltip'), { variant: 'success' });
      resetForm();
    } else {
      enqueueSnackbar(t('addApiKeyModal.errorTooltip'), { variant: 'error' });
    }
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
      {({ values, errors, handleSubmit, handleChange, handleBlur, touched }) => (
        <InfoModal
          title={t('addApiKeyModal.title')}
          info={t('addApiKeyModal.text')}
          modalState={modalState.shown}
          btnText={t('addApiKeyModal.addKeyBtn')}
          successCallback={handleSubmit}
          closeCallback={() => setModalState({ shown: false, target: null })}
        >
          <Form>
            <Input
              name="key"
              label={t('formLabels.key')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.key}
              error={touched.key && errors.key}
            />
          </Form>
        </InfoModal>
      )}
    </Formik>
  );
};

export default AddApiKeyModal;

const Form = styled.form``;
