import { useState, useEffect } from 'react';
import MessagesView from './MessagesView';
import { usePagination } from '../../utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getMessages } from '../../redux/modules/message';

const MessageContainer = () => {
  const [sort, setSort] = useState('-createdDate');
  const dispatch = useDispatch();
  const { totalCount } = useSelector(({ message }) => message);
  const { page, count, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination({ totalCount });
  useEffect(() => {
    dispatch(getMessages({ page, rows: rowsPerPage, sort }));
  }, [page, rowsPerPage, sort]);
  return (
    <MessagesView
      page={page}
      count={count}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      sort={sort}
      setSort={setSort}
    />
  );
};

export default MessageContainer;
