import { useSelector } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Paper } from '../../shared';
import { useTranslation } from 'react-i18next';

const SingleMessageView = () => {
  const { currentMessage, error } = useSelector(({ message }) => message);
  const { title, text, createdDate } = currentMessage || {};
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Paper>
        {currentMessage?._id ? (
          <>
            <Title>{title}</Title>
            <Date>{dayjs(createdDate).format('DD.MM.YYYY HH:mm')}</Date>
            <Content>{text}</Content>
          </>
        ) : error !== null ? (
          <NoData>{t('singleMessagePage.notFound')}</NoData>
        ) : (
          ''
        )}
      </Paper>
    </Wrapper>
  );
};

export default SingleMessageView;

const Wrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  ${({ theme }) => `${theme.mq.tablet}{
      padding: 45px 0 80px;
  }`}
`;

const Title = styled.h2`
  font-size: 26px;
  ${({ theme }) => `${theme.mq.tablet}{
      font-size: 32px;
  }`}
`;

const Date = styled.p`
  font-size: 14px;
  margin-top: 3px;
`;

const Content = styled.p`
  margin-top: 20px;
  padding-bottom: 20px;
  white-space: break-spaces;
  font-size: 14px;
  ${({ theme }) => `${theme.mq.tablet}{
      font-size: 16px;
  }`}
`;

const NoData = styled.div`
  text-align: center;
  padding: 40px 0;
`;
