import { useState } from 'react';
import Modal from '../../../shared/Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Button from '../../../shared/Button';
import Input from '../../../shared/Input';
import Select from '../../../shared/Select';
import { useSnackbar } from 'notistack';
import WarningModal from '../../../shared/WarningModal';
import useCities from '../../../utils/hooks/useCities';
import ReactTooltip from 'react-tooltip';
import { Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('validationTexts.nameRequired')),
  identifier: Yup.string()
    .required(i18n.t('validationTexts.identifierRequired'))
    .test('notIncludeForbidenChars', i18n.t('validationTexts.wrongIdentifier'), (val) => !val?.includes('#')),
  address: Yup.object().shape({
    postalCode: Yup.string().test('pattern', i18n.t('validationTexts.wrongPostalCode'), (value) =>
      value ? value.match(/^[0-9]{2}-[0-9]{3}$/) : true,
    ),
  }),
});

const solarEdgeValidationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('validationTexts.nameRequired')),
  identifier: Yup.string()
    .required(i18n.t('validationTexts.identifierRequired'))
    .test('notIncludeForbidenChars', i18n.t('validationTexts.wrongIdentifier'), (val) => !val?.includes('#')),
  solarEdgeSiteId: Yup.string()
    .required(i18n.t('validationTexts.identifierRequired'))
    .test('notIncludeForbidenChars', i18n.t('validationTexts.wrongIdentifier'), (val) => !val?.includes('#')),
  address: Yup.object().shape({
    postalCode: Yup.string().test('pattern', i18n.t('validationTexts.wrongPostalCode'), (value) =>
      value ? value.match(/^[0-9]{2}-[0-9]{3}$/) : true,
    ),
  }),
});

const AddDeviceModal = ({ isAddDeviceModal, setAddDeviceModal, createDevice, user, editDevice }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [warningModal, setWarningModal] = useState({ shown: false, targetId: null });
  const [dataSource, setDeviceType] = useState(null);
  const { cities, handleCurrentsChange, handleSearchTermChange } = useCities();
  const citiesOptions = cities.map(({ name, id }) => ({ label: name, value: id }));
  const initialValues = {
    name: '',
    identifier: '',
    solarEdgeSiteId: '',
    isThreePhases: false,
    address: {
      city: user?.address?.city ?? '',
      postalCode: user?.address?.postalCode ?? '',
    },
  };
  return (
    <Modal
      title={t('addDeviceModal.title')}
      show={isAddDeviceModal}
      exitButton={true}
      onCancel={() => {
        setAddDeviceModal(false);
        setDeviceType(null);
      }}
    >
      <WarningModal
        modalState={warningModal}
        setModalState={setWarningModal}
        text={t('addDeviceModal.identifierTakenButDeleted')}
        onSuccess={() => {
          editDevice(warningModal.targetId, { deleted: false }, true);
          setWarningModal({ shown: false, targetId: null });
          setAddDeviceModal(false);
          setDeviceType(null);
        }}
        onDecline={() => setWarningModal({ shown: false, targetId: null })}
      />
      {dataSource === null ? (
        <TypeChooseWrapper data-cy="device-type-choose">
          <StyledReactTooltip delayShow={500} id={`popup-dataSourceInfo`} effect="solid" />
          <p>{t('addDeviceModal.chooseTypeText')}</p>
          <div>
            <TypeBtn onClick={() => setDeviceType('mae')}>{t('addDeviceModal.mae')}</TypeBtn>
            <TypeBtn onClick={() => setDeviceType('polandgreenenergry')}>Poland Green Energy</TypeBtn>
            <TypeBtn
              data-tip={t('addDeviceModal.addSolarEdgeApiKeyTooltip')}
              data-for={!user?.solarEdgeApiKey ? 'popup-dataSourceInfo' : ''}
              onClick={() => {
                if (user?.solarEdgeApiKey) {
                  setDeviceType('solarEdge');
                }
              }}
              disabled={!user?.solarEdgeApiKey}
            >
              SolarEdge
            </TypeBtn>
            <TypeBtn onClick={() => setDeviceType('custom')}>{t('addDeviceModal.customService')}</TypeBtn>
          </div>
        </TypeChooseWrapper>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={dataSource === 'solarEdge' ? solarEdgeValidationSchema : validationSchema}
          onSubmit={async (values, { setErrors }) => {
            createDevice(user?._id, { ...values, dataSource }).then((res) => {
              if (res?.status === 201) {
                enqueueSnackbar(t('snackbarTexts.deviceAdded'), { variant: 'success' });
                setAddDeviceModal(false);
                setDeviceType(null);
              } else if (res?.status === 300) {
                setWarningModal({ shown: true, targetId: res?.data?.data });
              } else if (res?.status === 404) {
                setErrors({ identifier: t('editDeviceModal.identifierTaken') });
              } else if (
                res?.status === 500 &&
                (res?.data?.message?.includes('duplicate key error') ||
                  res?.data?.message === `${t('formLabels.identifier')} ${values.identifier} ${t('validationTexts.isTaken')}`)
              ) {
                setErrors({ identifier: `Identyfikator ${values.identifier} ${t('validationTexts.isTaken')}` });
              } else {
                enqueueSnackbar(t('snackbarTexts.errorWhileDeviceAdding'), { variant: 'error' });
                setAddDeviceModal(false);
                setDeviceType(null);
              }
            });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Input
                name="name"
                label={t('formLabels.name')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={touched.name && errors.name}
              />
              <InputRow colsTemplate={dataSource === 'solarEdge' ? '1fr 1fr' : '1fr'}>
                {dataSource === 'solarEdge' && (
                  <Input
                    name="solarEdgeSiteId"
                    label={t('formLabels.installationIdentifier')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.solarEdgeSiteId}
                    error={touched.solarEdgeSiteId && errors.solarEdgeSiteId}
                  />
                )}
                <Input
                  name="identifier"
                  label={dataSource === 'solarEdge' ? t('formLabels.inverterIdentifier') : t('formLabels.identifier')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.identifier}
                  error={touched.identifier && errors.identifier}
                />
              </InputRow>
              <InputRow>
                <Select
                  variant="react-select"
                  menuPosition="fixed"
                  name="address.city"
                  label={t('formLabels.city')}
                  onChange={({ label }) => {
                    handleCurrentsChange(label);
                    setFieldValue('address.city', label);
                  }}
                  onInputChange={(v) => handleSearchTermChange(v)}
                  onBlur={handleBlur}
                  value={{ label: values?.address?.city }}
                  error={touched?.address?.city && errors?.address?.city}
                  options={citiesOptions}
                />
                <Input
                  name="address.postalCode"
                  label={t('formLabels.postalCode')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.address?.postalCode}
                  inputTooltip={t('addDeviceModal.postalCodeRequiredToWeather')}
                  error={touched?.address?.postalCode && errors?.address?.postalCode}
                />
              </InputRow>
              <SwitchWrapper>
                <SwitchLabel
                  data-cy="user-switch"
                  onClick={() => setFieldValue('isThreePhases', !values.isThreePhases)}
                  active={!values.isThreePhases}
                >
                  {t('editDeviceModal.singlePhase')}
                </SwitchLabel>
                <Switch color="primary" onChange={() => setFieldValue('isThreePhases', !values.isThreePhases)} checked={values.isThreePhases} />
                <SwitchLabel data-cy="admin-switch" onClick={() => setFieldValue(true)} active={values.isThreePhases}>
                  {t('editDeviceModal.threePhase')}
                </SwitchLabel>
              </SwitchWrapper>
              <Button fullWidth type="submit">
                {t('editDeviceModal.addBtn')}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default AddDeviceModal;

const Form = styled.form`
  width: 80vw;
  padding: 30px 0;
  ${({ theme }) => `${theme.mq.desktop}{
    width: 400px;
  }`}
`;

const TypeChooseWrapper = styled.div`
  padding: 30px 0;
  min-width: 70vw;
  max-width: 90vw;
  ${({ theme }) => `${theme.mq.tablet}{
    padding: 30px;
    min-width: 0;
    width: 500px;
  }`}
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    ${({ theme }) => `${theme.mq.small}{
      flex-direction: row;
    }`}
  }
  p {
    text-align: center;
    font-size: 20px;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const TypeBtn = styled.div`
  color: #fff;
  font-size: 24px;
  padding: 0 5px;
  width: 160px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  margin: 20px 10px 0;
  text-align: center;
  border-radius: 5px;
  background-color: ${({ theme }) => (theme.paperBackground === '#fff' ? theme.primary : theme.background)};
  user-select: none;
  transition: 300ms;
  ${({ disabled, theme }) =>
    disabled
      ? `background-color: ${theme.paperBackground === '#fff' ? '#ccc' : '#383838'}; cursor:not-allowed;`
      : `&:hover {
    background-color: ${theme.paperBackground === '#fff' ? `${theme.primary}95` : `${theme.background}80`};
  }`}
`;

const InputRow = styled.div`
  display: grid;
  grid-template-columns: ${({ colsTemplate }) => (colsTemplate ? colsTemplate : '1fr 1fr')};
  column-gap: 10px;
`;

const StyledReactTooltip = styled(ReactTooltip)`
  width: 200px;
  background-color: #222222ee;
  p {
    color: #fff;
    margin-bottom: 3px;
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme.primary};
  }
  .MuiSwitch-track {
    background-color: ${({ theme }) => theme.secondaryText}!important;
  }
`;

const SwitchLabel = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme, active }) => (active ? theme.primary : `${theme.secondaryText}80`)};
  transition: 300ms;
  width: 100px;
  margin: 0 5px;
  cursor: pointer;
  &:first-child {
    text-align: right;
  }
`;
