import styled from 'styled-components';
import Button from '../../shared/Button';
import Table from '../../shared/Table';
import StatusPill from '../../shared/StatusPill';
import Paper from '../../shared/Paper';
import Add from '@material-ui/icons/Add';
import { TableCell, TableRow, TablePagination, IconButton } from '@material-ui/core';
import AddDeviceModal from './modals/AddDeviceModal';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Input from '../../shared/Input';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PasswordConfirmationModal from '../../shared/PasswordConfirmationModal';
import WarningModal from '../../shared/WarningModal';
import { calculateCo2Emission } from '../../utils/objectsFormating';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const DevicesView = ({
  isAddDeviceModal,
  setAddDeviceModal,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  count,
  devices,
  user,
  createDevice,
  setTableSearchTerm,
  tableSearchTerm,
  isConfirmModal,
  setIsConfirmModal,
  warningModal,
  setWarningModal,
  editDevice,
  devicesLoading,
  debouncedTableSearchTerm,
  devicesEnergyProduction,
  devicesWeatherData,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <AddDeviceModal
        editDevice={editDevice}
        isAddDeviceModal={isAddDeviceModal}
        setAddDeviceModal={setAddDeviceModal}
        createDevice={createDevice}
        user={user}
      />
      <WarningModal
        text={t('devicesPage.deleteDeviceWarningText')}
        modalState={warningModal}
        setModalState={setWarningModal}
        onSuccess={() => setIsConfirmModal(true)}
        onDecline={() => setWarningModal({ shown: false, targetId: null })}
      />
      <PasswordConfirmationModal
        modalState={isConfirmModal}
        setModalState={setIsConfirmModal}
        successCallback={() => {
          editDevice(warningModal.targetId, { deleted: true });
          setWarningModal({ shown: false, targetId: null });
        }}
      />
      <PageTitle>{t('devicesPage.title')}</PageTitle>
      <Actions>
        <Button data-cy="add-device-btn" color="primary" onClick={() => setAddDeviceModal(true)} startIcon={<StyledAddIcon />}>
          {t('devicesPage.addDeviceBtn')}
        </Button>
      </Actions>
      <StyledPaper>
        <TableSearchWrapper>
          <Input
            background="transparent"
            name="tableSearch"
            label={t('tableLabels.searchDevice')}
            value={tableSearchTerm ?? ''}
            onChange={(e) => setTableSearchTerm(e.target.value)}
          />
        </TableSearchWrapper>
        <StyledTable
          rowCheckbox={false}
          items={devices}
          itemsLength={devices.length}
          loadingState={devicesLoading}
          searchTerm={debouncedTableSearchTerm}
          noItemsPlaceholder={() => (
            <TableNoDevicesPlaceholder>
              <p>{t('noDataLabels.noDevices')}</p>
              <Button onClick={() => setAddDeviceModal(true)} color="primary">
                {t('devicesPage.addDeviceBtn')}
              </Button>
            </TableNoDevicesPlaceholder>
          )}
          columns={[
            {
              label: t('tableLabels.name'),
            },
            {
              label: t('tableLabels.status'),
            },
            {
              label: t('tableLabels.energyProduction30days'),
            },
            {
              label: t('tableLabels.savedEmission'),
            },
            {
              label: t('tableLabels.atmosphericConditions'),
            },
            {
              label: '',
            },
          ]}
        >
          {devices?.map(({ _id, identifier, name, status, dataSource = 'custom', address: { postalCode } = {} }) => (
            <TableRow key={_id} data-cy="device-item">
              <TableCell>
                <StyledLink to={`/devices/${_id}`}>
                  {name} ({identifier}) <StyledOpenInNewIcon />
                </StyledLink>
              </TableCell>
              <TableCell>
                <StatusPill color={dataSource === 'mae' ? (status === 'ON' ? 'success' : 'danger') : 'disabled'}>
                  {dataSource === 'mae' ? (status === 'ON' ? t('statusPill.connected') : t('statusPill.disconnected')) : t('statusPill.noData')}
                </StatusPill>
              </TableCell>
              <TableCell>{devicesEnergyProduction[identifier] ? devicesEnergyProduction[identifier]?.toFixed(3) : 0} kWh</TableCell>
              <TableCell>{calculateCo2Emission(devicesEnergyProduction[identifier])} kg</TableCell>
              <TableCell>
                {' '}
                {devicesWeatherData[postalCode]
                  ? `${devicesWeatherData[postalCode]?.temperature ? `${devicesWeatherData[postalCode]?.temperature}°C` : ''}${
                      devicesWeatherData[postalCode]?.humidity
                        ? `${devicesWeatherData[postalCode]?.temperature ? ' / ' : ''}${devicesWeatherData[postalCode]?.humidity}%`
                        : ''
                    } (${dayjs(devicesWeatherData[postalCode]?.time).format('DD.MM.YYYY HH:mm')})`
                  : t('statusPill.noData')}
              </TableCell>
              <TableCell>
                <IconButton data-cy="delete-device-btn" onClick={() => setWarningModal({ shown: true, targetId: _id })}>
                  <StyledDeleteForeverIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </StyledTable>
        <StyledTablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={`${t('tableLabels.rows')}:`}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </StyledPaper>
    </Wrapper>
  );
};

export default DevicesView;

const Wrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  ${({ theme }) => `${theme.mq.tablet}{
      padding: 45px 0 80px;
  }`}
`;

const PageTitle = styled.h1``;

const StyledLink = styled(Link)`
  text-decoration: none;
  white-space: nowrap;
  color: ${({ theme }) => theme.lightPrimaryText};
  transition: 300ms;
  font-weight: 500;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  > button {
    margin-left: 15px;
  }
  ${({ theme }) => `${theme.mq.tablet}{
      margin-top: -20px;
  }`}
`;

const StyledAddIcon = styled(Add)`
  width: 20px;
  margin-right: 5px;
`;

const StyledPaper = styled(Paper)`
  margin: 15px 0 35px;
`;

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  height: 15px;
  width: 15px;
  path {
    fill: ${({ theme }) => theme.primary};
  }
`;

const TableSearchWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 5px;
  right: 15px;
`;

const StyledTable = styled(Table)`
  margin-top: 45px;
`;

const StyledDeleteForeverIcon = styled(DeleteForeverIcon)`
  path {
    fill: ${({ theme }) => theme.danger};
  }
`;

const TableNoDevicesPlaceholder = styled.div`
  button {
    margin-top: 10px;
  }
`;

const StyledTablePagination = styled(TablePagination)`
  color: ${({ theme }) => theme.primaryText};
  .MuiTablePagination-selectRoot {
    margin-right: 10px;
  }
`;
