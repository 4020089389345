import { useRef } from 'react';
import styled from 'styled-components';
import Input from '../../shared/Input';
import Paper from '../../shared/Paper';
import Logo from '../../shared/Logo';
import Button from '../../shared/Button';
import Spinner from '../../shared/Spinner';
import { theme } from '../../shared/theme';
import loginImgSrc from '../../shared/assets/login-image.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import RegisterModal from './modals/RegisterModal';
import ResetPasswordModal from './modals/ResetPasswordModal';
import ReCAPTCHA from 'react-google-recaptcha';
import { ReactComponent as AirPollutionImg } from '../../shared/assets/air_pollution.svg';
import { ReactComponent as CleanEnergyImg } from '../../shared/assets/clean_energy.svg';
import { ReactComponent as MoneySavedImg } from '../../shared/assets/money_saved.svg';
import { ReactComponent as PolandFlagImg } from '../../shared/assets/poland-flag.svg';
import { ReactComponent as BritishFlagImg } from '../../shared/assets/british-flag.svg';
// import { ReactComponent as GermanyFlagImg } from '../../shared/assets/germany-flag.svg';
import { useTranslation } from 'react-i18next';
import { handleLanguageChange } from '../../utils/other';
import { ReactComponent as TreesImg } from '../../shared/assets/planted_trees.svg';
import i18n from '../../i18n';

const validationSchema = Yup.object().shape({
  email: Yup.string().email(i18n.t('validationTexts.wrongEmail')).required(i18n.t('validationTexts.emailRequired')),
  password: Yup.string()
    .min(2, i18n.t('validationTexts.passworTooShort'))
    .max(50, i18n.t('validationTexts.passwordTooLong'))
    .required(i18n.t('validationTexts.passwordRequired')),
});

const LoginView = ({
  authenticateUser,
  isResetPasswordModal,
  setResetPasswordModal,
  resetPassword,
  isRegisterModal,
  setIsRegisterModal,
  createUserSelf,
  userCreateLoading,
  totalStats,
  summaryLoading,
  darkMode,
}) => {
  const { t } = useTranslation();
  const recaptchaRef = useRef();
  const initialValues = {
    email: '',
    password: '',
  };
  const { totalEnergyProduction, totalEmissionSaved, totalMoneySaved, treesEquivalent } = totalStats ?? {};
  return (
    <Wrapper>
      <LanguageChange active={localStorage.getItem('language') ?? 'pl'}>
        <PolandFlagImg id="pl" onClick={() => handleLanguageChange('pl')} />
        <BritishFlagImg id="en" onClick={() => handleLanguageChange('en')} />
        {/* <GermanyFlagImg id="de" onClick={() => handleLanguageChange('de')} /> */}
      </LanguageChange>
      <RegisterModal
        isRegisterModal={isRegisterModal}
        setIsRegisterModal={setIsRegisterModal}
        createUserSelf={createUserSelf}
        userCreateLoading={userCreateLoading}
      />
      <ResetPasswordModal isResetPasswordModal={isResetPasswordModal} setResetPasswordModal={setResetPasswordModal} resetPassword={resetPassword} />
      <LoginImg src={loginImgSrc} />
      <RightSide>
        <GlobalPaper>
          <GlobalDataTitle>{t('summaryWidget.title')}:</GlobalDataTitle>
          {summaryLoading ? (
            <Spinner component="div" padding="22px 0" />
          ) : (
            <GlobalDataWrapper>
              <GlobalDataBox key="clean_energy" color={theme.primary}>
                <CleanEnergyImg />
                <GlobalDataValue>{totalEnergyProduction !== 0 ? totalEnergyProduction?.toFixed(0) : totalEnergyProduction} kWh</GlobalDataValue>
                <GlobalDataBoxTitle>{t('summaryWidget.producedEnergy')}</GlobalDataBoxTitle>
              </GlobalDataBox>
              <GlobalDataBox color={theme.thirdary}>
                <AirPollutionImg />
                <GlobalDataValue lineHeight={'1.10'}>
                  {totalEmissionSaved !== 0 ? totalEmissionSaved?.toFixed(0) : totalEmissionSaved} kg CO<sub>2</sub>
                </GlobalDataValue>
                <GlobalDataBoxTitle>{t('summaryWidget.savedEmission')}</GlobalDataBoxTitle>
              </GlobalDataBox>
              <GlobalDataBox color={theme.secondary}>
                <MoneySavedImg />
                <GlobalDataValue>{totalMoneySaved !== 0 ? totalMoneySaved?.toFixed(0) : totalMoneySaved} PLN</GlobalDataValue>
                <GlobalDataBoxTitle>{t('summaryWidget.savedMoney')}</GlobalDataBoxTitle>
              </GlobalDataBox>
              <GlobalDataBox color={theme.trees}>
                <TreesImg />
                <GlobalDataValue>{treesEquivalent !== 0 ? treesEquivalent?.toFixed(0) : treesEquivalent}</GlobalDataValue>
                <GlobalDataBoxTitle>{t('summaryWidget.treesEquivalent')}</GlobalDataBoxTitle>
              </GlobalDataBox>
            </GlobalDataWrapper>
          )}
        </GlobalPaper>
        <StyledPaper>
          <LogoWrapper>
            <Logo smallMobile={false} />
          </LogoWrapper>
          <Title>{t('loginPage.title')}</Title>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                let recaptchaToken = '';
                if (process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY !== 'disable') {
                  recaptchaToken = await recaptchaRef?.current?.executeAsync();
                  recaptchaRef?.current?.reset();
                }
                const res = await authenticateUser(values, recaptchaToken);
                if (res?.status === 401) {
                  setErrors({ email: t('validationTexts.wrongEmailOrPassword') });
                } else {
                  setSubmitting(false);
                }
              } catch (error) {
                setErrors({ password: error });
                setSubmitting(false);
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Input
                  name="email"
                  label={t('formLabels.email')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && errors.email}
                />
                <Input
                  name="password"
                  label={t('formLabels.password')}
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={touched.password && errors.password}
                />
                {process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY !== 'disable' && (
                  <ReCAPTCHA
                    theme={darkMode === 'true' ? 'dark' : 'light'}
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                    size="invisible"
                  />
                )}
                <Button fullWidth type="submit">
                  {t('loginPage.loginBtn')}
                </Button>
              </Form>
            )}
          </Formik>
          <AccessBox>
            <p>{t('loginPage.registerResetText')}</p>
            <UserActionsWrapper>
              <p>
                <span data-cy="register-btn" onClick={() => setIsRegisterModal(true)}>
                  {t('loginPage.registerBtn')}
                </span>
                &nbsp;{t('loginPage.or')}&nbsp;
                <span data-cy="reset-password-btn" onClick={() => setResetPasswordModal(true)}>
                  {t('loginPage.resetPasswordBtn')}
                </span>
              </p>
            </UserActionsWrapper>
          </AccessBox>
        </StyledPaper>
      </RightSide>
    </Wrapper>
  );
};

export default LoginView;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 0;
  ${({ theme }) => `${theme.mq.desktop}{
    align-items: center;
    padding: 40px 0;
  }`}
`;

const LoginImg = styled.img`
  display: none;
  ${({ theme }) => `${theme.mq.desktop}{
    display: block;
    width: 500px;
  }`}
  ${({ theme }) => `${theme.mq.medium}{
    width: 450px;
  }`}
`;

const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 20px 30px;
  margin: 0 auto 10px;
  ${({ theme }) => `${theme.mq.small}{
    padding: 20px 40px 15px;
  }`}
  ${({ theme }) => `${theme.mq.desktop}{
    margin: 10px 0 0;
    width: 510px;
  }`}
`;

const Title = styled.h2`
  margin: 10px 0;
  font-size: 24px;
  font-weight: 400;
  color: ${({ theme }) => (theme.background === '#404040' ? theme.primaryText : '#757575')};
`;

const AccessBox = styled.div`
  text-align: center;
  padding: 20px;
  span,
  p {
    font-size: 16px;
  }
  span {
    margin-top: 10px;
    display: inline-block;
    color: ${({ theme }) => theme.fourth};
    cursor: pointer;
    user-select: none;
  }
`;

const Form = styled.form``;

const LogoWrapper = styled.div`
  svg path.black-element {
    ${({ theme }) => theme.background === '#404040' && `fill:#fff`}
  }
`;

const UserActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  p span {
    color: ${({ theme }) => theme.primary};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const GlobalPaper = styled(Paper)`
  padding: 20px 30px;
  width: 100%;
  ${({ theme }) => `${theme.mq.desktop}{
    width: 510px;
  }`}
`;

const GlobalDataTitle = styled.p`
  font-size: 18px;
  margin: 0 0 5px 5px;
  text-align: center;
  ${({ theme }) => `${theme.mq.desktop}{
    text-align: left;
    grid-template-columns: 1fr 2px 1fr;
  }`}
`;

const GlobalDataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  svg {
    display: block;
    height: 70px;
    width: 70px;
    margin: 0 auto;
  }
  ${({ theme }) => `${theme.mq.desktop}{
    grid-template-columns: 1fr 1fr;
    row-gap: 0;
    svg{
      width: 100px;
      height: 100px;
    }
  }`}
`;

const GlobalDataBox = styled.div`
  padding: 0 5px;
  ${({ color }) => `p{
    color: ${color}
  }`};
`;

const GlobalDataValue = styled.p`
  text-align: center;
  font-size: 16px;
  sub {
    font-size: 10px;
  }
  ${({ lineHeight }) => lineHeight && `line-height: 1.10;`}
`;

const GlobalDataBoxTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;

const RightSide = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
  ${({ theme }) => `${theme.mq.desktop}{
    margin: 0;
    display:block;
  }`};
`;

const LanguageChange = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  svg {
    margin-left: 5px;
    width: 20px;
    cursor: pointer;
  }
  ${({ active }) =>
    active &&
    `#${active}{
      opacity:.5;
      pointer-events:none;
    }`}
`;
