import { useRef } from 'react';
import styled from 'styled-components';
import Button from '../../shared/Button';
import Input from '../../shared/Input';
import DataPaper from '../../shared/DataPaper';
import ChartPaper from '../../shared/ChartPaper';
import Table from '../../shared/Table';
import { theme } from '../../shared/theme';
import {
  Collapse,
  TableCell,
  TableRow,
  Checkbox,
  ClickAwayListener,
  Grow,
  Popper,
  TablePagination,
  MenuItem,
  MenuList,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  ListItemText,
} from '@material-ui/core';
import Paper from '../../shared/Paper';
import StatusPill from '../../shared/StatusPill';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LaunchIcon from '@material-ui/icons/Launch';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { formatAddress } from '../../utils/objectsFormating';
import EditUserModal from '../UsersPage/modals/EditUserModal';
import EditIcon from '@material-ui/icons/Edit';
import Select from '../../shared/Select';
import dayjs from 'dayjs';
import { dateFilterOptions, phaseFilterOptions } from '../../shared/consts';
import { ReactComponent as DataPlaceholderImg } from '../../shared/assets/user-nodevices.svg';
import { calculateCo2Emission, calculateMoneySaved } from '../../utils/objectsFormating';
import { ReactComponent as ProductionIcon } from '../../shared/assets/production.svg';
import { ReactComponent as UsageIcon } from '../../shared/assets/usage.svg';
import { ReactComponent as SavingsIcon } from '../../shared/assets/savings.svg';
import { ReactComponent as CO2Icon } from '../../shared/assets/co2.svg';
import { ReactComponent as UserIcon } from '../../shared/assets/user.svg';
import { ReactComponent as MailIcon } from '../../shared/assets/mail.svg';
import { ReactComponent as PhoneIcon } from '../../shared/assets/phone.svg';
import { ReactComponent as LocationIcon } from '../../shared/assets/location.svg';
import { ReactComponent as DevicesIcon } from '../../shared/assets/devices.svg';
import { useTranslation } from 'react-i18next';

const SingleUserView = ({
  user,
  filters,
  handleFiltersChange,
  isFiltersCollapsed,
  checkedItems,
  handleFiltersToggle,
  handleCheckAll,
  handleCheck,
  devices,
  chartData,
  isExportOverlayOpen,
  setIsExportOverlayOpen,
  handleExportPDF,
  currentUser,
  tableSearchTerm,
  debouncedTableSearchTerm,
  setTableSearchTerm,
  pagiCount,
  pagiPage,
  setPagiPage,
  pagiRowsPerPage,
  setPagiRowsPerPage,
  devicesLoading,
  checkedIds,
  handleExportCSV,
  editUserModal,
  setEditUserModal,
  editUser,
  boxesData,
  devicesEnergyProduction,
  previousValues,
  filterErrors,
  devicesWeatherData,
  devicesEnergyPrice,
  selfConsumption,
}) => {
  const { t } = useTranslation();
  const printRef = useRef();
  return (
    <Wrapper>
      <EditUserModal editUserModal={editUserModal} setEditUserModal={setEditUserModal} editUser={editUser} usersList={[currentUser ?? {}]} />
      <PageTitle>
        {t('singleUserPage.title')} - {currentUser?.name} {currentUser?.surname}
      </PageTitle>
      <Actions>
        {currentUser?.devices.length > 0 && (
          <>
            <ButtonWrapper>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<StyledLaunchIcon />}
                endIcon={<StyledArrowDropDownIcon $active={isExportOverlayOpen} $iconColor={theme.primary} />}
                onClick={() => {
                  setIsExportOverlayOpen(true);
                }}
              >
                {t('btns.export')}
              </Button>
              <StyledPopper open={isExportOverlayOpen} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={() => setIsExportOverlayOpen(false)}>
                        <MenuList autoFocusItem={open} id="menu-list-grow">
                          <StyledMenuItem
                            onClick={() => {
                              setIsExportOverlayOpen(false);
                              handleExportPDF(printRef.current);
                            }}
                          >
                            {t('btns.exportOptions.asPdf')}
                          </StyledMenuItem>
                          <StyledMenuItem
                            onClick={() => {
                              setIsExportOverlayOpen(false);
                              handleExportCSV();
                            }}
                          >
                            {t('btns.exportOptions.asCsv')}
                          </StyledMenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </StyledPopper>
            </ButtonWrapper>
            <Button
              color="secondary"
              onClick={handleFiltersToggle}
              startIcon={<StyledFilterListIcon />}
              endIcon={<StyledArrowDropDownIcon $active={isFiltersCollapsed} />}
            >
              {t('btns.filters')}
            </Button>
          </>
        )}
        <Button color="primary" startIcon={<EditIcon />} onClick={() => setEditUserModal({ shown: true, userId: currentUser?._id })}>
          {t('btns.editData')}
        </Button>
      </Actions>
      <Collapse in={isFiltersCollapsed}>
        <Filters>
          <Select
            name="dateRange"
            options={dateFilterOptions}
            label={t('formLabels.dateRange')}
            value={filters.dateRange}
            onChange={(e) => handleFiltersChange('dateRange', e.target.value)}
          />
          {filters.dateRange === 'custom' && (
            <>
              {' '}
              <Input
                name="dateFrom"
                label={t('formLabels.dateFrom')}
                type="date"
                onChange={(e) => handleFiltersChange('dateFrom', e.target.value)}
                value={dayjs(filters.dateFrom).format('YYYY-MM-DD')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Input
                name="dateTo"
                label={t('formLabels.dateTo')}
                type="date"
                onChange={(e) => handleFiltersChange('dateTo', e.target.value)}
                value={dayjs(filters.dateTo).format('YYYY-MM-DD')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <ErrorMsg>{filterErrors}</ErrorMsg>
            </>
          )}
          <Select
            name="phase"
            options={phaseFilterOptions}
            label={t('formLabels.phase')}
            value={filters.phase}
            onChange={(e) => handleFiltersChange('phase', e.target.value)}
          />
        </Filters>
      </Collapse>
      <div ref={printRef}>
        <DataGrid>
          <UserDataPaper title={t('singleUserPage.userData')}>
            <List>
              <ListItem>
                <StyledListItemIcon>
                  <UserIcon />
                </StyledListItemIcon>
                <ListItemText primary={`${currentUser?.name} ${currentUser?.surname}`} />
              </ListItem>
              <Divider />
              <ListItem>
                <StyledListItemIcon>
                  <MailIcon />
                </StyledListItemIcon>
                <ListItemText primary={currentUser?.email} />
              </ListItem>
              <Divider />
              <ListItem>
                <StyledListItemIcon>
                  <PhoneIcon />
                </StyledListItemIcon>
                <ListItemText primary={currentUser?.phoneNumber || 'Brak'} />
              </ListItem>
              <Divider />
              <ListItem>
                <StyledListItemIcon>
                  <LocationIcon />
                </StyledListItemIcon>
                <ListItemText primary={formatAddress(currentUser?.address)} />
              </ListItem>
            </List>
          </UserDataPaper>
          {currentUser?.devices.length > 0 ? (
            <>
              <DataPaper
                title={t('dataBoxes.producedEnergy.title')}
                icon={<ProductionIcon />}
                color={theme.primary}
                unit="kWh"
                value={boxesData.totalProduction ?? 0}
                maxValue={boxesData.totalProduction > previousValues.production ? boxesData.totalProduction : previousValues.production}
                dateRange={filters?.dateRange}
                previousValue={previousValues.production}
                popupContent={() => (
                  <div dangerouslySetInnerHTML={{ __html: t('dataBoxes.producedEnergy.tooltip', { interpolation: { escapeValue: false } }) }} />
                )}
              />
              <DataPaper
                title={t('dataBoxes.usedEnergy.title')}
                icon={<UsageIcon />}
                color={theme.thirdary}
                unit="kWh"
                value={boxesData.totalUsage ?? 0}
                maxValue={boxesData.totalUsage > previousValues.usage ? boxesData.totalUsage : previousValues.usage}
                dateRange={filters?.dateRange}
                previousValue={previousValues.usage}
                selfConsumption={selfConsumption.total ?? 0}
                popupContent={() => (
                  <div dangerouslySetInnerHTML={{ __html: t('dataBoxes.usedEnergy.tooltip', { interpolation: { escapeValue: false } }) }} />
                )}
              />
              <DataPaper
                title={t('dataBoxes.savedMoney.title')}
                icon={<SavingsIcon />}
                progressBar={false}
                color={theme.fourth}
                unit="zł"
                roundTo={2}
                value={calculateMoneySaved(devicesEnergyProduction, devicesEnergyPrice)}
                dateRange={filters?.dateRange}
                popupContent={() => (
                  <div dangerouslySetInnerHTML={{ __html: t('dataBoxes.savedMoney.tooltip', { interpolation: { escapeValue: false } }) }} />
                )}
              />
              <DataPaper
                title={t('dataBoxes.savedEmission.title')}
                icon={<CO2Icon />}
                progressBar={false}
                color={theme.secondary}
                unit="kg"
                value={calculateCo2Emission(boxesData.totalProduction)}
                dateRange={filters?.dateRange}
                popupContent={() => (
                  <div dangerouslySetInnerHTML={{ __html: t('dataBoxes.savedEmission.tooltip', { interpolation: { escapeValue: false } }) }} />
                )}
              />
            </>
          ) : (
            <DataPlaceholder>
              <DataPlaceholderImg />
            </DataPlaceholder>
          )}
        </DataGrid>
        {currentUser?.devices.length > 0 && <ChartPaper loadingState={devicesLoading} data={chartData} selfConsumption={selfConsumption.chart} />}
        <StyledPaper title={t('tableLabels.devices')} checkedCounter={checkedIds.devices.length} icon={() => <DevicesIcon />}>
          <TableSearchWrapper>
            <Input
              background="transparent"
              name="tableSearch"
              label={t('tableLabels.searchDevice')}
              value={tableSearchTerm ?? ''}
              onChange={(e) => setTableSearchTerm(e.target.value)}
            />
          </TableSearchWrapper>
          <Table
            checkedItemsValues={Object.values(checkedItems?.[user?._id]?.devices ?? {}) ?? []}
            items={devices}
            onCheckAll={() => handleCheckAll(user?._id, devices)}
            noItemsPlaceholder={() => <p>{t('singleUserPage.userNoDevices')}</p>}
            noSearchItemsPlaceholder={t('noDataLabels.noMatchingDevices')}
            searchTerm={debouncedTableSearchTerm}
            itemsLength={devices.length}
            loadingState={devicesLoading}
            columns={[
              {
                label: t('tableLabels.name'),
              },
              {
                label: t('tableLabels.status'),
              },
              {
                label: t('tableLabels.energyProduction'),
              },
              {
                label: t('tableLabels.savedEmission'),
              },
              {
                label: t('tableLabels.atmosphericConditions'),
              },
            ]}
          >
            {devices?.map(({ _id, identifier, name, status, dataSource = 'custom', address: { postalCode } = {} }) => (
              <TableRow key={_id}>
                <TableCell>
                  <StyledCheckbox
                    color="primary"
                    checked={checkedItems?.[user?._id]?.devices[identifier] ?? false}
                    onChange={() => handleCheck(user?._id, identifier)}
                    name={`check_${_id}`}
                  />
                </TableCell>
                <TableCell>
                  {' '}
                  <StyledLink to={`/users/${user?._id}/${_id}`}>
                    {name} ({identifier}) <StyledOpenInNewIcon />
                  </StyledLink>
                </TableCell>
                <TableCell>
                  <StatusPill color={dataSource === 'mae' ? (status === 'ON' ? 'success' : 'danger') : 'disabled'}>
                    {dataSource === 'mae' ? (status === 'ON' ? t('statusPill.connected') : t('statusPill.disconnected')) : t('noDataLabels.noData')}
                  </StatusPill>
                </TableCell>
                <TableCell>{devicesEnergyProduction[identifier] ? devicesEnergyProduction[identifier]?.toFixed(3) : 0} kWh</TableCell>
                <TableCell>{calculateCo2Emission(devicesEnergyProduction[identifier])} kg</TableCell>
                <TableCell>
                  {devicesWeatherData[postalCode]
                    ? `${devicesWeatherData[postalCode]?.temperature}°C / ${devicesWeatherData[postalCode]?.humidity}% (${dayjs(
                        devicesWeatherData[postalCode]?.time,
                      ).format('DD.MM.YYYY HH:mm')})`
                    : t('noDataLabels.noData')}
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <StyledTablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            labelRowsPerPage={`${t('tableLabels.rows')}:`}
            count={pagiCount}
            rowsPerPage={pagiRowsPerPage}
            page={pagiPage}
            onChangePage={(event, newPage) => setPagiPage(newPage)}
            onChangeRowsPerPage={(event) => setPagiRowsPerPage(parseInt(event.target.value, 10))}
          />
        </StyledPaper>
      </div>
    </Wrapper>
  );
};

export default SingleUserView;

const Wrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  ${({ theme }) => `${theme.mq.tablet}{
      padding: 45px 0 80px;
  }`}
`;

const PageTitle = styled.h1``;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  > button {
    margin-left: 15px;
    &:last-child {
      margin-top: 10px;
    }
  }
  ${({ theme }) => `${theme.mq.tablet}{
      margin-top: -50px;
  }`}
  ${({ theme }) => `${theme.mq.small}{
      align-items:flex-end;
      > button:last-child{
          margin-top: 0;
        }
      }
  }`}
`;

const Filters = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  > div {
    width: calc(50% - 5px);
    &:nth-child(2) {
      margin-right: 10px;
    }
    &:first-child,
    &:last-child {
      width: 100%;
    }
  }
  margin-top: 25px;
  ${({ theme }) => `${theme.mq.tablet}{
      margin-top: 10px;
      >div{
        width: calc(25% - 7.5px);
        &:first-child, &:last-child {
          width: calc(25% - 7.5px);
        }
        &:not(:last-child){
          margin-right:10px;
        }
      }
  }`}
`;

const DataGrid = styled.div`
  display: grid;
  margin: 15px 0 35px;
  grid-template-columns: 1fr;
  gap: 10px;
  ${({ theme }) => `${theme.mq.small}{
      grid-template-columns: 1fr 1fr;
  }`}
  ${({ theme }) => `${theme.mq.tablet}{
      gap: 30px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
  }`}
`;

const StyledPaper = styled(Paper)`
  margin: 35px 0;
  padding: 100px 15px 15px;
  ${({ theme }) => `${theme.mq.small}{
      padding: 65px 15px 15px;
  }`}
`;

const StyledLaunchIcon = styled(LaunchIcon)`
  width: 20px;
  margin-right: 5px;
  path {
    fill: ${({ theme }) => theme.primary};
  }
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  width: 20px;
  path {
    fill: ${({ $iconColor }) => ($iconColor ? $iconColor : '#fff')};
  }
  transition: 300ms;
  ${({ $active }) => $active && `transform: rotate(180deg);`}
`;

const StyledFilterListIcon = styled(FilterListIcon)`
  width: 20px;
  margin-right: 5px;
`;

const ButtonWrapper = styled.div`
  position: relative;
`;

const StyledPopper = styled(Popper)`
  position: absolute !important;
  right: 0;
  top: 50px !important;
  z-index: 9;
  > div {
    padding: 10px 15px;
  }
  li:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.background};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.lightPrimaryText};
  white-space: nowrap;
  transition: 300ms;
  font-weight: 500;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  height: 15px;
  width: 15px;
  path {
    fill: ${({ theme }) => theme.primary};
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 40px;
  ${({ theme }) => `${theme.mq.small}{
      min-width:56px;
  }`}
  svg {
    width: 40px;
    height: 40px;
  }
  .fil1 {
    fill: ${({ theme }) => theme.defaultIcon};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  path {
    fill: ${({ theme }) => (theme.paperBackground === '#fff' ? theme.primary : theme.primaryText)};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.primaryText};
`;

const TableSearchWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 35px;
  right: 15px;
  ${({ theme }) => `${theme.mq.small}{
      top: 5px;
  }`}
`;

const StyledTablePagination = styled(TablePagination)`
  color: ${({ theme }) => theme.primaryText};
  .MuiTablePagination-selectRoot {
    margin-right: 10px;
  }
`;

const UserDataPaper = styled(Paper)`
  grid-row: 1/3;
  grid-column: 1/3;
  padding-top: 45px;
`;

const DataPlaceholder = styled.div`
  margin: auto;
  grid-row: 1/3;
  grid-column: 3/-1;
  margin-top: 20px;
  svg {
    width: 350px;
    height: 250px;
  }
  .percent,
  .line {
    fill: ${({ theme }) => (theme.paperBackground === '#fff' ? '#3f3d56' : theme.primaryText)};
  }
  .chart {
    fill: ${({ theme }) => (theme.paperBackground === '#fff' ? '#3f3d56' : '#8c8c8c')};
  }
`;

const ErrorMsg = styled.p`
  position: absolute;
  color: ${({ theme }) => theme.danger};
  font-size: 13px;
  bottom: 0;
`;
