import { useState } from 'react';
import ReportErrorView from './ReportErrorView';
import { connect } from 'react-redux';
import { sendErrorReport } from '../../redux/modules/errorReport';
import { useSnackbar } from 'notistack';

const ReportErrorContainer = ({ sendErrorReport, reportError, reportLoading, user }) => {
  const [reportSended, setReportSended] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSend = (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('text', values.text);
    for (var i = 0; i < values.files.length; i++) {
      formData.append(`attachedFiles[]`, values.files[i]);
    }
    sendErrorReport(formData, user?._id).then((res) =>
      res?.status === 200
        ? enqueueSnackbar('Zgłoszenie zostało wysłane', { variant: 'success' })
        : enqueueSnackbar('Błąd podczas wysyłania zgłoszenia', { variant: 'error' }),
    );
    setReportSended(true);
  };

  return (
    <ReportErrorView
      reportLoading={reportLoading}
      reportError={reportError}
      reportSended={reportSended}
      setReportSended={setReportSended}
      handleSend={handleSend}
    />
  );
};

const mapStateToProps = (state) => ({
  reportLoading: state.errorReport.loading,
  reportError: state.errorReport.error,
});

const mapDispatchToProps = (dispatch) => {
  return {
    sendErrorReport: (reportBody, userId) => dispatch(sendErrorReport(reportBody, userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportErrorContainer);
