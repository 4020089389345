export const theme = {
  background: '#EFF1F9',
  paperBackground: '#fff',
  primary: '#03C895',
  lightPrimary: 'rgba(3, 200, 149, 0.05)',
  thirdary: '#35ADFA',
  secondary: '#FCD440',
  fourth: '#01B8FF',
  primaryText: '#334151',
  lightPrimaryText: '#4F4F4F',
  secondaryText: '#99A6B7',
  danger: '#FF473D',
  defaultIcon: '#757575',
  trees: '#1EAA96',
  chartColors: {
    usage: '#35ADFA',
    production: '#03C895',
    humidity: '#7C8DC4',
    temperature: '#FCD440',
    cloudCover: '#8a8a8a',
    uvIndex: '#8f30e9',
  },
  mq: {
    verySmall: `@media (min-width: 360px)`,
    small: `@media (min-width: 480px)`,
    tablet: `@media (min-width: 768px)`,
    desktop: `@media (min-width: 1024px)`,
    medium: `@media (min-width: 1200px)`,
    huge: `@media(min-width: 1440px)`,
  },
  mqh: {
    smallLaptop: `@media (max-height: 1050px)`,
  },
};

export const darkTheme = {
  background: '#404040',
  paperBackground: '#333',
  primary: '#03C895',
  lightPrimary: 'rgba(3, 200, 149, 0.05)',
  thirdary: '#35ADFA',
  secondary: '#FCD440',
  fourth: '#01B8FF',
  primaryText: '#e9e9e9',
  lightPrimaryText: '#c4c4c4',
  secondaryText: '#99A6B7',
  danger: '#FF473D',
  defaultIcon: '#d1d1d1',
  trees: '#1EAA96',
  chartColors: {
    usage: '#35ADFA',
    production: '#03C895',
    humidity: '#7C8DC4',
    temperature: '#FCD440',
    cloudCover: '#8a8a8a',
    uvIndex: '#8f30e9',
  },
  mq: {
    verySmall: `@media (min-width: 360px)`,
    small: `@media (min-width: 480px)`,
    tablet: `@media (min-width: 768px)`,
    desktop: `@media (min-width: 1024px)`,
    medium: `@media (min-width: 1200px)`,
    huge: `@media(min-width: 1440px)`,
  },
  mqh: {
    smallLaptop: `@media (max-height: 1050px)`,
  },
};
