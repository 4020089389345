import { useState, useEffect } from 'react';
import Modal from '../../../shared/Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Button from '../../../shared/Button';
import Input from '../../../shared/Input';
import Select from '../../../shared/Select';
import { useSnackbar } from 'notistack';
import WarningModal from '../../../shared/WarningModal';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import i18n from '../../../i18n';
import { getUserOptions } from '../../../redux/modules/auth';
import { createMessage, updateMessage, deleteMessage } from '../../../redux/modules/message';
import { formatUserOptions, getUserName } from '../../../utils/objectsFormating';
import useDebounce from '../../../utils/hooks/useDebounce';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const validationSchema = Yup.object().shape({
  title: Yup.string().required(i18n.t('validationTexts.titleRequired')),
  text: Yup.string().min(3, i18n.t('validationTexts.messageTooShort')).required(i18n.t('validationTexts.messageRequired')),
  users: Yup.array().when('sendTo', {
    is: 'selected',
    then: Yup.array().min(1, i18n.t('validationTexts.usersLength')),
  }),
});

const sendToOptions = [
  { label: i18n.t('formLabels.sendToOptions.all'), value: 'all' },
  { label: i18n.t('formLabels.sendToOptions.selected'), value: 'selected' },
];

const MessageModal = ({ modalState, setModalState }) => {
  const createMode = modalState.target === 'new' ?? true;
  const { messages } = useSelector(({ message }) => message);
  const { text, title, to, sendTo } = messages?.find(({ _id }) => _id === modalState.target) ?? {};
  const users = createMode ? [] : to?.map(({ user }) => ({ value: user?._id, label: getUserName(user) }));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [warningModal, setWarningModal] = useState({ shown: false, targetId: null });
  const initialValues = { users, title: createMode ? '' : title, text: createMode ? '' : text, sendTo: createMode ? 'all' : sendTo };

  const handleCreate = async ({ users, ...rest }) => {
    const usersIds = users?.map(({ value }) => value);
    const res = await dispatch(createMessage({ ...rest, users: usersIds }));
    if (res.status === 201) {
      enqueueSnackbar(t('snackbarTexts.messageSended'), { variant: 'success' });
      setModalState({ shown: false, target: null });
    } else {
      enqueueSnackbar(t('snackbarTexts.errorOccured'), { variant: 'error' });
    }
  };
  const handleEdit = async ({ users, ...rest }) => {
    const newTo = users?.map(({ value }) => value).map((id) => ({ user: id, seen: to.find(({ user }) => user?._id === id)?.seen ?? false }));
    const res = await dispatch(updateMessage(modalState.target, { ...rest, to: newTo }));
    if (res.status === 200) {
      enqueueSnackbar(t('snackbarTexts.changesSaved'), { variant: 'success' });
      setModalState({ shown: false, target: null });
    } else {
      enqueueSnackbar(t('snackbarTexts.errorOccured'), { variant: 'error' });
    }
  };

  return (
    <Modal
      title={createMode ? t('messageModal.createTitle') : t('messageModal.editTitle')}
      show={modalState?.shown}
      exitButton={true}
      onCancel={() => {
        setModalState({ shown: false, target: null });
      }}
    >
      <WarningModal
        modalState={warningModal}
        setModalState={setWarningModal}
        text={t('messageModal.deleteWarningText')}
        onSuccess={async () => {
          const res = await dispatch(deleteMessage(modalState.target));
          if (res.status === 200) {
            setWarningModal({ shown: false });
            setModalState({ shown: false, target: null });
            enqueueSnackbar(t('snackbarTexts.messageDeleted'), { variant: 'success' });
          } else {
            enqueueSnackbar(t('snackbarTexts.errorOccured'), { variant: 'error' });
          }
        }}
        onDecline={() => setWarningModal({ shown: false })}
      />
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={createMode ? handleCreate : handleEdit}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Input
              name="title"
              label={t('formLabels.title')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              error={touched.title && errors.title}
            />
            <Select
              label={t('formLabels.sendTo')}
              onChange={(e) => setFieldValue('sendTo', e.target.value)}
              options={sendToOptions}
              value={values.sendTo}
            />
            {values?.sendTo === 'selected' && (
              <UserChoose setFieldValue={setFieldValue} addedUsers={values?.users} error={touched.users && errors.users} />
            )}
            <Input
              name="text"
              label={t('formLabels.text')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.text}
              error={touched.text && errors.text}
              multiline
              rows={7}
            />
            <CenterBox>
              {createMode ? (
                <Button type="submit">{t('messageModal.send')}</Button>
              ) : (
                <>
                  <Button style={{ marginRight: 10 }} type="submit">
                    {t('messageModal.save')}
                  </Button>
                  <Button onClick={() => setWarningModal({ shown: true })} color="danger">
                    {t('messageModal.delete')}
                  </Button>
                </>
              )}
            </CenterBox>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const UserPill = ({ handleRemove, user: { label, value } }) => {
  return (
    <UserPillWrapper>
      <span>{label}</span>
      <IconButton style={{ marginLeft: 3 }} size="small" onClick={() => handleRemove(value)}>
        <Close fontSize="small" />
      </IconButton>
    </UserPillWrapper>
  );
};

const UserChoose = ({ setFieldValue, addedUsers, error }) => {
  const dispatch = useDispatch();
  const { userOptions } = useSelector(({ auth }) => auth);
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const handleRemove = (id) => {
    setFieldValue(
      'users',
      addedUsers.filter(({ value }) => value !== id),
    );
  };
  const debounced = useDebounce(searchTerm);
  useEffect(() => {
    dispatch(getUserOptions(debounced, { alreadyAdded: addedUsers?.map(({ value }) => value) }));
  }, [debounced, addedUsers]);
  return (
    <UserChooseWrapper>
      <Label>{t('formLabels.sendToOptions.selected')}:</Label>
      <AddedUsers>
        {addedUsers?.map((user) => (
          <UserPill key={user?.value} user={user} handleRemove={handleRemove} />
        ))}
      </AddedUsers>
      <Select
        label={t('formLabels.addUser')}
        variant="react-select"
        onInputChange={(v) => setSearchTerm(v)}
        onChange={(user) => setFieldValue('users', [...addedUsers, user])}
        options={formatUserOptions(userOptions)}
        value={{}}
        noOptionsMessage={() => t('noDataLabels.noUsers')}
        error={error}
      />
    </UserChooseWrapper>
  );
};

export default MessageModal;

const Form = styled.form`
  width: 80vw;
  padding: 30px 0;
  ${({ theme }) => `${theme.mq.desktop}{
    width: 600px;
  }`}
`;

const UserChooseWrapper = styled.div``;

const Label = styled.p`
  margin-bottom: 5px;
`;

const AddedUsers = styled.div`
  display: flex;
  margin-bottom: 5px;
  flex-wrap: wrap;
`;

const UserPillWrapper = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 14px;
  margin-bottom: 10px;
  margin-right: 5px;
  padding: 4px 7px 4px 10px;
  border-radius: 4px;
  background: ${({ theme }) => theme.background};
  cursor: default;
  user-select: none;
  box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 10%);
`;

const CenterBox = styled.div`
  text-align: center;
`;
