import { useRef } from 'react';
import styled from 'styled-components';
import Button from '../../shared/Button';
import Input from '../../shared/Input';
import DataPaper from '../../shared/DataPaper';
import ChartPaper from '../../shared/ChartPaper';
import { theme } from '../../shared/theme';
import {
  Collapse,
  ClickAwayListener,
  Grow,
  Popper,
  MenuItem,
  MenuList,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  ListItemText,
  Switch,
  ListItemSecondaryAction,
} from '@material-ui/core';
import Paper from '../../shared/Paper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LaunchIcon from '@material-ui/icons/Launch';
import FilterListIcon from '@material-ui/icons/FilterList';
import EditIcon from '@material-ui/icons/Edit';
import StatusPill from '../../shared/StatusPill';
import EditDeviceModal from './modals/EditDeviceModal';
import dayjs from 'dayjs';
import Select from '../../shared/Select';
import { dateFilterOptions, phaseFilterOptions } from '../../shared/consts';
import { calculateCo2Emission, calculateMoneySaved } from '../../utils/objectsFormating';
import Spinner from '../../shared/Spinner';
import { ReactComponent as NotFoundImg } from '../../shared/assets/not-found.svg';
import { Link } from 'react-router-dom';
import { dataSourceOptions } from '../../shared/consts';
import { ReactComponent as ProductionIcon } from '../../shared/assets/production.svg';
import { ReactComponent as UsageIcon } from '../../shared/assets/usage.svg';
import { ReactComponent as SavingsIcon } from '../../shared/assets/savings.svg';
import { ReactComponent as DeviceIcon } from '../../shared/assets/active_devices.svg';
import { ReactComponent as ProcessorIcon } from '../../shared/assets/processor.svg';
import { ReactComponent as ConnectionIcon } from '../../shared/assets/connection.svg';
import { ReactComponent as CalendarIcon } from '../../shared/assets/calendar.svg';
import { ReactComponent as ServerIcon } from '../../shared/assets/server.svg';
import { ReactComponent as ThunderIcon } from '../../shared/assets/thunder.svg';
import { ReactComponent as AlertIcon } from '../../shared/assets/alert.svg';
import { ReactComponent as SunIcon } from '../../shared/assets/sun.svg';
import { ReactComponent as LocationIcon } from '../../shared/assets/location2.svg';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import i18n from '../../i18n';

const productionDateRanges = [
  {
    label: i18n.t('consts.productionDateRanges.1h'),
    value: '1h',
  },
  {
    label: i18n.t('consts.productionDateRanges.2h'),
    value: '2h',
  },
  {
    label: i18n.t('consts.productionDateRanges.3h'),
    value: '3h',
  },
  {
    label: i18n.t('consts.productionDateRanges.6h'),
    value: '6h',
  },
  {
    label: i18n.t('consts.productionDateRanges.daily'),
    value: 'daily',
  },
  {
    label: i18n.t('consts.productionDateRanges.weekly'),
    value: 'weekly',
  },
  {
    label: i18n.t('consts.productionDateRanges.mounthly'),
    value: 'mounthly',
  },
];

const renderPowerFactorWarning = (powerFactorWarningLevel = {}) => {
  const disc = {
    first: i18n.t('consts.phasesDisc.first'),
    second: i18n.t('consts.phasesDisc.second'),
    third: i18n.t('consts.phasesDisc.third'),
  };
  let variant = 'none',
    texts = [];
  if (Object.values(powerFactorWarningLevel).some(({ level }) => level > 0)) {
    variant = 'warning';
  }
  if (Object.values(powerFactorWarningLevel).some(({ level }) => level > 1)) {
    variant = 'danger';
  }
  for (const [key, { level, value }] of Object.entries(powerFactorWarningLevel)) {
    if (level > 0) {
      texts.push(`${value} ${i18n.t('singleDevicePage.deviceDetails.onPhase')} ${disc[key]}`);
    }
  }
  const text = texts.join(', ');
  switch (variant) {
    case 'warning':
      return (
        <WarningPill data-tip data-for="popup-device-warning" color="warning">
          {i18n.t('singleDevicePage.deviceDetails.lowPowerFactor')}
          <StyledReactTooltip
            delayShow={500}
            id="popup-device-warning"
            getContent={() => (
              <p>
                {i18n.t('singleDevicePage.deviceDetails.lowPowerFactor')} {text}. {i18n.t('singleDevicePage.deviceDetails.powerFactorWarningText')}
              </p>
            )}
            effect="solid"
          />
        </WarningPill>
      );
    case 'danger':
      return (
        <WarningPill data-tip data-for="popup-device-warning" color="danger">
          {i18n.t('singleDevicePage.deviceDetails.incorrectPowerFactor')}
          <StyledReactTooltip
            delayShow={500}
            id="popup-device-warning"
            getContent={() => (
              <p>
                {i18n.t('singleDevicePage.deviceDetails.incorrectPowerFactor')} {text}.{' '}
                {i18n.t('singleDevicePage.deviceDetails.powerFactorWarningText')}
              </p>
            )}
            effect="solid"
          />
        </WarningPill>
      );
    default:
      return '';
  }
};

const SingleDeviceView = ({
  filters,
  handleFiltersChange,
  isFiltersCollapsed,
  handleFiltersToggle,
  isExportOverlayOpen,
  setIsExportOverlayOpen,
  handleExportPDF,
  isEditDeviceModal,
  setEditDeviceModal,
  currentDevice,
  editDevice,
  handleExportCSV,
  chartData,
  boxesData,
  previousValues,
  switchesState,
  dispatchSwitchesState,
  filterErrors,
  deviceLoading,
  newestWeatherPing,
  chartWeatherData,
  statsLoading,
  deviceError,
  selfConsumption,
  emailConsent,
  userRole,
}) => {
  const { t } = useTranslation();
  const printRef = useRef();
  return !deviceLoading ? (
    !currentDevice?._id && deviceError !== null ? (
      <NoDeviceFound>
        <NotFoundImg />
        <p>{t('singleDevicePage.deviceNotFound')}</p>
        <Button component={Link} to="/devices">
          {t('singleDevicePage.returnBtn')}
        </Button>
      </NoDeviceFound>
    ) : (
      <Wrapper>
        <EditDeviceModal
          isEditDeviceModal={isEditDeviceModal}
          setEditDeviceModal={setEditDeviceModal}
          currentDevice={currentDevice}
          editDevice={editDevice}
        />
        <PageTitle>
          {t('singleDevicePage.title')} - {currentDevice?.name}
        </PageTitle>
        <Actions>
          <ButtonWrapper>
            <Button
              data-cy="export-btn"
              variant="outlined"
              color="primary"
              startIcon={<StyledLaunchIcon />}
              endIcon={<StyledArrowDropDownIcon $active={isExportOverlayOpen} $iconColor={theme.primary} />}
              onClick={() => {
                setIsExportOverlayOpen(true);
              }}
            >
              {t('btns.export')}
            </Button>
            <StyledPopper open={isExportOverlayOpen} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={() => setIsExportOverlayOpen(false)}>
                      <MenuList autoFocusItem={open} id="menu-list-grow">
                        <StyledMenuItem
                          onClick={() => {
                            setIsExportOverlayOpen(false);
                            handleExportPDF(printRef.current);
                          }}
                        >
                          {t('btns.exportOptions.asPdf')}
                        </StyledMenuItem>
                        <StyledMenuItem
                          onClick={() => {
                            setIsExportOverlayOpen(false);
                            handleExportCSV();
                          }}
                        >
                          {t('btns.exportOptions.asCsv')}
                        </StyledMenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </StyledPopper>
          </ButtonWrapper>
          <Button
            data-cy="toggle-filters-btn"
            color="secondary"
            onClick={handleFiltersToggle}
            startIcon={<StyledFilterListIcon />}
            endIcon={<StyledArrowDropDownIcon $active={isFiltersCollapsed} />}
          >
            {t('btns.filters')}
          </Button>
          <Button color="primary" startIcon={<EditIcon />} onClick={() => setEditDeviceModal(true)} data-cy="edit-device-btn">
            {t('btns.editData')}
          </Button>
        </Actions>
        <Collapse in={isFiltersCollapsed}>
          <Filters>
            <Select
              name="dateRange"
              options={dateFilterOptions}
              label={t('formLabels.dateRange')}
              value={filters.dateRange}
              onChange={(e) => handleFiltersChange('dateRange', e.target.value)}
            />
            {filters.dateRange === 'custom' && (
              <>
                {' '}
                <Input
                  name="dateFrom"
                  label={t('formLabels.dateFrom')}
                  type="date"
                  onChange={(e) => handleFiltersChange('dateFrom', e.target.value)}
                  value={dayjs(filters.dateFrom).format('YYYY-MM-DD')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Input
                  name="dateTo"
                  label={t('formLabels.dateTo')}
                  type="date"
                  onChange={(e) => handleFiltersChange('dateTo', e.target.value)}
                  value={dayjs(filters.dateTo).format('YYYY-MM-DD')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <ErrorMsg>{filterErrors}</ErrorMsg>
              </>
            )}
            {currentDevice?.dataSource !== 'solarEdge' && (
              <Select
                name="phase"
                options={phaseFilterOptions}
                label={t('formLabels.phase')}
                value={filters.phase}
                onChange={(e) => handleFiltersChange('phase', e.target.value)}
              />
            )}
          </Filters>
        </Collapse>
        <div ref={printRef}>
          <DataGrid>
            <DataPaper
              title={t('dataBoxes.producedEnergy.title')}
              icon={<ProductionIcon />}
              color={theme.primary}
              unit="kWh"
              value={boxesData.totalProduction ?? 0}
              maxValue={boxesData.totalProduction > previousValues.production ? boxesData.totalProduction : previousValues.production}
              dateRange={filters?.dateRange}
              previousValue={previousValues.production}
              popupContent={() => (
                <div dangerouslySetInnerHTML={{ __html: t('dataBoxes.producedEnergy.tooltip', { interpolation: { escapeValue: false } }) }} />
              )}
            />
            <DataPaper
              title={t('dataBoxes.usedEnergy.title')}
              icon={<UsageIcon />}
              color={theme.thirdary}
              unit="kWh"
              value={boxesData.totalUsage ?? 0}
              maxValue={boxesData.totalUsage > previousValues.usage ? boxesData.totalUsage : previousValues.usage}
              dateRange={filters?.dateRange}
              previousValue={previousValues.usage}
              selfConsumption={selfConsumption.total ?? 0}
              popupContent={() => (
                <div dangerouslySetInnerHTML={{ __html: t('dataBoxes.usedEnergy.tooltip', { interpolation: { escapeValue: false } }) }} />
              )}
            />
            <DataPaper
              title={t('dataBoxes.savings.title')}
              icon={<SavingsIcon />}
              color={theme.fourth}
              popupContent={() => (
                <div dangerouslySetInnerHTML={{ __html: t('dataBoxes.savings.tooltip', { interpolation: { escapeValue: false } }) }} />
              )}
            >
              <DataPaperValue>
                <span>{t('dataBoxes.savings.money')}:</span> {calculateMoneySaved(boxesData.totalProduction, currentDevice?.energyPrice)} zł
              </DataPaperValue>
              <DataPaperValue>
                <span>{t('dataBoxes.savings.emission')}:</span> {calculateCo2Emission(boxesData.totalProduction)} kg
              </DataPaperValue>
            </DataPaper>
            <DataPaper
              title={t('dataBoxes.atmosphericConditions.title')}
              icon={<SunIcon />}
              color={theme.secondary}
              popupContent={() => (
                <div dangerouslySetInnerHTML={{ __html: t('dataBoxes.atmosphericConditions.tooltip', { interpolation: { escapeValue: false } }) }} />
              )}
            >
              <WeatherGrid>
                <DataPaperValue>
                  <span>{t('dataBoxes.atmosphericConditions.temperature')}:</span>{' '}
                  {newestWeatherPing.temperature || newestWeatherPing.temperature === 0 ? `${newestWeatherPing.temperature}°C` : '-'}
                </DataPaperValue>
                <DataPaperValue>
                  <span>{t('dataBoxes.atmosphericConditions.humidity')}:</span>{' '}
                  {newestWeatherPing.humidity || newestWeatherPing.humidity === 0 ? `${newestWeatherPing.humidity}%` : '-'}
                </DataPaperValue>
                <DataPaperValue>
                  <span>{t('dataBoxes.atmosphericConditions.cloudCover')}:</span>{' '}
                  {newestWeatherPing.cloudCover || newestWeatherPing.cloudCover === 0 ? `${newestWeatherPing.cloudCover}%` : '-'}
                </DataPaperValue>
                <DataPaperValue>
                  <span>{t('dataBoxes.atmosphericConditions.uvIndex')}:</span>{' '}
                  {newestWeatherPing.uvIndex || newestWeatherPing.uvIndex === 0 ? `${newestWeatherPing.uvIndex}` : '-'}
                </DataPaperValue>
              </WeatherGrid>
              <DataPaperDate>
                {t('dataBoxes.atmosphericConditions.lastUpdate')}:{' '}
                {newestWeatherPing?.time ? dayjs(newestWeatherPing?.time).format('DD.MM.YYYY HH:mm') : t('dataBoxes.atmosphericConditions.none')}
              </DataPaperDate>
            </DataPaper>
          </DataGrid>
          <ChartPaper
            showDataSwitch={true}
            data={chartData}
            loadingState={statsLoading}
            weatherData={chartWeatherData}
            selfConsumption={selfConsumption.chart}
          />
          <DeviceDataWrapper>
            <UserDataPaper title={t('singleDevicePage.deviceDetails.title')} data-cy="device-data">
              <List>
                <StyledListItem>
                  <StyledListItemIcon title={t('singleDevicePage.deviceDetails.name')}>
                    <DeviceIcon />
                  </StyledListItemIcon>
                  <ListItemText primary={currentDevice?.name} />
                </StyledListItem>
                <Divider />
                <StyledListItem>
                  <StyledListItemIcon title={t('singleDevicePage.deviceDetails.identifier')}>
                    <ProcessorIcon />
                  </StyledListItemIcon>
                  <ListItemText
                    primary={
                      currentDevice?.dataSource === 'solarEdge'
                        ? `${currentDevice?.solarEdgeSiteId} / ${currentDevice?.identifier}`
                        : currentDevice?.identifier
                    }
                  />
                </StyledListItem>
                <Divider />
                <StyledListItem>
                  <StyledListItemIcon title={t('singleDevicePage.deviceDetails.localization')}>
                    <LocationIcon />
                  </StyledListItemIcon>
                  <ListItemText
                    primary={`${currentDevice?.address?.city}${currentDevice?.address?.postalCode ? `, ${currentDevice?.address?.postalCode}` : ''}`}
                  />
                </StyledListItem>
                <Divider />
                <StyledListItem>
                  <StyledListItemIcon $iconSize="25px" title={t('singleDevicePage.deviceDetails.status')}>
                    <ConnectionIcon />
                  </StyledListItemIcon>
                  <ListItemText
                    primary={
                      <StatusPill color={currentDevice?.dataSource === 'mae' ? (currentDevice?.status === 'ON' ? 'success' : 'danger') : 'disabled'}>
                        {currentDevice?.dataSource === 'mae'
                          ? currentDevice?.status === 'ON'
                            ? t('statusPill.connected')
                            : t('statusPill.disconnected')
                          : t('noDataLabels.noData')}
                      </StatusPill>
                    }
                  />
                </StyledListItem>
                <Divider />
                <StyledListItem>
                  <StyledListItemIcon title={t('singleDevicePage.deviceDetails.creationData')}>
                    <CalendarIcon />
                  </StyledListItemIcon>
                  <ListItemText primary={dayjs(currentDevice?.createdDate).format('DD.MM.YYYY')} />
                </StyledListItem>
                <Divider />
                <StyledListItem data-cy="device-warnings">
                  <StyledListItemIcon $iconSize="30px" title={t('singleDevicePage.deviceDetails.dataSource')}>
                    <ServerIcon />
                  </StyledListItemIcon>
                  <StyledListItemText>{dataSourceOptions.find(({ value }) => value === currentDevice?.dataSource)?.label}</StyledListItemText>
                </StyledListItem>
                <Divider />
                <StyledListItem data-cy="device-source">
                  <StyledListItemIcon $iconSize="30px" title={t('singleDevicePage.deviceDetails.phase')}>
                    <ThunderIcon />
                  </StyledListItemIcon>
                  <StyledListItemText>
                    {currentDevice?.isThreePhases ? t('singleDevicePage.deviceDetails.threePhase') : t('singleDevicePage.deviceDetails.singlePhase')}
                  </StyledListItemText>
                </StyledListItem>
                <Divider />
                <StyledListItem data-cy="device-warnings">
                  <StyledListItemIcon title={t('singleDevicePage.deviceDetails.alarms')}>
                    <AlertIcon />
                  </StyledListItemIcon>
                  <StyledListItemText>
                    {currentDevice?.isFire && <WarningPill>{t('singleDevicePage.deviceDetails.warnings.fire')}</WarningPill>}
                    {currentDevice?.isFlooded && <WarningPill>{t('singleDevicePage.deviceDetails.warnings.flooded')}</WarningPill>}
                    {currentDevice?.damage?.isDamaged && (
                      <WarningPill>
                        {t('singleDevicePage.deviceDetails.warnings.isDamaged')}
                        {currentDevice?.damage?.description ? ` (${currentDevice?.damage?.description})` : ''}
                      </WarningPill>
                    )}
                    {renderPowerFactorWarning(currentDevice?.powerFactorWarningLevel)}
                    {!currentDevice?.isFlooded &&
                      !currentDevice?.isFire &&
                      !currentDevice?.damage?.isDamaged &&
                      Object.values(currentDevice?.powerFactorWarningLevel ?? {}).every(({ level }) => level === 0) &&
                      t('singleDevicePage.deviceDetails.warnings.none')}
                  </StyledListItemText>
                </StyledListItem>
              </List>
            </UserDataPaper>
            <UserDataPaper title={t('singleDevicePage.deviceSettings.title')}>
              <List>
                <SettingsItem>
                  <SettingText>
                    <OptionWrapper>
                      {t('singleDevicePage.deviceSettings.energyPrice')}
                      <OptionInput
                        name="energyPriceValue"
                        step={0.1}
                        type="number"
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const val = e.target.value !== '' ? Math.abs(e.target.value) : '';
                          dispatchSwitchesState({
                            type: 'set_energy_price',
                            payload: val,
                          });
                        }}
                        onBlur={() => {
                          dispatchSwitchesState({
                            type: 'send_energy_price',
                            payload: {
                              deviceId: currentDevice?._id,
                              callback: editDevice,
                            },
                          });
                        }}
                        value={switchesState.energyPrice}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      PLN
                    </OptionWrapper>
                  </SettingText>
                </SettingsItem>
                <Divider />
                <SettingsItem>
                  <SettingText>
                    <OptionWrapper>
                      {t('singleDevicePage.deviceSettings.productionLimitUpper')}
                      <span>
                        {' '}
                        <OptionInput
                          name="limitValue"
                          label="Wartość"
                          step={0.1}
                          type="number"
                          onKeyDown={(e) => {
                            if (e.key === '-') {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            const val = e.target.value !== '' ? Math.abs(e.target.value) : '';
                            dispatchSwitchesState({
                              type: 'set_limit_notification',
                              payload: {
                                isActive: switchesState.limitNotification?.isActive,
                                range: switchesState.limitNotification?.range,
                                value: val,
                              },
                            });
                          }}
                          onBlur={() => {
                            dispatchSwitchesState({
                              type: 'send_limit_notification',
                              payload: {
                                isActive: switchesState.limitNotification?.isActive,
                                value: switchesState.limitNotification?.value,
                                range: switchesState.limitNotification?.range,
                                deviceId: currentDevice?._id,
                                callback: editDevice,
                              },
                            });
                          }}
                          value={switchesState.limitNotification?.value}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // error={touched.identifier && errors.identifier}
                        />
                        {t('singleDevicePage.deviceSettings.kwhDuringPeriod')}
                        <OptionSelect
                          onChange={({ target: { value } }) => {
                            dispatchSwitchesState({
                              type: 'send_limit_notification',
                              payload: {
                                isActive: switchesState.limitNotification?.isActive,
                                value: switchesState.limitNotification?.value,
                                range: value,
                                deviceId: currentDevice?._id,
                                callback: editDevice,
                              },
                            });
                          }}
                          hideError={true}
                          options={productionDateRanges}
                          value={switchesState.limitNotification?.range ?? 'daily'}
                        />
                      </span>
                    </OptionWrapper>
                  </SettingText>
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={(e) => {
                        dispatchSwitchesState({
                          type: 'send_limit_notification',
                          payload: {
                            deviceId: currentDevice?._id,
                            callback: editDevice,
                            isActive: e.target.checked,
                            value: switchesState.limitNotification?.value,
                            range: switchesState.limitNotification?.range,
                          },
                        });
                      }}
                      checked={switchesState.limitNotification?.isActive}
                    />
                  </ListItemSecondaryAction>
                </SettingsItem>
                <Divider />
                <SettingsItem>
                  <SettingText>
                    <OptionWrapper>
                      {t('singleDevicePage.deviceSettings.productionLimitBelow')}{' '}
                      <span>
                        {t('singleDevicePage.deviceSettings.below')}
                        <OptionInput
                          name="daily_productivity"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          step={0.1}
                          type="number"
                          onKeyDown={(e) => {
                            if (e.key === '-') {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            const val = e.target.value !== '' ? Math.abs(e.target.value) : '';
                            dispatchSwitchesState({
                              type: 'set_productivity_notification',
                              payload: {
                                isActive: switchesState.productivityNotification?.isActive,
                                range: switchesState.productivityNotification?.range,
                                value: val,
                              },
                            });
                          }}
                          onBlur={() => {
                            dispatchSwitchesState({
                              type: 'send_productivity_notification',
                              payload: {
                                isActive: switchesState.productivityNotification?.isActive,
                                value: switchesState.productivityNotification?.value,
                                range: switchesState.productivityNotification?.range,
                                deviceId: currentDevice?._id,
                                callback: editDevice,
                              },
                            });
                          }}
                          value={switchesState.productivityNotification?.value}
                        />
                        {t('singleDevicePage.deviceSettings.kwhDuringPeriod')}
                        <OptionSelect
                          onChange={({ target: { value } }) => {
                            dispatchSwitchesState({
                              type: 'send_productivity_notification',
                              payload: {
                                isActive: switchesState.productivityNotification?.isActive,
                                value: switchesState.productivityNotification?.value,
                                range: value,
                                deviceId: currentDevice?._id,
                                callback: editDevice,
                              },
                            });
                          }}
                          hideError={true}
                          options={productionDateRanges}
                          value={switchesState.productivityNotification?.range ?? 'daily'}
                        />
                      </span>
                    </OptionWrapper>
                  </SettingText>
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={(e) =>
                        dispatchSwitchesState({
                          type: 'send_productivity_notification',
                          payload: {
                            isActive: e.target.checked,
                            value: switchesState.productivityNotification?.value,
                            range: switchesState.productivityNotification?.range,
                            deviceId: currentDevice?._id,
                            callback: editDevice,
                          },
                        })
                      }
                      checked={switchesState.productivityNotification?.isActive}
                    />
                  </ListItemSecondaryAction>
                </SettingsItem>
                <Divider />
                <SettingsItem>
                  <SettingText>{t('singleDevicePage.deviceSettings.noDataReading')}</SettingText>
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={(e) =>
                        dispatchSwitchesState({
                          type: 'send_no_reading_data_notification',
                          payload: {
                            value: e.target.checked,
                            deviceId: currentDevice?._id,
                            callback: editDevice,
                          },
                        })
                      }
                      checked={switchesState.noReadingDataNotification}
                    />
                  </ListItemSecondaryAction>
                </SettingsItem>
                <Divider />
                <SettingsItem>
                  <SettingText>{t('singleDevicePage.deviceSettings.voltageJumps')}</SettingText>
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={(e) =>
                        dispatchSwitchesState({
                          type: 'send_voltage_change_notification',
                          payload: {
                            value: e.target.checked,
                            deviceId: currentDevice?._id,
                            callback: editDevice,
                          },
                        })
                      }
                      checked={switchesState.voltageChangeNotification}
                    />
                  </ListItemSecondaryAction>
                </SettingsItem>
                <Divider />
                <SettingsItem>
                  <SettingText disabled={!emailConsent && userRole === 'user'}>{t('singleDevicePage.deviceSettings.monthlyReport')}</SettingText>
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      disabled={!emailConsent && userRole === 'user'}
                      onChange={(e) =>
                        dispatchSwitchesState({
                          type: 'send_monthly_production_notification',
                          payload: {
                            value: e.target.checked,
                            deviceId: currentDevice?._id,
                            callback: editDevice,
                          },
                        })
                      }
                      checked={switchesState.monthlyProductionNotification}
                    />
                  </ListItemSecondaryAction>
                </SettingsItem>
              </List>
            </UserDataPaper>
          </DeviceDataWrapper>
        </div>
      </Wrapper>
    )
  ) : (
    <Spinner component="div" />
  );
};

export default SingleDeviceView;

const Wrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  ${({ theme }) => `${theme.mq.tablet}{
      padding: 45px 0 80px;
  }`}
`;

const PageTitle = styled.h1``;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  flex-wrap: wrap;
  button {
    margin-left: 15px;
    margin-bottom: 10px;
  }
  ${({ theme }) => `${theme.mq.tablet}{
      margin-top: -50px;
  }`}
`;

const Filters = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  > div {
    width: calc(50% - 5px);
    &:nth-child(2) {
      margin-right: 10px;
    }
    &:first-child,
    &:last-child {
      width: 100%;
    }
  }
  margin-top: 25px;
  ${({ theme }) => `${theme.mq.tablet}{
      margin-top: 10px;
      >div{
        width: calc(25% - 7.5px);
        &:first-child, &:last-child {
          width: calc(25% - 7.5px);
        }
        &:not(:last-child){
          margin-right:10px;
        }
      }
  }`}
`;

const DataGrid = styled.div`
  display: grid;
  margin: 15px 0 35px;
  grid-template-columns: 1fr;
  gap: 10px;
  ${({ theme }) => `${theme.mq.tablet}{
      gap: 30px;
  }`}
  ${({ theme }) => `${theme.mq.small}{
      grid-template-columns: 1fr 1fr;
  }`}
  ${({ theme }) => `${theme.mq.desktop}{
      grid-template-columns: 1fr 1fr 1fr 1fr;
  }`}
`;

const StyledLaunchIcon = styled(LaunchIcon)`
  width: 20px;
  margin-right: 5px;
  path {
    fill: ${({ theme }) => theme.primary};
  }
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  width: 20px;
  path {
    fill: ${({ $iconColor }) => ($iconColor ? $iconColor : '#fff')};
  }
  transition: 300ms;
  ${({ $active }) => $active && `transform: rotate(180deg);`}
`;

const StyledFilterListIcon = styled(FilterListIcon)`
  width: 20px;
  margin-right: 5px;
`;

const ButtonWrapper = styled.div`
  position: relative;
`;

const StyledPopper = styled(Popper)`
  position: absolute !important;
  right: 0;
  top: 50px !important;
  z-index: 9;
  > div {
    padding: 10px 15px;
  }
  li:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.background};
  }
`;

const UserDataPaper = styled(Paper)``;

const DeviceDataWrapper = styled.div`
  display: block;
  margin-top: 30px;
  > div:last-child {
    margin-top: 30px;
  }
  ${({ theme }) => `${theme.mq.desktop}{
     display: grid;
     grid-gap: 30px;
     grid-template-columns: 1fr 1fr;
     >div:last-child{
      margin-top: 0;
    }
  }`}
`;

const OptionWrapper = styled.div`
  span {
    > div {
      display: inline;
    }
  }
  ${({ theme }) => `${theme.mq.tablet}{
      span {
        white-space: nowrap;
      }
  }`}
`;

const OptionInput = styled.input`
  width: 70px;
  min-width: 70px;
  height: 25px;
  font-size: 1rem;
  padding: 0 5px;
  margin: 5px;
  background: ${({ theme }) => theme.background};
  border: none;
  border-bottom: 1px solid;
  outline: none;
  border-color: ${({ theme }) => (theme.paperbackground === '#fff' ? '#334151' : '#e9e9e9')};
  color: ${({ theme }) => theme.primaryText};
  &:focus {
    border-color: ${({ theme }) => theme.primary};
  }
`;

const OptionSelect = styled(Select)`
  width: 125px;
  height: 25px;
  margin: 5px;
  padding: 0 5px;
  background: ${({ theme }) => theme.background};
`;

const StyledListItem = styled(ListItem)`
  display: grid;
  grid-template-columns: 40px 1fr;
  column-gap: 10px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 0;
  display: flex;
  justify-content: center;
  svg {
    width: ${({ $iconSize }) => ($iconSize ? $iconSize : '40px')};
    height: ${({ $iconSize }) => ($iconSize ? $iconSize : '40px')};
    ${({ theme }) =>
      theme.background === '#404040' &&
      `
      path {
        fill: ${theme.defaultIcon}!important;
      }`}
  }
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.primaryText};
`;

const ErrorMsg = styled.p`
  position: absolute;
  color: ${({ theme }) => theme.danger};
  font-size: 13px;
  bottom: 0;
`;

const SettingsItem = styled.div`
  position: relative;
  padding: 16px 90px 16px 16px;
  .MuiSwitch-root {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;

const SettingText = styled.div`
  display: flex;
  align-items: flex-end;
  ${({ disabled }) => disabled && 'color: #ccc'}
`;

const DataPaperValue = styled.p`
  font-weight: 500;
  font-size: 16px;
  span {
    color: ${({ theme }) => theme.secondaryText};
  }
  &:nth-child(3) {
    margin: 10px 0 5px;
  }
`;

const DataPaperDate = styled.p`
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondaryText};
`;

const StyledListItemText = styled(ListItemText)`
  white-space: break-spaces;
`;

const WarningPill = styled.div`
  display: inline-block;
  background-color: ${({ theme, color }) => (color === 'warning' ? theme.secondary : theme.danger)};
  color: #fff;
  padding: 3px 10px;
  margin-right: 7.5px;
  cursor: default;
`;

const NoDeviceFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    margin-top: 30px;
    max-width: 700px;
    max-height: 500px;
  }
  p {
    margin: 30px 0 20px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
`;

const WeatherGrid = styled.div`
  display: grid;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 15px;
  row-gap: 5px;
  > p {
    &:nth-child(3) {
      margin: 0;
    }
  }
`;

const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 300px;
  background-color: #222222ee;
  p {
    color: #fff;
    margin-bottom: 3px;
  }
`;
