import styled from 'styled-components';
import Button from '../../shared/Button';
import Table from '../../shared/Table';
import StatusPill from '../../shared/StatusPill';
import Paper from '../../shared/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Collapse, TableCell, TableRow, IconButton, TablePagination } from '@material-ui/core';
import AddUserModal from './modals/AddUserModal';
import EditUserModal from './modals/EditUserModal';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Input from '../../shared/Input';
import { calculateCo2Emission } from '../../utils/objectsFormating';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const UsersView = ({
  tableCollapsedItems,
  handleTableCollapse,
  isAddUserModal,
  setIsAddUserModal,
  createUser,
  createAdmin,
  usersList,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  count,
  editUserModal,
  setEditUserModal,
  editUser,
  tableSearchTerm,
  setTableSearchTerm,
  debouncedTableSearchTerm,
  usersLoading,
  devicesEnergyProduction,
  usersEnergyProduction,
  devicesWeatherData,
  deleteUser,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <AddUserModal
        isAddUserModal={isAddUserModal}
        setIsAddUserModal={setIsAddUserModal}
        createUser={createUser}
        createAdmin={createAdmin}
        editUser={editUser}
      />
      <EditUserModal
        editUserModal={editUserModal}
        setEditUserModal={setEditUserModal}
        editUser={editUser}
        usersList={usersList}
        deleteUser={deleteUser}
      />
      <PageTitle>{t('usersPage.title')}</PageTitle>
      <Actions>
        <Button data-cy="add-user-btn" color="primary" onClick={() => setIsAddUserModal(true)} startIcon={<StyledPersonAddIcon />}>
          {t('usersPage.addUserBtn')}
        </Button>
      </Actions>
      <StyledPaper>
        <TableSearchWrapper>
          <Input
            background="transparent"
            name="tableSearch"
            label={t('tableLabels.searchUser')}
            value={tableSearchTerm ?? ''}
            onChange={(e) => setTableSearchTerm(e.target.value)}
          />
        </TableSearchWrapper>
        <StyledTable
          rowCheckbox={false}
          items={usersList}
          itemsLength={usersList.length}
          searchTerm={debouncedTableSearchTerm}
          loadingState={usersLoading}
          noItemsPlaceholder={() => (
            <TableNoDevicesPlaceholder>
              <p>{t('noDataLabels.noUsers')}</p>
              <Button onClick={() => setIsAddUserModal(true)} color="primary">
                {t('usersPage.addUserBtn')}
              </Button>
            </TableNoDevicesPlaceholder>
          )}
          columns={[
            {
              label: t('tableLabels.name'),
              width: '18%',
            },
            {
              label: t('tableLabels.email'),
            },
            {
              label: t('tableLabels.status'),
            },
            {
              label: t('tableLabels.deviceNumber'),
            },
            {
              label: t('tableLabels.energyProduction30days'),
            },
            {
              label: t('tableLabels.totalSavedEmission'),
            },
            {
              label: '',
            },
          ]}
        >
          {usersList?.length > 0 ? (
            usersList?.map(({ _id: userId, name, surname, active, email, devices }) => {
              const notDeletedDevices = devices.filter(({ deleted }) => deleted === false);
              return (
                <>
                  <TableRow key={userId} data-cy="user-item">
                    <TableCell>
                      <StyledLink to={`/users/${userId}`}>
                        <NameWrapper>
                          {name} {surname} <StyledOpenInNewIcon />
                        </NameWrapper>
                      </StyledLink>
                    </TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>
                      <StatusPill color={active ? 'success' : 'danger'}>{active ? t('statusPill.active') : t('statusPill.blocked')}</StatusPill>
                    </TableCell>
                    <TableCell>{notDeletedDevices?.length}</TableCell>
                    <StyledTableCell $bigPadding={!notDeletedDevices?.length > 0}>
                      {usersEnergyProduction[userId] ? usersEnergyProduction[userId].toFixed(3) : 0} kWh
                    </StyledTableCell>
                    <StyledTableCell $bigPadding={!notDeletedDevices?.length > 0}>
                      {calculateCo2Emission(usersEnergyProduction[userId]) ? calculateCo2Emission(usersEnergyProduction[userId])?.toFixed(3) : 0} kg
                    </StyledTableCell>
                    <TableCell>
                      <FlexBox>
                        <IconButton data-cy="edit-user-btn" onClick={() => setEditUserModal({ shown: true, userId })}>
                          <StyledEditIcon />
                        </IconButton>
                        {notDeletedDevices?.length > 0 && (
                          <IconButton onClick={() => handleTableCollapse(userId)}>
                            <StyledKeyboardArrowDownIcon $active={tableCollapsedItems[userId]} />
                          </IconButton>
                        )}
                      </FlexBox>
                    </TableCell>
                  </TableRow>
                  {notDeletedDevices?.length > 0 && (
                    <UserDevicesTableRow key={`devices_${userId}`}>
                      <NoPaddingCell colSpan={99}>
                        <LinesTableCell>
                          <YLine active={tableCollapsedItems[userId]} itemsCount={notDeletedDevices.length} />
                          {notDeletedDevices.map(({ _id }, i) => (
                            <XLine key={_id} index={i} />
                          ))}
                        </LinesTableCell>
                        <UserDevicesTableCell>
                          <Collapse in={tableCollapsedItems[userId]}>
                            <Table
                              rowCheckbox={false}
                              columns={[
                                {
                                  label: t('tableLabels.name'),
                                },
                                {
                                  label: t('tableLabels.status'),
                                },
                                {
                                  label: t('tableLabels.energyProduction30days'),
                                },
                                {
                                  label: t('tableLabels.savedEmission'),
                                },
                                {
                                  label: t('tableLabels.atmosphericConditions'),
                                },
                              ]}
                            >
                              {notDeletedDevices?.map(({ _id, identifier, name, status, dataSource = 'custom', address: { postalCode } = {} }) => (
                                <TableRow key={_id}>
                                  <TableCell>
                                    <StyledLink to={`/users/${userId}/${_id}`}>
                                      {name} ({identifier})
                                      <StyledOpenInNewIcon />
                                    </StyledLink>
                                  </TableCell>
                                  <TableCell>
                                    <StatusPill color={dataSource === 'mae' ? (status === 'ON' ? 'success' : 'danger') : 'disabled'}>
                                      {dataSource === 'mae'
                                        ? status === 'ON'
                                          ? t('statusPill.connected')
                                          : t('statusPill.disconnected')
                                        : t('noDataLabels.noData')}
                                    </StatusPill>
                                  </TableCell>
                                  <TableCell>
                                    {devicesEnergyProduction[identifier] ? devicesEnergyProduction[identifier].toFixed(3) : 0} kWh
                                  </TableCell>
                                  <TableCell>{calculateCo2Emission(devicesEnergyProduction[identifier])} kg</TableCell>
                                  <TableCell>
                                    {' '}
                                    {devicesWeatherData[postalCode]
                                      ? `${devicesWeatherData[postalCode]?.temperature ? `${devicesWeatherData[postalCode]?.temperature}°C` : ''}${
                                          devicesWeatherData[postalCode]?.humidity
                                            ? `${devicesWeatherData[postalCode]?.temperature ? ' / ' : ''}${
                                                devicesWeatherData[postalCode]?.humidity
                                              }%`
                                            : ''
                                        } (${dayjs(devicesWeatherData[postalCode]?.time).format('DD.MM.YYYY HH:mm')})`
                                      : t('noDataLabels.noData')}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </Table>
                          </Collapse>
                        </UserDevicesTableCell>
                      </NoPaddingCell>
                    </UserDevicesTableRow>
                  )}
                </>
              );
            })
          ) : (
            <TableRow>
              <NoDataTableCell colSpan={5}>{t('noDataLabels.noUsers')}</NoDataTableCell>
            </TableRow>
          )}
        </StyledTable>
        <StyledTablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={count}
          labelRowsPerPage={`${t('tableLabels.rows')}:`}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </StyledPaper>
    </Wrapper>
  );
};

export default UsersView;

const Wrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  ${({ theme }) => `${theme.mq.tablet}{
      padding: 45px 0;
  }`}
`;

const PageTitle = styled.h1``;

const StyledLink = styled(Link)`
  text-decoration: none;
  white-space: nowrap;
  color: ${({ theme }) => theme.lightPrimaryText};
  transition: 300ms;
  font-weight: 500;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  > button {
    margin-left: 15px;
  }
  ${({ theme }) => `${theme.mq.tablet}{
      margin-top: -20px;
  }`}
`;

const StyledPersonAddIcon = styled(PersonAddIcon)`
  width: 20px;
  margin-right: 5px;
`;

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
  width: 25px;
  ${({ $active }) => $active && `transform: rotate(180deg);`}
`;
const StyledEditIcon = styled(EditIcon)`
  width: 25px;
`;

const StyledPaper = styled(Paper)`
  margin: 35px 0;
`;

const UserDevicesTableRow = styled.tr`
  padding: 0 !important;
  border-bottom: 2px solid #eee !important;
  border-color: #eee !important;
`;

const UserDevicesTableCell = styled(TableCell)`
  padding: 0 !important;
  width: 1200px;
  td {
    padding: 13px 16px;
  }
`;

const TableSearchWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 5px;
  right: 15px;
`;

const LinesTableCell = styled(TableCell)`
  position: relative;
  overflow: hidden;
  width: 100px;
`;

const StyledTableCell = styled(TableCell)`
  padding: ${({ $bigPadding }) => ($bigPadding ? '18px 16px!important' : '4px 16px')};
`;

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  height: 15px;
  width: 15px;
  path {
    fill: ${({ theme }) => theme.primary};
  }
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTable = styled(Table)`
  margin-top: 45px;
`;

const NoDataTableCell = styled(TableCell)`
  text-align: center;
  height: 80px;
  font-size: 18px;
`;

const TableNoDevicesPlaceholder = styled.div`
  button {
    margin-top: 10px;
  }
`;

const XLine = styled.div`
  position: absolute;
  top: ${({ index }) => 45 + (index + 1) * 51.5}px;
  margin: auto;
  right: -25px;
  left: 0;
  width: 25px;
  height: 2px;
  background-color: #ccc;
`;

const YLine = styled.div`
  background-color: #ccc;
  width: 2px;
  height: ${({ itemsCount }) => 41 + itemsCount * 51.5}px;
  position: absolute;
  left: 0;
  top: ${({ active }) => (active ? '5px' : '10px')};
  transition: 300ms;
  right: 0;
  margin: auto;
  padding: 0;
`;

const NameWrapper = styled.div`
  max-width: 200px;
  word-wrap: break-word;
`;

const NoPaddingCell = styled.td`
  padding: 0 !important;
  > td {
    padding: 0 !important;
    border: 0;
  }
`;

const StyledTablePagination = styled(TablePagination)`
  color: ${({ theme }) => theme.primaryText};
  .MuiTablePagination-selectRoot {
    margin-right: 10px;
  }
`;
