import axios from 'axios';

//ACTION TYPES---------------------------------
const AUTHENTICATE_REQUEST = 'AUTHENTICATE_REQUEST';
const AUTHENTICATE_FAIL = 'AUTHENTICATE_FAIL';
const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
const TOKEN_AUTH_FAILED = 'TOKEN_AUTH_FAILED';
const TOKEN_AUTH_REQUEST = 'TOKEN_AUTH_REQUEST';
const TOKEN_AUTH_SUCCESS = 'TOKEN_AUTH_SUCCESS';
const EDIT_USER_FAILED = 'EDIT_USER_FAILED';
const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
const EDIT_AUTHENTICATED_USER_SUCCESS = 'EDIT_AUTHENTICATED_USER_SUCCESS';
const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
const RESTORE_USER = 'RESTORE_USER';
const CREATE_USER_FAILED = 'CREATE_USER_FAILED';
const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
const CREATE_USER_SELF_REQUEST = 'CREATE_USER_SELF_REQUEST';
const CREATE_USER_SELF_SUCCESS = 'CREATE_USER_SELF_SUCCESS';
const CREATE_USER_SELF_FAILED = 'CREATE_USER_SELF_FAILED';
const GET_USERS_FAILED = 'GET_USERS_FAILED';
const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
const GET_USER_BY_ID_FAILED = 'GET_USER_BY_ID_FAILED';
const GET_USER_BY_ID_REQUEST = 'GET_USER_BY_ID_REQUEST';
const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
const SEND_ACCESS_REQUEST_REQUEST = 'SEND_ACCESS_REQUEST_REQUEST';
const SEND_ACCESS_REQUEST_SUCCESS = 'SEND_ACCESS_REQUEST_SUCCESS';
const SEND_ACCESS_REQUEST_FAILED = 'SEND_ACCESS_REQUEST_FAILED';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
const GET_API_KEY_REQUEST = 'GET_API_KEY_REQUEST';
const GET_API_KEY_SUCCESS = 'GET_API_KEY_SUCCESS';
const GET_API_KEY_FAIL = 'GET_API_KEY_FAIL';
const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
const GET_USER_OPTIONS_SUCCESS = 'GET_USER_OPTIONS_SUCCESS';

//ACTIONS-------------------------------------

export const authenticateUser = (user, recaptchaToken) => async (dispatch) => {
  dispatch(authRequest());
  try {
    const response = await axios.post('/user/login', { ...user, recaptchaToken });
    dispatch(authenticateUserSuccess(response.data.user));
  } catch (err) {
    dispatch(authFail(err));
    return err.response;
  }
};

const authRequest = () => ({
  type: AUTHENTICATE_REQUEST,
});

const authenticateUserSuccess = (user) => ({
  type: AUTHENTICATE_USER_SUCCESS,
  payload: user,
});

const authFail = (error) => ({
  type: AUTHENTICATE_FAIL,
  payload: error,
});

export const logoutUser = () => async (dispatch) => {
  dispatch(authRequest());
  try {
    await axios.post('/user/logout');
    dispatch(logoutUserSuccess());
  } catch (err) {
    dispatch(authFail(err));
  }
};

const logoutUserSuccess = () => ({
  type: LOGOUT_USER_SUCCESS,
});

export const checkToken = () => async (dispatch) => {
  try {
    dispatch(tokenAuthRequest());
    const userResponse = await axios.get('/user/token');

    dispatch(tokenAuthSuccess(userResponse.data));
  } catch (error) {
    dispatch(tokenAuthFailed(error));
  }
};

export const tokenAuthFailed = (error) => {
  return {
    type: TOKEN_AUTH_FAILED,
    payload: error,
  };
};

const tokenAuthRequest = () => ({
  type: TOKEN_AUTH_REQUEST,
});

const tokenAuthSuccess = (user) => ({
  type: TOKEN_AUTH_SUCCESS,
  payload: user,
});

export const editUser =
  (userId, body, restore = false) =>
  async (dispatch, getState) => {
    const {
      auth: {
        user: { _id: currentUserId },
      },
    } = getState();
    dispatch(editUserRequest());
    try {
      const response = await axios.patch(`/user/${userId}`, body);
      if (restore) {
        dispatch(restoreUser(response.data));
      } else {
        if (currentUserId === userId) {
          dispatch(editAuthenticatedUserSuccess(response.data));
        } else {
          dispatch(editUserSuccess(response.data));
        }
      }
      return response;
    } catch (err) {
      dispatch(editUserFail(err));
      return err?.response ?? {};
    }
  };

const editUserRequest = () => ({
  type: EDIT_USER_REQUEST,
});

const editAuthenticatedUserSuccess = (user) => ({
  type: EDIT_AUTHENTICATED_USER_SUCCESS,
  payload: user,
});

const editUserSuccess = (user) => ({
  type: EDIT_USER_SUCCESS,
  payload: user,
});

const restoreUser = (user) => ({
  type: RESTORE_USER,
  payload: user,
});

const editUserFail = (error) => ({
  type: EDIT_USER_FAILED,
  payload: error,
});

export const createUserSelf = (body) => async (dispatch) => {
  dispatch(createUserSelfRequest());
  try {
    const response = await axios.post(`/user/register/user-self`, body);
    dispatch(createUserSelfSuccess());
    return response;
  } catch (err) {
    console.log(err);
    dispatch(createUserSelfFail(err));
    return err?.response ?? {};
  }
};

const createUserSelfRequest = () => ({
  type: CREATE_USER_SELF_REQUEST,
});

const createUserSelfSuccess = () => ({
  type: CREATE_USER_SELF_SUCCESS,
});

const createUserSelfFail = (error) => ({
  type: CREATE_USER_SELF_FAILED,
  payload: error,
});

export const createAdmin = (body) => async (dispatch) => {
  dispatch(createAdminRequest());
  try {
    const response = await axios.post(`/user/register/admin`, body);
    dispatch(createAdminSuccess(response?.data?.data));
    return response;
  } catch (err) {
    dispatch(createAdminFail(err));
    return err?.response ?? {};
  }
};

const createAdminRequest = () => ({
  type: CREATE_USER_REQUEST,
});

const createAdminSuccess = (user) => ({
  type: CREATE_USER_SUCCESS,
  payload: user,
});

const createAdminFail = (error) => ({
  type: CREATE_USER_FAILED,
  payload: error,
});

export const createUser = (body) => async (dispatch) => {
  dispatch(createUserRequest());
  try {
    const response = await axios.post(`/user/register/user`, body);
    dispatch(createUserSuccess(response?.data?.data));
    return response;
  } catch (err) {
    dispatch(createUserFail(err));
    return err?.response ?? {};
  }
};

const createUserRequest = () => ({
  type: CREATE_USER_REQUEST,
});

const createUserSuccess = (user) => ({
  type: CREATE_USER_SUCCESS,
  payload: user,
});

const createUserFail = (error) => ({
  type: CREATE_USER_FAILED,
  payload: error,
});

export const getUsers =
  (populates, searchTerm = '', pageSize = '', pageNumber = '', skipDeviceCheck = false) =>
  async (dispatch) => {
    dispatch(getUsersRequest());
    const props = [{ populates }, { searchTerm }, { pageSize }, { pageNumber }, { skipDeviceCheck }];
    let query = '';
    for (const prop of props) {
      const [[key, value]] = Object.entries(prop);
      query += `&${key}=${encodeURIComponent(value)}`;
    }
    query = query.replace('&', '?');
    try {
      const response = await axios.get(`/user${query}`);
      const { docs, ...paginationObject } = response.data;
      dispatch(getUsersSuccess(docs, paginationObject));
    } catch (err) {
      dispatch(getUsersFail(err));
    }
  };

const getUsersRequest = () => ({
  type: GET_USERS_REQUEST,
});

const getUsersSuccess = (users, paginationMetadata) => ({
  type: GET_USERS_SUCCESS,
  payload: users,
  paginationMetadata,
});

const getUsersFail = (error) => ({
  type: GET_USERS_FAILED,
  payload: error,
});

export const getUserById = (id) => async (dispatch) => {
  dispatch(getUserByIdRequest());
  try {
    const response = await axios.get(`/user/${id}`);
    dispatch(getUserByIdSuccess(response.data));
  } catch (err) {
    dispatch(getUserByIdFail(err));
  }
};

const getUserByIdRequest = () => ({
  type: GET_USER_BY_ID_REQUEST,
});

const getUserByIdSuccess = (user) => ({
  type: GET_USER_BY_ID_SUCCESS,
  payload: user,
});

const getUserByIdFail = (error) => ({
  type: GET_USER_BY_ID_FAILED,
  payload: error,
});

export const sendAccessRequest = (body) => async (dispatch) => {
  dispatch(sendAccessRequestRequest());
  try {
    const response = await axios.post(`/user/access-request`, body);
    dispatch(sendAccessRequestSuccess(response.data));
    return response;
  } catch (err) {
    dispatch(sendAccessRequestFail(err));
  }
};

const sendAccessRequestRequest = () => ({
  type: SEND_ACCESS_REQUEST_REQUEST,
});

const sendAccessRequestSuccess = () => ({
  type: SEND_ACCESS_REQUEST_SUCCESS,
});

const sendAccessRequestFail = (error) => ({
  type: SEND_ACCESS_REQUEST_FAILED,
  payload: error,
});

export const confirmPassword = (password) => async (dispatch, getState) => {
  const {
    auth: {
      user: { _id: userId },
    },
  } = getState();
  try {
    const response = await axios.post('/user/confirmPassword', { password, userId });
    if (response.status === 200) {
      return true;
    }
  } catch (err) {
    return false;
  }
};

export const resetPassword = (email, skipGeneration) => async (dispatch) => {
  dispatch(resetPasswordRequest());
  try {
    const response = await axios.post(`/user/resetPassword`, { ...email, skipGeneration });
    dispatch(resetPasswordSuccess(response.data.message));
    return response;
  } catch (err) {
    resetPasswordFail(err);
    return err?.response;
  }
};

const resetPasswordRequest = () => ({
  type: RESET_PASSWORD_REQUEST,
});

const resetPasswordSuccess = (message) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: message,
});

const resetPasswordFail = (error) => ({
  type: RESET_PASSWORD_FAIL,
  payload: error,
});

export const changePassword = (userId, values) => async (dispatch) => {
  dispatch(changePasswordRequest());
  try {
    const response = await axios.post(`/user/${userId}/change-password`, {
      oldPassword: values.oldPassword,
      newPassword: values.confirmedPassword,
    });
    const { data } = response;
    dispatch(changePasswordSuccess(data));
    console.log(response);
    return response;
  } catch (error) {
    dispatch(changePasswordFail(error));
  }
};

const changePasswordRequest = () => ({
  type: CHANGE_PASSWORD_REQUEST,
});

const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

const changePasswordFail = (error) => ({
  type: CHANGE_PASSWORD_FAIL,
  payload: error,
});

export const generateApiKey = (userId, password) => async (dispatch) => {
  dispatch(generateApiKeyRequest());
  try {
    const response = await axios.post(`/user/generateApiKey`, { userId, password });
    const { data } = response;
    dispatch(generateApiKeySuccess());
    return data.apiKey;
  } catch (error) {
    dispatch(generateApiKeyFail(error));
  }
};

const generateApiKeyRequest = () => ({
  type: GET_API_KEY_REQUEST,
});

const generateApiKeySuccess = () => ({
  type: GET_API_KEY_SUCCESS,
});

const generateApiKeyFail = (error) => ({
  type: GET_API_KEY_FAIL,
  payload: error,
});

export const deleteUser = (userId) => async (dispatch) => {
  dispatch(deleteUserRequest());
  try {
    const response = await axios.delete(`/user/${userId}`);
    dispatch(deleteUserSuccess());
    return response;
  } catch (error) {
    dispatch(deleteUserFail(error));
  }
};

const deleteUserRequest = () => ({
  type: DELETE_USER_REQUEST,
});

const deleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS,
});

const deleteUserFail = (error) => ({
  type: DELETE_USER_FAIL,
  payload: error,
});

export const getUserOptions =
  (searchTerm, { alreadyAdded = [], rows = 30 }) =>
  async (dispatch) => {
    try {
      const response = await axios.post(`/user/options?searchTerm=${encodeURIComponent(searchTerm)}`, { alreadyAdded, rows });
      dispatch(getUserOptionsSuccess(response.data));
      return response;
    } catch (error) {
      return error;
    }
  };

const getUserOptionsSuccess = (users) => ({
  type: GET_USER_OPTIONS_SUCCESS,
  payload: users,
});

const initialState = {
  isAuthenticated: false,
  user: null,
  usersList: [],
  currentUser: null,
  paginationMetadata: null,
  loading: true,
  usersLoading: false,
  selfCreateLoading: false,
  error: null,
  userOptions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        loading: false,
      };
    case AUTHENTICATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case TOKEN_AUTH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TOKEN_AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        loading: false,
        error: null,
      };
    case TOKEN_AUTH_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isAuthenticated: false,
      };
    case EDIT_USER_REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case EDIT_AUTHENTICATED_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        currentUser: state.currentUser?._id === action.payload._id ? action.payload : state.currentUser,
        usersList:
          action.payload?.deleted === true
            ? state.usersList.filter((user) => user?._id !== action.payload._id)
            : state.usersList.map((user) => (user?._id === action.payload._id ? action.payload : user)),
        paginationMetadata: {
          ...state.paginationMetadata,
          totalCount: action.payload?.deleted === true ? state.paginationMetadata?.totalCount - 1 : state.paginationMetadata?.totalCount,
        },
        loading: false,
        error: null,
      };
    case RESTORE_USER:
      return {
        ...state,
        usersList: [...state.usersList, action.payload],
        loading: false,
        error: null,
      };
    case EDIT_USER_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CREATE_USER_REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        usersList: [...state.usersList, action.payload],
        paginationMetadata: { ...state.paginationMetadata, totalCount: state.paginationMetadata.totalCount + 1 },
        error: null,
      };
    case CREATE_USER_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_USERS_REQUEST:
      return {
        ...state,
        usersLoading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
        paginationMetadata: action.paginationMetadata,
        usersLoading: false,
        error: null,
      };
    case GET_USERS_FAILED:
      return {
        ...state,
        error: action.payload,
        usersLoading: false,
      };
    case GET_USER_BY_ID_REQUEST:
      return {
        ...state,
      };
    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        error: null,
      };
    case GET_USER_BY_ID_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case SEND_ACCESS_REQUEST_REQUEST:
      return {
        ...state,
      };
    case SEND_ACCESS_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case SEND_ACCESS_REQUEST_FAILED:
      return {
        ...state,
      };
    case GET_API_KEY_REQUEST:
      return {
        ...state,
      };
    case GET_API_KEY_SUCCESS:
      return {
        ...state,
        user: state.user.apiKey === null ? { ...state.user, apiKey: true } : state.user,
      };
    case GET_API_KEY_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case 'JWT_EXPIRED':
      return {
        ...state,
        loading: false,
      };

    case CREATE_USER_SELF_REQUEST:
      return {
        ...state,
        selfCreateLoading: true,
      };
    case CREATE_USER_SELF_SUCCESS:
      return {
        ...state,
        selfCreateLoading: false,
      };
    case CREATE_USER_SELF_FAILED:
      return {
        ...state,
        selfCreateLoading: false,
        error: action.payload,
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_USER_OPTIONS_SUCCESS:
      return {
        ...state,
        userOptions: action.payload,
      };
    default:
      return state;
  }
};
