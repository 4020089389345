import { useEffect, useReducer, useState } from 'react';
import SingleDeviceView from './SingleDeviceView';
import { connect } from 'react-redux';
import * as htmlToImage from 'html-to-image';
import { jsPDF } from 'jspdf';
import { getDeviceById, editDevice } from '../../redux/modules/device';
import { getDeviceStats, getDeviceTodaysStats } from '../../redux/modules/dailyStats';
import { getWeatherData } from '../../redux/modules/weather';
import { getNewestWeatherData } from '../../utils/other';
import {
  formatEnergyChartData,
  formatWeatherChartData,
  formatBoxesData,
  calculateCo2Emission,
  calculateMoneySaved,
  formatPreviousValues,
} from '../../utils/objectsFormating';
import { useFilters, useSelfConsumption } from '../../utils/hooks';

const switchesInitialState = {
  limitNotification: {
    isActive: false,
    value: 0,
    range: 'daily',
  },
  productivityNotification: {
    isActive: false,
    value: 0,
    range: 'daily',
  },
  energyPrice: 0,
  monthlyProductionNotification: false,
  noReadingDataNotification: false,
  voltageChangeNotification: false,
};

function switchesReducer(state, action) {
  switch (action.type) {
    case 'set_all':
      return {
        limitNotification: action.payload.limitNotification ?? { isActive: false, value: 0, range: 'daily' },
        productivityNotification: action.payload.productivityNotification ?? { isActive: false, value: 0, range: 'daily' },
        monthlyProductionNotification: action.payload.monthlyProductionNotification ?? false,
        noReadingDataNotification: action.payload.noReadingDataNotification ?? false,
        voltageChangeNotification: action.payload.voltageChangeNotification ?? false,
        energyPrice: action.payload.energyPrice ?? 0,
      };
    case 'set_limit_notification':
      return { ...state, limitNotification: action.payload };
    case 'send_limit_notification':
      action?.payload?.callback(action?.payload?.deviceId, {
        limitNotification: {
          ...state.limitNotification,
          value: action?.payload?.value ?? 0,
          isActive: action?.payload?.isActive ?? false,
          range: action?.payload?.range ?? 'daily',
        },
      });
      return state;
    case 'set_productivity_notification':
      return { ...state, productivityNotification: action.payload };
    case 'send_productivity_notification':
      action?.payload?.callback(action?.payload?.deviceId, {
        productivityNotification: {
          ...state.productivityNotification,
          value: action?.payload?.value ?? 0,
          isActive: action?.payload?.isActive ?? false,
          range: action?.payload?.range ?? 'daily',
        },
      });
      return state;
    case 'set_energy_price':
      return { ...state, energyPrice: action.payload };
    case 'send_energy_price':
      action?.payload?.callback(action?.payload?.deviceId, {
        energyPrice: state.energyPrice ? state.energyPrice : 0,
      });
      return state;
    case 'send_monthly_production_notification':
      action?.payload?.callback(action?.payload?.deviceId, {
        monthlyProductionNotification: action.payload?.value,
      });
      return { ...state, monthlyProductionNotification: action.payload };
    case 'send_no_reading_data_notification':
      action?.payload?.callback(action?.payload?.deviceId, {
        noReadingDataNotification: action.payload?.value,
      });
      return { ...state, noReadingDataNotification: action.payload };
    case 'send_voltage_change_notification':
      action?.payload?.callback(action?.payload?.deviceId, {
        voltageChangeNotification: action.payload?.value,
      });
      return { ...state, voltageChangeNotification: action.payload };
    default:
      throw new Error();
  }
}

const SingleDeviceContainer = ({
  match: {
    params: { deviceId },
  },
  getDeviceById,
  currentDevice,
  editDevice,
  history,
  deviceStats,
  deviceTodaysStats,
  getDeviceStats,
  getDeviceTodaysStats,
  currentDeviceChangingData,
  getWeatherData,
  previousDeviceTotalProduction,
  previousDeviceTotalUsage,
  user,
  deviceLoading,
  statsLoading,
  weatherData,
  deviceError,
}) => {
  const [isFiltersCollapsed, setIsFiltersCollapsed] = useState(false);
  const [isExportOverlayOpen, setIsExportOverlayOpen] = useState(false);
  const [boxesData, setBoxesData] = useState({
    totalProduction: 0,
    totalUsage: 0,
  });
  const [chartData, setChartData] = useState({
    chartData: [],
    dateFormat: 'HH:mm',
    isDaily: 'power',
  });
  const [chartWeatherData, setChartWeatherData] = useState([]);
  const [newestWeatherPing, setNewestWeatherPing] = useState({
    temperature: 0,
    humidity: 0,
  });
  const [isEditDeviceModal, setEditDeviceModal] = useState(false);
  const [switchesState, dispatchSwitchesState] = useReducer(switchesReducer, switchesInitialState);
  const { filters, filterErrors, handleFiltersChange } = useFilters();
  const [previousValues, setPreviousValues] = useState({
    production: 0,
    usage: 0,
  });
  const selfConsumption = useSelfConsumption(chartData);
  useEffect(() => {
    getDeviceById(deviceId);
  }, [deviceId]);

  useEffect(() => {
    if (currentDevice?._id === deviceId && currentDevice?.deleted === true) {
      history.push(user.role === 'admin' ? '/users' : '/devices');
    }
    if (currentDevice) {
      dispatchSwitchesState({ type: 'set_all', payload: currentDevice });
    }
  }, [currentDevice]);

  useEffect(() => {
    if (weatherData) {
      setNewestWeatherPing(getNewestWeatherData(weatherData) ?? {});
    }
  }, [weatherData]);

  useEffect(() => {
    if (currentDevice?._id === deviceId && currentDevice?.identifier && filterErrors === '') {
      getWeatherData({ postalCode: currentDevice?.address?.postalCode }, filters?.dateFrom, filters?.dateTo);
      if (filters.dateRange === 'today') {
        getDeviceTodaysStats(currentDevice?.identifier);
      } else {
        getDeviceStats(currentDevice?.identifier, filters?.dateFrom, filters?.dateTo);
      }
    }
  }, [filters?.dateFrom, filters?.dateRange, filters?.dateTo, currentDevice?.identifier, currentDevice?._id]);

  useEffect(() => {
    const { newData, dateFormat, isDaily } = formatEnergyChartData(
      filters.dateRange === 'today' ? deviceTodaysStats : deviceStats,
      filters,
      weatherData,
    );
    const { newTotalProduction, newTotalUsage } = formatBoxesData({ data: newData, filters });
    setChartData({
      chartData: newData,
      dateFormat: dateFormat,
      isDaily: isDaily,
    });
    setBoxesData({
      totalProduction: newTotalProduction,
      totalUsage: newTotalUsage,
    });
    setPreviousValues(() => ({
      production: formatPreviousValues(previousDeviceTotalProduction, filters.phase),
      usage: formatPreviousValues(previousDeviceTotalUsage, filters.phase),
    }));
    setChartWeatherData(formatWeatherChartData(weatherData, filters?.dateRange));
  }, [deviceStats, deviceTodaysStats, filters?.dateRange, filters?.phase, weatherData]);

  useEffect(() => {
    const lsFilters = localStorage.getItem('filtersDateRange');
    if (lsFilters && lsFilters !== 'today') {
      handleFiltersChange('dateRange', lsFilters);
    }
  }, []);

  const handleFiltersToggle = () => {
    setIsFiltersCollapsed((prev) => !prev);
  };

  const handleExportPDF = (element) => {
    htmlToImage.toPng(element, { quality: 1, width: 1250, height: 2000 }).then(function (dataUrl) {
      var link = document.createElement('a');
      link.download = 'my-image-name.jpeg';
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(dataUrl);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(dataUrl, 'PNG', 10, 0, pdfWidth - 10, pdfHeight);
      pdf.save(`EnergyMeter_raport-${new Date().toISOString().slice(0, 10)}.pdf`);
    });
  };

  const handleExportCSV = () => {
    const overallDataHeaders = ['Wyprodukowana energia', 'Zużyta energia', 'Zaoszczędzone pieniądze', 'Zaoszczędzona emisja'].join(';');
    const overallData = [
      `${boxesData?.totalProduction} kWh`,
      `${boxesData?.totalUsage} kWh`,
      `${calculateMoneySaved(boxesData.totalProduction, currentDevice?.energyPrice)} zł`,
      `${calculateCo2Emission(boxesData?.totalProduction)} kg`,
    ].join(';');
    const rows = [overallDataHeaders, overallData];
    let csvContent = rows.join('\n');
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,\uFEFF' + encodeURIComponent(csvContent);
    hiddenElement.target = '_blank';
    hiddenElement.download = `EnergyMeter_raport-${`${currentDevice.identifier}`}-${new Date().toISOString().slice(0, 10)}.csv`;
    hiddenElement.click();
  };

  return (
    <SingleDeviceView
      filters={filters}
      handleFiltersChange={handleFiltersChange}
      isFiltersCollapsed={isFiltersCollapsed}
      handleFiltersToggle={handleFiltersToggle}
      isExportOverlayOpen={isExportOverlayOpen}
      setIsExportOverlayOpen={setIsExportOverlayOpen}
      handleExportPDF={handleExportPDF}
      isEditDeviceModal={isEditDeviceModal}
      setEditDeviceModal={setEditDeviceModal}
      currentDevice={currentDevice}
      editDevice={editDevice}
      handleExportCSV={handleExportCSV}
      chartData={chartData}
      boxesData={boxesData}
      currentDeviceChangingData={currentDeviceChangingData}
      previousValues={previousValues}
      switchesState={switchesState}
      dispatchSwitchesState={dispatchSwitchesState}
      filterErrors={filterErrors}
      deviceLoading={deviceLoading}
      newestWeatherPing={newestWeatherPing}
      chartWeatherData={chartWeatherData}
      statsLoading={statsLoading}
      deviceError={deviceError}
      selfConsumption={selfConsumption}
      emailConsent={user?.consents?.emails}
      userRole={user?.role}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  currentDevice: state.device.currentDevice,
  currentDeviceChangingData: state.device.currentDeviceChangingData,
  deviceStats: state.dailyStats.deviceStats,
  deviceTodaysStats: state.dailyStats.deviceTodaysStats,
  previousDeviceTotalProduction: state.dailyStats.previousDeviceTotalProduction,
  previousDeviceTotalUsage: state.dailyStats.previousDeviceTotalUsage,
  statsLoading: state.dailyStats.loading,
  deviceLoading: state.device.loading,
  weatherData: state.weather.data,
  deviceError: state.device.error,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getDeviceById: (deviceId) => dispatch(getDeviceById(deviceId)),
    editDevice: (deviceId, deviceBody) => dispatch(editDevice(deviceId, deviceBody)),
    getDeviceStats: (deviceId, startDate, endDate) => dispatch(getDeviceStats(deviceId, startDate, endDate)),
    getDeviceTodaysStats: (deviceId) => dispatch(getDeviceTodaysStats(deviceId)),
    getWeatherData: (query, startDate, endDate) => dispatch(getWeatherData(query, startDate, endDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleDeviceContainer);
