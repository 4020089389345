import styled from 'styled-components';
import Paper from '../../shared/Paper';
import { useTranslation } from 'react-i18next';

const TermsView = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Paper>
        <PageTitle>{t('termsPage.title')}</PageTitle>
        <Content dangerouslySetInnerHTML={{ __html: t('termsPage.content', { interpolation: { escapeValue: false } }) }} />
      </Paper>
    </Wrapper>
  );
};

export default TermsView;

const Wrapper = styled.div`
  padding: 30px 0;
`;

const PageTitle = styled.h1``;

const Content = styled.div`
  padding: 20px 10px 30px;
  p {
    margin-bottom: 10px;
    font-size: 16px;
    a {
      color: ${({ theme }) => theme.primaryText};
      transition: 200ms;
      &:hover {
        color: ${({ theme }) => theme.primary};
      }
    }
  }
  ul {
    margin: 0 0 0 35px;
    list-style: disc;
  }
  h5 {
    margin-top: 20px;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: ${({ theme }) => theme.primary};
  }
`;
