import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from './Button';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { withRouter } from 'react-router-dom';
import rejectIcon from './assets/rejected-icon.svg';

const GalleryFooter = ({ currentIndex, currentView, carouselProps: { views, deleteModalHandler, setModalShown, hideDelete } }) => {
  return (
    <GalleryFooterWrapper>
      {currentView.name && <p>{currentView.name}</p>}
      <p>
        {currentIndex + 1} z {views.length}
      </p>
      {!hideDelete && (
        <Button
          variant="danger"
          onClick={() => {
            setModalShown(false);
            deleteModalHandler(currentView._id);
          }}
        >
          Usuń zdjęcie
        </Button>
      )}
    </GalleryFooterWrapper>
  );
};

const Gallery = ({
  images = [],
  deleteModalHandler,
  location: { pathname },
  queryDocumentId,
  history,
  variant,
  previewSize,
  hideDelete,
  showSmallDeleteBtns = false,
}) => {
  const [modalShown, setModalShown] = useState(false),
    [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    if (queryDocumentId) {
      const targetIndex = images.findIndex((el) => el._id === queryDocumentId);
      if (targetIndex !== -1 && !modalShown && currentImage !== targetIndex) {
        setModalShown(true);
        setCurrentImage(targetIndex);
      }
    }
  }, [queryDocumentId]);

  return (
    <GalleryWrapper>
      <ModalGateway>
        {modalShown && (
          <Modal
            onClose={() => {
              history.push(pathname);
              setCurrentImage(null);
              setModalShown(false);
            }}
          >
            <Carousel
              styles={{
                view: (base) => ({
                  ...base,
                  maxHeight: '85vh',
                  maxWidth: '90vw',
                  margin: 'auto',
                  '>img': {
                    display: 'block',
                    maxWidth: '90vw',
                    maxHeight: '83vh',
                    width: 'auto',
                    height: 'auto',
                    margin: 'auto auto 0',
                  },
                }),
              }}
              currentIndex={currentImage}
              views={images}
              setModalShown={setModalShown}
              deleteModalHandler={deleteModalHandler}
              hideDelete={hideDelete}
              components={{ Footer: GalleryFooter }}
            />
          </Modal>
        )}
      </ModalGateway>
      <GalleryGrid>
        {images.map(({ src }, index) => (
          <GalleryItem variant={variant} key={`Image_${index}`} previewSize={previewSize}>
            <Image
              src={src}
              onClick={() => {
                setCurrentImage(index);
                setModalShown(true);
              }}
            />
            {showSmallDeleteBtns && <RoundDeleteBtn type="button" title="Usuń załącznik" onClick={() => deleteModalHandler(index)} />}
          </GalleryItem>
        ))}
      </GalleryGrid>
    </GalleryWrapper>
  );
};

export default withRouter(Gallery);

const GalleryWrapper = styled.div``;

const GalleryGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
`;

const GalleryItem = styled.div`
  position: relative;
  width: ${({ previewSize }) => (previewSize ? previewSize : '100px')};
  height: ${({ previewSize }) => (previewSize ? previewSize : '100px')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  ${({ variant }) =>
    variant === 'dashboard' &&
    `
        width:80px;
        height:80px;
    `}
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  border: 1px solid #efefef;
  cursor: pointer;
  transition: 300ms;
  &:hover {
    transform: scale(1.05);
    opacity: 0.8;
  }
`;

const GalleryFooterWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  p {
    color: #fff;
    margin-bottom: 10px;
  }
  button {
    max-width: 150px;
    margin: auto;
  }
`;

const RoundDeleteBtn = styled.button`
  position: absolute;
  right: -10px;
  top: -10px;
  height: 20px;
  width: 20px;
  background-color: #d9d9d9;
  background-image: url(${rejectIcon});
  background-position: center;
  background-size: 10px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: 300ms;
  &:hover {
    background-color: ${({ theme }) => theme.danger};
  }
`;
