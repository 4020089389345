import MaterialButton from '@material-ui/core/Button';
import styled from 'styled-components';

const Button = ({ variant = 'contained', color = 'primary', children, ...props }) => {
  return (
    <StyledMaterialButton {...props} variant={variant} color={color}>
      {children}
    </StyledMaterialButton>
  );
};
export default Button;

const StyledMaterialButton = styled(MaterialButton)`
  border-radius: 0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  padding: 10px 15px;
  border-width: 2px;
  font-size: 10px;
  &:hover {
    border-width: 2px;
  }
  ${({ theme }) => `${theme.mq.desktop}{
    font-size: 14px;
  }`}
  ${({ theme, color }) =>
    color === 'danger'
      ? `
    color:#fff;
    background-color:${theme.danger};
    &:hover{background-color:#da3f37;}
  `
      : ''}
`;
