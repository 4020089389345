import { useState } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import Modal from '../../../shared/Modal';
import Button from '../../../shared/Button';
import Checkbox from '../../../shared/Checkbox';
import { useSnackbar } from 'notistack';
import { List, ListItem, Divider } from '@material-ui/core';
import WarningModal from '../../../shared/WarningModal';
import PasswordConfirmationModal from '../../../shared/PasswordConfirmationModal';
import { useTranslation } from 'react-i18next';

const UserConsentsModal = ({ modalState, setModalState, user, editUser, deleteUser, logoutUser, history }) => {
  const { t } = useTranslation();
  const [warningModal, setWarningModal] = useState({ shown: false, values: {} });
  const [confirmModal, setConfirmModal] = useState(false);
  const initialValues = {
    terms: user?.consents?.terms ?? false,
    emails: user?.consents?.emails ?? false,
    dataAnalysis: user?.consents?.dataAnalysis ?? false,
    marketing: user?.consents?.marketing ?? false,
  };
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async (values) => {
    if (!values?.terms || !values?.dataAnalysis) {
      setWarningModal({ shown: true, values });
    } else {
      const res = await editUser(user?._id, { consents: values });
      if (res?.status === 200) {
        setModalState(false);
        enqueueSnackbar(t('snackbarTexts.changesSaved'), { variant: 'success' });
      }
    }
  };
  return (
    <Modal title={t('notificationSettingsModal.title')} show={modalState} exitButton={true} onCancel={() => setModalState(false)}>
      <PasswordConfirmationModal
        modalState={confirmModal}
        setModalState={setConfirmModal}
        successCallback={async () => {
          const res = await deleteUser(user?._id);
          setWarningModal({ shown: false });
          setModalState(false);
          await logoutUser();
          history.push('/');
          res?.status === 200
            ? enqueueSnackbar(t('snackbarTexts.accountDeleted'), { variant: 'success' })
            : enqueueSnackbar(t('snackbarTexts.errorDuringDeleting'), { variant: 'error' });
        }}
      />
      <WarningModal
        modalState={warningModal}
        setModalState={setWarningModal}
        text={t('userConsentsModal.removeConsentsWarning')}
        onSuccess={() => setConfirmModal(true)}
        acceptBtnText={t('userConsentsModal.deleteAccount')}
        declineBtnText={t('userConsentsModal.cancel')}
        onDecline={() => setWarningModal({ shown: false, targetId: null })}
        swapColors
      />
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <List style={{ margin: '5px 0 0' }}>
              <ListItem>
                <Checkbox onChange={() => setFieldValue('terms', !values?.terms)} value={values?.terms} hideError margin="0">
                  {t('userConsentsModal.terms')}
                </Checkbox>
              </ListItem>
              <Divider />
              <ListItem>
                <Checkbox onChange={() => setFieldValue('dataAnalysis', !values?.dataAnalysis)} value={values?.dataAnalysis} hideError margin="0">
                  {t('userConsentsModal.dataAnalize')}
                </Checkbox>
              </ListItem>
              <Divider />
              <ListItem>
                <Checkbox onChange={() => setFieldValue('emails', !values?.emails)} value={values?.emails} hideError margin="0">
                  {t('userConsentsModal.mails')}
                </Checkbox>
              </ListItem>
              <Divider />
              <ListItem>
                <Checkbox onChange={() => setFieldValue('marketing', !values?.marketing)} value={values?.marketing} hideError margin="0">
                  {t('userConsentsModal.marketing')}
                </Checkbox>
              </ListItem>
            </List>
            <CenterBox>
              <Button type="submit">{t('userConsentsModal.saveBtn')}</Button>
            </CenterBox>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default UserConsentsModal;

const Form = styled.form`
  width: 400px;
`;

const CenterBox = styled.div`
  text-align: center;
`;
