import { useState, useEffect } from 'react';
import LoginView from './LoginView';
import { connect } from 'react-redux';
import { authenticateUser, resetPassword, createUserSelf } from '../../redux/modules/auth';
import { getSummary } from '../../redux/modules/dailyStats';
import { toggleDarkMode } from '../../redux/modules/navigation';

const LoginContainer = ({
  authenticateUser,
  resetPassword,
  getSummary,
  darkMode,
  toggleDarkMode,
  createUserSelf,
  userCreateLoading,
  summaryLoading,
  history,
  totalStats,
}) => {
  const {
    location: { pathname },
  } = history;
  const [isRegisterModal, setIsRegisterModal] = useState(false);
  const [isResetPasswordModal, setResetPasswordModal] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    getSummary();
    const lsDarkMode = localStorage.getItem('darkMode');
    if (darkMode !== lsDarkMode) {
      toggleDarkMode(localStorage);
    }
  }, []);

  useEffect(() => {
    if (pathname === '/reset-password') {
      setResetPasswordModal(true);
      setShouldRedirect(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (!isResetPasswordModal && shouldRedirect) {
      if (pathname === '/reset-password') {
        history.push('/');
      }
    }
  }, [isResetPasswordModal]);

  return (
    <LoginView
      authenticateUser={authenticateUser}
      isResetPasswordModal={isResetPasswordModal}
      setResetPasswordModal={setResetPasswordModal}
      resetPassword={resetPassword}
      totalStats={totalStats}
      isRegisterModal={isRegisterModal}
      setIsRegisterModal={setIsRegisterModal}
      createUserSelf={createUserSelf}
      userCreateLoading={userCreateLoading}
      summaryLoading={summaryLoading}
      darkMode={darkMode}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  totalStats: state.dailyStats.totalStats,
  summaryLoading: state.dailyStats.summaryLoading,
  userCreateLoading: state.auth.selfCreateLoading,
  darkMode: state.navigation.darkMode,
});

const mapDispatchToProps = (dispatch) => {
  return {
    authenticateUser: (user, recaptchaToken) => dispatch(authenticateUser(user, recaptchaToken)),
    createUserSelf: (body) => dispatch(createUserSelf(body)),
    resetPassword: (email) => dispatch(resetPassword(email)),
    getSummary: () => dispatch(getSummary()),
    toggleDarkMode: () => dispatch(toggleDarkMode()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
