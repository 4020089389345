import { useState } from 'react';
import Modal from '../../../shared/Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Button from '../../../shared/Button';
import Input from '../../../shared/Input';
import { ReactComponent as SuccessImg } from '../../../shared/assets/access-request-sent.svg';
import ReCaptcha from '../../../shared/ReCaptcha';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

const validationSchema = Yup.object().shape({
  email: Yup.string().email(i18n.t('validationTexts.wrongEmail')).required(i18n.t('validationTexts.emailRequired')),
  recaptchaToken: Yup.string().required(i18n.t('validationTexts.recaptchaRequired')),
});

const ResetPasswordModal = ({ isResetPasswordModal, setResetPasswordModal, resetPassword }) => {
  const { t } = useTranslation();
  const [requestSent, setRequestSent] = useState(false);
  const initialValues = {
    email: '',
    recaptchaToken: '',
  };
  return (
    <StyledModal
      title={t('passwordResetModal.title')}
      show={isResetPasswordModal}
      exitButton={true}
      onCancel={() => {
        setResetPasswordModal(false);
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setFieldError }) => {
          const res = await resetPassword(values);
          if (res.status === 200) {
            setRequestSent(true);
          } else if (res.status === 404) {
            setFieldError('email', res?.data?.message);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) =>
          requestSent ? (
            <CenterBox>
              <StyledSuccessImg />
              <SuccessText>
                <span>{t('passwordResetModal.requestSended')}</span>
                <br /> {t('passwordResetModal.messageSendedToEmail')}
              </SuccessText>
              <Button
                fullWidth
                type="button"
                onClick={() => {
                  setRequestSent(false);
                  setResetPasswordModal(false);
                }}
              >
                {t('passwordResetModal.closeBtn')}
              </Button>
            </CenterBox>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Input
                name="email"
                label={t('formLabels.email')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={touched.email && errors.email}
              />
              <ReCaptcha setFieldValue={setFieldValue} error={errors.recaptchaToken} />
              <Button fullWidth type="submit">
                {t('passwordResetModal.sendRequestBtn')}
              </Button>
            </Form>
          )
        }
      </Formik>
    </StyledModal>
  );
};

export default ResetPasswordModal;

const Form = styled.form`
  width: 100%;
  padding: 30px 0;
  ${({ theme }) => `${theme.mq.tablet}{
      width: 400px;
  }`}
`;

const SuccessText = styled.p`
  margin: 20px 0;
  > span {
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.primary};
  }
`;

const CenterBox = styled.div`
  text-align: center;
`;

const StyledSuccessImg = styled(SuccessImg)`
  width: 300px;
  height: 300px;
  margin: 20px 20px 0;
`;

const StyledModal = styled(Modal)`
  width: 90vw;
  max-width: 400px;
  padding-bottom: 30px;
  ${({ theme }) => `${theme.mq.tablet}{
    width: auto;
    max-width: 100%;
  }`}
`;
