import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCitiesOnly } from '../../redux/modules/gus';
import useDebounce from './useDebounce';

const useCities = () => {
  const cities = useSelector((state) => state.gus.cities);

  const dispatch = useDispatch();
  const getCitiesFunc = (searchTerm) => dispatch(getCitiesOnly(searchTerm));

  const [citiesSearchTerm, setCitiesSearchTerm] = useState('');
  const debouncedCitiesSearchTerm = useDebounce(citiesSearchTerm);

  const [, setCurrentCity] = useState('');

  useEffect(() => {
    getCitiesFunc(debouncedCitiesSearchTerm);
  }, [debouncedCitiesSearchTerm]);

  const handleSearchTermChange = (value) => {
    setCitiesSearchTerm(value);
  };
  const handleCurrentsChange = (value) => {
    setCurrentCity(value);
  };
  const getInitalOptions = () => {
    getCitiesFunc('');
  };

  return {
    cities,
    debouncedCitiesSearchTerm,
    handleCurrentsChange,
    handleSearchTermChange,
    getInitalOptions,
  };
};

export default useCities;
