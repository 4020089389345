import { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import ReactTooltip from 'react-tooltip';
import { theme } from './theme';

const Input = ({ error, width, className, hideError, type, background, showPasswordBtn = true, inputTooltip, ...props }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <InputWrapper data-cy={`input-${props.name}`} width={width} className={className}>
      {inputTooltip && (
        <>
          <StyledReactTooltip delayShow={1000} id={`popup-${props.name}`} getContent={() => <p>{inputTooltip}</p>} effect="solid" />
          <TooltipWrapper data-tip data-for={`popup-${props.name}`}>
            <InfoIcon style={{ color: theme.defaultIcon }} />
          </TooltipWrapper>
        </>
      )}
      <StyledTextField background={background} error={!!error} type={type === 'password' && passwordVisible ? 'text' : type} {...props} />
      {showPasswordBtn && type === 'password' && (
        <InputAdornment>
          <IconButton onClick={() => setPasswordVisible((prev) => !prev)}>{passwordVisible ? <Visibility /> : <VisibilityOff />}</IconButton>
        </InputAdornment>
      )}
      {!hideError && (
        <ErrorMsg className={`error-msg ${error && error !== '' ? 'active' : ''}`} data-cy={`${props.name}-error-msg`}>
          {error}
        </ErrorMsg>
      )}
    </InputWrapper>
  );
};

export default Input;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 5px;
  ${({ width }) => (width ? width : 'width: 100%')};
  button {
    position: absolute;
    right: 5px;
    top: 0;
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  background-color: ${({ theme, background }) => (background ? background : theme.background)};
  font-size: 14px;
  input,
  textarea {
    padding: 6px 13px 7px;
    color: ${({ theme }) => theme.primaryText};
  }
  label {
    left: 13px;
    top: 1px;
    color: ${({ theme }) => theme.primaryText};
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid ${({ theme }) => theme.primaryText}80;
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid ${({ theme }) => theme.primaryText};
  }
  svg path {
    fill: #fff;
  }
  input::-webkit-calendar-picker-indicator {
    ${({ theme }) =>
      theme.paperBackground === '#fff'
        ? `filter: invert(19%) sepia(10%) saturate(1698%) hue-rotate(172deg) brightness(97%) contrast(82%);`
        : 'filter: invert(98%) sepia(35%) saturate(22%) hue-rotate(149deg) brightness(111%) contrast(83%);'}
  }
`;

const ErrorMsg = styled.p`
  color: ${({ theme }) => theme.danger};
  font-size: 0.75rem;
  min-height: 17px;
  padding: 1px 13px 0;
`;

const TooltipWrapper = styled.a`
  position: absolute;
  right: 5px;
  display: block;
  top: 18px;
  z-index: 1;
  svg {
    width: 18px;
  }
`;

const StyledReactTooltip = styled(ReactTooltip)`
  width: 200px;
  background-color: #222222ee;
  p {
    color: #fff;
    margin-bottom: 3px;
  }
`;
