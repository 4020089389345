import PaperMaterial from '@material-ui/core/Paper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Paper = ({ background, title, icon, className, children, checkedCounter, ...props }) => {
  const { t } = useTranslation();
  return (
    <StyledPaper background={background} className={className} $title={title} {...props}>
      {icon && <IconWrapper>{icon()}</IconWrapper>}
      {title && (
        <PaperTitle icon={icon}>
          {title} {(checkedCounter || checkedCounter === 0) && <ChcekedCounter>({`${t('tableLabels.checked')}: ${checkedCounter}`})</ChcekedCounter>}
        </PaperTitle>
      )}
      {children}
    </StyledPaper>
  );
};

export default Paper;

const StyledPaper = styled(PaperMaterial)`
  padding: 15px;
  background-color: ${({ background, theme }) => (background ? background : theme.paperBackground)};
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  ${({ $title }) =>
    $title &&
    `
  padding-top: 60px;
  `}
`;

const PaperTitle = styled.h3`
  position: absolute;
  top: 20px;
  left: ${({ icon }) => (icon ? '65px' : '20px')};
  width: 100%;
  font-size: 15px;
  ${({ theme, icon }) => `${theme.mq.tablet}{
    font-size:18px;
    left: ${icon ? '75px' : '20px'};
  }`}
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 15px;
  left: 10px;
  > svg {
    width: 40px;
    height: 40px;
    .fil2 {
      fill: ${({ theme }) => theme.defaultIcon};
    }
  }
  ${({ theme }) => `${theme.mq.tablet}{
    left: 20px;
  }`}
`;

const ChcekedCounter = styled.span`
  color: ${({ theme }) => theme.secondaryText};
  font-size: 11px;
  ${({ theme }) => `${theme.mq.tablet}{
    font-size:13px;
    top: 24px;
    left: 195px;
  }`}
`;
