import axios from 'axios';
const GET_VOIVODESHIPS_REQUEST = 'GET_VOIVODESHIPS_REQUEST';
const GET_VOIVODESHIPS_SUCCESS = 'GET_VOIVODESHIPS_SUCCESS';
const GET_DISTRICTS_REQUEST = 'GET_DISTRICTS_REQUEST';
const GET_DISTRICTS_SUCCESS = 'GET_DISTRICTS_SUCCESS';
const GET_COMMUNES_REQUEST = 'GET_COMMUNES_REQUEST';
const GET_COMMUNES_SUCCESS = 'GET_COMMUNES_SUCCESS';
const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST';
const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
const GUS_FAILED = 'GUS_FAILED';

const gusFailed = (err) => ({
  type: GUS_FAILED,
  payload: err,
});

const getVoivodeshipsRequest = () => ({
  type: GET_VOIVODESHIPS_REQUEST,
});

export const getVoivodeships =
  (searchTerm = '') =>
  async (dispatch) => {
    dispatch(getVoivodeshipsRequest());
    try {
      const response = await axios.get(`/gus/voivodeship?name=${searchTerm}`);
      dispatch(getVoivodeshipsSuccess(response.data));
      return response;
    } catch (err) {
      dispatch(gusFailed(err));
    }
  };

const getVoivodeshipsSuccess = (voivodeships) => ({
  type: GET_VOIVODESHIPS_SUCCESS,
  payload: voivodeships,
});

const getDistrictsRequest = () => ({
  type: GET_DISTRICTS_REQUEST,
});

export const getDistricts =
  (searchTerm = '', voivodeship) =>
  async (dispatch) => {
    dispatch(getDistrictsRequest());
    try {
      const response = await axios.get(`/gus/county?name=${searchTerm}&voivodeship=${voivodeship}&pageSize=20`);
      dispatch(getDistrictsSuccess(response.data));
      return response;
    } catch (err) {
      dispatch(gusFailed(err));
    }
  };

const getDistrictsSuccess = (districts) => ({
  type: GET_DISTRICTS_SUCCESS,
  payload: districts,
});

const getCommunesRequest = () => ({
  type: GET_COMMUNES_REQUEST,
});

export const getCommunes =
  (searchTerm = '', voivodeship, county) =>
  async (dispatch) => {
    dispatch(getCommunesRequest());
    try {
      const response = await axios.get(`/gus/commune?name=${searchTerm}&voivodeship=${voivodeship}&county=${county}&pageSize=20`);
      dispatch(getCommunesSuccess(response.data));
      return response;
    } catch (err) {
      dispatch(gusFailed(err));
    }
  };

const getCommunesSuccess = (districts) => ({
  type: GET_COMMUNES_SUCCESS,
  payload: districts,
});

export const getCities =
  (searchTerm = '', voivodeship, county, commune) =>
  async (dispatch) => {
    dispatch(getCitiesRequest());
    try {
      const response = await axios.get(`/gus/locality?name=${searchTerm}&voivodeship=${voivodeship}&county=${county}&commune=${commune}&pageSize=20`);
      dispatch(getCitiesSuccess(response.data));
      return response;
    } catch (err) {
      dispatch(gusFailed(err));
    }
  };

const getCitiesRequest = () => ({
  type: GET_CITIES_REQUEST,
});

const getCitiesSuccess = (cities) => ({
  type: GET_CITIES_SUCCESS,
  payload: cities,
});

export const getCitiesOnly =
  (searchTerm = '') =>
  async (dispatch) => {
    dispatch(getCitiesOnlyRequest());
    try {
      const response = await axios.get(`/gus/locality-only?name=${searchTerm}`);
      dispatch(getCitiesOnlySuccess(response.data));
      return response;
    } catch (err) {
      dispatch(gusFailed(err));
    }
  };

const getCitiesOnlyRequest = () => ({
  type: GET_CITIES_REQUEST,
});

const getCitiesOnlySuccess = (cities) => ({
  type: GET_CITIES_SUCCESS,
  payload: cities,
});

const initialState = {
  voivodeships: [],
  districts: [],
  communes: [],
  cities: [],
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_VOIVODESHIPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_VOIVODESHIPS_SUCCESS:
      return {
        ...state,
        voivodeships: action.payload,
        loading: false,
      };
    case GET_DISTRICTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: action.payload,
        loading: false,
      };
    case GET_COMMUNES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COMMUNES_SUCCESS:
      return {
        ...state,
        communes: action.payload,
        loading: false,
      };
    case GET_CITIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case GUS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
