import { useEffect, useState } from 'react';
import { calculateSelfConsumption } from '../objectsFormating';

const useSelfConsumption = ({ chartData, isDaily }) => {
  const [data, setData] = useState({
    chart: [],
    total: 0,
  });

  useEffect(() => {
    if (chartData?.length > 0) {
      setData(calculateSelfConsumption(chartData, isDaily));
    }
  }, [chartData]);

  return data;
};

export default useSelfConsumption;
