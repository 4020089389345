import Paper from './Paper';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

const DataPaper = ({
  title,
  icon,
  progressBar = true,
  unit,
  value,
  color,
  maxValue,
  dateRange,
  previousValue,
  roundTo = 3,
  children,
  selfConsumption,
  popupContent,
}) => {
  const { t } = useTranslation();
  const lastRangeName =
    dateRange === 'today' || dateRange === 'yesterday'
      ? t('dataBoxes.day')
      : dateRange === 'last7'
      ? t('dataBoxes.week')
      : dateRange === 'last30'
      ? t('dataBoxes.month')
      : t('dataBoxes.period');

  const progressPercent = value === 0 ? 0 : ((value / maxValue) * 100).toFixed(2);
  let difference = (((value - previousValue) / previousValue) * 100).toFixed(2);
  if (isNaN(difference) || difference === 'Infinity' || difference === '0.00') {
    difference = 0;
  }
  return (
    <PopupTrigger data-tip data-for={`popup-${title}`}>
      <StyledPaper data-cy={`data-paper-${title}`}>
        {popupContent && <StyledReactTooltip delayShow={1500} id={`popup-${title}`} getContent={popupContent} effect="solid" />}
        <Header>
          <Title>
            {title} {typeof selfConsumption !== 'undefined' ? <span>({t('dataBoxes.usedEnergy.selfconsumption')})</span> : ''}
          </Title>
          <IconWrapper>{icon}</IconWrapper>
        </Header>
        {children ? (
          children
        ) : (
          <Body>
            <Value>
              {value % 1 !== 0 ? value?.toFixed(roundTo) : value} {unit}{' '}
              {typeof selfConsumption !== 'undefined' ? (
                <SmallValue>({selfConsumption % 1 !== 0 ? selfConsumption?.toFixed(roundTo) : selfConsumption} kWh)</SmallValue>
              ) : (
                ''
              )}
            </Value>
            {progressBar && (
              <>
                <StyledLinearProgress barcolor={color} variant="determinate" value={Number(progressPercent)} />
                {typeof previousValue !== 'undefined' && (
                  <ProgressBarFooter>
                    <ProgressBarLabel>{`${t('dataBoxes.previous')} ${lastRangeName}`}</ProgressBarLabel>
                    <LastMonthDifference>
                      <StyledArrowDropDownIcon hidden={difference === 0} $direction={difference > 0} />
                      {difference < 0 ? difference.toString().replace('-', '') : difference}%
                    </LastMonthDifference>
                  </ProgressBarFooter>
                )}
              </>
            )}
          </Body>
        )}
      </StyledPaper>
    </PopupTrigger>
  );
};

export default DataPaper;

const StyledPaper = styled(Paper)`
  padding: 12px 15px;
  min-height: 110px;
  height: 100%;
`;

const Header = styled.div``;

const Title = styled.h4`
  font-size: 14px;
  padding-right: 20px;
  > span {
    font-size: ${localStorage.getItem('language') ?? 'pl' === 'de' ? '9.5px' : '11px'};
    color: ${({ theme }) => theme.secondaryText};
  }
  ${({ theme }) => `${theme.mq.medium}{
      font-size:19px;
  }`}
  ${({ theme }) => `${theme.mq.tablet}{
      font-size:${localStorage.getItem('language') ?? 'pl' === 'de' ? '15px' : '16px'};
      padding-right: 0;
  }`}
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  .fil1 {
    fill: ${({ theme }) => theme.defaultIcon};
  }
  svg {
    width: 40px;
    height: 40px;
  }
`;

const Body = styled.div``;

const Value = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.primaryText};
  font-weight: 500;
  margin: 10px 0 5px;
  display: block;
  span {
    color: ${({ theme }) => theme.secondaryText};
  }
`;

const StyledLinearProgress = styled(LinearProgress)`
  &.MuiLinearProgress-colorPrimary {
    background-color: #c4c4c4;
    height: 5px;
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: ${({ barcolor }) => barcolor};
  }
`;

const ProgressBarFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2.5px;
`;

const ProgressBarLabel = styled.span`
  color: ${({ theme }) => theme.secondaryText};
  font-size: 12px;
  font-weight: 500;
`;

const LastMonthDifference = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondaryText};
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  width: 20px;
  transform: rotate(${({ $direction }) => ($direction ? '180deg' : '0')});
  ${({ hidden }) => hidden && `opacity: 0;`}
  path {
    fill: ${({ $direction, theme }) => ($direction ? theme.primary : theme.danger)};
  }
`;

const SmallValue = styled.span`
  margin-top: 2.5px;
  font-size: 13px;
`;

const PopupTrigger = styled.a`
  display: block;
`;

const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 300px;
  background-color: #222222ee;
  p {
    color: #fff;
    margin-bottom: 3px;
  }
`;
