import styled from 'styled-components';
import { Grow } from '@material-ui/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Opacity, Error, Update, Warning, Mail } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { theme } from '../../shared/theme';
import { Link } from 'react-router-dom';
import { ReactComponent as AlertIcon } from '../../shared/assets/notification.svg';
import { useTranslation } from 'react-i18next';
import { truncateText } from '../../utils/other';
dayjs.extend(relativeTime);

const NotificationsOverlay = ({ isNotificationsOverlayOpen, notifications, markAsReadNotifications, user }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return (
    <Grow style={{ transformOrigin: '100% 0 0' }} in={isNotificationsOverlayOpen}>
      <Wrapper>
        <Header>
          <Title>{t('notificationOverlay.title')}</Title>
          <ReadAllBtn
            disabled={notifications?.length === 0}
            onClick={() =>
              notifications?.length !== 0 &&
              markAsReadNotifications(user?._id).then((res) =>
                res?.status === 200
                  ? enqueueSnackbar(t('notificationOverlay.snackbar.success'), { variant: 'success' })
                  : enqueueSnackbar(t('notificationOverlay.snackbar.error'), { variant: 'error' }),
              )
            }
          >
            {t('notificationOverlay.markAsReaded')}
          </ReadAllBtn>
        </Header>
        <Body>
          {notifications?.length > 0 ? (
            notifications
              ?.sort((a, b) => (new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1))
              ?.map(({ _id, type, createdDate, device = {}, message = {}, text }) => (
                <NotificationItem
                  key={_id}
                  icon={
                    type === 'DEVICE_DAMAGE' ? (
                      <Warning style={{ color: theme.danger }} />
                    ) : type === 'DEVICE_ALERT' ? (
                      <Opacity style={{ color: theme.secondary }} />
                    ) : type === 'INFO' ? (
                      <Error style={{ color: theme.primary }} />
                    ) : type === 'UPDATE' ? (
                      <Update style={{ color: theme.primary }} />
                    ) : type === 'MESSAGE' ? (
                      <Mail style={{ color: theme.fourth }} />
                    ) : type === 'ALERT' || type === 'NO_READINGS' || type === 'LIMIT_BELOW' || type === 'LIMIT_UP' || type === 'VOLTAGE_JUMP' ? (
                      <AlertIcon />
                    ) : null
                  }
                  title={
                    type === 'DEVICE_DAMAGE' ? (
                      <p>{t('notificationOverlay.titles.deviceDamage')}</p>
                    ) : type === 'DEVICE_ALERT' ? (
                      <p>{t('notificationOverlay.titles.deviceNotification')}</p>
                    ) : type === 'NO_READINGS' ? (
                      <p>{t('notificationOverlay.titles.noReadings')}</p>
                    ) : type === 'LIMIT_BELOW' || type === 'LIMIT_UP' ? (
                      <p>{t('notificationOverlay.titles.productionLimit')}</p>
                    ) : type === 'VOLTAGE_JUMP' ? (
                      <p>{t('notificationOverlay.titles.voltageJumps')}</p>
                    ) : type === 'INFO' ? (
                      <p>{t('notificationOverlay.titles.information')}</p>
                    ) : type === 'UPDATE' ? (
                      <p>{t('notificationOverlay.titles.newVersion')}</p>
                    ) : type === 'ALERT' ? (
                      <p>{t('notificationOverlay.titles.deviceAlert')}</p>
                    ) : type === 'MESSAGE' ? (
                      <p>{t('notificationOverlay.titles.message')}</p>
                    ) : null
                  }
                  text={() =>
                    type === 'DEVICE_DAMAGE' ? (
                      <p>
                        {t('notificationOverlay.device')} <DeviceLink to={`/devices/${device._id}`}>{device.name}</DeviceLink>{' '}
                        {t('notificationOverlay.damaged')}
                        {text && ` (${text})`}
                      </p>
                    ) : type === 'DEVICE_ALERT' ? (
                      <p>
                        {t('notificationOverlay.device')} <span>“Device #1”</span> {t('notificationOverlay.measurementsDrops')}
                      </p>
                    ) : type === 'NO_READINGS' ? (
                      <p>
                        {t('notificationOverlay.noReadings')} {text} {t('notificationOverlay.forDevice')}{' '}
                        <DeviceLink to={`/devices/${device._id}`}>{device.name}</DeviceLink>.
                      </p>
                    ) : type === 'LIMIT_BELOW' ? (
                      <p>
                        {t('notificationOverlay.limitBelow')} <DeviceLink to={`/devices/${device._id}`}>{device.name}</DeviceLink>.
                      </p>
                    ) : type === 'LIMIT_UP' ? (
                      <p>
                        {t('notificationOverlay.limitUp')} <DeviceLink to={`/devices/${device._id}`}>{device.name}</DeviceLink>.
                      </p>
                    ) : type === 'INFO' ? (
                      <p>
                        {t('notificationOverlay.device')} <span>“Device #1”</span> został wyłączony
                      </p>
                    ) : type === 'UPDATE' ? (
                      <p>
                        Pełną liste zmian znajdziesz <span>tutaj</span>!
                      </p>
                    ) : type === 'VOLTAGE_JUMP' ? (
                      <p>
                        {t('notificationOverlay.voltageDrop')} {text} {t('notificationOverlay.inMeasurementsForDevice')}{' '}
                        <DeviceLink to={`/devices/${device._id}`}>{device.name}</DeviceLink>.
                      </p>
                    ) : type === 'ALERT' ? (
                      <p>
                        {t('notificationOverlay.device')} <DeviceLink to={`/devices/${device._id}`}>{device.name}</DeviceLink>{' '}
                        {t('notificationOverlay.undefinedAlert')}
                      </p>
                    ) : type === 'MESSAGE' ? (
                      <>
                        <MessageSnippet>
                          {message?.title || message?.text
                            ? truncateText(`${message?.title ?? ''}${message?.text ? ` - ${message?.text}` : ''}`, 150)
                            : ''}
                        </MessageSnippet>
                        <DeviceLink onClick={() => markAsReadNotifications(user?._id, _id)} to={`/messages/${message?._id}`}>
                          {t('notificationOverlay.viewMessage')}
                        </DeviceLink>
                      </>
                    ) : null
                  }
                  date={createdDate}
                />
              ))
          ) : (
            <NoNotificationsInfo>{t('notificationOverlay.noNotifications')}</NoNotificationsInfo>
          )}
        </Body>
      </Wrapper>
    </Grow>
  );
};

export default NotificationsOverlay;

const NotificationItem = ({ icon, title, text, date }) => {
  const time = dayjs(date).locale('pl').fromNow();
  return (
    <NotifWrapper>
      <NotifIcon>{icon}</NotifIcon>
      <NotifBody>
        <NotifTitle>{title}</NotifTitle>
        <NotifTextsWrapper>
          <NotifText>{text()}</NotifText>
          <NotifTime>{time}</NotifTime>
        </NotifTextsWrapper>
      </NotifBody>
    </NotifWrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 45px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: ${({ theme }) => theme.paperBackground};
  width: 90vw;
  ${({ theme }) => `${theme.mq.tablet}{
    top: 35px;
    right: 100px;
  }`}
  ${({ theme }) => `${theme.mq.verySmall}{
    width: 335px;
  }`}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.background};
`;

const Title = styled.h3`
  font-size: 19px;
`;

const ReadAllBtn = styled.span`
  font-size: 10px;
  text-align: right;
  cursor: pointer;
  color: ${({ theme }) => theme.fourth};
  ${({ disabled }) =>
    disabled &&
    `
  color: #9e9e9e;
  cursor: not-allowed;
  `}
`;

const Body = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const NotifWrapper = styled.div`
  padding: 7.5px 15px;
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
`;

const NotifIcon = styled.div`
  margin: 2.5px 7.5px 0 0;
  svg {
    width: 25px;
    height: 25px;
  }
`;

const NotifBody = styled.div`
  width: 100%;
`;

const NotifTitle = styled.h5`
  font-size: 12px;
  margin-bottom: 2.5px;
`;

const NotifText = styled.div`
  color: ${({ theme }) => theme.lightPrimaryText};
  font-size: 12px;
  span,
  a {
    color: ${({ theme }) => theme.fourth};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const NotifTime = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.lightPrimaryText};
  text-align: right;
`;

const NotifTextsWrapper = styled.div``;

const NoNotificationsInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryText};
`;

const DeviceLink = styled(Link)``;

const MessageSnippet = styled.p`
  margin-bottom: 3px;
`;
