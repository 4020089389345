import { connect } from 'react-redux';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import GlobalStyle from '../../shared/GlobalStyle';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';

import NavigationContainer from '../Navigation/NavigationContainer';
import LoginContainer from '../LoginPage/LoginContainer';
import ResetPasswordApprove from '../LoginPage/ResetPasswordApprove';
import DashboardContainer from '../DashboardPage/DashboardContainer';
import ProfileContainer from '../ProfilePage/ProfileContainer';
import ReportErrorContainer from '../ReportError/ReportErrorContainer';
import UsersContainer from '../UsersPage/UsersContainer';
import SingleUserContainer from '../SingleUser/SingleUserContainer';
import SingleDeviceContainer from '../SingleDevice/SingleDeviceContainer';
import DevicesContainer from '../DevicesPage/DevicesContainer';
import DocsContainer from '../DocsPage/DocsContainer';
import MessagesContainer from '../MessagesPage/MessagesContainer';
import SingleMessageContainer from '../SingleMessagePage/SingleMessageContainer';
import TermsContainer from '../TermsPage/TermsContainer';

const AppRouter = ({ isAuthenticated, user, loading }) => {
  return (
    <Router>
      <GlobalStyle />
      <AppWrapper>
        {isAuthenticated && <NavigationContainer />}
        <PagesWrapper pt={isAuthenticated.toString()}>
          <Switch>
            <AuthenticatedRoute path="/profile" exact component={ProfileContainer} isAuthenticated={isAuthenticated} loading={loading} user={user} />
            <AuthenticatedRoute
              path="/report-error"
              exact
              component={ReportErrorContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
              user={user}
            />
            <AuthenticatedRoute
              path={user?.role === 'admin' ? '/users/:userId/:deviceId' : '/devices/:deviceId'}
              exact
              component={SingleDeviceContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
              user={user}
            />
            <AuthenticatedRoute
              path="/devices"
              exact
              component={DevicesContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
              user={user}
              userRole="user"
            />
            <AuthenticatedRoute path="/docs" exact component={DocsContainer} isAuthenticated={isAuthenticated} loading={loading} user={user} />
            <AuthenticatedRoute
              userRole="admin"
              path="/users/:id"
              exact
              component={SingleUserContainer}
              isAuthenticated={isAuthenticated}
              loading={loading}
              user={user}
            />
            <AuthenticatedRoute
              userRole="admin"
              path="/users"
              exact
              component={UsersContainer}
              isAuthenticated={isAuthenticated}
              user={user}
              loading={loading}
            />
            <AuthenticatedRoute
              path="/messages"
              exact
              component={MessagesContainer}
              isAuthenticated={isAuthenticated}
              user={user}
              loading={loading}
            />
            <AuthenticatedRoute
              path="/messages/:id"
              exact
              component={SingleMessageContainer}
              isAuthenticated={isAuthenticated}
              user={user}
              loading={loading}
            />
            <Route path="/terms" exact component={TermsContainer} loading={loading} user={user} />
            <Route path="/reset-password-confirm/:endpoint" exact component={ResetPasswordApprove} />
            {isAuthenticated ? (
              <>
                <AuthenticatedRoute path="/" exact component={DashboardContainer} isAuthenticated={isAuthenticated} loading={loading} user={user} />
              </>
            ) : (
              <>
                <UnauthenticatedRoute path="/" exact component={LoginContainer} isAuthenticated={isAuthenticated} loading={loading} user={user} />
                <UnauthenticatedRoute
                  path="/reset-password"
                  exact
                  component={LoginContainer}
                  isAuthenticated={isAuthenticated}
                  loading={loading}
                  user={user}
                />
                <UnauthenticatedRoute
                  path="/reset-password-confirm/:endpoint"
                  exact
                  component={ResetPasswordApprove}
                  isAuthenticated={isAuthenticated}
                  loading={loading}
                  user={user}
                />
              </>
            )}
          </Switch>
        </PagesWrapper>
      </AppWrapper>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, null)(AppRouter);

const AppWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100vh);
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  body {
    background: ${({ theme }) => theme.background};
    overflow-x: hidden;
    ${({ theme }) => `${theme.mq.small}{
      overflow-x: auto;
    }`}
  }
`;

const PagesWrapper = styled.div`
  width: 100%;
  padding-top: ${({ pt }) => (pt === 'true' ? '70px' : '0')};
`;
