import styled from 'styled-components';
import Paper from '../../shared/Paper';
import { handleCopyText } from '../../utils/other';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import exampleEnergyBody from './exampleEnergyBody.json';
import exampleWeatherBody from './exampleWeatherBody.json';
import exampleStatusBody from './exampleStatusBody.json';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const DocsView = ({ activeTab, setActiveTab }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Wrapper>
      <PageTitle>{t('docsPage.title')}</PageTitle>
      <Container>
        <StyledPaper title={t('docsPage.requests')}>
          <RequestList>
            <RequestItem active={activeTab === 0} onClick={() => setActiveTab(0)}>
              {t('docsPage.productionUsageDataTransmission.title')}{' '}
            </RequestItem>
            <RequestItem active={activeTab === 1} onClick={() => setActiveTab(1)}>
              {t('docsPage.weatherDataTransmission.title')}{' '}
            </RequestItem>
            <RequestItem active={activeTab === 2} onClick={() => setActiveTab(2)}>
              {t('docsPage.deviceAlarmStatusChange.title')}{' '}
            </RequestItem>
          </RequestList>
        </StyledPaper>
        <Paper>
          <TabPanel hidden={activeTab !== 0}>
            <p>
              <p>{t('docsPage.productionUsageDataTransmission.headingText')}</p>
              <Label>{t('docsPage.address')}:</Label>{' '}
              <List>
                <ListItem>
                  <Prop>{t('docsPage.method')}</Prop> - POST
                </ListItem>
                <ListItem>
                  <Prop>URL</Prop> - https://iot-e1-env.gatechsa.pl:3000/api/daily-power-stats/insert-data/[{t('docsPage.deviceIdentifier')}]
                </ListItem>
              </List>
              <Label>{t('docsPage.headers')}:</Label>
              <List>
                <ListItem>
                  <Prop>x-api-key</Prop> <Type>[String]</Type> - {t('docsPage.apiKey')}
                </ListItem>
                <ListItem>
                  <Prop>authorization</Prop> <Type>[Basic Auth]</Type> - Basic username:password <Type>[Base64]</Type>
                </ListItem>
              </List>
              <Label>{t('docsPage.properties')}:</Label>
              <List>
                <ListItem>
                  <Prop>date</Prop> <Type>[Date]</Type> - {t('docsPage.measurementDateDesc')}
                </ListItem>
                <ListItem>
                  <Prop>phases</Prop> <Type>[Object]</Type> - {t('docsPage.phasesDesc')}
                </ListItem>
                <SubList>
                  <ListItem>
                    <Prop>first</Prop> <Type>[Array]</Type> - {t('docsPage.firstPhaseDesc')}
                  </ListItem>
                  <ListItem>
                    <Prop>second</Prop> <Type>[Array]</Type> - {t('docsPage.secondPhaseDesc')}
                  </ListItem>
                  <ListItem>
                    <Prop>third</Prop> <Type>[Array]</Type> - {t('docsPage.thirdPhaseDesc')}
                  </ListItem>
                  <SubList>
                    <ListItem>
                      <Prop>time</Prop> <Type>[Date]</Type> - {t('docsPage.measurementDateDesc')}
                    </ListItem>
                    <ListItem>
                      <Prop>power</Prop> <Type>[Number]</Type> - {t('docsPage.currentPowerDesc')} (W)
                    </ListItem>
                    <ListItem>
                      <Prop>voltage</Prop> <Type>[Number]</Type> - {t('docsPage.voltageDesc')} (V)
                    </ListItem>
                    <ListItem>
                      <Prop>totalForwardActiveEnergy</Prop> <Type>[Number]</Type> - {t('docsPage.totalForwardActiveEnergyDesc')}
                    </ListItem>
                    <ListItem>
                      <Prop>totalReverseActiveEnergy</Prop> <Type>[Number]</Type> - {t('docsPage.totalReverseActiveEnergyDesc')}
                    </ListItem>
                  </SubList>
                </SubList>
              </List>
              <p>{t('docsPage.productionUsageDataTransmission.bottomText')}</p>
              <p>{t('docsPage.arrayAndObject')}</p>
              <Label>{t('docsPage.exampleRequest')}:</Label>
              <CodeExamplePre>
                <StyledFileCopyIcon
                  onClick={() =>
                    handleCopyText(JSON.stringify(exampleEnergyBody), () => enqueueSnackbar(t('docsPage.codeCopied'), { variant: 'success' }))
                  }
                />
                <StyledCode>{`${JSON.stringify(exampleEnergyBody, null, 2)}`}</StyledCode>
              </CodeExamplePre>
            </p>
          </TabPanel>
          <TabPanel hidden={activeTab !== 1}>
            <p>{t('docsPage.weatherDataTransmission.headingText')}</p>
            <p>
              <Label>{t('docsPage.address')}:</Label>{' '}
              <List>
                <ListItem>
                  <Prop>{t('docsPage.method')}</Prop> - POST
                </ListItem>
                <ListItem>
                  <Prop>URL</Prop> - https://iot-e1-env.gatechsa.pl:3000/api/weather/insert-data/[{t('docsPage.deviceIdentifier')}]
                </ListItem>
              </List>
              <Label>{t('docsPage.headers')}:</Label>
              <List>
                <ListItem>
                  <Prop>x-api-key</Prop> <Type>[String]</Type> - {t('docsPage.apiKey')}
                </ListItem>
                <ListItem>
                  <Prop>authorization</Prop> <Type>[Basic Auth]</Type> - Basic username:password <Type>[Base64]</Type>
                </ListItem>
              </List>
              <Label>{t('docsPage.properties')}:</Label>
              <List>
                <ListItem>
                  <Prop>date</Prop> <Type>[Date]</Type> - {t('docsPage.measurementDateDesc')}
                </ListItem>
                <ListItem>
                  <Prop>temperature</Prop> <Type>[Number]</Type> - {t('docsPage.temperatureDesc')}
                </ListItem>
                <ListItem>
                  <Prop>humidity</Prop> <Type>[Number]</Type> - {t('docsPage.humidityDesc')}
                </ListItem>
                <ListItem>
                  <Prop>cloudCover</Prop> <Type>[Number]</Type> - {t('docsPage.cloudCoverDesc')}
                </ListItem>
                <ListItem>
                  <Prop>uvIndex</Prop> <Type>[Number]</Type> - {t('docsPage.uvIndexDesc')}
                </ListItem>
              </List>
              <p>{t('docsPage.arrayAndObject')}</p>
              <Label>{t('docsPage.exampleRequest')}:</Label>
              <CodeExamplePre>
                <StyledFileCopyIcon
                  onClick={() =>
                    handleCopyText(JSON.stringify(exampleWeatherBody), () => enqueueSnackbar(t('docsPage.codeCopied'), { variant: 'success' }))
                  }
                />
                <StyledCode>{`${JSON.stringify(exampleWeatherBody, null, 2)}`}</StyledCode>
              </CodeExamplePre>
            </p>
          </TabPanel>
          <TabPanel hidden={activeTab !== 2}>
            <p>
              <p>{t('docsPage.deviceAlarmStatusChange.headingText')}</p>
              <Label>{t('docsPage.address')}:</Label>{' '}
              <List>
                <ListItem>
                  <Prop>{t('docsPage.method')}</Prop> - PATCH
                </ListItem>
                <ListItem>
                  <Prop>URL</Prop> - https://iot-e1-env.gatechsa.pl:3000/api/device/statuses/[{t('docsPage.deviceIdentifier')}]
                </ListItem>
              </List>
              <Label>{t('docsPage.headers')}:</Label>
              <List>
                <ListItem>
                  <Prop>x-api-key</Prop> <Type>[String]</Type> - {t('docsPage.apiKey')}
                </ListItem>
                <ListItem>
                  <Prop>authorization</Prop> <Type>[Basic Auth]</Type> - Basic username:password <Type>[Base64]</Type>
                </ListItem>
              </List>
              <Label>{t('docsPage.properties')}:</Label>
              <List>
                <ListItem>
                  <Prop>isFlooded</Prop> <Type>[Boolean]</Type> - {t('docsPage.floodedDesc')}
                </ListItem>
                <ListItem>
                  <Prop>isFire</Prop> <Type>[Boolean]</Type> - {t('docsPage.fireDesc')}
                </ListItem>
                <ListItem>
                  <Prop>isDamaged</Prop> <Type>[Boolean]</Type> - {t('docsPage.damagedDesc')}
                </ListItem>
                <ListItem>
                  <Prop>damageDescription</Prop> <Type>[String]</Type> - {t('docsPage.damageDescriptionDesc')}
                </ListItem>
              </List>
              <Label>{t('docsPage.exampleRequest')}:</Label>
              <CodeExamplePre>
                <StyledFileCopyIcon
                  onClick={() =>
                    handleCopyText(JSON.stringify(exampleStatusBody), () => enqueueSnackbar(t('docsPage.codeCopied'), { variant: 'success' }))
                  }
                />
                <StyledCode>{`${JSON.stringify(exampleStatusBody, null, 2)}`}</StyledCode>
              </CodeExamplePre>
            </p>
          </TabPanel>
        </Paper>
      </Container>
    </Wrapper>
  );
};

export default DocsView;

const Wrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  ${({ theme }) => `${theme.mq.tablet}{
      padding: 45px 0 80px;
  }`}
`;

const PageTitle = styled.h1``;

const Container = styled.div`
  display: block;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  margin: 35px 0;
  align-items: flex-start;
  ${({ theme }) => `${theme.mq.tablet}{
     display: grid;
  }`}
`;

const RequestList = styled.ul`
  margin: 0 0 20px;
`;

const RequestItem = styled.li`
  margin: 0 10px;
  cursor: pointer;
  transition: 300ms;
  padding: 10px 15px;
  ${({ active, theme }) => active && `background-color: ${theme.background};`}
  &:hover {
    background-color: ${({ theme }) => theme.background};
  }
`;

const Label = styled.span`
  font-weight: 600;
  display: block;
  margin-top: 10px;
`;

const List = styled.ul`
  margin: 2.5px 5px;
`;

const SubList = styled.ul`
  margin-left: 25px;
`;

const Type = styled.span`
  color: ${({ theme }) => theme.primary};
`;

const Prop = styled.span`
  color: ${({ theme }) => theme.primaryText};
  font-weight: 600;
`;

const ListItem = styled.li`
  margin: 0 0 2.5px;
  word-break: break-word;
`;

const StyledCode = styled.code`
  position: relative;
  padding: 5px;
  background: ${({ theme }) => theme.background};
  min-height: 80px;
  max-height: 250px;
  overflow-y: overlay;
  overflow-x: hidden;
  word-break: break-all;
  display: block;
`;

const TabPanel = styled.div`
  width: 100%;
  ${({ theme }) => `${theme.mq.small}{
    width: auto;
  }`}
  p {
    margin: 10px 0;
  }
  ${({ hidden }) => hidden && `display:none;`}
`;

const StyledFileCopyIcon = styled(FileCopyIcon)`
  position: sticky;
  height: 22px;
  width: 22px;
  left: 82.5%;
  top: 15px;
  cursor: pointer;
  transition: 300ms;
  margin-bottom: -30px;
  z-index: 9;
  &:hover {
    opacity: 0.8;
  }
  path {
    fill: #757575;
  }
`;

const StyledPaper = styled(Paper)`
  margin-bottom: 20px;
  ${({ theme }) => `${theme.mq.tablet}{
    margin-bottom: 0;
  }`}
`;

const CodeExamplePre = styled.pre`
  position: relative;
`;
