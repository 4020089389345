import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import UploadIcon from './assets/upload.svg';
import i18n from '../i18n';

const FileUpload = ({
  onDrop,
  name,
  label,
  value,
  error,
  hideError,
  placeholder = i18n.t('formLabels.addOrDropFiles'),
  disabled = false,
  children,
  testId,
  className,
}) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <FileUploadWrapper className={className}>
      {!children && <Label>{label}</Label>}
      <FileUploadBox data-testid={testId} {...getRootProps()} disabled={disabled} hide={children !== undefined ? true : false}>
        <StyledInput name={name} {...getInputProps()} />
        {!children && (
          <>
            <InputValue>
              {value
                ? value.name?.length > 35
                  ? `${i18n.t('formLabels.addedFiles')} ${value.name.substring(0, 15)}...${value.name.substring(
                      value.name.length - 10,
                      value.name.length,
                    )}`
                  : value.name
                : placeholder}
            </InputValue>
          </>
        )}
        {children}
      </FileUploadBox>
      {!hideError && <ErrorInfo error={error}>{error}</ErrorInfo>}
    </FileUploadWrapper>
  );
};

export default FileUpload;
const FileUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileUploadBox = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 20px;
  margin: 2px 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  &:focus {
    border-color: ${({ theme }) => theme.primary};
    border-width: 2px;
    padding: 19px;
    &::before {
      left: 14px;
    }
  }
  &::before {
    content: url(${UploadIcon});
    display: block;
    height: 25px;
    width: 25px;
    filter: invert(64%) sepia(52%) saturate(1320%) hue-rotate(114deg) brightness(87%) contrast(98%);
    position: absolute;
    left: 15px;
  }
  ${({ disabled }) =>
    disabled &&
    `
        pointer-events:none;
        user-select:none;
        opacity:.3;
        cursor: not-allowed; 
    `}
  ${({ hide }) =>
    hide &&
    `
    border: none;
    border-width: 0;
    padding: 0!important;
    &::before {
      display: none;
    }
    &::focus {
        border-width: 0;
        padding: 0!important;
    }
    `}
`;

const StyledInput = styled.input``;

const Label = styled.label`
  margin-left: 5px;
`;

const InputValue = styled.p`
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.primaryText};
`;

const ErrorInfo = styled.p`
  font-size: 10px;
  color: ${({ theme }) => theme.danger};
  text-align: right;
  margin-bottom: 5px;
  visibility: hidden;
  ${({ error }) => (error ? ` visibility: visible;` : `height: 13px;`)}
`;
