import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../shared/Button';
import { theme } from '../../shared/theme';
import { withRouter, useParams, useLocation } from 'react-router';
import axios from 'axios';
import ResetPasswordImg from '../../shared/assets/reset-password.svg';
import { useTranslation } from 'react-i18next';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPasswordApprove = ({ history }) => {
  const { t } = useTranslation();
  const { endpoint } = useParams();
  const query = useQuery();
  const [resetPasswordText, setResetPasswordText] = useState('Ładowanie...');

  const getResetLink = async () => {
    const response = await axios.get(`/reset-link/${endpoint}?email=${query.get('email')}`);
    if (response.status === 500) {
      setResetPasswordText(t('resetPasswordConfirm.somethingWrong'));
    } else if (response.status === 400) {
      setResetPasswordText(t('resetPasswordConfirm.linkExpired'));
    } else if (response.status === 201) {
      setResetPasswordText(t('resetPasswordConfirm.newPasswordSended'));
    } else if (response.status === 200) {
      setResetPasswordText(t('resetPasswordConfirm.emailConfirmed'));
    }
  };

  useEffect(() => {
    getResetLink();
  }, []);

  return (
    <Wrapper>
      <Column image>
        <ResetPasswordImage src={ResetPasswordImg} />
      </Column>
      <Column>
        <Title>{resetPasswordText}</Title>
        <StyledButton type="button" onClick={() => history.push('/')}>
          {t('resetPasswordConfirm.back')}
        </StyledButton>
      </Column>
    </Wrapper>
  );
};

export default withRouter(ResetPasswordApprove);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ image }) =>
    image &&
    `
display: none;`}
  ${theme.mq.tablet} {
    ${({ image }) =>
      image &&
      `
display: block;`}
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  padding-bottom: 30px;
`;

const StyledButton = styled(Button)`
  width: 250px;
  margin: 0 auto
  font-weight: 700;
`;

const ResetPasswordImage = styled.img`
  margin: 0 auto;
  width: 80%;
  display: block;
`;
