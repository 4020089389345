import { ReactComponent as DefaultLogo } from '../shared/assets/logo.svg';
import { ReactComponent as MaeLogo } from '../shared/assets/mae_logo.svg';
import { ReactComponent as WawerLogo } from '../shared/assets/wawer_logo.svg';
import styled from 'styled-components';

const getLogo = () => {
  switch (process.env.REACT_APP_THEME) {
    case 'mae':
      return <MaeLogo />;
    case 'wawer':
      return <WawerLogo />;
    default:
      return <StyledDefaultLogo />;
  }
};

const Logo = ({ smallMobile = true }) => <Wrapper smallMobile={smallMobile}>{getLogo()}</Wrapper>;

export default Logo;

const Wrapper = styled.div`
  > svg {
    ${({ smallMobile }) =>
      smallMobile && process.env.REACT_APP_THEME !== 'energyMeter'
        ? `width:100px;`
        : process.env.REACT_APP_THEME !== 'energyMeter'
        ? 'width: 130px;'
        : process.env.REACT_APP_THEME === 'energyMeter' && !smallMobile
        ? 'width: 270px;height:60px;'
        : ''}
    ${({ theme }) => `${theme.mq.tablet}{
      ${process.env.REACT_APP_THEME === 'mae' || process.env.REACT_APP_THEME === 'wawer' ? `width: 130px` : ''}
    }`}
    ${({ theme }) => `
      ${process.env.REACT_APP_THEME === 'wawer' && theme.paperBackground === '#333' ? `background: #484848` : ''}
    `}
  }
`;

const StyledDefaultLogo = styled(DefaultLogo)`
  width: 150px;
  ${({ theme }) => `${theme.mq.tablet}{
    width: auto;
  }`}
`;
