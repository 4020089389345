import SingleMessageView from './SingleMessageView';
import { useSelector, useDispatch } from 'react-redux';
import { getMessage, markAsRead } from '../../redux/modules/message';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const SingleMessageContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { user } = useSelector(({ auth }) => auth);
  const { currentMessage } = useSelector(({ message }) => message);
  useEffect(() => {
    dispatch(getMessage(id));
  }, [id]);
  useEffect(() => {
    if (id === currentMessage?._id) {
      const { seen } = currentMessage?.to?.find((el) => el?.user === user?._id) ?? {};
      if (seen === false) {
        dispatch(markAsRead(user?._id, currentMessage?._id));
      }
    }
  }, [currentMessage?._id, currentMessage?.to, user, id]);
  return <SingleMessageView currentMessage={currentMessage} />;
};

export default SingleMessageContainer;
