import i18n from '../i18n';

export const dateFilterOptions = [
  { label: i18n.t('consts.dateRange.today'), value: 'today' },
  { label: i18n.t('consts.dateRange.yesterday'), value: 'yesterday' },
  { label: i18n.t('consts.dateRange.last7'), value: 'last7' },
  { label: i18n.t('consts.dateRange.last30'), value: 'last30' },
  { label: i18n.t('consts.dateRange.custom'), value: 'custom' },
];

export const phaseFilterOptions = [
  { label: i18n.t('consts.phases.all'), value: '0' },
  { label: i18n.t('consts.phases.first'), value: '1' },
  { label: i18n.t('consts.phases.second'), value: '2' },
  { label: i18n.t('consts.phases.third'), value: '3' },
];

export const dataSourceOptions = [
  { label: i18n.t('consts.dataSources.mae'), value: 'mae' },
  { label: 'Poland Green Energy', value: 'polandgreenenergry' },
  { label: 'SolarEdge', value: 'solarEdge' },
  { label: i18n.t('consts.dataSources.custom'), value: 'custom' },
];

export const languageOptions = [
  { label: i18n.t('consts.languages.polish'), value: 'pl' },
  { label: i18n.t('consts.languages.english'), value: 'en' },
  // { label: i18n.t('consts.languages.german'), value: 'de' },
];
