import styled from 'styled-components';
import MaterialCheckbox from '@material-ui/core/Checkbox';

const Checkbox = ({ value, name, error, touched, onChange, children, hideError, margin }) => {
  return (
    <CheckboxWrapper hideError={hideError} margin={margin}>
      <StyledMaterialCheckbox checked={value} onChange={onChange} name={name} color="primary" error={error} $touched={touched} />
      <div>
        <CheckboxLabel onClick={onChange}>{children}</CheckboxLabel>
        {!hideError && touched && error !== '' && <CheckError>{error}</CheckError>}
      </div>
    </CheckboxWrapper>
  );
};

export default Checkbox;

const CheckboxLabel = styled.span`
  color: ${({ theme }) => theme.primaryText};
  display: inline-block;
  margin: 2px 0 7px 5px;
  a {
    font-size: inherit;
    color: ${({ theme }) => theme.primary};
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: ${({ hideError, margin }) => (margin ? margin : hideError ? '-10px 0 5px' : '-10px 0 20px')};
  position: relative;
  .MuiButtonBase-root {
    padding: 0;
  }
`;

const CheckError = styled.p`
  color: ${({ theme }) => theme.danger};
  font-size: 0.75rem;
  min-height: 17px;
  position: absolute;
  left: 29px;
  bottom: -11px;
`;

const StyledMaterialCheckbox = styled(MaterialCheckbox)`
  ${({ error, $touched, theme }) =>
    error &&
    $touched &&
    `
      color: ${theme.danger};
    `}
`;
