import { useEffect, useState } from 'react';
import { StylesProvider, createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { theme, darkTheme } from './shared/theme';
import { plPL } from '@material-ui/core/locale';
import { Helmet } from 'react-helmet';
import AppRouter from './features/AppRouter/AppRouter';
import { useSwipeable } from 'react-swipeable';
import { connect } from 'react-redux';
import { toggleSidebar } from './redux/modules/navigation';
import './shared/static/global-fonts.css';
import { checkToken, changePassword } from './redux/modules/auth';
import { SnackbarProvider } from 'notistack';
import ChangePasswordModal from './ChangePasswordModal';
import favicon from './shared/assets/favicon.ico';
import maeFavicon from './shared/assets/mae_favicon.svg';
import wawerFavicon from './shared/assets/wawer_favicon.svg';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
dayjs.locale(localStorage.getItem('language') ?? 'pl');

function App({ toggleSidebar, darkMode, checkToken, user, changePassword, sidebarState }) {
  const [isChangePasswordModalOn, setChangePasswordModal] = useState(false);

  const currentTheme = darkMode === 'true' ? darkTheme : theme;
  const muiTheme = createMuiTheme(
    {
      palette: {
        type: darkMode === 'true' ? 'dark' : 'light',
        primary: {
          main: currentTheme.primary,
          contrastText: '#fff',
        },
        secondary: {
          main: currentTheme.secondary,
          contrastText: '#fff',
        },
      },
    },
    plPL,
  );
  const swipeHandlers = useSwipeable({
    onSwipedRight: ({ event }) => {
      const pathClassNames = event.path.map(({ className }) => className).join(' ');
      if (!sidebarState && !pathClassNames.includes('MuiTablePagination-root') && !pathClassNames.includes('MuiTableContainer-root')) {
        toggleSidebar(true);
      }
    },
    onSwipedLeft: () => {
      if (sidebarState) {
        toggleSidebar(false);
      }
    },
  });

  const HeadMetaData = () => {
    switch (process.env.REACT_APP_THEME) {
      case 'mae':
        return (
          <Helmet>
            <meta name="description" content="System monitorowania i diagnostyki Energii \ Energy monitoring and diagnostics system" />
            <meta charSet="utf-8" />
            <title>Mazowiecka Agencja Energetyczna</title>
            <link rel="icon" href={maeFavicon} />
            <link rel="apple-touch-icon" href={maeFavicon} />
          </Helmet>
        );
      case 'wawer':
        return (
          <Helmet>
            <meta name="description" content="System monitorowania i diagnostyki Energii \ Energy monitoring and diagnostics system" />
            <meta charSet="utf-8" />
            <title>Wawer</title>
            <link rel="icon" href={wawerFavicon} />
            <link rel="apple-touch-icon" href={wawerFavicon} />
          </Helmet>
        );
      default:
        return (
          <Helmet>
            <meta name="description" content="System monitorowania i diagnostyki Energii \ Energy monitoring and diagnostics system" />
            <meta charSet="utf-8" />
            <title>EnergyMeter</title>
            <link rel="icon" href={favicon} />
            <link rel="apple-touch-icon" href={favicon} />
          </Helmet>
        );
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    if (user?.passwordChanged === true) {
      setChangePasswordModal(true);
    }
  }, [user]);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={darkMode === 'true' ? darkTheme : theme}>
        <MuiThemeProvider theme={muiTheme}>
          <HeadMetaData />
          <div {...swipeHandlers}>
            <SnackbarProvider maxSnack={3}>
              <AppRouter currentTheme={currentTheme} />
              <ChangePasswordModal
                isChangePasswordModalOn={isChangePasswordModalOn}
                setChangePasswordModal={setChangePasswordModal}
                changePassword={changePassword}
                user={user}
              />
            </SnackbarProvider>
          </div>
        </MuiThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

const mapStateToProps = (state) => ({
  darkMode: state.navigation.darkMode,
  user: state.auth.user,
  sidebarState: state.navigation.sidebarState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSidebar: (target) => dispatch(toggleSidebar(target)),
    checkToken: () => dispatch(checkToken()),
    changePassword: (userId, values) => dispatch(changePassword(userId, values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
