import Modal from './Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Button from './Button';
import Input from './Input';
import { connect } from 'react-redux';
import { confirmPassword } from '../redux/modules/auth';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const validationSchema = Yup.object().shape({
  password: Yup.string().required(i18n.t('tableLabels.passwordRequired')),
});

const PasswordConfirmationModal = ({ modalState, setModalState, successCallback, confirmPassword, initialState = false }) => {
  const { t } = useTranslation();
  const initialValues = {
    password: '',
    active: true,
  };
  return (
    <Modal
      title={t('passwordConfirmationModal.title')}
      show={modalState}
      exitButton={true}
      onCancel={() => {
        setModalState(initialState);
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setFieldError }) => {
          const passwordConfirmed = await confirmPassword(values.password);
          if (passwordConfirmed) {
            await successCallback(values.password);
            setModalState(initialState);
          } else {
            setFieldError('password', t('validationTexts.wrongPassword'));
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Text>{t('passwordConfirmationModal.infoText')}</Text>
            <Input
              name="password"
              label={t('formLabels.password')}
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={touched.password && errors.password}
            />
            <Button data-cy="password-confirmation-btn" fullWidth type="submit">
              {t('passwordConfirmationModal.submitBtn')}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirmPassword: (password) => dispatch(confirmPassword(password)),
  };
};

export default connect(null, mapDispatchToProps)(PasswordConfirmationModal);

const Form = styled.form`
  width: 80vw;
  padding: 20px 0 0;
  ${({ theme }) => `${theme.mq.desktop}{
    width: 300px;
  }`}
`;

const Text = styled.p`
  margin-bottom: 10px;
`;
