import axios from 'axios';
import { formatQuery } from '../../utils/objectsFormating';

const MESSAGE_REQUEST = 'MESSAGE_REQUEST';
const MESSAGE_FAILED = 'MESSAGE_FAILED';
const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
const GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS';
const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';
const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
const MARK_MESSAGE_AS_READ_SUCCESS = 'MARK_MESSAGE_AS_READ_SUCCESS';

const messageRequest = () => ({
  type: MESSAGE_REQUEST,
});

const messageFail = (error) => ({
  type: MESSAGE_FAILED,
  payload: error,
});

export const createMessage = (body) => async (dispatch) => {
  dispatch(messageRequest());
  try {
    const response = await axios.post(`/message`, body);
    dispatch(createMessageSuccess(response.data));
    return response;
  } catch (error) {
    dispatch(messageFail(error));
    return error;
  }
};

const createMessageSuccess = (notifications) => ({
  type: CREATE_MESSAGE_SUCCESS,
  payload: notifications,
});

export const getMessages = (pagination) => async (dispatch) => {
  dispatch(messageRequest());
  const query = formatQuery(pagination);
  try {
    const response = await axios.get(`/message?${query}`);
    dispatch(getMessagesSuccess(response.data));
  } catch (error) {
    dispatch(messageFail(error));
  }
};

const getMessagesSuccess = (message) => ({
  type: GET_MESSAGES_SUCCESS,
  payload: message,
});

export const getMessage = (messageId) => async (dispatch) => {
  dispatch(messageRequest());
  try {
    const response = await axios.get(`/message/${messageId}`);
    dispatch(getMessageSuccess(response.data));
  } catch (error) {
    dispatch(messageFail(error));
  }
};

const getMessageSuccess = (message) => ({
  type: GET_MESSAGE_SUCCESS,
  payload: message,
});

export const updateMessage = (messageId, body) => async (dispatch) => {
  dispatch(messageRequest());
  try {
    const response = await axios.patch(`/message/${messageId}`, body);
    dispatch(updateMessageSuccess(response.data));
    return response;
  } catch (error) {
    dispatch(messageFail(error));
    return error;
  }
};

const updateMessageSuccess = (message) => ({
  type: UPDATE_MESSAGE_SUCCESS,
  payload: message,
});

export const deleteMessage = (messageId) => async (dispatch) => {
  dispatch(messageRequest());
  try {
    const response = await axios.delete(`/message/${messageId}`);
    dispatch(deleteMessageSuccess(messageId));
    return response;
  } catch (error) {
    dispatch(messageFail(error));
    return error;
  }
};

const deleteMessageSuccess = (messageId) => ({
  type: DELETE_MESSAGE_SUCCESS,
  payload: messageId,
});

export const markAsRead = (userId, messageId) => async (dispatch) => {
  dispatch(messageRequest());
  try {
    const response = await axios.patch(`/message/read/${userId}`, { messageId });
    dispatch(markAsReadSuccess(response.data));
    return response;
  } catch (error) {
    dispatch(messageFail(error));
  }
};

const markAsReadSuccess = (messageId) => ({
  type: MARK_MESSAGE_AS_READ_SUCCESS,
  payload: messageId,
});

const initialState = {
  messages: [],
  currentMessage: {},
  loading: false,
  error: null,
  totalCount: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case MESSAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MESSAGE_FAILED:
      return {
        ...state,
        loading: false,
        currentMessage: {},
        error: payload,
      };
    case CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: [...state.messages, payload],
      };
    case GET_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentMessage: payload,
      };
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: payload.docs,
        totalCount: payload.totalCount,
      };
    case UPDATE_MESSAGE_SUCCESS:
    case MARK_MESSAGE_AS_READ_SUCCESS:
      return {
        ...state,
        messages: state.messages?.map((el) => (el?._id === payload?._id ? payload : el)),
        loading: false,
      };
    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: state.messages?.filter(({ _id }) => _id !== payload),
        totalCount: state.totalCount - 1,
      };
    default:
      return state;
  }
};
