import { TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox } from '@material-ui/core';
import { default as MaterialTable } from '@material-ui/core/Table';
import { ArrowDropDown } from '@material-ui/icons';
import styled from 'styled-components';
import Spinner from './Spinner';
import i18n from '../i18n';

const Table = ({
  children,
  className,
  rowCheckbox = true,
  checkboxPlaceholderCell = false,
  columns,
  checkedItemsValues = [],
  items,
  onCheckAll,
  noItemsPlaceholder = () => i18n.t('table.noRecords'),
  noSearchItemsPlaceholder = i18n.t('table.noMatchingRecords'),
  itemsLength = 1,
  searchTerm = '',
  loadingState = false,
  sort,
  setSort,
}) => {
  const handleSort = (name) => {
    if (setSort) {
      if (sort === name) {
        setSort(`-${name}`);
      } else {
        setSort(name);
      }
    }
  };
  const checkedTrueItems = checkedItemsValues.filter((value) => value === true);
  return (
    <TableContainer className={className}>
      <StyledMaterialTable>
        <StyledTableHead $rowCheckbox={rowCheckbox}>
          <TableRow>
            {(rowCheckbox || checkboxPlaceholderCell) && (
              <TableCell key="checkAll_checkbox" style={{ width: 50 }}>
                {rowCheckbox && (
                  <StyledCheckbox
                    color="primary"
                    name="checkAll"
                    checked={checkedTrueItems.length > 0}
                    onChange={onCheckAll}
                    indeterminate={checkedTrueItems.length > 0 && checkedTrueItems.length !== items.length}
                  />
                )}
              </TableCell>
            )}
            {columns?.map(({ colSpan, name, align, width, label, sorting = false }, i) => (
              <StyledTableCell
                onClick={() => {
                  if (sorting) {
                    handleSort(name);
                  }
                }}
                sortActive={sort === name}
                colSpan={colSpan}
                key={`${name}_${i}`}
                align={align}
                style={{ width }}
                sorting={sorting}
              >
                {label}
                {sorting && <ArrowDropDown />}
              </StyledTableCell>
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody data-cy="table-body">
          {loadingState ? (
            <tr>
              <Spinner colSpan={15} padding="30px!important" />
            </tr>
          ) : itemsLength && itemsLength > 0 ? (
            children
          ) : (
            <tr>
              <NoData colSpan={15}>
                {searchTerm !== '' ? (
                  <>
                    {`${noSearchItemsPlaceholder} ${i18n.t('table.forPhase')}`}
                    <span>{searchTerm}</span>
                  </>
                ) : (
                  noItemsPlaceholder()
                )}
              </NoData>
            </tr>
          )}
        </TableBody>
      </StyledMaterialTable>
    </TableContainer>
  );
};

export default Table;

const StyledMaterialTable = styled(MaterialTable)`
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
  thead {
    background-color: ${({ theme }) => theme.lightPrimary};
  }
  th {
    font-family: 'Rubik';
  }
  td,
  tr,
  th {
    font-weight: 500;
    color: ${({ theme }) => theme.lightPrimaryText};
    padding: 4px 16px;
    border-color: rgba(0, 0, 0, 0.05);
  }
`;

const StyledTableHead = styled(TableHead)`
  ${({ $rowCheckbox }) => !$rowCheckbox && `th{padding:13px 16px;}`}
`;

const NoData = styled.td`
  text-align: center;
  padding: 30px 0 !important;
  p {
    max-width: 350px;
    margin: auto;
  }
  > span {
    color: ${({ theme }) => theme.primary};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  path {
    fill: ${({ theme }) => (theme.paperBackground === '#fff' ? theme.primary : theme.primaryText)};
  }
`;

const StyledTableCell = styled(TableCell)`
  ${({ sorting }) => (sorting ? `cursor:pointer;` : '')}
  svg {
    margin-bottom: -6px;
    transition: 300ms;
    ${({ sortActive }) => (sortActive ? `transform: rotate(180deg);` : '')}
  }
`;
