import axios from 'axios';

const GET_DEVICE_WEATHER_REQUEST = 'GET_DEVICE_WEATHER_REQUEST';
const GET_DEVICE_WEATHER_SUCCESS = 'GET_DEVICE_WEATHER_SUCCESS';
const GET_DEVICE_WEATHER_FAIL = 'GET_DEVICE_WEATHER_FAIL';

const GET_USER_NEWEST_WEATHER_REQUEST = 'GET_USER_NEWEST_WEATHER_REQUEST';
const GET_USER_NEWEST_WEATHER_SUCCESS = 'GET_USER_NEWEST_WEATHER_SUCCESS';

export const getWeatherData = (query, startDate, endDate) => async (dispatch) => {
  dispatch(getWeatherDataRequest());
  try {
    const response = await axios.get(`/weather?${new URLSearchParams(query).toString()}&startDate=${startDate}&endDate=${endDate}`);
    const { docs, ...paginationMetadata } = response.data;
    dispatch(getWeatherDataSuccess({ docs, paginationMetadata }));
  } catch (err) {
    dispatch(getWeatherDataFail(err));
  }
};

const getWeatherDataRequest = () => ({
  type: GET_DEVICE_WEATHER_REQUEST,
});

const getWeatherDataSuccess = ({ docs, ...paginationMetadata }) => ({
  type: GET_DEVICE_WEATHER_SUCCESS,
  payload: docs,
  paginationMetadata,
});

const getWeatherDataFail = (error) => ({
  type: GET_DEVICE_WEATHER_FAIL,
  payload: error,
});

export const getUserNewestWeatherData =
  (userId = 'all') =>
  async (dispatch) => {
    dispatch(getUserNewestWeatherDataRequest());
    try {
      const response = await axios.get(`/weather/newest/user/${userId}`);
      dispatch(getUserNewestWeatherDataSuccess(response.data));
    } catch (err) {
      dispatch(getWeatherDataFail(err));
    }
  };

const getUserNewestWeatherDataRequest = () => ({
  type: GET_USER_NEWEST_WEATHER_REQUEST,
});

const getUserNewestWeatherDataSuccess = (docs) => ({
  type: GET_USER_NEWEST_WEATHER_SUCCESS,
  payload: docs,
});

const initialState = {
  loading: false,
  data: [],
  error: null,
  paginationMetadata: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICE_WEATHER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DEVICE_WEATHER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        paginationMetadata: action.paginationMetadata,
        loading: false,
      };
    case GET_DEVICE_WEATHER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_USER_NEWEST_WEATHER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_NEWEST_WEATHER_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
