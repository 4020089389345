import { useState } from 'react';
import { default as MaterialSelect } from '@material-ui/core/Select';
import { MenuItem, FormControl, InputLabel } from '@material-ui/core';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { theme, darkTheme } from './theme';
import { useSelector } from 'react-redux';
import { capitalizeText } from '../utils/other';
import i18n from '../i18n';

const Select = ({ variant = 'material', hideError, error, label, disabled, menuPosition, options = [], ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const darkMode = useSelector(({ navigation }) => navigation.darkMode);
  const currentTheme = darkMode === 'true' ? darkTheme : theme;

  const reactSelectCustomStyles = {
    menu: (provided) => ({
      ...provided,
      background: currentTheme.background,
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      cursor: 'pointer',
      color: currentTheme.primaryText,
      backgroundColor: isFocused ? currentTheme.paperBackground : 'transparent',
    }),
  };
  return (
    <Wrapper>
      {variant === 'material' ? (
        <FormControl data-cy={`select-${props.name}`}>
          {label && <InputLabel id={`label_${label}`}>{label}</InputLabel>}
          <MaterialSelect disabled={disabled} labelId={`label_${label}`} {...props}>
            {options.map(({ label, value }) => (
              <MenuItem key={`MenuItem_${value}`} value={value}>
                {label}
              </MenuItem>
            ))}
          </MaterialSelect>
        </FormControl>
      ) : (
        <ReactSelectWrapper data-cy={`select-${props.name}`} disabled={disabled} darkMode={darkMode} isOpen={isOpen}>
          {label && (
            <Label active={isOpen || props.value.label !== ''} htmlFor={props.name}>
              {label}
            </Label>
          )}
          <StyledReactSelect
            styles={reactSelectCustomStyles}
            noOptionsMessage={() => i18n.t('noDataLabels.noResults')}
            placeholder=""
            menuIsOpen={isOpen}
            formatOptionLabel={({ label }) => capitalizeText(label)}
            menuPosition={menuPosition}
            onMenuOpen={() => setIsOpen(true)}
            classNamePrefix="ReactSelect"
            onMenuClose={() => {
              setIsOpen(false);
            }}
            closeMenuOnScroll={(e) => {
              if (!e.target.className?.includes('ReactSelect__menu-list') && menuPosition === 'fixed') {
                setIsOpen(false);
              }
            }}
            options={options}
            {...props}
          />
        </ReactSelectWrapper>
      )}
      {!hideError && <ErrorMsg className={error && error !== '' ? 'active' : ''}>{error}</ErrorMsg>}
    </Wrapper>
  );
};

export default Select;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 5px;
  ${({ width }) => (width ? width : 'width: 100%')};
  .MuiFormControl-root {
    background-color: ${({ theme }) => theme.background};
    label {
      left: 5px;
    }
    .MuiInput-input {
      margin-left: 5px;
    }
  }
  button {
    position: absolute;
    right: 5px;
    top: 0;
  }
`;

const ErrorMsg = styled.p`
  color: ${({ theme }) => theme.danger};
  font-size: 0.75rem;
  min-height: 17px;
  padding: 1px 13px 0;
`;

const ReactSelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 48px;
  background-color: ${({ theme }) => theme.background};
  border-bottom: 1px solid;
  transition: 300ms;
  border-color: ${({ darkMode }) => (darkMode === 'true' ? '#e9e9e9' : '#334151')};

  label {
    color: ${({ isOpen, theme }) => (isOpen ? theme.primary : theme.primaryText)};
  }
  &:after {
    left: 0;
    right: 0;
    bottom: -2px;
    content: '';
    position: absolute;
    transform: ${({ isOpen }) => (isOpen ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #03c895;
    pointer-events: none;
  }
  &:hover {
    border-color: ${({ darkMode }) => (darkMode === 'true' ? '#e9e9e980' : '#33415180')};
  }
  ${({ disabled }) =>
    disabled &&
    `
      opacity: .5;
      cursor: not-allowed;
      label{
        cursor: not-allowed;
      }
      [class$='-control'] {
        pointer-events:none;
      }
  `}
`;

const StyledReactSelect = styled(ReactSelect)`
  width: 100%;
  [class$='-control'] {
    background: transparent;
    border: none;
    border-color: transparent;
    cursor: pointer;
    box-shadow: none;
  }
  [class$='-control'] + div {
    top: inherit !important;
    left: inherit !important;
    z-index: 2;
  }
  [class$='-indicatorSeparator'] {
    display: none;
  }
  input,
  [class$='-singleValue'] {
    text-transform: capitalize;
    color: ${({ theme }) => theme.primaryText}!important;
  }
`;

const Label = styled.label`
  z-index: 1;
  left: 13px;
  top: -3px;
  pointer-events: none;
  cursor: pointer;
  font-size: 1rem;
  position: absolute;
  transform: ${({ active }) => (active ? 'translate(-13px, 1.5px) scale(0.75)' : 'translate(0, 24px) scale(1)')};
  transition: 300ms;
`;
