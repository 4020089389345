import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import Logo from '../../shared/Logo';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import UserOverlay from './UserOverlay';
import NotificationsOverlay from './NotificationsOverlay';
import { useRef } from 'react';
import useOutsideClick from '../../utils/hooks/useOutsideClick';
import MobileSidebar from './MobileSidebar';
import Hamburger from '../../shared/Hamburger';
import { ReactComponent as BellIcon } from '../../shared/assets/bell.svg';
import { useTranslation } from 'react-i18next';

const NavigationView = ({
  user,
  handleLogout,
  isProfileOverlayOpen,
  setIsProfileOverlayOpen,
  isNotificationsOverlayOpen,
  setIsNotificationsOverlayOpen,
  handleOverlaysOutsideClick,
  toggleSidebar,
  sidebarState,
  notifications,
  markAsReadNotifications,
  pathname,
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  useOutsideClick(ref, handleOverlaysOutsideClick);
  return (
    <Container>
      <Wrapper>
        <LogoWrapper to="/">
          <Logo />
        </LogoWrapper>
        <Menu>
          <MenuItem $active={pathname === '/'} to="/">
            {t('navigation.dashboard')}
          </MenuItem>
          {user?.role === 'user' && (
            <MenuItem $active={pathname.includes('/devices')} to="/devices">
              {t('navigation.devices')}
            </MenuItem>
          )}
          {user?.role === 'admin' && (
            <>
              <MenuItem $active={pathname.includes('/users')} to="/users">
                {t('navigation.users')}
              </MenuItem>
            </>
          )}
          <MenuItem $active={pathname.includes('/messages')} to="/messages">
            {t('navigation.messages')}
          </MenuItem>
        </Menu>
        <FlexBox ref={ref}>
          <NotificationBellWrapper
            onClick={() => {
              setIsNotificationsOverlayOpen((prev) => !prev);
              if (isProfileOverlayOpen) {
                setIsProfileOverlayOpen(false);
              }
            }}
            notificationActive={notifications?.length > 0}
          >
            <BellIcon />
          </NotificationBellWrapper>
          <User
            data-cy="user-overlay-trigger"
            onClick={() => {
              setIsProfileOverlayOpen((prev) => !prev);
              if (isNotificationsOverlayOpen) {
                setIsNotificationsOverlayOpen(false);
              }
            }}
          >
            {`${user.name} ${user.surname}`}
            <StyledArrowForwardIosIcon active={isProfileOverlayOpen.toString()} />
          </User>
          <UserOverlay
            pathname={pathname}
            user={user}
            handleLogout={handleLogout}
            isProfileOverlayOpen={isProfileOverlayOpen}
            setIsProfileOverlayOpen={setIsProfileOverlayOpen}
          />
          <NotificationsOverlay
            isNotificationsOverlayOpen={isNotificationsOverlayOpen}
            notifications={notifications}
            markAsReadNotifications={markAsReadNotifications}
            user={user}
          />
          <HamburgerWrapper>
            <Hamburger active={sidebarState} onClick={toggleSidebar} />
          </HamburgerWrapper>
        </FlexBox>
      </Wrapper>
      <MobileSidebar pathname={pathname} user={user} toggleSidebar={toggleSidebar} sidebarState={sidebarState} handleLogout={handleLogout} />
    </Container>
  );
};

export default withRouter(NavigationView);

const Container = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.paperBackground};
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  ${({ theme }) => `${theme.mq.tablet}{
    height: 70px;
  }`}
`;

const Wrapper = styled.div`
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const LogoWrapper = styled(Link)`
  svg path.black-element {
    ${({ theme }) => theme.background === '#404040' && `fill:#fff`}
  }
`;

const Menu = styled.ul`
  display: none;
  ${({ theme }) => `${theme.mq.tablet}{
    display:inline-block;
  }`}
`;

const MenuItem = styled(Link)`
  text-decoration: none;
  margin: 0 10px;
  color: ${({ theme }) => theme.lightPrimaryText};
  transition: 300ms;
  font-weight: 500;
  ${({ $active, theme }) => $active && `color:${theme.primary};`}
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const User = styled.div`
  cursor: pointer;
  margin-left: 10px;
  padding-left: 15px;
  border-left: 1px solid rgba(89, 104, 130, 0.25);
  color: ${({ theme }) => theme.lightPrimaryText};
  display: none;
  align-items: center;
  font-weight: 500;
  ${({ theme }) => `${theme.mq.tablet}{
    display:flex;
  }`}
`;

const NotificationBellWrapper = styled.div`
  position: relative;
  cursor: pointer;
  ${({ notificationActive, theme }) =>
    notificationActive &&
    `
    svg path.yellow{fill:${theme.secondary}!important}
  `}
  svg path.stroke {
    ${({ theme }) => `fill: ${theme.defaultIcon}`}
  }
  svg path.background {
    ${({ theme }) => `fill: ${theme.paperBackground}`}
  }
  svg {
    width: 20px;
    ${({ theme }) => `${theme.mq.tablet}{
      width: 24px;
    }`}
  }
`;
const StyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)`
  transform: rotate(90deg);
  width: 15px;
  margin-left: 5px;
  transition: 300ms;
  ${({ active }) => active === 'true' && `transform:rotate(-90deg);`}
  path {
    fill: ${({ theme }) => theme.lightPrimaryText};
  } ;
`;

const HamburgerWrapper = styled.div`
  display: block;
  padding: 0 5px 0 10px;
  ${({ theme }) => `${theme.mq.tablet}{
      display:none;
  }`}
  >div>div {
    background: ${({ theme }) => theme.primaryText}!important;
  }
`;
