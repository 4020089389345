import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const ReCaptcha = ({ setFieldValue, fieldName = 'recaptchaToken', error, className, align = 'center', margin = '5px 0' }) => {
  const { darkMode } = useSelector(({ navigation }) => navigation);
  return (
    <Wrapper className={className} align={align} margin={margin}>
      {process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY !== 'disable' && (
        <>
          <ReCAPTCHA
            theme={darkMode === 'true' ? 'dark' : 'light'}
            size={window.innerWidth < 400 ? 'compact' : 'normal'}
            hl={localStorage.getItem('language') ?? 'pl'}
            onChange={(token) => {
              setFieldValue(fieldName, token);
            }}
            onExpired={() => setFieldValue(fieldName, '')}
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
          />
          {error !== '' && <CaptchaError>{error}</CaptchaError>}
        </>
      )}
    </Wrapper>
  );
};

export default ReCaptcha;

const Wrapper = styled.div`
  text-align: ${({ align }) => align};
  margin: ${({ margin }) => margin};
  > div {
    display: inline-block;
  }
`;

const CaptchaError = styled.p`
  color: ${({ theme }) => theme.danger};
  font-size: 0.75rem;
  min-height: 17px;
  padding: 1px 13px 0;
`;
