import Modal from '../src/shared/Modal';
import styled from 'styled-components';
import Button from '../src/shared/Button';
import { Formik } from 'formik';
import Input from '../src/shared/Input';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const ChangePasswordModal = ({ isChangePasswordModalOn, setChangePasswordModal, user, changePassword }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    oldPassword: '',
    password: '',
    confirmedPassword: '',
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(t('validationTexts.oldPasswordRequired')),
    password: Yup.string().required(t('validationTexts.passwordRequired')),
    confirmedPassword: Yup.string().required(t('validationTexts.confirmPasswordRequired')),
  });

  return (
    <Modal
      title={t('changePasswordModal.title')}
      show={isChangePasswordModalOn}
      exitButton={true}
      onCancel={() => {
        setChangePasswordModal(false);
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setFieldError }) => {
          const res = await changePassword(user?._id, values);
          if (res?.status === 200) {
            enqueueSnackbar(t('changePasswordModal.passwordChanged'), { variant: 'success' });
            setChangePasswordModal(false);
          } else {
            setFieldError('oldPassword', t('validationTexts.wrongPassword'));
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit, handleBlur }) => (
          <Form onSubmit={handleSubmit}>
            <AddingNewWrapper>
              <StyledInput
                name="oldPassword"
                label={t('changePasswordModal.oldPassword')}
                type="password"
                error={touched.oldPassword && errors.oldPassword}
                value={values.oldPassword}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <StyledInput
                name="password"
                label={t('changePasswordModal.password')}
                type="password"
                error={touched.password && errors.password}
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <StyledInput
                name="confirmedPassword"
                label={t('changePasswordModal.confirmedPassword')}
                type="password"
                error={touched.confirmedPassword && errors.confirmedPassword}
                value={values.confirmedPassword}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <SubmitBox>
                <Button type="submit">{t('changePasswordModal.submit')}</Button>
              </SubmitBox>
            </AddingNewWrapper>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangePasswordModal;

const Form = styled.form`
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 0;
`;

const AddingNewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  margin: 10px auto;
`;

const StyledInput = styled(Input)`
  width: 250px;
`;

const SubmitBox = styled.div`
  padding-top: 20px;
  text-align: center;
`;

// const SuccessWrapper = styled.div``;

// const SuccessText = styled.div``;
