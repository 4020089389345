import { Route, Redirect } from 'react-router-dom';
import Spinner from '../../shared/Spinner';
import { useSnackbar } from 'notistack';

const AuthenticatedRoute = ({ component: C, isAuthenticated, loading, userRole, user, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return <Spinner component="div" />;
        } else if (isAuthenticated && (!userRole || userRole === user?.role)) {
          return <C {...props} isAuthenticated user={user} {...rest} />;
        } else {
          if (!isAuthenticated) {
            enqueueSnackbar('Twoja sesja wygasła zaloguj się ponownie', { variant: 'error' });
          }
          return <Redirect to={`/`} />;
        }
      }}
    />
  );
};

export default AuthenticatedRoute;
