import { useState, useEffect } from 'react';

const usePagination = ({ initPage = 0, initRowsPerPage = 10, totalCount = 0 } = {}) => {
  const [page, setPage] = useState(initPage);
  const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage);
  const [count, setCount] = useState(totalCount);

  useEffect(() => {
    setCount(totalCount);
    // if (paginationMetadata?.totalCount < rowsPerPage) {
    setPage(0);
    // }
  }, [totalCount]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return { page, rowsPerPage, count, setCount, handleChangePage, handleChangeRowsPerPage };
};

export default usePagination;
