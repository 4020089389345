import { useEffect, useState } from 'react';
import UsersView from './UsersView';
import { connect } from 'react-redux';
import { createUser, getUsers, editUser, createAdmin, deleteUser } from '../../redux/modules/auth';
import useDebounce from '../../utils/hooks/useDebounce';
import { getAdminStats } from '../../redux/modules/dailyStats';
import { getUserNewestWeatherData } from '../../redux/modules/weather';
import { formatDeviceEnergyProduction, formatUsersEnergyProduction, formatDevicesWeatherData } from '../../utils/objectsFormating';
import dayjs from 'dayjs';

const UsersContainer = ({
  createUser,
  createAdmin,
  getUsers,
  usersList,
  editUser,
  paginationMetadata,
  usersLoading,
  getAdminStats,
  adminStats,
  weatherData,
  getUserNewestWeatherData,
  deleteUser,
}) => {
  const [isAddUserModal, setIsAddUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState({ shown: false, userId: null });
  const [tableCollapsedItems, setTableCollapsedItems] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [tableSearchTerm, setTableSearchTerm] = useState('');
  const debouncedTableSearchTerm = useDebounce(tableSearchTerm);
  const [devicesEnergyProduction, setDevicesEnergyProduction] = useState({});
  const [usersEnergyProduction, setUsersEnergyProduction] = useState({});
  const [devicesWeatherData, setDevicesWeatherData] = useState({});

  useEffect(() => {
    getUsers('devices', debouncedTableSearchTerm, rowsPerPage, page + 1);
  }, [debouncedTableSearchTerm, rowsPerPage, page]);

  useEffect(() => {
    getUserNewestWeatherData();
    getAdminStats(dayjs().subtract(30, 'day').startOf('day').toISOString(), dayjs().endOf('day').toISOString());
  }, []);

  useEffect(() => {
    if (weatherData.length > 0) {
      setDevicesWeatherData(formatDevicesWeatherData(weatherData, usersList.map(({ devices }) => devices).flat()));
    }
  }, [weatherData, usersList]);

  useEffect(() => {
    const deviceEnergyProd = formatDeviceEnergyProduction(adminStats, { dateRange: 'last30', phase: '0' });
    const userEnergyProd = formatUsersEnergyProduction(deviceEnergyProd, usersList);
    setDevicesEnergyProduction(deviceEnergyProd);
    setUsersEnergyProduction(userEnergyProd);
  }, [adminStats, usersList]);

  useEffect(() => {
    if (paginationMetadata?.totalCount) {
      if (paginationMetadata?.totalCount < rowsPerPage) {
        setPage(0);
      }
      setCount(paginationMetadata.totalCount);
    }
  }, [paginationMetadata]);

  const handleTableCollapse = (target) => {
    setTableCollapsedItems((prev) => ({
      ...prev,
      [target]: !prev[target],
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <UsersView
      tableCollapsedItems={tableCollapsedItems}
      handleTableCollapse={handleTableCollapse}
      isAddUserModal={isAddUserModal}
      setIsAddUserModal={setIsAddUserModal}
      createUser={createUser}
      createAdmin={createAdmin}
      usersList={usersList}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
      count={count}
      editUserModal={editUserModal}
      setEditUserModal={setEditUserModal}
      editUser={editUser}
      tableSearchTerm={tableSearchTerm}
      debouncedTableSearchTerm={debouncedTableSearchTerm}
      setTableSearchTerm={setTableSearchTerm}
      usersLoading={usersLoading}
      devicesEnergyProduction={devicesEnergyProduction}
      usersEnergyProduction={usersEnergyProduction}
      weatherData={weatherData}
      devicesWeatherData={devicesWeatherData}
      deleteUser={deleteUser}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  usersList: state.auth.usersList,
  usersLoading: state.auth.usersLoading,
  paginationMetadata: state.auth.paginationMetadata,
  adminStats: state.dailyStats.adminStats,
  weatherData: state.weather.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (body) => dispatch(createUser(body)),
    createAdmin: (body) => dispatch(createAdmin(body)),
    editUser: (userId, body, restore) => dispatch(editUser(userId, body, restore)),
    getUsers: (populates, searchTerm, pageSize, pageNumber) => dispatch(getUsers(populates, searchTerm, pageSize, pageNumber)),
    getAdminStats: (startDate, endDate) => dispatch(getAdminStats(startDate, endDate)),
    getUserNewestWeatherData: (userId) => dispatch(getUserNewestWeatherData(userId)),
    deleteUser: (userId) => dispatch(deleteUser(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
