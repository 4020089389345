import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Logo from '../../shared/Logo';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useTranslation } from 'react-i18next';

const MobileSidebar = ({ user, sidebarState, toggleSidebar, handleLogout, pathname }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <SidebarContent isOpen={sidebarState}>
        <LogoWrapper to="/">
          <Logo />
        </LogoWrapper>
        <MobileMenu>
          <MenuItem $active={pathname === '/'} to="/">
            {t('navigation.dashboard')}
          </MenuItem>
          {user?.role === 'admin' ? (
            <MenuItem $active={pathname.includes('/users')} to="/users">
              {t('navigation.users')}
            </MenuItem>
          ) : (
            <MenuItem $active={pathname.includes('/devices')} to="/devices">
              {t('navigation.devices')}
            </MenuItem>
          )}
          <MenuItem $active={pathname === '/profile'} to="/profile">
            {t('navigation.profile')}
          </MenuItem>
          <MenuItem $active={pathname === '/terms'} to="/terms">
            {t('navigation.terms')}
          </MenuItem>
          <MenuItem $active={pathname === '/docs'} to="/docs">
            {t('navigation.docs')}
          </MenuItem>
          <MenuItem $active={pathname === '/report-error'} to="/report-error">
            {t('navigation.reportError')}
          </MenuItem>
        </MobileMenu>
        <MobileUser isOpen={sidebarState}>
          <UserName>{`${user.name} ${user.surname}`}</UserName>
          <UserEmail>{user.email}</UserEmail>
          <LogoutListItem color="danger" onClick={handleLogout}>
            <StyledExitToAppIcon />
            {t('navigation.logout')}
          </LogoutListItem>
        </MobileUser>
      </SidebarContent>
      <Mask onClick={() => toggleSidebar(false)} isOpen={sidebarState} />
    </Wrapper>
  );
};

export default MobileSidebar;

const Wrapper = styled.div`
  display: block;
  ${({ theme }) => `${theme.mq.tablet}{
        display:none;
    }`}
`;

const LogoWrapper = styled.div`
  svg path.black-element {
    ${({ theme }) => theme.background === '#404040' && `fill:#fff`}
  }
`;

const MenuItem = styled(Link)`
  text-decoration: none;
  margin: 0 10px;
  color: ${({ theme }) => theme.lightPrimaryText};
  transition: 300ms;
  ${({ $active, theme }) => $active && `color:${theme.primary};`}
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const SidebarContent = styled.div`
  width: 275px;
  height: 100vh;
  background-color: ${({ theme }) => theme.paperBackground};
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  padding: 30px 20px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  transition: 300ms;
  ${({ isOpen }) =>
    isOpen
      ? `
      left:0;
    `
      : `
      left:-300px;
    `}
`;

const Mask = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  transition: 150ms;
  ${({ isOpen }) =>
    isOpen
      ? `
      left:0;
    `
      : `
      left:-100vw;
    `}
`;

const MobileMenu = styled.ul`
  > a {
    display: block;
    margin: 10px;
  }
`;

const MobileUser = styled.div`
  position: fixed;
  bottom: 30px;
  color: ${({ theme }) => theme.lightPrimaryText};
  transition: 300ms;
  ${({ isOpen }) =>
    isOpen
      ? `
      left: 20px;
    `
      : `
      left:-300px;
    `}
`;

const LogoutListItem = styled.li`
  width: 100%;
  text-decoration: none;
  color: ${({ theme, color }) => (color ? theme[color] : theme.primaryText)};
  padding: 7.5px 0;
  margin-top: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 300ms;
`;

const StyledExitToAppIcon = styled(ExitToAppIcon)`
  height: 20px;
  width: 20px;
  margin-right: 5px;
  margin-top: -0.5px;
  path {
    fill: ${({ theme }) => theme.danger};
  }
`;

const UserName = styled.p``;

const UserEmail = styled.p`
  color: ${({ theme }) => theme.secondaryText};
`;
