import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as EmailIcon } from '../../shared/assets/envelope.svg';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Grow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const UserOverlay = ({ handleLogout, isProfileOverlayOpen, user, pathname }) => {
  const { t } = useTranslation();
  return (
    <Grow style={{ transformOrigin: '100% 0 0' }} in={isProfileOverlayOpen}>
      <Wrapper data-cy="user-overlay">
        <Email>
          <StyledEmailIcon />
          {user.email}
        </Email>
        <OverlayList>
          <OverlayListItem $active={pathname === '/profile'} to="/profile">
            {t('navigation.profile')}
          </OverlayListItem>
          <OverlayListItem $active={pathname === '/terms'} to="/terms">
            {t('navigation.terms')}
          </OverlayListItem>
          <OverlayListItem $active={pathname === '/docs'} to="/docs">
            {t('navigation.docs')}
          </OverlayListItem>
          <OverlayListItem $active={pathname === '/report-error'} to="/report-error">
            {t('navigation.reportError')}
          </OverlayListItem>
          <LogoutListItem color="danger" data-cy="logout-btn" onClick={handleLogout}>
            <StyledExitToAppIcon />
            {t('navigation.logout')}
          </LogoutListItem>
        </OverlayList>
      </Wrapper>
    </Grow>
  );
};

export default UserOverlay;

const Wrapper = styled.div`
  position: absolute;
  right: -5px;
  top: 35px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: ${({ theme }) => theme.paperBackground};
`;

const Email = styled.div`
  padding: 17px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.background};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.primaryText};
  font-weight: 500;
`;

const OverlayList = styled.ul`
  margin: 0;
`;

const OverlayListItem = styled(Link)`
  display: block;
  width: 100%;
  text-decoration: none;
  color: ${({ theme, color }) => (color ? theme[color] : theme.primaryText)};
  font-size: 14px;
  padding: 10px 25px;
  transition: 300ms;
  border-bottom: 1px solid ${({ theme }) => theme.background};
  ${({ $active, theme }) =>
    $active &&
    `
    color:${theme.primary};
    pointer-events:none;
  `}
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const LogoutListItem = styled.li`
  width: 100%;
  text-decoration: none;
  color: ${({ theme, color }) => (color ? theme[color] : theme.primaryText)};
  font-size: 14px;
  padding: 7.5px 25px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 300ms;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const StyledEmailIcon = styled(EmailIcon)`
  margin-right: 10px;
  width: 25px;
  height: 25px;
  path {
    fill: ${({ theme }) => theme.defaultIcon};
  }
`;

const StyledExitToAppIcon = styled(ExitToAppIcon)`
  height: 18px;
  width: 18px;
  margin-right: 5px;
  margin-top: -0.5px;
  path {
    fill: ${({ theme }) => theme.danger};
  }
`;
