import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
*, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
::-moz-selection { background: #1890FF; }
::selection { background: #1890FF; }
::-webkit-scrollbar {
    background-color: #fff0;
    width: 12px;
}
::-webkit-scrollbar-button {
    display: none;
}
::-webkit-scrollbar-thumb {
    background-color: #dadce0;
    border-radius: 16px;
}
::-webkit-scrollbar-track {
    background-color: #fff0;
}
html {
    scroll-behavior: smooth;
}
body{
    background: ${({ theme }) => theme.background};
    margin: 0;
    overflow: overlay;
}
body.freeze{
    overflow-y: hidden;
}
#root{
    overflow-x: hidden;
}
ul {
    list-style-type: none;
    padding: 0;
}
h1,h2,h3,h4,h5,h6 {
    margin: 0;
    font-weight: 500;
    font-family: "Rubik", sans-serif;
    color: ${({ theme }) => theme.primaryText};
}
h1{
    font-size: 38px;
}
h2{
    font-size: 30px;
}
h4{
    font-size: 19px;
}
h6{
    font-size: 12px;
    font-weight: 400;
}
input,textarea, button {
    font-family: "Roboto",sans-serif;
}
button {
    outline: none;
    color: #fff;
}
a, label, div {
    font-family: "Roboto", sans-serif;
}
span {
    font-family: "Roboto", sans-serif;
    margin: 0;
}
p, li {
    font-family: "Roboto", sans-serif;
    color: ${({ theme }) => theme.primaryText};
    margin: 0;
}
.tox-notification {
    display: none;
}
.Toastify__toast--success {
    background-color: rgb(69,193,91);
}
.Toastify__toast--warning {
    background-color: #4C5BAC;
}
.Toastify__toast--error {
    background-color: rgb(228,82,121);
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.background} inset !important;
}
input:-webkit-autofill {
    -webkit-text-fill-color: ${({ theme }) => theme.primaryText} !important;
}
.grecaptcha-badge{
    z-index:99;
}
`;
export default GlobalStyle;
