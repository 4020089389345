import { useState } from 'react';
import styled from 'styled-components';
import Paper from './Paper';
import { theme } from './theme';
import { AreaChart, XAxis, YAxis, Area, Tooltip, CartesianGrid, ResponsiveContainer, ReferenceLine } from 'recharts';
import Spinner from './Spinner';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ReactComponent as ChartIcon } from './assets/chart.svg';
import { ReactComponent as ThermometerIcon } from '../shared/assets/thermometer.svg';
import { ReactComponent as HumidityIcon } from '../shared/assets/humidity.svg';
import { ReactComponent as ProductionIcon } from '../shared/assets/production-arrow.svg';
import { ReactComponent as UsageIcon } from '../shared/assets/usage-arrow.svg';
import { ReactComponent as UvIndexIcon } from '../shared/assets/uv-index.svg';
import { ReactComponent as CloudIcon } from '../shared/assets/cloud.svg';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
dayjs.extend(utc);
dayjs.extend(timezone);

const ChartPaper = ({ data: { chartData = [], dateFormat, isDaily }, showDataSwitch = false, loadingState, selfConsumption }) => {
  const [areaFilters, setAreaFilters] = useState({
    usage: true,
    production: true,
    humidity: false,
    temperature: false,
    cloudCover: false,
    uvIndex: false,
  });
  const { t } = useTranslation();
  const handleFilterToggle = (field) => {
    const trueLength = Object.values(areaFilters).filter((v) => v)?.length;
    let additionalChange = {};
    if (trueLength === 1) {
      if (field === 'production') {
        additionalChange = { usage: true };
      } else {
        additionalChange = { production: true };
      }
    }
    setAreaFilters((prev) => ({
      ...prev,
      [field]: !prev[field],
      ...additionalChange,
    }));
  };

  return (
    <StyledPaper icon={() => <StyledChartIcon />} title={t('chartPaper.title')}>
      <DataSwitch showDataSwitch={showDataSwitch}>
        <SwitchItem data-tip data-for={`popup-production`} onClick={() => handleFilterToggle('production')} active={areaFilters.production}>
          <StyledReactTooltip
            delayShow={1000}
            id={`popup-production`}
            getContent={() => <p>{t('chartPaper.hints.energyProduction')}</p>}
            effect="solid"
          />
          <ProductionIcon />
        </SwitchItem>
        <SwitchItem data-tip data-for={`popup-usage`} onClick={() => handleFilterToggle('usage')} active={areaFilters.usage}>
          <StyledReactTooltip delayShow={1000} id={`popup-usage`} getContent={() => <p>{t('chartPaper.hints.energyUsage')}</p>} effect="solid" />
          <UsageIcon />
        </SwitchItem>
        {showDataSwitch && (
          <>
            <SwitchItem data-tip data-for={`popup-humidity`} onClick={() => handleFilterToggle('humidity')} active={areaFilters.humidity}>
              <StyledReactTooltip
                delayShow={1000}
                id={`popup-humidity`}
                getContent={() => (
                  <FilterTipWrapper>
                    {t('chartPaper.hints.humidity')}
                    <span>Powered by AccuWeather</span>
                  </FilterTipWrapper>
                )}
                effect="solid"
              />
              <HumidityIcon />
            </SwitchItem>
            <SwitchItem data-tip data-for={`popup-temperature`} onClick={() => handleFilterToggle('temperature')} active={areaFilters.temperature}>
              <StyledReactTooltip
                delayShow={1000}
                id={`popup-temperature`}
                getContent={() => (
                  <FilterTipWrapper>
                    {t('chartPaper.hints.temperature')}
                    <span>Powered by AccuWeather</span>
                  </FilterTipWrapper>
                )}
                effect="solid"
              />
              <ThermometerIcon />
            </SwitchItem>
            <SwitchItem data-tip data-for={`popup-cloudCover`} onClick={() => handleFilterToggle('cloudCover')} active={areaFilters.cloudCover}>
              <StyledReactTooltip
                delayShow={1000}
                id={`popup-cloudCover`}
                getContent={() => (
                  <FilterTipWrapper>
                    {t('chartPaper.hints.cloudCover')}
                    <span>Powered by AccuWeather</span>
                  </FilterTipWrapper>
                )}
                effect="solid"
              />
              <CloudIcon />
            </SwitchItem>
            <SwitchItem data-tip data-for={`popup-uvIndex`} onClick={() => handleFilterToggle('uvIndex')} active={areaFilters.uvIndex}>
              <StyledReactTooltip
                delayShow={1000}
                id={`popup-uvIndex`}
                getContent={() => (
                  <FilterTipWrapper>
                    {t('chartPaper.hints.uvIndex')}
                    <span>Powered by AccuWeather</span>
                  </FilterTipWrapper>
                )}
                effect="solid"
              />
              <UvIndexIcon />
            </SwitchItem>
          </>
        )}
      </DataSwitch>
      <Subtitle>{t('chartPaper.subtitle')}</Subtitle>
      {loadingState ? (
        <NoDataMask>
          <Spinner component="div" />
        </NoDataMask>
      ) : chartData?.length === 0 ? (
        <NoDataMask>
          <NoDataMessage>{t('chartPaper.noData')}</NoDataMessage>
        </NoDataMask>
      ) : (
        <ChartWrapper showDataSwitch={showDataSwitch}>
          <StyledResponsiveContainer width="100%" height={350}>
            <AreaChart key="chart" data={chartData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="green" x1="1" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={theme.primary} stopOpacity={1} />
                  <stop offset="20%" stopColor={theme.primary} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={theme.primary} stopOpacity={0.2} />
                </linearGradient>
                <linearGradient id="blue" x1="1" y1="0" x2="0" y2="2">
                  <stop offset="0%" stopColor={theme.thirdary} stopOpacity={1} />
                  <stop offset="20%" stopColor={theme.thirdary} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={theme.thirdary} stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <ReferenceLine stroke={theme.primary} x={dayjs().set('minutes', 0).set('second', 0).set('millisecond', 0).toISOString()} />
              <XAxis minTickGap={5} tickFormatter={(date) => dayjs(date).format(dateFormat)} dataKey="date" />
              <YAxis tickCount={10} domain={[0, (dataMax) => Math.round(dataMax + 5 * 1) / 1]} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip animationDuration={300} content={(props) => CustomTooltip(props, isDaily, areaFilters, selfConsumption)} />
              {areaFilters.production && (
                <Area
                  key="production"
                  type="monotone"
                  dataKey={isDaily ? 'dailyPower' : 'power'}
                  stroke={theme.chartColors.production}
                  fillOpacity={1}
                  fill="url(#green)"
                />
              )}
              {areaFilters.usage && (
                <Area
                  key="usage"
                  type="monotone"
                  dataKey={isDaily ? 'dailyUsage' : 'usage'}
                  stroke={theme.chartColors.usage}
                  fillOpacity={1}
                  fill="url(#blue)"
                />
              )}
              {areaFilters.temperature && (
                <Area key="temperature" type="monotone" dataKey="temperature" stroke={theme.chartColors.temperature} temperature fillOpacity={0} />
              )}
              {areaFilters.humidity && <Area key="humidity" type="monotone" dataKey="humidity" stroke={theme.chartColors.humidity} fillOpacity={0} />}
              {areaFilters.uvIndex && <Area key="uv" type="monotone" dataKey="uvIndex" stroke={theme.chartColors.uvIndex} fillOpacity={0} />}
              {areaFilters.cloudCover && (
                <Area key="clouds" type="monotone" dataKey="cloudCover" stroke={theme.chartColors.cloudCover} fillOpacity={0} />
              )}
            </AreaChart>
          </StyledResponsiveContainer>
        </ChartWrapper>
      )}
    </StyledPaper>
  );
};

export default ChartPaper;

const CustomTooltip = (props, isDaily, areaFilters, selfConsumption) => {
  const { t } = useTranslation();
  const payload = props?.payload ? props?.payload[0]?.payload : {};
  const selfConsumptionValue = selfConsumption?.find(({ date }) => date === payload?.date)?.selfConsumption;
  return (
    <TooltipWrapper>
      <TooltipDate>{`${dayjs(payload?.date).format(isDaily ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm')} (${dayjs(payload?.date).format(
        'dddd',
      )})`}</TooltipDate>
      {areaFilters?.production && (
        <TooltipValue color={theme.primary}>
          <span>{t('chartPaper.tooltip.averageProduction')}: </span>
          {payload?.[isDaily ? 'dailyPower' : 'power']
            ? Math.round((payload?.[isDaily ? 'dailyPower' : 'power'] + Number.EPSILON) * 100000) / 100000
            : 0}{' '}
          kWh
        </TooltipValue>
      )}
      {areaFilters?.usage && (
        <TooltipValue color={theme.thirdary}>
          <span>{t('chartPaper.tooltip.averageUsage')}: </span>
          {payload?.[isDaily ? 'dailyUsage' : 'usage']
            ? Math.round((payload?.[isDaily ? 'dailyUsage' : 'usage'] + Number.EPSILON) * 100000) / 100000
            : 0}{' '}
          kWh
        </TooltipValue>
      )}
      {areaFilters?.temperature && (
        <TooltipValue color={theme.chartColors.temperature}>
          <span>{t('chartPaper.tooltip.temperature')}: </span>
          {payload?.temperature > 0 ? Math.round((payload?.temperature + Number.EPSILON) * 100000) / 100000 : 0}°C
        </TooltipValue>
      )}
      {areaFilters?.humidity && (
        <TooltipValue color={theme.chartColors.humidity}>
          <span>{t('chartPaper.tooltip.humidity')}: </span>
          {payload?.humidity > 0 ? Math.round((payload?.humidity + Number.EPSILON) * 100000) / 100000 : 0}%
        </TooltipValue>
      )}
      {areaFilters?.cloudCover && (
        <TooltipValue color={theme.chartColors.cloudCover}>
          <span>{t('chartPaper.tooltip.cloudCover')}: </span>
          {payload?.cloudCover ?? 0}%
        </TooltipValue>
      )}
      {areaFilters?.uvIndex && (
        <TooltipValue color={theme.chartColors.uvIndex}>
          <span>{t('chartPaper.tooltip.uvIndex')}: </span>
          {payload?.uvIndex ?? 0}
        </TooltipValue>
      )}
      {(areaFilters?.production || areaFilters?.usage) && selfConsumption?.length > 0 && (
        <TooltipDetails color={theme.thirdary}>
          <span>{t('chartPaper.tooltip.selfConsumption')}: </span>
          {selfConsumptionValue > 0 ? Math.round((selfConsumptionValue + Number.EPSILON) * 100000) / 100000 : 0} kWh
        </TooltipDetails>
      )}
    </TooltipWrapper>
  );
};

const Subtitle = styled.h6`
  position: absolute;
  top: 45px;
  left: 65px;
  width: 80%;
  max-width: 90%;
  ${({ theme }) => `${theme.mq.tablet}{
    width: 100%;
    left: 75px;
  }`}
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  tspan {
    fill: ${({ theme }) => theme.secondaryText};
    font-size: 12px;
    text-transform: capitalize;
  }
`;

const ChartWrapper = styled.div`
  ${({ showDataSwitch }) => (showDataSwitch ? 'margin: 110px 0 0 -15px;' : 'margin: 60px 0 0 -15px;')}
  ${({ theme }) => `${theme.mq.small}{
    margin: 65px 0 0 -15px;
  }`}
  ${({ theme }) => `${theme.mq.tablet}{
      margin: 15px 15px 0 0;
  }`}
`;

const TooltipWrapper = styled.div`
  background-color: ${({ theme }) => theme.paperBackground};
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 4px;
`;

const TooltipDate = styled.p`
  font-weight: 500;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.primaryText};
`;

const TooltipValue = styled.p`
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ color }) => color};
`;

const TooltipDetails = styled(TooltipValue)`
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondaryText};
`;

const NoDataMessage = styled.p`
  text-align: center;
  width: 250px;
  font-size: 18px;
  font-weight: 600;
`;

const NoDataMask = styled.div`
  width: 100%;
  height: 100%;
  background-color: #00000010;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPaper = styled(Paper)`
  position: relative;
  min-height: 440px;
`;

const DataSwitch = styled.div`
  display: flex;
  width: ${({ showDataSwitch }) => (showDataSwitch ? 245 : 100)}px;
  height: 40px;
  border: 2px solid ${({ theme }) => theme.defaultIcon};
  background-color: ${({ theme }) => theme.paperBackground};
  position: absolute;
  top: 70px;
  border-radius: 4px;
  overflow: hidden;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 1;
  ${({ theme }) => `${theme.mq.small}{
    top: 70px;
    right: 15px;
    left: auto;
  }`}
  ${({ theme }) => `${theme.mq.tablet}{
    top: 10px;
    right: 30px;
  }`}
`;

const SwitchItem = styled.a`
  cursor: pointer;
  height: 100%;
  width: 50px;
  z-index: 2;
  padding: 5px;
  transition: 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: #999;
    height: 100%;
    width: 30px;
    path {
      transition: 300ms;
      fill: ${({ theme }) => theme.defaultIcon};
    }
  }
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.defaultIcon};
  }
  ${({ active, theme }) =>
    active &&
    `
    background-color: ${theme.primary};
    svg path {
      fill: #fff;
    }
  `}
`;

const StyledChartIcon = styled(ChartIcon)`
  .fil7 {
    fill: ${({ theme }) => theme.defaultIcon};
  }
`;

const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 300px;
  background-color: #222222ee;
  p {
    color: #fff;
    margin-bottom: 3px;
  }
`;

const FilterTipWrapper = styled.div`
  span {
    display: block;
    margin-top: 5px;
    font-size: 10px;
    text-align: right;
  }
`;
