import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//locales
import pl from './locales/pl.json';
import en from './locales/en.json';
import de from './locales/de.json';

const resources = {
  de: {
    translation: de,
  },
  pl: {
    translation: pl,
  },
  en: {
    translation: en,
  },
};

const lng = localStorage.getItem('language') ?? 'pl';

i18n.use(initReactI18next).init({
  resources,
  lng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
