import { useEffect, useState } from 'react';
import DevicesView from './DevicesView';
import { connect } from 'react-redux';
import { getDevices, createDevice, editDevice } from '../../redux/modules/device';
import useDebounce from '../../utils/hooks/useDebounce';
import { getUserStats } from '../../redux/modules/dailyStats';
import { getUserNewestWeatherData } from '../../redux/modules/weather';
import { formatDeviceEnergyProduction, formatDevicesWeatherData } from '../../utils/objectsFormating';
import dayjs from 'dayjs';

const DevicesContainer = ({
  paginationMetadata,
  getDevices,
  user,
  devices,
  createDevice,
  editDevice,
  devicesLoading,
  userStats,
  getUserStats,
  weatherData,
  getUserNewestWeatherData,
}) => {
  const [isAddDeviceModal, setAddDeviceModal] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [warningModal, setWarningModal] = useState({ shown: false, targetId: null });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [tableSearchTerm, setTableSearchTerm] = useState('');
  const debouncedTableSearchTerm = useDebounce(tableSearchTerm);
  const [devicesEnergyProduction, setDevicesEnergyProduction] = useState({});
  const [devicesWeatherData, setDevicesWeatherData] = useState({});

  useEffect(() => {
    if (user) {
      getDevices(user?._id, debouncedTableSearchTerm, rowsPerPage, page + 1);
    }
  }, [user, debouncedTableSearchTerm, rowsPerPage, page]);

  useEffect(() => {
    if (user?._id) {
      getUserStats(user?._id, dayjs().subtract(30, 'day').startOf('day').toISOString(), dayjs().endOf('day').toISOString());
      getUserNewestWeatherData(user?._id);
    }
  }, [user?._id]);

  useEffect(() => {
    if (weatherData.length > 0) {
      setDevicesWeatherData(formatDevicesWeatherData(weatherData, devices));
    }
  }, [weatherData, devices, user]);

  useEffect(() => {
    if (paginationMetadata?.totalCount) {
      if (paginationMetadata?.totalCount < rowsPerPage) {
        setPage(0);
      }
      setCount(paginationMetadata.totalCount);
    }
  }, [paginationMetadata]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    const energyProd = formatDeviceEnergyProduction(userStats, { dateRange: 'last30', phase: '0' });
    setDevicesEnergyProduction(energyProd);
  }, [userStats]);

  return (
    <DevicesView
      isAddDeviceModal={isAddDeviceModal}
      setAddDeviceModal={setAddDeviceModal}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
      count={count}
      devices={devices}
      user={user}
      createDevice={createDevice}
      setTableSearchTerm={setTableSearchTerm}
      tableSearchTerm={tableSearchTerm}
      isConfirmModal={isConfirmModal}
      setIsConfirmModal={setIsConfirmModal}
      warningModal={warningModal}
      setWarningModal={setWarningModal}
      editDevice={editDevice}
      devicesLoading={devicesLoading}
      debouncedTableSearchTerm={debouncedTableSearchTerm}
      devicesEnergyProduction={devicesEnergyProduction}
      devicesWeatherData={devicesWeatherData}
    />
  );
};

const mapStateToProps = (state) => ({
  devices: state.device.devices,
  devicesLoading: state.device.loading,
  paginationMetadata: state.device.paginationMetadata,
  userStats: state.dailyStats.userStats,
  weatherData: state.weather.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getDevices: (userId, searchTerm, pageSize, pageNumber) => dispatch(getDevices(userId, searchTerm, pageSize, pageNumber)),
    createDevice: (userId, deviceBody) => dispatch(createDevice(userId, deviceBody)),
    editDevice: (deviceId, deviceBody, restore) => dispatch(editDevice(deviceId, deviceBody, restore)),
    getUserStats: (userId, startDate, endDate) => dispatch(getUserStats(userId, startDate, endDate)),
    getUserNewestWeatherData: (userId) => dispatch(getUserNewestWeatherData(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesContainer);
