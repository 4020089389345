import Modal from './Modal';
import styled from 'styled-components';
import Button from './Button';

const InfoModal = ({ modalState, setModalState, successCallback, closeCallback, children, info, title, btnText = 'Potwierdź' }) => {
  return (
    <Modal
      title={title}
      show={modalState}
      exitButton={true}
      onCancel={() => {
        if (closeCallback) {
          closeCallback();
        } else {
          setModalState(false);
        }
      }}
    >
      {info && <Text>{info}</Text>}
      {children}
      <CenterBox>
        <Button onClick={successCallback}>{btnText}</Button>
      </CenterBox>
    </Modal>
  );
};

export default InfoModal;

const Text = styled.p`
  text-align: center;
  padding: 20px 0 10px;
  max-width: 450px;
  margin-bottom: 10px;
`;

const CenterBox = styled.div`
  text-align: center;
`;
