import Modal from './Modal';
import styled from 'styled-components';
import Button from './Button';
import i18n from '../i18n/index';

const WarningModal = ({
  modalState: { shown },
  setModalState,
  onSuccess,
  onDecline,
  acceptBtnText = i18n.t('warningModal.yes'),
  declineBtnText = i18n.t('warningModal.no'),
  text,
  swapColors,
}) => {
  return (
    <Modal
      title={i18n.t('warningModal.title')}
      show={shown}
      exitButton={true}
      onCancel={() => {
        setModalState({ shown: false, targetId: null });
      }}
    >
      <Wrapper>
        <Text>{text}</Text>
        <Buttons>
          <Button data-cy="accept-btn" onClick={onSuccess} color={swapColors ? 'danger' : 'primary'}>
            {acceptBtnText}
          </Button>
          <Button data-cy="decline-btn" onClick={onDecline} color={swapColors ? 'primary' : 'danger'}>
            {declineBtnText}
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

export default WarningModal;

const Wrapper = styled.div``;

const Text = styled.p`
  margin: 20px 0;
  font-size: 18px;
  max-width: 360px;
  text-align: center;
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 275px;
  margin: 0 auto;
`;
