import { Formik } from 'formik';
import styled from 'styled-components';
import Modal from '../../../shared/Modal';
import Button from '../../../shared/Button';
import Checkbox from '../../../shared/Checkbox';
import { useSnackbar } from 'notistack';
import { List, ListItem, Divider } from '@material-ui/core';
import { ReactComponent as BellIcon } from '../../../shared/assets/bell.svg';
import { ReactComponent as EmailIcon } from '../../../shared/assets/envelope.svg';
import { useTranslation } from 'react-i18next';

const NotificationsSettingsModal = ({ modalState, setModalState, user, editUser }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = user?.notificationsSettings ?? {
    limitUp: { email: true, notification: true },
    limitBelow: { email: true, notification: true },
    noReadings: { email: true, notification: true },
    voltage: { email: true, notification: true },
  };

  return (
    <Modal title={t('notificationSettingsModal.title')} show={modalState} exitButton={true} onCancel={() => setModalState(false)}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values) => {
          const res = await editUser(user?._id, { notificationsSettings: values });
          if (res?.status === 200) {
            setModalState(false);
            enqueueSnackbar(t('snackbarTexts.changesSaved'), { variant: 'success' });
          }
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <List style={{ margin: '5px 0 0' }}>
              <StyledListItem>
                <p></p>
                <BellIcon />
                <EmailIcon />
              </StyledListItem>
              <StyledListItem>
                <p>{t('notificationSettingsModal.limitUp')}</p>
                <Checkbox
                  onChange={() => setFieldValue('limitUp.notification', !values?.limitUp?.notification)}
                  value={values?.limitUp?.notification}
                  hideError
                  margin="0"
                ></Checkbox>
                <Checkbox
                  onChange={() => setFieldValue('limitUp.email', !values?.limitUp?.email)}
                  value={values?.limitUp?.email}
                  hideError
                  margin="0"
                ></Checkbox>
              </StyledListItem>
              <Divider />
              <StyledListItem>
                <p>{t('notificationSettingsModal.limitBelow')}</p>
                <Checkbox
                  onChange={() => setFieldValue('limitBelow.notification', !values?.limitBelow?.notification)}
                  value={values?.limitBelow?.notification}
                  hideError
                  margin="0"
                ></Checkbox>
                <Checkbox
                  onChange={() => setFieldValue('limitBelow.email', !values?.limitBelow?.email)}
                  value={values?.limitBelow?.email}
                  hideError
                  margin="0"
                ></Checkbox>
              </StyledListItem>
              <Divider />
              <StyledListItem>
                <p>{t('notificationSettingsModal.noReadings')}</p>
                <Checkbox
                  onChange={() => setFieldValue('noReadings.notification', !values?.noReadings?.notification)}
                  value={values?.noReadings?.notification}
                  hideError
                  margin="0"
                ></Checkbox>
                <Checkbox
                  onChange={() => setFieldValue('noReadings.email', !values?.noReadings?.email)}
                  value={values?.noReadings?.email}
                  hideError
                  margin="0"
                ></Checkbox>
              </StyledListItem>
              <Divider />
              <StyledListItem>
                <p>{t('notificationSettingsModal.voltageJumps')}</p>
                <Checkbox
                  onChange={() => setFieldValue('voltage.notification', !values?.voltage?.notification)}
                  value={values?.voltage?.notification}
                  hideError
                  margin="0"
                ></Checkbox>
                <Checkbox
                  onChange={() => setFieldValue('voltage.email', !values?.voltage?.email)}
                  value={values?.voltage?.email}
                  hideError
                  margin="0"
                ></Checkbox>
              </StyledListItem>
            </List>
            <CenterBox>
              <Button type="submit">{t('notificationSettingsModal.saveBtn')}</Button>
            </CenterBox>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default NotificationsSettingsModal;

const Form = styled.form`
  width: 400px;
`;

const CenterBox = styled.div`
  text-align: center;
`;

const StyledListItem = styled(ListItem)`
  display: grid;
  grid-template-columns: 1fr 35px 35px;
  p {
    font-weight: 500;
  }
`;
