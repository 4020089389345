import { useState } from 'react';
import Modal from '../../../shared/Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Button from '../../../shared/Button';
import Input from '../../../shared/Input';
import Select from '../../../shared/Select';
import { dataSourceOptions } from '../../../shared/consts';
import PasswordConfirmationModal from '../../../shared/PasswordConfirmationModal';
import WarningModal from '../../../shared/WarningModal';
import { useSnackbar } from 'notistack';
import useCities from '../../../utils/hooks/useCities';
import { Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import i18n from '../../../i18n';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Nazwa jest wymagana'),
  address: Yup.object().shape({
    postalCode: Yup.string().test('pattern', 'Kod pocztowy jest nieprawidłowy', (value) => (value ? value.match(/^[0-9]{2}-[0-9]{3}$/) : true)),
  }),
});

const EditDeviceModal = ({ isEditDeviceModal, setEditDeviceModal, currentDevice, editDevice }) => {
  const { t } = useTranslation();
  const [warningModal, setWarningModal] = useState({ shown: false });
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { cities, handleCurrentsChange, handleSearchTermChange } = useCities();
  const citiesOptions = cities.map(({ name, id }) => ({ label: name, value: id }));
  const initialValues = {
    name: currentDevice?.name,
    dataSource: currentDevice?.dataSource,
    isThreePhases: currentDevice?.isThreePhases ?? false,
    address: {
      city: currentDevice?.address?.city ?? '',
      postalCode: currentDevice?.address?.postalCode ?? '',
    },
  };
  return (
    <Modal
      title={t('editDeviceModal.title')}
      show={isEditDeviceModal}
      exitButton={true}
      onCancel={() => {
        setEditDeviceModal(false);
      }}
    >
      <WarningModal
        text={t('devicesPage.deleteDeviceWarningText')}
        modalState={warningModal}
        setModalState={setWarningModal}
        onSuccess={() => setIsConfirmModal(true)}
        onDecline={() => setWarningModal({ shown: false })}
      />
      <PasswordConfirmationModal
        modalState={isConfirmModal}
        setModalState={setIsConfirmModal}
        successCallback={async () => {
          const res = editDevice(currentDevice?._id, { deleted: true });
          if (res?.status === 200) {
            enqueueSnackbar(t('editDeviceModal.deviceDeletedSuccessfuly'), { variant: 'success' });
            setWarningModal({ shown: false });
          } else {
            enqueueSnackbar(t('editDeviceModal.errorWhileDeletingDevice'), { variant: 'error' });
          }
        }}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setFieldError }) => {
          const res = await editDevice(currentDevice._id, values);
          if (res?.status === 200) {
            enqueueSnackbar(t('formLabels.changesSaved'), { variant: 'success' });
            setEditDeviceModal(false);
          } else if (res?.status === 404) {
            setFieldError('dataSource', t('editDeviceModal.identifierTaken'));
          } else {
            enqueueSnackbar(t('formLabels.errorWhileEditing'), { variant: 'error' });
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Input
              name="name"
              label={t('formLabels.name')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={touched.name && errors.name}
            />
            <Select
              name="dataSource"
              label={t('formLabels.dataSource')}
              options={dataSourceOptions}
              value={values.dataSource}
              onChange={({ target }) => setFieldValue('dataSource', target.value)}
              error={errors.dataSource}
            />
            <InputRow>
              <Select
                variant="react-select"
                menuPosition="fixed"
                name="address.city"
                label={t('formLabels.city')}
                onChange={({ label }) => {
                  handleCurrentsChange(label);
                  setFieldValue('address.city', label);
                }}
                onInputChange={(v) => handleSearchTermChange(v)}
                onBlur={handleBlur}
                value={{ label: values?.address?.city }}
                error={touched?.address?.city && errors?.address?.city}
                options={citiesOptions}
              />
              <Input
                name="address.postalCode"
                label={t('formLabels.postalCode')}
                inputTooltip={t('addDeviceModal.postalCodeRequiredToWeather')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.address?.postalCode}
                error={touched?.address?.postalCode && errors?.address?.postalCode}
              />
            </InputRow>
            <SwitchWrapper>
              <SwitchLabel data-cy="user-switch" onClick={() => setFieldValue('isThreePhases', !values.isThreePhases)} active={!values.isThreePhases}>
                {t('editDeviceModal.singlePhase')}
              </SwitchLabel>
              <Switch color="primary" onChange={() => setFieldValue('isThreePhases', !values.isThreePhases)} checked={values.isThreePhases} />
              <SwitchLabel data-cy="admin-switch" onClick={() => setFieldValue(true)} active={values.isThreePhases}>
                {t('editDeviceModal.threePhase')}
              </SwitchLabel>
            </SwitchWrapper>
            <Buttons>
              <Button type="submit">{t('editDeviceModal.saveBtn')}</Button>
              <Button data-cy="delete-device-btn" onClick={() => setWarningModal({ shown: true })} color="danger">
                {t('editDeviceModal.deleteBtn')}
              </Button>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditDeviceModal;

const Form = styled.form`
  width: 80vw;
  padding: 30px 0;
  ${({ theme }) => `${theme.mq.desktop}{
    width: 400px;
  }`}
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 200px;
  margin: 0 auto;
`;

const InputRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme.primary};
  }
  .MuiSwitch-track {
    background-color: ${({ theme }) => theme.secondaryText}!important;
  }
`;

const SwitchLabel = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme, active }) => (active ? theme.primary : `${theme.secondaryText}80`)};
  transition: 300ms;
  width: 100px;
  margin: 0 5px;
  cursor: pointer;
  &:first-child {
    text-align: right;
  }
`;
