import axios from 'axios';

const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
const SET_SIDEBAR = 'SET_SIDEBAR';
const TOGGLE_DARKMODE = 'TOGGLE_DARKMODE';
const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';
const MARK_AS_READ_NOTIFICATIONS_REQUEST = 'MARK_AS_READ_NOTIFICATIONS_REQUEST';
const MARK_AS_READ_NOTIFICATIONS_SUCCESS = 'MARK_AS_READ_NOTIFICATIONS_SUCCESS';
const MARK_AS_READ_NOTIFICATIONS_FAIL = 'MARK_AS_READ_NOTIFICATIONS_FAIL';

export const toggleSidebar =
  (target = null) =>
  async (dispatch) => {
    if (target === null) {
      dispatch({
        type: TOGGLE_SIDEBAR,
      });
    } else {
      dispatch({
        type: SET_SIDEBAR,
        payload: target,
      });
    }
  };

export const toggleDarkMode = () => async (dispatch, getState) => {
  const {
    navigation: { darkMode },
  } = getState();
  if (darkMode && darkMode === 'true') {
    localStorage.setItem('darkMode', false);
    dispatch({
      type: TOGGLE_DARKMODE,
    });
  } else {
    localStorage.setItem('darkMode', true);
    dispatch({
      type: TOGGLE_DARKMODE,
    });
  }
};

export const getNotifications = (userId) => async (dispatch) => {
  dispatch(getNotificationsRequest());
  try {
    const response = await axios.get(`/notification/${userId}`);
    dispatch(getNotificationsSuccess(response.data.docs));
  } catch (error) {
    dispatch(getNotificationsFail(error));
  }
};

const getNotificationsRequest = () => ({
  type: GET_NOTIFICATIONS_REQUEST,
});

const getNotificationsSuccess = (notifications) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: notifications,
});

const getNotificationsFail = (error) => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
});

export const markAsReadNotifications = (userId, notifId) => async (dispatch, getState) => {
  let notifIds = [];
  if (notifId) {
    notifIds = [notifId];
  } else {
    const {
      navigation: { notifications },
    } = getState();
    notifIds = notifications?.map(({ _id }) => _id);
  }
  dispatch(markAsReadNotificationsRequest());
  try {
    const response = await axios.patch(`/notification/${userId}`, { notifIds });
    dispatch(markAsReadNotificationsSuccess(response.data));
    return response;
  } catch (error) {
    dispatch(markAsReadNotificationsFail(error));
  }
};

const markAsReadNotificationsRequest = () => ({
  type: MARK_AS_READ_NOTIFICATIONS_REQUEST,
});

const markAsReadNotificationsSuccess = (notifications) => ({
  type: MARK_AS_READ_NOTIFICATIONS_SUCCESS,
  payload: notifications,
});

const markAsReadNotificationsFail = (error) => ({
  type: MARK_AS_READ_NOTIFICATIONS_FAIL,
  payload: error,
});

const initialState = {
  sidebarState: false,
  darkMode: localStorage.getItem('darkMode'),
  notifications: [],
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarState: !state.sidebarState,
      };
    case SET_SIDEBAR:
      return {
        ...state,
        sidebarState: action.payload,
      };
    case TOGGLE_DARKMODE:
      return {
        ...state,
        darkMode: state.darkMode === 'true' ? 'false' : 'true',
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };
    case MARK_AS_READ_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };
    case GET_NOTIFICATIONS_FAIL:
    case MARK_AS_READ_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
