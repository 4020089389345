import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './';
import axios from 'axios';
import { socketMiddleware } from '../sockets';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['datatheme'] = process.env.REACT_APP_THEME;
const JWT_EXPIRED = 'JWT_EXPIRED';

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const Store = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware, socketMiddleware(process.env.REACT_APP_SOCKETS_URL))));
};

const store = Store();

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { dispatch } = store;
    if (error.response.data.message === 'Invalid token specified' || error.response.data.message === 'jwt expired') {
      dispatch({
        type: JWT_EXPIRED,
      });
    }
    return Promise.reject(error);
  },
);

export default store;
