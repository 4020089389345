import { useEffect, useState } from 'react';
import Modal from '../../../shared/Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Button from '../../../shared/Button';
import Select from '../../../shared/Select';
import Input from '../../../shared/Input';
import { useSnackbar } from 'notistack';
import { useGus } from '../../../utils/hooks';
import { capitalizeText } from '../../../utils/other';
import { useTranslation } from 'react-i18next';
import PasswordConfirmationModal from '../../../shared/PasswordConfirmationModal';
import WarningModal from '../../../shared/WarningModal';
import InfoModal from '../../../shared/InfoModal';
import i18n from '../../../i18n';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('validationTexts.firstnameRequired')),
  surname: Yup.string().required(i18n.t('validationTexts.surnameRequired')),
  email: Yup.string().email(i18n.t('validationTexts.wrongEmail')).required(i18n.t('validationTexts.emailRequired')),
  phoneNumber: Yup.string().min(9, i18n.t('validationTexts.phoneTooShort')),
  address: Yup.object().shape({
    postalCode: Yup.string().test('pattern', i18n.t('validationTexts.wrongPostalCode'), (value) =>
      value ? value.match(/^[0-9]{2}-[0-9]{3}$/) : true,
    ),
  }),
});

const EditUserDataModal = ({ isEditUserDataModal, setEditUserDataModal, user, editUser, deleteUser, logoutUser, history, resetPassword }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { voivodeships, districts, communes, cities, handleCurrentsChange, handleSearchTermChange, getInitalOptions } = useGus();
  const [confirmModal, setConfirmModal] = useState({ shown: false, target: null });
  const [warningModal, setWarningModal] = useState({ shown: false });
  const [resetModal, setResetModal] = useState(false);

  const initialValues = {
    name: user.name,
    surname: user.surname,
    email: user.email,
    phoneNumber: user.phoneNumber,
    address: {
      street: user?.address?.street,
      houseNumber: user?.address?.houseNumber,
      apartmentNumber: user?.address?.apartmentNumber,
      district: user?.address?.district,
      voivodeship: user?.address?.voivodeship,
      commune: user?.address?.commune,
      city: user?.address?.city,
      postalCode: user?.address?.postalCode,
    },
  };

  const voivodeshipsOptions = voivodeships.map(({ name, id }) => ({ label: name, value: id }));
  const districtsOptions = districts.map(({ name, id }) => ({ label: name, value: id }));
  const communesOptions = communes.map(({ name, id }) => ({ label: name, value: id }));
  const citiesOptions = cities.map(({ name, id }) => ({ label: name, value: id }));

  useEffect(() => {
    if (isEditUserDataModal) {
      getInitalOptions(user?.address?.voivodeship, user?.address?.district, user?.address?.commune);
    }
  }, [isEditUserDataModal]);

  return (
    <Modal
      title={t('profilePage.editUserBtn')}
      show={isEditUserDataModal}
      exitButton={true}
      onCancel={() => {
        setEditUserDataModal(false);
      }}
    >
      <PasswordConfirmationModal
        modalState={confirmModal.shown}
        setModalState={setConfirmModal}
        initialState={{ shown: false, target: null }}
        successCallback={async () => {
          if (confirmModal.target === 'deleteUser') {
            const res = await deleteUser(user?._id);
            setWarningModal({ shown: false });
            setEditUserDataModal(false);
            await logoutUser();
            history.push('/');
            res?.status === 200
              ? enqueueSnackbar(t('snackbarTexts.accountDeleted'), { variant: 'success' })
              : enqueueSnackbar(t('snackbarTexts.errorDuringDeleting'), { variant: 'error' });
          } else if (confirmModal.target === 'resetPassword') {
            await resetPassword({ email: user?.email }, true);
            setResetModal(true);
          }
        }}
      />
      <WarningModal
        modalState={warningModal}
        setModalState={setWarningModal}
        text={t('editUserDataModal.deleteText')}
        onSuccess={() => setConfirmModal({ shown: true, target: 'deleteUser' })}
        onDecline={() => setWarningModal({ shown: false, targetId: null })}
      />
      <InfoModal
        modalState={resetModal}
        setModalState={setResetModal}
        info={t('passwordResetModal.messageSendedToEmail')}
        btnText={t('passwordResetModal.understand')}
        successCallback={() => setResetModal(false)}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setFieldError }) => {
          const res = await editUser(user?._id, values);
          if (res?.status === 200) {
            enqueueSnackbar(t('snackbarTexts.changesSaved'), { variant: 'success' });
            setEditUserDataModal(false);
          } else {
            if (res?.status === 409 && res?.data?.message === `Email ${values.email} ${t('snackbarTexts.changesSaved')}`) {
              setFieldError('email', res?.data?.message);
            } else {
              enqueueSnackbar(t('snackbarTexts.errorWhileEditing'), { variant: 'error' });
            }
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <InputRow colsTemplate="1fr 1fr">
              <Input
                name="name"
                label={t('formLabels.firstName')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={touched.name && errors.name}
              />
              <Input
                name="surname"
                label={t('formLabels.surname')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.surname}
                error={touched.surname && errors.surname}
              />
            </InputRow>
            <InputRow colsTemplate="1fr 1fr">
              <Input
                name="email"
                label="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={touched.email && errors.email}
              />
              <Input
                name="phoneNumber"
                label={t('formLabels.phoneNumber')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
                error={touched.phoneNumber && errors.phoneNumber}
              />
            </InputRow>
            <SectionLabel>{t('formLabels.address')}</SectionLabel>
            <InputRow colsTemplate="1fr 1fr 1fr">
              <Input
                name="address.street"
                label={t('formLabels.street')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.address?.street}
                error={touched?.address?.street && errors?.address?.street}
              />
              <Input
                name="address.houseNumber"
                label={t('formLabels.houseNumber')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.address?.houseNumber}
                error={touched?.address?.houseNumber && errors?.address?.houseNumber}
              />
              <Input
                name="address.apartmentNumber"
                label={t('formLabels.apartmentNumber')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.address?.apartmentNumber}
                error={touched?.address?.apartmentNumber && errors?.address?.apartmentNumber}
              />
            </InputRow>
            <InputRow colsTemplate="1fr 1fr">
              <Select
                variant="react-select"
                name="address.voivodeship"
                menuPosition="fixed"
                label={t('formLabels.voivodeship')}
                onChange={({ label }) => {
                  handleCurrentsChange('voivodeship', label);
                  setFieldValue('address.voivodeship', label);
                  if (label !== values.address.voivodeship) {
                    setFieldValue('address.district', '');
                    setFieldValue('address.commune', '');
                    setFieldValue('address.city', '');
                  }
                }}
                onInputChange={(v) => handleSearchTermChange('voivodeship', v)}
                onBlur={handleBlur}
                value={{ label: capitalizeText(values?.address?.voivodeship) }}
                error={touched?.address?.voivodeship && errors?.address?.voivodeship}
                options={voivodeshipsOptions}
              />
              <Select
                variant="react-select"
                menuPosition="fixed"
                name="address.district"
                disabled={values?.address?.voivodeship === '' ?? true}
                label={t('formLabels.district')}
                onChange={({ label }) => {
                  handleCurrentsChange('district', label);
                  setFieldValue('address.district', label);
                }}
                onInputChange={(v) => handleSearchTermChange('district', v)}
                onBlur={handleBlur}
                value={{ label: values?.address?.district }}
                error={touched?.address?.district && errors?.address?.district}
                options={districtsOptions}
              />
            </InputRow>
            <InputRow colsTemplate="1fr 1fr 1fr">
              <Select
                variant="react-select"
                menuPosition="fixed"
                name="address.commune"
                disabled={values?.address?.district === '' ?? true}
                label={t('formLabels.commune')}
                onChange={({ label }) => {
                  handleCurrentsChange('commune', label);
                  setFieldValue('address.commune', label);
                }}
                onInputChange={(v) => handleSearchTermChange('commune', v)}
                onBlur={handleBlur}
                value={{ label: values?.address?.commune }}
                error={touched?.address?.commune && errors?.address?.commune}
                options={communesOptions}
              />
              <Select
                variant="react-select"
                menuPosition="fixed"
                name="address.city"
                disabled={values?.address?.commune === '' ?? true}
                label={t('formLabels.city')}
                onChange={({ label }) => {
                  handleCurrentsChange('city', label);
                  setFieldValue('address.city', label);
                }}
                onInputChange={(v) => handleSearchTermChange('city', v)}
                onBlur={handleBlur}
                value={{ label: values?.address?.city }}
                error={touched?.address?.city && errors?.address?.city}
                options={citiesOptions}
              />
              <Input
                name="address.postalCode"
                label={t('formLabels.postalCode')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.address?.postalCode}
                error={touched?.address?.postalCode && errors?.address?.postalCode}
              />
            </InputRow>
            <CenterBox>
              <Button type="submit">{t('editUserModal.saveBtn')}</Button>
              <Button color="secondary" onClick={() => setConfirmModal({ shown: true, target: 'resetPassword' })}>
                {t('editUserModal.changePasswordBtn')}
              </Button>
              <Button color="danger" onClick={() => setWarningModal({ shown: true })}>
                {t('editUserDataModal.deleteAccountText')}
              </Button>
            </CenterBox>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditUserDataModal;

const Form = styled.form`
  width: 80vw;
  padding: 30px 0;
  ${({ theme }) => `${theme.mq.desktop}{
    width: 500px;
  }`}
`;

const InputRow = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  ${({ theme, colsTemplate }) => `${theme.mq.small}{
    grid-template-columns: ${colsTemplate ? colsTemplate : '1fr'};
  }`}
`;

const SectionLabel = styled.p`
  font-size: 16px;
  margin: 0 0 3px 5px;
`;

const CenterBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  > button {
    padding: 10px;
  }
`;
