import { useState, useEffect } from 'react';
import SingleUserView from './SingleUserView';
import { connect } from 'react-redux';
import * as htmlToImage from 'html-to-image';
import { jsPDF } from 'jspdf';
import { getUserById, editUser } from '../../redux/modules/auth';
import { getDevices, getDevicesEnergyPrice } from '../../redux/modules/device';
import { getUserStats, getUserTodaysStats } from '../../redux/modules/dailyStats';
import { getWeatherData } from '../../redux/modules/weather';
import {
  formatEnergyChartData,
  formatBoxesData,
  formatDeviceEnergyProduction,
  calculateCo2Emission,
  calculateMoneySaved,
  formatPreviousValues,
  formatDevicesWeatherData,
} from '../../utils/objectsFormating';
import useDebounce from '../../utils/hooks/useDebounce';
import { useFilters, useSelfConsumption } from '../../utils/hooks';

const SingleUserContainer = ({
  match: {
    params: { id: userId },
  },
  user,
  getUserById,
  currentUser,
  getDevices,
  devices,
  devicesLoading,
  devicesPaginationMetadata,
  editUser,
  userStats,
  userTodaysStats,
  getUserStats,
  getUserTodaysStats,
  previousUserTotalProduction,
  previousUserTotalUsage,
  getWeatherData,
  weatherData,
  devicesEnergyPrice,
}) => {
  const [isFiltersCollapsed, setIsFiltersCollapsed] = useState(false);
  const [isExportOverlayOpen, setIsExportOverlayOpen] = useState(false);
  const [boxesData, setBoxesData] = useState({
    totalProduction: 0,
    totalUsage: 0,
  });
  const [chartData, setChartData] = useState({
    chartData: [],
    dateFormat: 'HH:mm',
    isDaily: 'power',
  });
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedIds, setCheckedIds] = useState({
    devices: [],
  });
  const [initalCheck, setInitalCheck] = useState(false);
  const [tableSearchTerm, setTableSearchTerm] = useState('');
  const debouncedTableSearchTerm = useDebounce(tableSearchTerm);
  const [pagiCount, setPagiCount] = useState(0);
  const [pagiPage, setPagiPage] = useState(0);
  const [pagiRowsPerPage, setPagiRowsPerPage] = useState(10);
  const [editUserModal, setEditUserModal] = useState({ shown: false, userId: null });
  const [devicesEnergyProduction, setDevicesEnergyProduction] = useState({});
  const { filters, filterErrors, handleFiltersChange } = useFilters();
  const [previousValues, setPreviousValues] = useState({
    production: 0,
    usage: 0,
  });
  const [devicesWeatherData, setDevicesWeatherData] = useState({});
  const selfConsumption = useSelfConsumption(chartData);

  useEffect(() => {
    if (userId) {
      getUserById(userId);
      getDevices(userId);
      getDevicesEnergyPrice(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (currentUser?._id) {
      getDevices(currentUser?._id, debouncedTableSearchTerm, pagiRowsPerPage, pagiPage + 1);
    }
  }, [debouncedTableSearchTerm, currentUser, pagiRowsPerPage, pagiPage]);

  useEffect(() => {
    if (currentUser?._id && filterErrors === '') {
      getWeatherData({ userId: currentUser?._id }, filters.dateFrom, filters.dateTo);
      if (filters.dateRange === 'today') {
        getUserTodaysStats(currentUser?._id);
      } else {
        getUserStats(currentUser?._id, filters?.dateFrom, filters?.dateTo);
      }
    }
  }, [filters?.dateRange, filters?.dateFrom, filters?.dateTo, currentUser?._id]);

  useEffect(() => {
    const { newData, dateFormat, isDaily } = formatEnergyChartData(
      filters.dateRange === 'today'
        ? Object.values(userTodaysStats).filter(({ deviceId }) => checkedIds?.devices.includes(deviceId))
        : userStats.filter(({ deviceId }) => checkedIds?.devices.includes(deviceId)),
      filters,
    );
    const { newTotalProduction, newTotalUsage } = formatBoxesData({ data: newData, filters });
    const energyProd = formatDeviceEnergyProduction(filters.dateRange === 'today' ? Object.values(userTodaysStats) : userStats, filters);
    setChartData({
      chartData: newData,
      dateFormat: dateFormat,
      isDaily: isDaily,
    });
    setBoxesData({
      totalProduction: newTotalProduction,
      totalUsage: newTotalUsage,
    });
    setDevicesEnergyProduction(energyProd);
    setPreviousValues(() => ({
      production: formatPreviousValues(previousUserTotalProduction, filters.phase),
      usage: formatPreviousValues(previousUserTotalUsage, filters.phase),
    }));
  }, [userStats, userTodaysStats, filters?.dateRange, filters?.phase, checkedIds]);

  const handleFiltersToggle = () => {
    setIsFiltersCollapsed((prev) => !prev);
  };

  useEffect(() => {
    if (weatherData.length > 0) {
      setDevicesWeatherData(formatDevicesWeatherData(weatherData, devices));
    }
  }, [weatherData, devices, user]);

  const handleCheckAll = (targetUser, array) => {
    let updatedChecked = { ...checkedItems };
    const newDevicesObj = {};
    if (targetUser === 'all') {
      const targetState = Object.values(updatedChecked).some(({ checked }) => checked === true) ? false : true;
      for (const user of Object.keys(updatedChecked)) {
        updatedChecked[user].checked = targetState;
      }
    } else {
      const targetState = Object.values(updatedChecked[targetUser].devices).some((el) => el === true) ? false : true;
      for (const { identifier } of array) {
        newDevicesObj[identifier] = targetState;
      }
      updatedChecked[targetUser].devices = { ...newDevicesObj };
    }
    const allCheckedDevicesIds = [];
    for (const user of Object.values(updatedChecked)) {
      for (const [id, checked] of Object.entries(user?.devices)) {
        if (checked) {
          allCheckedDevicesIds.push(id);
        }
      }
    }
    setCheckedIds({
      devices: allCheckedDevicesIds,
    });
    setCheckedItems({ ...updatedChecked });
  };

  const handleCheck = (targetUser, targetId) => {
    let updatedChecked = { ...checkedItems };
    if (targetId === 'checked') {
      updatedChecked[targetUser].checked = !updatedChecked[targetUser].checked;
    } else {
      updatedChecked[targetUser].devices[targetId] = !updatedChecked[targetUser].devices[targetId];
    }
    const allCheckedDevicesIds = [];
    for (const user of Object.values(updatedChecked)) {
      for (const [id, checked] of Object.entries(user?.devices)) {
        if (checked) {
          allCheckedDevicesIds.push(id);
        }
      }
    }
    setCheckedIds({
      devices: allCheckedDevicesIds,
    });
    setCheckedItems(updatedChecked);
  };

  const handleExportPDF = (element) => {
    htmlToImage.toPng(element, { quality: 1, width: 1250, height: 2000 }).then(function (dataUrl) {
      var link = document.createElement('a');
      link.download = 'my-image-name.jpeg';
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(dataUrl);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(dataUrl, 'PNG', 10, 0, pdfWidth - 10, pdfHeight);
      pdf.save(`EnergyMeter_raport-${new Date().toISOString().slice(0, 10)}.pdf`);
    });
  };

  const handleExportCSV = () => {
    const overallDataHeaders = ['Wyprodukowana energia', 'Zużyta energia', 'Zaoszczędzone pieniądze', 'Zaoszczędzona emisja'].join(';');
    const devicesDataHeaders = ['Identyfikator', 'Nazwa', 'Status', 'Produkcja energii', 'Zaoszczędzona emisja'].join(';');
    const overallData = [
      `${boxesData?.totalProduction} kWh`,
      `${boxesData.totalUsage} kWh`,
      `${calculateMoneySaved(
        devicesEnergyProduction,
        devices
          .filter(({ identifier }) => checkedIds?.devices.includes(identifier))
          .reduce((acc, cur) => {
            acc[cur.identifier] = cur.energyPrice;
            return acc;
          }, {}),
      )} zł`,
      `${calculateCo2Emission(boxesData?.totalProduction)} kg`,
    ].join(';');
    const devicesData = devices
      .map(({ name, identifier, status }) => [
        `${identifier};`,
        `${name};`,
        `${status === 'ON' ? 'Połączony' : 'Rozłączony'};`,
        `${devicesEnergyProduction[identifier] ?? 0} kWh;`,
        `${calculateCo2Emission(devicesEnergyProduction[identifier])} kg`,
      ])
      .join('\n')
      .replace(/,/g, '');
    const rows = [overallDataHeaders, overallData, devicesDataHeaders, devicesData];
    let csvContent = rows.join('\n');
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,\uFEFF' + encodeURIComponent(csvContent);
    hiddenElement.target = '_blank';
    hiddenElement.download = `EnergyMeter_raport-${`${currentUser.name}-${currentUser.surname}`}-${new Date().toISOString().slice(0, 10)}.csv`;
    hiddenElement.click();
  };

  useEffect(() => {
    if (user?._id) {
      let checkedObj = {};
      const devicesObj = {};
      for (const { identifier } of devices) {
        devicesObj[identifier] = checkedIds.devices.includes(identifier);
      }
      checkedObj = {
        [user?._id]: {
          user: user?._id,
          checked: false,
          devices: devicesObj,
        },
      };
      setCheckedItems(checkedObj);
    }
  }, [devices, user]);

  useEffect(() => {
    if (user?.role === 'user' && devices.length === 0) {
      setPagiPage(0);
    }
  }, [devices]);

  useEffect(() => {
    if (devicesPaginationMetadata?.totalCount || devicesPaginationMetadata?.totalCount === 0) {
      setPagiCount(devicesPaginationMetadata.totalCount);
    }
  }, [devicesPaginationMetadata]);

  useEffect(() => {
    if (!initalCheck && devices.length > 0 && currentUser?._id === userId && devices.every(({ _id }) => currentUser?.devices?.includes(_id))) {
      setCheckedIds({
        devices: devices.map(({ identifier }) => identifier),
      });
      setInitalCheck(true);
    }
  }, [devices, currentUser]);

  return (
    <SingleUserView
      user={user}
      filters={filters}
      handleFiltersChange={handleFiltersChange}
      isFiltersCollapsed={isFiltersCollapsed}
      handleFiltersToggle={handleFiltersToggle}
      checkedItems={checkedItems}
      handleCheckAll={handleCheckAll}
      handleCheck={handleCheck}
      devices={devices}
      isExportOverlayOpen={isExportOverlayOpen}
      setIsExportOverlayOpen={setIsExportOverlayOpen}
      handleExportPDF={handleExportPDF}
      currentUser={currentUser}
      tableSearchTerm={tableSearchTerm}
      debouncedTableSearchTerm={debouncedTableSearchTerm}
      setTableSearchTerm={setTableSearchTerm}
      pagiCount={pagiCount}
      setPagiCount={setPagiCount}
      pagiPage={pagiPage}
      setPagiPage={setPagiPage}
      pagiRowsPerPage={pagiRowsPerPage}
      setPagiRowsPerPage={setPagiRowsPerPage}
      checkedIds={checkedIds}
      devicesLoading={devicesLoading}
      handleExportCSV={handleExportCSV}
      editUserModal={editUserModal}
      setEditUserModal={setEditUserModal}
      editUser={editUser}
      boxesData={boxesData}
      chartData={chartData}
      devicesEnergyProduction={devicesEnergyProduction}
      filterErrors={filterErrors}
      previousValues={previousValues}
      devicesWeatherData={devicesWeatherData}
      devicesEnergyPrice={devicesEnergyPrice}
      selfConsumption={selfConsumption}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  currentUser: state.auth.currentUser,
  devices: state.device.devices,
  devicesLoading: state.device.loading,
  devicesPaginationMetadata: state.device.paginationMetadata,
  userStats: state.dailyStats.userStats,
  userTodaysStats: state.dailyStats.userTodaysStats,
  previousUserTotalProduction: state.dailyStats.previousUserTotalProduction,
  previousUserTotalUsage: state.dailyStats.previousUserTotalUsage,
  weatherData: state.weather.data,
  devicesEnergyPrice: state.device.devicesEnergyPrice,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUserById: (userId) => dispatch(getUserById(userId)),
    getDevices: (userId, searchTerm, pageSize, pageNumber) => dispatch(getDevices(userId, searchTerm, pageSize, pageNumber)),
    editUser: (userId, body, restore) => dispatch(editUser(userId, body, restore)),
    getUserStats: (userId, startDate, endDate) => dispatch(getUserStats(userId, startDate, endDate)),
    getUserTodaysStats: (userId) => dispatch(getUserTodaysStats(userId)),
    getWeatherData: (query, startDate, endDate) => dispatch(getWeatherData(query, startDate, endDate)),
    getDevicesEnergyPrice: (userId) => dispatch(getDevicesEnergyPrice(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleUserContainer);
