import { ReactComponent as HamburgerIcon } from './assets/hamburger.svg';
import styled from 'styled-components';

const Hamburger = ({ active, onClick }) => {
  return <StyledHamburgerIcon active={active} onClick={onClick} />;
};

export default Hamburger;

const StyledHamburgerIcon = styled(HamburgerIcon)`
  height: 24px;
  width: 24px;
  path {
    transition: 200ms;
  }
  ${({ active }) =>
    active &&
    `
    .bar-1 {
      transform: rotate(45deg) translate(4px,-5px);
    }
    .bar-3{
      transform: rotate(-45deg) translate(-10px, 0px);
    }
    .bar-2 {
      opacity: 0;
    }
  `}
`;
