import styled from 'styled-components';

const StatusPill = ({ color = 'success', children }) => {
  return <Wrapper color={color}>{children}</Wrapper>;
};

export default StatusPill;

const Wrapper = styled.div`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  padding: 2px 9px;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  background-color: ${({ theme, color }) => (color === 'success' ? theme.primary : color === 'danger' ? theme.danger : theme.secondary)};
`;
