import { capitalizeText } from './other';
import i18n from '../i18n';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const emptyHours = [
  { hour: 0, power: 0, usage: 0 },
  { hour: 1, power: 0, usage: 0 },
  { hour: 2, power: 0, usage: 0 },
  { hour: 3, power: 0, usage: 0 },
  { hour: 4, power: 0, usage: 0 },
  { hour: 5, power: 0, usage: 0 },
  { hour: 6, power: 0, usage: 0 },
  { hour: 7, power: 0, usage: 0 },
  { hour: 8, power: 0, usage: 0 },
  { hour: 9, power: 0, usage: 0 },
  { hour: 10, power: 0, usage: 0 },
  { hour: 11, power: 0, usage: 0 },
  { hour: 12, power: 0, usage: 0 },
  { hour: 13, power: 0, usage: 0 },
  { hour: 14, power: 0, usage: 0 },
  { hour: 15, power: 0, usage: 0 },
  { hour: 16, power: 0, usage: 0 },
  { hour: 17, power: 0, usage: 0 },
  { hour: 18, power: 0, usage: 0 },
  { hour: 19, power: 0, usage: 0 },
  { hour: 20, power: 0, usage: 0 },
  { hour: 21, power: 0, usage: 0 },
  { hour: 22, power: 0, usage: 0 },
  { hour: 23, power: 0, usage: 0 },
];

const phaseDisc = {
  1: 'first',
  2: 'second',
  3: 'third',
};

export const formatAddress = (obj = {}) => {
  const { street, postalCode, city, houseNumber, apartmentNumber, voivodeship, commune, district } = obj;
  if (!street && !city && !postalCode) {
    return i18n.t('noDataLabels.noAddress');
  }
  let text = `${street ? street : ''}${houseNumber ? ` ${houseNumber}` : ''}${apartmentNumber ? `/${apartmentNumber}` : ''}${
    voivodeship ? `, ${capitalizeText(voivodeship)}` : ''
  }${district ? `, ${district}` : ''}${commune ? `, ${commune}` : ''}${postalCode ? `, ${postalCode}` : ''}${
    city ? `${postalCode ? ` ` : ', '}${city}` : ''
  }`;
  text = text.replace(/ , /g, ', ');
  return text;
};

const filterDataByPhase = (data, phase) => {
  let filteredData = data;
  if (phase === '1' || phase === '2' || phase === '3') {
    filteredData = filteredData.map(({ phases, ...rest }) => ({ ...rest, ...phases[phaseDisc[phase]] }));
  } else if (phase === '0') {
    const mergedData = [];
    for (const { phases = {}, ...rest } of Object.values(filteredData)) {
      let mergedHours = [...emptyHours];
      for (const { hours = [] } of Object.values(phases)) {
        for (const { hour, power, usage } of hours) {
          mergedHours[hour] = {
            ...mergedHours[hour],
            power: mergedHours[hour]?.power + power,
            usage: mergedHours[hour]?.usage + usage,
          };
        }
      }
      mergedData.push({
        ...rest,
        dailyPower: Object.values(phases)
          ?.flat()
          ?.reduce((a, b) => a + (b.dailyPower || 0), 0),
        dailyUsage: Object.values(phases)
          ?.flat()
          ?.reduce((a, b) => a + (b.dailyUsage || 0), 0),
        hours: mergedHours,
      });
    }
    filteredData = mergedData;
  }
  return filteredData;
};

const getAverageWeatherValues = (array) => ({
  cloudCover: array.length > 0 ? array.reduce((a, b) => a + parseFloat(b.cloudCover || 0), 0) / array.length : 0,
  humidity: array.length > 0 ? array.reduce((a, b) => a + parseFloat(b.humidity || 0), 0) / array.length : 0,
  temperature: array.length > 0 ? array.reduce((a, b) => a + parseFloat(b.temperature || 0), 0) / array.length : 0,
  uvIndex: array.length > 0 ? array.reduce((a, b) => a + parseFloat(b.uvIndex || 0), 0) / array.length : 0,
});

export const formatEnergyChartData = (data, { dateRange, dateFrom, dateTo, phase }, weatherData) => {
  const filteredData = filterDataByPhase(data, phase);
  let newData = filteredData;
  let dateFormat = 'HH:mm';
  let isDaily = false;
  let dataSource = newData?.[0];
  if (dateRange === 'today' || dateRange === 'yesterday') {
    let mergedHours = [...emptyHours];
    for (const { hours = [] } of filteredData) {
      for (const { hour, power, usage } of hours) {
        const i = mergedHours.findIndex((el) => el.hour === hour);
        if (i !== -1) {
          mergedHours[i] = {
            ...mergedHours[i],
            power: mergedHours[i]?.power + power,
            usage: mergedHours[i]?.usage + usage,
          };
        }
      }
    }
    dataSource = {
      ...dataSource,
      deviceId: 'merged',
      dailyPower: data?.reduce((a, b) => a + (b.dailyPower || 0), 0),
      dailyUsage: data?.reduce((a, b) => a + (b.dailyUsage || 0), 0),
      hours: mergedHours,
    };
    if (dateRange === 'today') {
      newData = dataSource?.hours?.map(({ hour, ...rest }) => ({
        date: dayjs().set('hour', hour).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString(),
        ...rest,
      }));
    } else if (dateRange === 'yesterday') {
      newData = dataSource?.hours?.map(({ hour, ...rest }) => ({
        date: dayjs().subtract('1', 'day').set('hour', hour).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString(),
        ...rest,
      }));
    }
  } else if (dateRange === 'last7' || dateRange === 'last30') {
    isDaily = true;
    dateFormat = 'DD.MM';
    let datesSchema = [];
    let currentDate = dayjs(dateFrom);
    while (currentDate.isSameOrBefore(dayjs(dateTo))) {
      datesSchema.push({
        date: currentDate.toISOString(),
        dailyUsage: 0,
        dailyPower: 0,
      });
      currentDate = currentDate.add(1, 'day');
    }
    for (const stats of filteredData) {
      const targetIndex = datesSchema.findIndex(({ date }) => dayjs(date).format('YYYY-MM-DD') === dayjs(stats.date).format('YYYY-MM-DD'));
      datesSchema[targetIndex] = {
        ...datesSchema[targetIndex],
        dailyPower: datesSchema[targetIndex]?.dailyPower + stats.dailyPower,
        dailyUsage: datesSchema[targetIndex]?.dailyUsage + stats.dailyUsage,
      };
    }
    newData = datesSchema;
  } else if (dateRange === 'custom') {
    let datesSchema = [];
    if (dayjs(dateTo).diff(dateFrom, 'hour') < 72) {
      for (const { hours, date } of filteredData) {
        for (const { hour, ...hourData } of hours) {
          datesSchema.push({
            ...hourData,
            date: dayjs(date).set('hour', hour).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString(),
          });
        }
      }
    } else {
      isDaily = true;
      dateFormat = 'DD.MM';
      let currentDate = dayjs(dateFrom);
      while (currentDate.isSameOrBefore(dayjs(dateTo))) {
        datesSchema.push({
          date: currentDate.toISOString(),
          dailyUsage: 0,
          dailyPower: 0,
        });
        currentDate = currentDate.add(1, 'day');
      }
      for (const stats of filteredData) {
        const targetIndex = datesSchema.findIndex(({ date }) => dayjs(date).format('YYYY-MM-DD') === dayjs(stats.date).format('YYYY-MM-DD'));
        datesSchema[targetIndex] = {
          ...datesSchema[targetIndex],
          dailyPower: datesSchema[targetIndex]?.dailyPower + stats.dailyPower,
          dailyUsage: datesSchema[targetIndex]?.dailyUsage + stats.dailyUsage,
        };
      }
    }
    newData = datesSchema;
  }
  //inject weatherData,
  if (weatherData) {
    const mergedWeatherData = [];
    for (const { pings = [] } of weatherData) {
      for (const { time, ...ping } of pings) {
        mergedWeatherData.push({ date: time, ...ping });
      }
    }
    newData = newData.map((el) => {
      const dayFormat = isDaily ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH';
      const pingDate = dayjs(el.date).format(dayFormat);
      if (isDaily) {
        const pingsFromDay = mergedWeatherData.filter((wd) => dayjs(wd.date).format(dayFormat) === pingDate);
        const { cloudCover = 0, humidity = 0, temperature = 0, uvIndex = 0 } = getAverageWeatherValues(pingsFromDay);
        return { ...el, cloudCover, humidity, temperature, uvIndex };
      } else {
        const {
          cloudCover = 0,
          humidity = 0,
          temperature = 0,
          uvIndex = 0,
        } = mergedWeatherData.find((wd) => dayjs(wd.date).format(dayFormat) === pingDate) ?? {};
        return { ...el, cloudCover, humidity, temperature, uvIndex };
      }
    });
  }
  return { newData, dateFormat, isDaily };
};

export const formatWeatherChartData = (weatherData = []) => {
  const merged = [];
  for (const { pings } of weatherData) {
    for (const { time, ...ping } of pings) {
      merged.push({ date: time, ...ping });
    }
  }
  return merged.sort((a, b) => new Date(a.date) - new Date(b.date));
};

export const formatBoxesData = ({ data = [], filters: { dateRange, dateFrom, dateTo } }) => {
  let isDaily = false;
  if (dateRange === 'last7' || dateRange === 'last30' || (dateRange === 'custom' && dayjs(dateTo).diff(dateFrom, 'hour') > 72)) {
    isDaily = true;
  }
  const newTotalProduction = data?.reduce((a, b) => a + (b[isDaily ? 'dailyPower' : 'power'] || 0), 0);
  const newTotalUsage = data?.reduce((a, b) => a + (b[isDaily ? 'dailyUsage' : 'usage'] || 0), 0);
  return { newTotalProduction, newTotalUsage };
};

export const formatDeviceEnergyProduction = (data, { dateRange, phase }) => {
  const energyProd = {};
  const filteredData = filterDataByPhase(data, phase);
  if (dateRange === 'today' || dateRange === 'yesterday') {
    for (const { deviceId, hours } of filteredData) {
      const hoursSum = hours?.reduce((a, b) => a + (b.power || 0), 0);
      energyProd[deviceId] = energyProd[deviceId] ? energyProd[deviceId] + hoursSum : hoursSum;
    }
  } else if (dateRange === 'last7' || dateRange === 'last30' || dateRange === 'custom') {
    for (const { deviceId, dailyPower } of filteredData) {
      energyProd[deviceId] = energyProd[deviceId] ? energyProd[deviceId] + dailyPower : dailyPower;
    }
  }
  return energyProd;
};

export const formatUsersEnergyProduction = (devicesEnergyProd, usersList) => {
  const usersDevices = {};
  for (const { devices, _id } of usersList) {
    const devicesIds = devices.map((d) => d.identifier);
    usersDevices[_id] = { devices: devicesIds };
  }
  for (const [userId, { devices }] of Object.entries(usersDevices)) {
    const productionSum = Object.entries(devicesEnergyProd)
      .filter(([devId]) => devices.includes(devId))
      .map(([, value]) => value)
      .reduce((sum, curr) => sum + curr || 0, 0);
    usersDevices[userId] = productionSum;
  }
  return usersDevices;
};

export const calculateCo2Emission = (value = 0, unit = 'kg') => {
  let emission = value * 719;
  if (unit === 'kg') {
    emission = emission / 1000;
  }
  emission = emission > 0 ? emission.toFixed(3) : 0;
  return parseFloat(emission);
};

export const calculateMoneySaved = (energyProduction, energyPrice) => {
  let moneySaved;
  if (typeof energyProduction === 'number') {
    moneySaved = energyProduction * energyPrice;
    moneySaved = moneySaved > 0 ? moneySaved.toFixed(2) : 0;
  } else {
    const arr = [];
    for (const [identifier, energyProd] of Object.entries(energyProduction)) {
      arr.push(energyProd * energyPrice[identifier]);
    }
    moneySaved = arr.reduce((a, b) => a + (b || 0), 0, 0);
  }
  return parseFloat(moneySaved);
};

export const formatPreviousValues = (prevValues, phase) => {
  if (phase === '1' || phase === '2' || phase === '3') {
    return prevValues[phaseDisc[phase]];
  } else if (phase === '0') {
    return Object.values(prevValues)?.reduce((a, b) => a + (b || 0), 0);
  }
};

export const formatDevicesWeatherData = (weatherData, devices) => {
  const obj = {};
  for (const { address } of devices) {
    const newestDevicePing = weatherData
      .filter(({ postalCode }) => postalCode === address?.postalCode)
      .map(({ pings }) => pings)
      .flat()
      .sort((a, b) => new Date(b.time) - new Date(a.time))[0];
    obj[address?.postalCode] = newestDevicePing ?? null;
  }
  return obj;
};

export const calculateSelfConsumption = (data, isDaily) => {
  const selfConsumptionData = [];
  for (const { date, ...energyData } of data) {
    const usage = energyData[isDaily ? 'dailyUsage' : 'usage'];
    const prod = energyData[isDaily ? 'dailyPower' : 'power'];
    let val = prod - usage;
    if (val < 0) {
      val = prod;
    } else if (prod > usage) {
      val = usage;
    }
    selfConsumptionData.push({
      date,
      selfConsumption: val,
    });
  }
  const sum = selfConsumptionData?.reduce((a, { selfConsumption }) => a + (selfConsumption || 0), 0);
  return {
    chart: selfConsumptionData,
    total: sum,
  };
};

export const getUserName = ({ name, surname, email } = {}) => (name || surname ? `${name ? name : ''}${surname ? ` ${surname}` : ''}` : email);

export const formatUserOptions = (usersList) => {
  return usersList?.map(({ _id, ...userName }) => ({
    value: _id,
    label: getUserName(userName),
  }));
};

export const formatQuery = (filters) => {
  const filtered = {};
  for (const [key, value] of Object.entries(filters)) {
    if (value || value === 0) {
      filtered[key] = value;
    }
  }
  return new URLSearchParams(filtered).toString();
};
