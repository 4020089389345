import { combineReducers } from 'redux';
import auth from './modules/auth';
import navigation from './modules/navigation';
import errorReport from './modules/errorReport';
import device from './modules/device';
import dailyStats from './modules/dailyStats';
import gus from './modules/gus';
import weather from './modules/weather';
import message from './modules/message';
const phasesDefaultValues = {
  first: 0,
  second: 0,
  third: 0,
};
const appReducer = combineReducers({
  auth,
  navigation,
  errorReport,
  device,
  dailyStats,
  gus,
  weather,
  message,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER_SUCCESS' || action.type === 'JWT_EXPIRED') {
    state = {
      dailyStats: {
        deviceStats: [],
        deviceTodaysStats: [],
        userStats: [],
        userTodaysStats: [],
        adminStats: [],
        adminTodaysStats: [],
        previousDeviceTotalProduction: phasesDefaultValues,
        previousDeviceTotalUsage: phasesDefaultValues,
        previousUserTotalProduction: phasesDefaultValues,
        previousUserTotalUsage: phasesDefaultValues,
        previousAdminTotalProduction: phasesDefaultValues,
        previousAdminTotalUsage: phasesDefaultValues,
        error: null,
        loading: false,
        totalStats: {
          totalEnergyProduction: state.dailyStats.totalStats.totalEnergyProduction,
          totalEmissionSaved: state.dailyStats.totalStats.totalEmissionSaved,
          totalMoneySaved: state.dailyStats.totalStats.totalMoneySaved,
          treesEquivalent: state.dailyStats.totalStats.treesEquivalent,
        },
      },
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
