import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCities, getVoivodeships, getDistricts, getCommunes } from '../../redux/modules/gus';
import useDebounce from './useDebounce';

const useGus = () => {
  const voivodeships = useSelector((state) => state.gus.voivodeships);
  const districts = useSelector((state) => state.gus.districts);
  const communes = useSelector((state) => state.gus.communes);
  const cities = useSelector((state) => state.gus.cities);

  const dispatch = useDispatch();
  const getVoivodeshipsFunc = (searchTerm) => dispatch(getVoivodeships(searchTerm));
  const getDistrictsFunc = (searchTerm, voivodeship) => dispatch(getDistricts(searchTerm, voivodeship));
  const getCommunesFunc = (searchTerm, voivodeship, county) => dispatch(getCommunes(searchTerm, voivodeship, county));
  const getCitiesFunc = (searchTerm, voivodeship, county, commune) => dispatch(getCities(searchTerm, voivodeship, county, commune));

  const [voivodeshipsSearchTerm, setVoivodeshipsSearchTerm] = useState('');
  const debouncedVoivodeshipsSearchTerm = useDebounce(voivodeshipsSearchTerm);
  const [districtsSearchTerm, setDistrictsSearchTerm] = useState('');
  const debouncedDistrictsSearchTerm = useDebounce(districtsSearchTerm);
  const [communesSearchTerm, setCommunesSearchTerm] = useState('');
  const debouncedCommunesSearchTerm = useDebounce(communesSearchTerm);
  const [citiesSearchTerm, setCitiesSearchTerm] = useState('');
  const debouncedCitiesSearchTerm = useDebounce(citiesSearchTerm);

  const [currentVoivodeship, setCurrentVoivodeship] = useState('');
  const [currentDistrict, setCurrentDistrict] = useState('');
  const [currentCommune, setCurrentCommune] = useState('');
  const [, setCurrentCity] = useState('');

  useEffect(() => {
    getVoivodeshipsFunc(debouncedVoivodeshipsSearchTerm);
  }, [debouncedVoivodeshipsSearchTerm]);

  useEffect(() => {
    getDistrictsFunc(debouncedDistrictsSearchTerm, currentVoivodeship);
  }, [debouncedDistrictsSearchTerm, currentVoivodeship]);

  useEffect(() => {
    getCommunesFunc(debouncedCommunesSearchTerm, currentVoivodeship, currentDistrict);
  }, [debouncedCommunesSearchTerm, currentVoivodeship, currentDistrict]);

  useEffect(() => {
    getCitiesFunc(debouncedCitiesSearchTerm, currentVoivodeship, currentDistrict, currentCommune);
  }, [debouncedCitiesSearchTerm, currentVoivodeship, currentDistrict, currentCommune]);

  const handleSearchTermChange = (field, value) => {
    let setFunc;
    if (field === 'voivodeship') {
      setFunc = setVoivodeshipsSearchTerm;
    } else if (field === 'district') {
      setFunc = setDistrictsSearchTerm;
    } else if (field === 'commune') {
      setFunc = setCommunesSearchTerm;
    } else if (field === 'city') {
      setFunc = setCitiesSearchTerm;
    }
    setFunc(value);
  };
  const handleCurrentsChange = (field, value) => {
    let setFunc;
    if (field === 'voivodeship') {
      setFunc = setCurrentVoivodeship;
    } else if (field === 'district') {
      setFunc = setCurrentDistrict;
    } else if (field === 'commune') {
      setFunc = setCurrentCommune;
    } else if (field === 'city') {
      setFunc = setCurrentCity;
    }
    setFunc(value);
  };
  const getInitalOptions = (voivodeship, district, commune) => {
    setCurrentVoivodeship(voivodeship);
    setCurrentDistrict(district);
    setCurrentCommune(commune);
    getVoivodeshipsFunc('');
    getDistrictsFunc('', voivodeship);
    getCommunesFunc('', voivodeship, district);
    getCitiesFunc('', voivodeship, district, commune);
  };

  return {
    voivodeships,
    districts,
    communes,
    cities,
    debouncedVoivodeshipsSearchTerm,
    debouncedDistrictsSearchTerm,
    debouncedCommunesSearchTerm,
    debouncedCitiesSearchTerm,
    handleCurrentsChange,
    handleSearchTermChange,
    getInitalOptions,
  };
};

export default useGus;
