import { useState, useEffect } from 'react';
import DashboardView from './DashboardView';
import { connect } from 'react-redux';
import * as htmlToImage from 'html-to-image';
import { jsPDF } from 'jspdf';
import { getUsers } from '../../redux/modules/auth';
import { getDevices, getDevicesEnergyPrice } from '../../redux/modules/device';
import { getAdminStats, getAdminTodaysStats, getUserStats, getUserTodaysStats } from '../../redux/modules/dailyStats';
import { getUserNewestWeatherData } from '../../redux/modules/weather';
import { useFilters, useDebounce, useSelfConsumption } from '../../utils/hooks';
import {
  formatEnergyChartData,
  formatBoxesData,
  formatDeviceEnergyProduction,
  formatUsersEnergyProduction,
  calculateCo2Emission,
  calculateMoneySaved,
  formatPreviousValues,
  formatDevicesWeatherData,
} from '../../utils/objectsFormating';

const DashboardContainer = ({
  user,
  getUsers,
  usersList,
  getDevices,
  devices,
  usersPaginationMetadata,
  devicesPaginationMetadata,
  usersLoading,
  devicesLoading,
  history,
  getAdminStats,
  getAdminTodaysStats,
  getUserStats,
  getUserTodaysStats,
  adminStats,
  adminTodaysStats,
  userStats,
  userTodaysStats,
  previousUserTotalProduction,
  previousAdminTotalProduction,
  previousUserTotalUsage,
  previousAdminTotalUsage,
  weatherData,
  getDevicesEnergyPrice,
  devicesEnergyPrice,
  getUserNewestWeatherData,
}) => {
  const [isFiltersCollapsed, setIsFiltersCollapsed] = useState(false);
  const [isExportOverlayOpen, setIsExportOverlayOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedIds, setCheckedIds] = useState({
    users: [],
    devices: [],
  });
  const [tableCollapsedItems, setTableCollapsedItems] = useState({});
  const [tableSearchTerm, setTableSearchTerm] = useState('');
  const debouncedTableSearchTerm = useDebounce(tableSearchTerm);
  const [pagiCount, setPagiCount] = useState(0);
  const [pagiPage, setPagiPage] = useState(0);
  const [pagiRowsPerPage, setPagiRowsPerPage] = useState(10);
  const [boxesData, setBoxesData] = useState({
    totalProduction: 0,
    totalUsage: 0,
  });
  const [chartData, setChartData] = useState({
    chartData: [],
    dateFormat: 'HH:mm',
    isDaily: 'power',
  });
  const [initalCheck, setInitalCheck] = useState(false);
  const [devicesEnergyProduction, setDevicesEnergyProduction] = useState({});
  const [usersEnergyProduction, setUsersEnergyProduction] = useState({});
  const { filters, filterErrors, handleFiltersChange } = useFilters();
  const [previousValues, setPreviousValues] = useState({
    production: 0,
    usage: 0,
  });
  const [devicesWeatherData, setDevicesWeatherData] = useState({});
  const selfConsumption = useSelfConsumption(chartData);

  useEffect(() => {
    if (user?._id) {
      getDevicesEnergyPrice(user.role === 'admin' ? 'all' : user?._id);
    }
  }, [user?._id]);

  useEffect(() => {
    if (user?.role === 'admin') {
      getUsers('devices', debouncedTableSearchTerm, pagiRowsPerPage, pagiPage + 1);
    } else if (user?.role === 'user') {
      getDevices(user?._id, debouncedTableSearchTerm, pagiRowsPerPage, pagiPage + 1);
    }
  }, [debouncedTableSearchTerm, user, pagiRowsPerPage, pagiPage]);

  useEffect(() => {
    if (user?.role === 'admin' && usersList.length === 0) {
      setPagiPage(0);
    } else if (user?.role === 'user' && devices.length === 0) {
      setPagiPage(0);
    }
  }, [devices, usersList]);

  useEffect(() => {
    if (user?._id && filterErrors === '') {
      if (user?.role === 'admin') {
        getUserNewestWeatherData();
        if (filters.dateRange === 'today') {
          getAdminTodaysStats();
        } else {
          getAdminStats(filters.dateFrom, filters.dateTo);
        }
      } else if (user?.role === 'user') {
        getUserNewestWeatherData(user?._id);
        if (filters.dateRange === 'today') {
          getUserTodaysStats(user?._id);
        } else {
          getUserStats(user?._id, filters.dateFrom, filters.dateTo);
        }
      }
    }
  }, [filters, user?._id]);

  useEffect(() => {
    if (user?.role === 'admin') {
      const { newData, dateFormat, isDaily } = formatEnergyChartData(
        filters.dateRange === 'today'
          ? Object.values(adminTodaysStats).filter(({ deviceId }) => checkedIds?.devices.includes(deviceId))
          : adminStats.filter(({ deviceId }) => checkedIds?.devices.includes(deviceId)),
        filters,
      );
      const { newTotalProduction, newTotalUsage } = formatBoxesData({ data: newData, filters });
      const deviceEnergyProd = formatDeviceEnergyProduction(filters.dateRange === 'today' ? Object.values(adminTodaysStats) : adminStats, filters);
      const userEnergyProd = formatUsersEnergyProduction(deviceEnergyProd, usersList);
      setChartData({
        chartData: newData,
        dateFormat: dateFormat,
        isDaily: isDaily,
      });
      setBoxesData({
        totalProduction: newTotalProduction,
        totalUsage: newTotalUsage,
      });
      setPreviousValues(() => ({
        production: formatPreviousValues(previousAdminTotalProduction, filters.phase),
        usage: formatPreviousValues(previousAdminTotalUsage, filters.phase),
      }));
      setDevicesEnergyProduction(deviceEnergyProd);
      setUsersEnergyProduction(userEnergyProd);
    } else if (user?.role === 'user') {
      const { newData, dateFormat, isDaily } = formatEnergyChartData(
        filters.dateRange === 'today'
          ? Object.values(userTodaysStats).filter(({ deviceId }) => checkedIds?.devices.includes(deviceId))
          : userStats.filter(({ deviceId }) => checkedIds?.devices.includes(deviceId)),
        filters,
      );
      const { newTotalProduction, newTotalUsage } = formatBoxesData({ data: newData, filters });
      const deviceEnergyProd = formatDeviceEnergyProduction(filters.dateRange === 'today' ? Object.values(userTodaysStats) : userStats, filters);
      setChartData({
        chartData: newData,
        dateFormat: dateFormat,
        isDaily: isDaily,
      });
      setBoxesData({
        totalProduction: newTotalProduction,
        totalUsage: newTotalUsage,
      });
      setDevicesEnergyProduction(deviceEnergyProd);
      setPreviousValues(() => ({
        production: formatPreviousValues(previousUserTotalProduction, filters.phase),
        usage: formatPreviousValues(previousUserTotalUsage, filters.phase),
      }));
    }
  }, [userStats, userTodaysStats, adminStats, adminTodaysStats, filters?.dateRange, checkedIds]);

  useEffect(() => {
    if (weatherData.length > 0) {
      setDevicesWeatherData(formatDevicesWeatherData(weatherData, user?.role === 'admin' ? usersList.map(({ devices }) => devices).flat() : devices));
    }
  }, [weatherData, usersList, user, devices]);

  const handleFiltersToggle = () => {
    setIsFiltersCollapsed((prev) => !prev);
  };

  const handleCheckAll = (targetUser, array) => {
    let updatedChecked = { ...checkedItems };
    const newDevicesObj = {};
    if (targetUser === 'all') {
      const targetState = Object.values(updatedChecked).some(({ checked }) => checked === true) ? false : true;
      for (const user of Object.keys(updatedChecked)) {
        updatedChecked[user].checked = targetState;
        let devicesObj = {};
        for (const device of Object.keys(updatedChecked[user].devices)) {
          devicesObj[device] = targetState;
        }
        updatedChecked[user].devices = devicesObj;
      }
    } else {
      const targetState = Object.values(updatedChecked[targetUser].devices).some((el) => el === true) ? false : true;
      for (const { identifier } of array) {
        newDevicesObj[identifier] = targetState;
      }
      updatedChecked[targetUser].devices = { ...newDevicesObj };
    }
    const allCheckedUsersIds = Object.values(updatedChecked)
      .filter(({ checked }) => checked)
      .map(({ user }) => user);
    const allCheckedDevicesIds = [];
    for (const user of Object.values(updatedChecked)) {
      for (const [id, checked] of Object.entries(user?.devices)) {
        if (checked) {
          allCheckedDevicesIds.push(id);
        }
      }
    }
    setCheckedIds({
      users: allCheckedUsersIds,
      devices: allCheckedDevicesIds,
    });
    setCheckedItems({ ...updatedChecked });
  };

  const handleCheck = (targetUser, targetId) => {
    let updatedChecked = { ...checkedItems };
    if (targetId === 'checked') {
      updatedChecked[targetUser].checked = !updatedChecked[targetUser].checked;
      let devicesObj = {};
      for (const device of Object.keys(updatedChecked[targetUser].devices)) {
        devicesObj[device] = updatedChecked[targetUser].checked;
      }
      updatedChecked[targetUser].devices = devicesObj;
    } else {
      updatedChecked[targetUser].devices[targetId] = !updatedChecked[targetUser].devices[targetId];
      if (updatedChecked[targetUser].devices[targetId] && !updatedChecked[targetUser].checked) {
        updatedChecked[targetUser].checked = true;
      } else if (!updatedChecked[targetUser].devices[targetId] && updatedChecked[targetUser].checked) {
        const shouldUncheckUser = Object.values(updatedChecked[targetUser].devices).some((el) => el === true) ? false : true;
        if (shouldUncheckUser) {
          updatedChecked[targetUser].checked = false;
        }
      }
    }
    const allCheckedUsersIds = Object.values(updatedChecked)
      .filter(({ checked }) => checked)
      .map(({ user }) => user);
    const allCheckedDevicesIds = [];
    for (const user of Object.values(updatedChecked)) {
      for (const [id, checked] of Object.entries(user?.devices)) {
        if (checked) {
          allCheckedDevicesIds.push(id);
        }
      }
    }
    setCheckedIds({
      users: allCheckedUsersIds,
      devices: allCheckedDevicesIds,
    });
    setCheckedItems(updatedChecked);
  };

  const handleExportPDF = (element) => {
    htmlToImage.toPng(element, { quality: 1, width: 1250, height: 2000 }).then(function (dataUrl) {
      var link = document.createElement('a');
      link.download = 'my-image-name.jpeg';
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(dataUrl);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(dataUrl, 'PNG', 10, 0, pdfWidth - 10, pdfHeight);
      pdf.save(`EnergyMeter_raport-${new Date().toISOString().slice(0, 10)}.pdf`);
    });
  };

  const handleExportCSV = () => {
    let rows;
    if (user?.role === 'admin') {
      const overallDataHeaders = ['Wyprodukowana energia', 'Zużyta energia', 'Zaoszczędzone pieniądze', 'Zaoszczędzona emisja'].join(';');
      const usersDataHeaders = ['Imię i nazwisko', 'Email', 'Liczba urządzeń', 'Łączna produkcja energii', 'Łączna zaoszczędzona produkcja Co2'].join(
        ';',
      );
      const overallData = [
        `${boxesData.totalProduction} kWh`,
        `${boxesData.totalUsage} kWh`,
        `${calculateMoneySaved(devicesEnergyProduction, devicesEnergyPrice)} zł`,
        `${calculateCo2Emission(boxesData.totalProduction)} kg\n`,
      ].join(';');
      const usersData = usersList
        .map(({ _id, name, surname, email, devices }) => [
          `${name} ${surname};`,
          `${email};`,
          `${devices.filter(({ deleted }) => deleted === false).length};`,
          `${usersEnergyProduction[_id]} kWh;`,
          `${calculateCo2Emission(usersEnergyProduction[_id])} kg`,
        ])
        .join('\n')
        .replace(/,/g, '');
      rows = [overallDataHeaders, overallData, usersDataHeaders, usersData];
    } else {
      const overallDataHeaders = ['Wyprodukowana energia', 'Zużyta energia', 'Zaoszczędzone pieniądze', 'Zaoszczędzona emisja'].join(';');
      const devicesDataHeaders = ['Identyfikator', 'Nazwa', 'Status', 'Produkcja energii', 'Zaoszczędzona emisja'].join(';');
      const overallData = [
        `${boxesData.totalProduction} kWh`,
        `${boxesData.totalUsage} kWh`,
        `${calculateMoneySaved(devicesEnergyProduction, devicesEnergyPrice)} zł`,
        `${calculateCo2Emission(boxesData.totalProduction)} kg\n`,
      ].join(';');
      const devicesData = devices
        .map(({ name, identifier, status }) => [
          `${identifier};`,
          `${name};`,
          `${status === 'ON' ? 'Połączony' : 'Rozłączony'};`,
          `${devicesEnergyProduction[identifier] ?? 0} kWh;`,
          `${calculateCo2Emission(devicesEnergyProduction[identifier])} kg`,
        ])
        .join('\n')
        .replace(/,/g, '');
      rows = [overallDataHeaders, overallData, devicesDataHeaders, devicesData];
    }

    let csvContent = rows.join('\n');
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,\uFEFF' + encodeURIComponent(csvContent);
    hiddenElement.target = '_blank';
    hiddenElement.download = `EnergyMeter_raport-${`${user?.name}-${user?.surname}`}-${new Date().toISOString().slice(0, 10)}.csv`;
    hiddenElement.click();
  };

  const handleTableCollapse = (target) => {
    setTableCollapsedItems((prev) => ({
      ...prev,
      [target]: !prev[target],
    }));
  };

  useEffect(() => {
    if (user?._id) {
      const collapsedObj = {};
      let checkedObj = {};
      for (const { _id } of usersList) {
        collapsedObj[_id] = false;
      }
      if (user.role === 'admin') {
        for (const { _id, devices } of usersList) {
          const devicesObj = {};
          for (const { identifier } of devices) {
            devicesObj[identifier] = checkedIds.devices.includes(identifier);
          }
          checkedObj[_id] = {
            user: _id,
            checked: checkedIds.users.includes(_id),
            devices: devicesObj,
          };
        }
      } else {
        const devicesObj = {};
        for (const { identifier } of devices) {
          devicesObj[identifier] = checkedIds.devices.includes(identifier);
        }
        checkedObj = {
          [user?._id]: {
            user: user?._id,
            checked: checkedIds.users.includes(user?._id),
            devices: devicesObj,
          },
        };
      }
      setTableCollapsedItems(collapsedObj);
      setCheckedItems(checkedObj);
    }
  }, [usersList, initalCheck, devices, user]);

  useEffect(() => {
    if (user?.role === 'admin') {
      if (usersPaginationMetadata?.totalCount || usersPaginationMetadata?.totalCount === 0) {
        setPagiCount(usersPaginationMetadata.totalCount);
      }
    } else {
      if (devicesPaginationMetadata?.totalCount || devicesPaginationMetadata?.totalCount === 0) {
        setPagiCount(devicesPaginationMetadata.totalCount);
      }
    }
  }, [usersPaginationMetadata, devicesPaginationMetadata]);

  useEffect(() => {
    if (user?.role === 'admin') {
      if (!initalCheck && usersList.length > 0) {
        setCheckedIds({
          users: usersList.map(({ _id }) => _id),
          devices: usersList.reduce((arr, { devices = [] }) => arr.concat(devices.map(({ identifier }) => identifier)), []),
        });
        setInitalCheck(true);
      }
    } else {
      if (!initalCheck && devices.length > 0) {
        setCheckedIds({
          users: [],
          devices: devices.map(({ identifier }) => identifier),
        });
        setInitalCheck(true);
      }
    }
  }, [devices, usersList]);

  return (
    <DashboardView
      user={user}
      filters={filters}
      handleFiltersChange={handleFiltersChange}
      isFiltersCollapsed={isFiltersCollapsed}
      handleFiltersToggle={handleFiltersToggle}
      checkedItems={checkedItems}
      handleCheckAll={handleCheckAll}
      handleCheck={handleCheck}
      devices={devices}
      chartData={chartData}
      isExportOverlayOpen={isExportOverlayOpen}
      setIsExportOverlayOpen={setIsExportOverlayOpen}
      handleExportPDF={handleExportPDF}
      tableCollapsedItems={tableCollapsedItems}
      handleTableCollapse={handleTableCollapse}
      usersList={usersList}
      tableSearchTerm={tableSearchTerm}
      debouncedTableSearchTerm={debouncedTableSearchTerm}
      setTableSearchTerm={setTableSearchTerm}
      pagiCount={pagiCount}
      setPagiCount={setPagiCount}
      pagiPage={pagiPage}
      setPagiPage={setPagiPage}
      pagiRowsPerPage={pagiRowsPerPage}
      setPagiRowsPerPage={setPagiRowsPerPage}
      checkedIds={checkedIds}
      usersLoading={usersLoading}
      devicesLoading={devicesLoading}
      handleExportCSV={handleExportCSV}
      history={history}
      boxesData={boxesData}
      filterErrors={filterErrors}
      devicesEnergyProduction={devicesEnergyProduction}
      usersEnergyProduction={usersEnergyProduction}
      previousValues={previousValues}
      devicesEnergyPrice={devicesEnergyPrice}
      devicesWeatherData={devicesWeatherData}
      selfConsumption={selfConsumption}
    />
  );
};

const mapStateToProps = (state) => ({
  usersList: state.auth.usersList,
  usersPaginationMetadata: state.auth.paginationMetadata,
  devices: state.device.devices,
  devicesPaginationMetadata: state.device.paginationMetadata,
  usersLoading: state.auth.usersLoading,
  devicesLoading: state.device.loading,
  adminStats: state.dailyStats.adminStats,
  adminTodaysStats: state.dailyStats.adminTodaysStats,
  userStats: state.dailyStats.userStats,
  userTodaysStats: state.dailyStats.userTodaysStats,
  previousUserTotalProduction: state.dailyStats.previousUserTotalProduction,
  previousUserTotalUsage: state.dailyStats.previousUserTotalUsage,
  previousAdminTotalProduction: state.dailyStats.previousAdminTotalProduction,
  previousAdminTotalUsage: state.dailyStats.previousAdminTotalUsage,
  weatherData: state.weather.data,
  devicesEnergyPrice: state.device.devicesEnergyPrice,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (populates, searchTerm, pageSize, pageNumber) => dispatch(getUsers(populates, searchTerm, pageSize, pageNumber)),
    getDevices: (userId, searchTerm, pageSize, pageNumber) => dispatch(getDevices(userId, searchTerm, pageSize, pageNumber)),
    getAdminStats: (startDate, endDate) => dispatch(getAdminStats(startDate, endDate)),
    getAdminTodaysStats: () => dispatch(getAdminTodaysStats()),
    getUserStats: (userId, startDate, endDate) => dispatch(getUserStats(userId, startDate, endDate)),
    getUserTodaysStats: (userId) => dispatch(getUserTodaysStats(userId)),
    getDevicesEnergyPrice: (userId) => dispatch(getDevicesEnergyPrice(userId)),
    getUserNewestWeatherData: (userId) => dispatch(getUserNewestWeatherData(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
