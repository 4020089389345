import axios from 'axios';

const GET_DEVICE_STATS_REQUEST = 'GET_DEVICE_STATS_REQUEST';
const GET_DEVICE_STATS_SUCCESS = 'GET_DEVICE_STATS_SUCCESS';
const GET_DEVICE_STATS_FAIL = 'GET_DEVICE_STATS_FAIL';
const GET_DEVICE_TODAYS_STATS_REQUEST = 'GET_DEVICE_TODAYS_STATS_REQUEST';
const GET_DEVICE_TODAYS_STATS_SUCCESS = 'GET_DEVICE_TODAYS_STATS_SUCCESS';
const GET_DEVICE_TODAYS_STATS_FAIL = 'GET_DEVICE_TODAYS_STATS_FAIL';

const GET_USER_STATS_REQUEST = 'GET_USER_STATS_REQUEST';
const GET_USER_STATS_SUCCESS = 'GET_USER_STATS_SUCCESS';
const GET_USER_STATS_FAIL = 'GET_USER_STATS_FAIL';
const GET_USER_TODAYS_STATS_REQUEST = 'GET_USER_TODAYS_STATS_REQUEST';
const GET_USER_TODAYS_STATS_SUCCESS = 'GET_USER_TODAYS_STATS_SUCCESS';
const GET_USER_TODAYS_STATS_FAIL = 'GET_USER_TODAYS_STATS_FAIL';

const GET_ADMIN_STATS_REQUEST = 'GET_ADMIN_STATS_REQUEST';
const GET_ADMIN_STATS_SUCCESS = 'GET_ADMIN_STATS_SUCCESS';
const GET_ADMIN_STATS_FAIL = 'GET_ADMIN_STATS_FAIL';
const GET_ADMIN_TODAYS_STATS_REQUEST = 'GET_ADMIN_TODAYS_STATS_REQUEST';
const GET_ADMIN_TODAYS_STATS_SUCCESS = 'GET_ADMIN_TODAYS_STATS_SUCCESS';
const GET_ADMIN_TODAYS_STATS_FAIL = 'GET_ADMIN_TODAYS_STATS_FAIL';

const GET_SUMMARY_REQUEST = 'GET_SUMMARY_REQUEST';
const GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS';
const GET_SUMMARY_FAIL = 'GET_SUMMARY_FAIL';

const phasesDefaultValues = {
  first: 0,
  second: 0,
  third: 0,
};

export const getDeviceStats =
  (deviceId, startDate, endDate, pageSize = 999) =>
  async (dispatch) => {
    dispatch(getDeviceStatsRequest());
    try {
      const response = await axios.get(
        `/daily-power-stats/by-device/?deviceId=${deviceId}&startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}`,
      );
      dispatch(getDeviceStatsSuccess(response.data));
    } catch (err) {
      dispatch(getDeviceStatsFail(err));
    }
  };

const getDeviceStatsRequest = () => ({
  type: GET_DEVICE_STATS_REQUEST,
});

const getDeviceStatsSuccess = ({ deviceStats, prevTotalProduction, prevTotalUsage }) => ({
  type: GET_DEVICE_STATS_SUCCESS,
  payload: { deviceStats, prevTotalProduction, prevTotalUsage },
});

const getDeviceStatsFail = (error) => ({
  type: GET_DEVICE_STATS_FAIL,
  payload: error,
});

export const getDeviceTodaysStats = (deviceId) => async (dispatch) => {
  dispatch(getDeviceTodaysStatsRequest());
  try {
    const response = await axios.get(`/daily-power-stats/by-device/today?deviceId=${deviceId}`);
    dispatch(getDeviceTodaysStatsSuccess(response.data));
  } catch (err) {
    dispatch(getDeviceTodaysStatsFail(err));
  }
};

const getDeviceTodaysStatsRequest = () => ({
  type: GET_DEVICE_TODAYS_STATS_REQUEST,
});

const getDeviceTodaysStatsSuccess = ({ deviceStats, prevTotalProduction, prevTotalUsage }) => ({
  type: GET_DEVICE_TODAYS_STATS_SUCCESS,
  payload: { deviceStats, prevTotalProduction, prevTotalUsage },
});

const getDeviceTodaysStatsFail = (error) => ({
  type: GET_DEVICE_TODAYS_STATS_FAIL,
  payload: error,
});

export const getUserStats =
  (userId, startDate, endDate, pageSize = 999) =>
  async (dispatch) => {
    dispatch(getUserStatsRequest());
    try {
      const response = await axios.get(`/daily-power-stats/by-user/?userId=${userId}&startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}`);
      dispatch(getUserStatsSuccess(response.data));
    } catch (err) {
      dispatch(getUserStatsFail(err));
    }
  };

const getUserStatsRequest = () => ({
  type: GET_USER_STATS_REQUEST,
});

const getUserStatsSuccess = ({ userStats, prevTotalProduction, prevTotalUsage }) => ({
  type: GET_USER_STATS_SUCCESS,
  payload: { userStats, prevTotalProduction, prevTotalUsage },
});

const getUserStatsFail = (error) => ({
  type: GET_USER_STATS_FAIL,
  payload: error,
});

export const getUserTodaysStats = (userId) => async (dispatch) => {
  dispatch(getUserTodaysStatsRequest());
  try {
    const response = await axios.get(`/daily-power-stats/by-user/today?userId=${userId}`);
    dispatch(getUserTodaysStatsSuccess(response.data));
  } catch (err) {
    dispatch(getUserTodaysStatsFail(err));
  }
};

const getUserTodaysStatsRequest = () => ({
  type: GET_USER_TODAYS_STATS_REQUEST,
});

const getUserTodaysStatsSuccess = ({ userStats, prevTotalProduction, prevTotalUsage }) => ({
  type: GET_USER_TODAYS_STATS_SUCCESS,
  payload: { userStats, prevTotalProduction, prevTotalUsage },
});

const getUserTodaysStatsFail = (error) => ({
  type: GET_USER_TODAYS_STATS_FAIL,
  payload: error,
});

export const getAdminStats =
  (startDate, endDate, pageSize = 999) =>
  async (dispatch) => {
    dispatch(getAdminStatsRequest());
    try {
      const response = await axios.get(`/daily-power-stats/all-users/?startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}`);
      dispatch(getAdminStatsSuccess(response.data));
    } catch (err) {
      dispatch(getAdminStatsFail(err));
    }
  };

const getAdminStatsRequest = () => ({
  type: GET_ADMIN_STATS_REQUEST,
});

const getAdminStatsSuccess = ({ adminStats, prevTotalProduction, prevTotalUsage }) => ({
  type: GET_ADMIN_STATS_SUCCESS,
  payload: { adminStats, prevTotalProduction, prevTotalUsage },
});

const getAdminStatsFail = (error) => ({
  type: GET_ADMIN_STATS_FAIL,
  payload: error,
});

export const getAdminTodaysStats = () => async (dispatch) => {
  dispatch(getAdminTodaysStatsRequest());
  try {
    const response = await axios.get(`/daily-power-stats/all-users/today`);
    dispatch(getAdminTodaysStatsSuccess(response.data));
  } catch (err) {
    dispatch(getAdminTodaysStatsFail(err));
  }
};

const getAdminTodaysStatsRequest = () => ({
  type: GET_ADMIN_TODAYS_STATS_REQUEST,
});

const getAdminTodaysStatsSuccess = ({ adminStats, prevTotalProduction, prevTotalUsage }) => ({
  type: GET_ADMIN_TODAYS_STATS_SUCCESS,
  payload: { adminStats, prevTotalProduction, prevTotalUsage },
});

const getAdminTodaysStatsFail = (error) => ({
  type: GET_ADMIN_TODAYS_STATS_FAIL,
  payload: error,
});

export const getSummary = () => async (dispatch) => {
  dispatch(getSummaryRequest());
  try {
    const response = await axios.get(`/daily-power-stats/summary`);
    dispatch(getSummarySuccess(response.data));
  } catch (err) {
    dispatch(getSummaryFail(err));
  }
};

const getSummaryRequest = () => ({
  type: GET_SUMMARY_REQUEST,
});

const getSummarySuccess = (stats) => ({
  type: GET_SUMMARY_SUCCESS,
  payload: stats,
});

const getSummaryFail = (error) => ({
  type: GET_SUMMARY_FAIL,
  payload: error,
});

const initialState = {
  deviceStats: [],
  deviceTodaysStats: [],
  userStats: [],
  userTodaysStats: [],
  adminStats: [],
  adminTodaysStats: [],
  previousDeviceTotalProduction: phasesDefaultValues,
  previousDeviceTotalUsage: phasesDefaultValues,
  previousUserTotalProduction: phasesDefaultValues,
  previousUserTotalUsage: phasesDefaultValues,
  previousAdminTotalProduction: phasesDefaultValues,
  previousAdminTotalUsage: phasesDefaultValues,
  totalStats: {
    totalEnergyProduction: 0,
    totalEmissionSaved: 0,
    totalMoneySaved: 0,
    treesEquivalent: 0,
  },
  error: null,
  loading: false,
  summaryLoading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICE_STATS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DEVICE_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        deviceStats: action.payload.deviceStats,
        previousDeviceTotalProduction: action.payload.prevTotalProduction,
        previousDeviceTotalUsage: action.payload.prevTotalUsage,
      };
    case GET_DEVICE_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_DEVICE_TODAYS_STATS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DEVICE_TODAYS_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        deviceTodaysStats: [action.payload.deviceStats],
        previousDeviceTotalProduction: action.payload.prevTotalProduction,
        previousDeviceTotalUsage: action.payload.prevTotalUsage,
      };
    case GET_DEVICE_TODAYS_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_USER_STATS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        userStats: action.payload.userStats,
        previousUserTotalProduction: action.payload.prevTotalProduction,
        previousUserTotalUsage: action.payload.prevTotalUsage,
      };
    case GET_USER_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_USER_TODAYS_STATS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_TODAYS_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        userTodaysStats: action.payload.userStats,
        previousUserTotalProduction: action.payload.prevTotalProduction,
        previousUserTotalUsage: action.payload.prevTotalUsage,
      };
    case GET_USER_TODAYS_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ADMIN_STATS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ADMIN_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        adminStats: action.payload.adminStats,
        previousAdminTotalProduction: action.payload.prevTotalProduction,
        previousAdminTotalUsage: action.payload.prevTotalUsage,
      };
    case GET_ADMIN_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ADMIN_TODAYS_STATS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ADMIN_TODAYS_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        adminTodaysStats: action.payload.adminStats,
        previousAdminTotalProduction: action.payload.prevTotalProduction,
        previousAdminTotalUsage: action.payload.prevTotalUsage,
      };
    case GET_ADMIN_TODAYS_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_SUMMARY_REQUEST:
      return {
        ...state,
        summaryLoading: true,
      };
    case GET_SUMMARY_SUCCESS:
      return {
        ...state,
        summaryLoading: false,
        totalStats: action.payload,
      };
    case GET_SUMMARY_FAIL:
      return {
        ...state,
        summaryLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
