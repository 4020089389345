export const capitalizeText = (text = '') => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const getNewestWeatherData = (weatherData) => {
  const newestDoc = weatherData?.sort((a, b) => new Date(b.date) - new Date(a.date))[0];
  return newestDoc?.pings?.sort((a, b) => new Date(b.time) - new Date(a.time))[0];
};

export const handleCopyText = (text, onSuccess) => {
  const dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.setAttribute('id', 'dummy_id');
  document.getElementById('dummy_id').value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
  onSuccess();
};

export const handleLanguageChange = (lang) => {
  localStorage.setItem('language', lang);
  window.location.reload();
};

export const truncateText = (source, size = 15, stringEnding = false) => {
  const startLength = stringEnding ? size - 10 : size - 3;
  if (source?.length > size) {
    return `${source.substring(0, startLength)}...${stringEnding ? source.substring(source.length - 7, source.length) : ''}`;
  } else {
    return source;
  }
};
