import { useState } from 'react';
import dayjs from 'dayjs';

const shapeFiltersState = (field, value) => {
  let newState = {};
  if (field === 'dateFrom') {
    value = dayjs(value, 'YYYY-MM-DD').startOf('day').toISOString();
  } else if (field === 'dateTo') {
    value = dayjs(value, 'YYYY-MM-DD').endOf('day').toISOString();
  } else if (field === 'dateRange') {
    if (value === 'today') {
      newState = {
        dateFrom: dayjs().startOf('day').toISOString(),
        dateTo: dayjs().endOf('day').toISOString(),
      };
    }
    if (value === 'yesterday') {
      newState = {
        dateFrom: dayjs().subtract(1, 'day').startOf('day').toISOString(),
        dateTo: dayjs().subtract(1, 'day').endOf('day').toISOString(),
      };
    }
    if (value === 'last7') {
      newState = {
        dateFrom: dayjs().subtract(7, 'day').startOf('day').toISOString(),
        dateTo: dayjs().endOf('day').toISOString(),
      };
    }
    if (value === 'last30') {
      newState = {
        dateFrom: dayjs().subtract(30, 'day').startOf('day').toISOString(),
        dateTo: dayjs().endOf('day').toISOString(),
      };
    }
  }
  newState = {
    [field]: value,
    ...newState,
  };
  return newState;
};

const useFilters = () => {
  const lsFiltersRange = localStorage.getItem('filtersDateRange') ?? 'today';
  const lsFiltersDateTo = localStorage.getItem('filtersDateTo') ?? dayjs().endOf('day').toISOString();
  const lsFiltersDateFrom = localStorage.getItem('filtersDateFrom') ?? dayjs().startOf('day').toISOString();
  const lsPhase = localStorage.getItem('filtersPhase') ?? '0';
  const newState = shapeFiltersState('dateRange', lsFiltersRange);
  const initalFiltersState = {
    dateRange: lsFiltersRange,
    dateFrom: lsFiltersRange === 'custom' ? dayjs(lsFiltersDateFrom).startOf('day').toISOString() : newState.dateFrom,
    dateTo: lsFiltersRange === 'custom' ? dayjs(lsFiltersDateTo).endOf('day').toISOString() : newState.dateTo,
    phase: lsPhase,
  };
  const [filters, setFilters] = useState(initalFiltersState);
  const [filterErrors, setFilterErrors] = useState('');

  const handleFiltersChange = (field, value) => {
    const newState = shapeFiltersState(field, value);
    let shouldUpdateVal = true;
    //errors handling
    if (field === 'dateFrom' && dayjs(newState.dateFrom).isSameOrAfter(filters.dateTo)) {
      setFilterErrors('Data od nie może być późniejsza niż data do');
      shouldUpdateVal = false;
    } else if (field === 'dateTo' && dayjs(newState.dateTo).isSameOrBefore(filters.dateFrom)) {
      setFilterErrors('Data do nie może być wcześniejsza niż data od');
      shouldUpdateVal = false;
    } else {
      setFilterErrors('');
    }
    if (shouldUpdateVal) {
      if (field === 'dateRange') {
        localStorage.setItem('filtersDateRange', value);
        if (newState.dateTo && value === 'custom') {
          localStorage.setItem('filtersDateTo', newState.dateTo);
        }
        if (newState.dateFrom && value === 'custom') {
          localStorage.setItem('filtersDateFrom', newState.dateFrom);
        }
      } else if (field === 'dateTo' && filters.dateRange === 'custom') {
        localStorage.setItem('filtersDateTo', value);
      } else if (field === 'dateFrom' && filters.dateRange === 'custom') {
        localStorage.setItem('filtersDateFrom', value);
      } else if (field === 'phase') {
        localStorage.setItem('filtersPhase', value);
      }
      setFilters((prev) => ({
        ...prev,
        ...newState,
      }));
    }
  };

  return { handleFiltersChange, filters, filterErrors };
};

export default useFilters;
