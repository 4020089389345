import { useState, useEffect } from 'react';
import ProfileView from './ProfileView';
import { connect } from 'react-redux';
import { editUser } from '../../redux/modules/auth';
import { toggleDarkMode } from '../../redux/modules/navigation';
import { getDevices } from '../../redux/modules/device';
import { getUsers, generateApiKey, deleteUser, logoutUser } from '../../redux/modules/auth';
import { resetPassword } from '../../redux/modules/auth';

const ProfileContainer = ({
  user,
  editUser,
  darkMode,
  toggleDarkMode,
  devices,
  usersList,
  getDevices,
  getUsers,
  generateApiKey,
  deleteUser,
  logoutUser,
  resetPassword,
}) => {
  const [isEditUserDataModal, setEditUserDataModal] = useState(false);
  const [isConfirmationModal, setConfirmationModal] = useState(false);
  const [isUserConsentsModal, setUserConsentsModal] = useState(false);
  const [isNotificationSettingsModal, setNotificationSettingsModal] = useState(false);
  const [generateApiKeyModal, setGenerateApiKeyModal] = useState({ shown: false, key: null });
  const [addApiKeyModal, setAddApiKeyModal] = useState({ shown: false, target: null });

  useEffect(() => {
    if (user?._id) {
      if (user?.role === 'admin') {
        getUsers('devices');
      } else {
        getDevices(user?._id, '', 999);
      }
    }
  }, [user]);

  return (
    <ProfileView
      isEditUserDataModal={isEditUserDataModal}
      setEditUserDataModal={setEditUserDataModal}
      user={user}
      editUser={editUser}
      toggleDarkMode={toggleDarkMode}
      darkMode={darkMode}
      devices={devices}
      usersList={usersList}
      generateApiKey={generateApiKey}
      isConfirmationModal={isConfirmationModal}
      setConfirmationModal={setConfirmationModal}
      generateApiKeyModal={generateApiKeyModal}
      setGenerateApiKeyModal={setGenerateApiKeyModal}
      addApiKeyModal={addApiKeyModal}
      setAddApiKeyModal={setAddApiKeyModal}
      deleteUser={deleteUser}
      logoutUser={logoutUser}
      isUserConsentsModal={isUserConsentsModal}
      setUserConsentsModal={setUserConsentsModal}
      isNotificationSettingsModal={isNotificationSettingsModal}
      setNotificationSettingsModal={setNotificationSettingsModal}
      resetPassword={resetPassword}
    />
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.navigation.darkMode,
  devices: state.device.devices,
  usersList: state.auth.usersList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    editUser: (userId, body) => dispatch(editUser(userId, body)),
    toggleDarkMode: () => dispatch(toggleDarkMode()),
    getUsers: (populates) => dispatch(getUsers(populates)),
    getDevices: (userId, searchTerm, pageLimit) => dispatch(getDevices(userId, searchTerm, pageLimit)),
    generateApiKey: (userId, password) => dispatch(generateApiKey(userId, password)),
    deleteUser: (userId) => dispatch(deleteUser(userId)),
    logoutUser: () => dispatch(logoutUser()),
    resetPassword: (email, skipGeneration) => dispatch(resetPassword(email, skipGeneration)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
