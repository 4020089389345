import axios from 'axios';
//ACTION TYPES---------------------------------
const GET_DEVICE_BY_ID_REQUEST = 'GET_DEVICE_BY_ID_REQUEST';
const GET_DEVICE_BY_ID_FAIL = 'GET_DEVICE_BY_ID_FAIL';
const GET_DEVICE_BY_ID_SUCCESS = 'GET_DEVICE_BY_ID_SUCCESS';
const GET_DEVICES_REQUEST = 'GET_DEVICES_REQUEST';
const GET_DEVICES_FAIL = 'GET_DEVICES_FAIL';
const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
const CREATE_DEVICE_REQUEST = 'CREATE_DEVICE_REQUEST';
const CREATE_DEVICE_FAIL = 'CREATE_DEVICE_FAIL';
const CREATE_DEVICE_SUCCESS = 'CREATE_DEVICE_SUCCESS';
const EDIT_DEVICE_REQUEST = 'EDIT_DEVICE_REQUEST';
const EDIT_DEVICE_FAIL = 'EDIT_DEVICE_FAIL';
const RESTORE_DEVICE = 'RESTORE_DEVICE';
const EDIT_DEVICE_SUCCESS = 'EDIT_DEVICE_SUCCESS';
const GET_DEVICE_DATA_FROM_SOCKET_SUCCESS = 'GET_DEVICE_DATA_FROM_SOCKET_SUCCESS';
const LEAVE_DEVICE_SOCKET_ROOM = 'LEAVE_DEVICE_SOCKET_ROOM';
const GET_DEVICES_ENERGY_PRICE_REQUEST = 'GET_DEVICES_ENERGY_PRICE_REQUEST';
const GET_DEVICES_ENERGY_PRICE_FAIL = 'GET_DEVICES_ENERGY_PRICE_FAIL';
const GET_DEVICES_ENERGY_PRICE_SUCCESS = 'GET_DEVICES_ENERGY_PRICE_SUCCESS';
const SOCKET_DEVICE_UPDATE = 'SOCKET_DEVICE_UPDATE';
//ACTIONS-------------------------------------

export const getDeviceById = (deviceId) => async (dispatch) => {
  dispatch(getDeviceByIdRequest());
  try {
    const response = await axios.get(`/device/${deviceId}`);
    dispatch(getDeviceByIdSuccess(response.data));
  } catch (err) {
    dispatch(getDeviceByIdFail(err));
  }
};

const getDeviceByIdRequest = () => ({
  type: GET_DEVICE_BY_ID_REQUEST,
});

const getDeviceByIdSuccess = (device) => ({
  type: GET_DEVICE_BY_ID_SUCCESS,
  payload: device,
});

const getDeviceByIdFail = (error) => ({
  type: GET_DEVICE_BY_ID_FAIL,
  payload: error,
});

export const getDevices =
  (userId, searchTerm = '', pageSize = '', pageNumber = '') =>
  async (dispatch) => {
    dispatch(getDevicesRequest());
    const props = [{ userId }, { searchTerm }, { pageSize }, { pageNumber }];
    console.log(props);
    let query = '';
    for (const prop of props) {
      const [[key, value]] = Object.entries(prop);
      query += `&${key}=${encodeURIComponent(value)}`;
    }
    query = query.replace('&', '?');
    try {
      const response = await axios.get(`/device/${query}`);
      const { docs, ...paginationObject } = response.data;
      dispatch(getDevicesSuccess(docs, paginationObject));
    } catch (err) {
      dispatch(getDevicesFail(err));
    }
  };

const getDevicesRequest = () => ({
  type: GET_DEVICES_REQUEST,
});

const getDevicesSuccess = (devices, paginationMetadata) => ({
  type: GET_DEVICES_SUCCESS,
  payload: devices,
  paginationMetadata,
});

const getDevicesFail = (error) => ({
  type: GET_DEVICES_FAIL,
  payload: error,
});

export const createDevice = (userId, deviceBody) => async (dispatch) => {
  dispatch(createDeviceRequest());
  try {
    const response = await axios.post(`/device?userId=${userId}`, deviceBody);
    dispatch(createDeviceSuccess(response.data));
    return response;
  } catch (err) {
    dispatch(createDeviceFail(err));
    return err?.response;
  }
};

const createDeviceRequest = () => ({
  type: CREATE_DEVICE_REQUEST,
});

const createDeviceSuccess = (device) => ({
  type: CREATE_DEVICE_SUCCESS,
  payload: device,
});

const createDeviceFail = (error) => ({
  type: CREATE_DEVICE_FAIL,
  payload: error,
});

export const editDevice =
  (deviceId, deviceBody, restore = false) =>
  async (dispatch) => {
    dispatch(editDeviceRequest());
    try {
      const response = await axios.patch(`/device/${deviceId}`, deviceBody);
      if (restore) {
        dispatch(restoreDevice(response.data));
      } else {
        dispatch(editDeviceSuccess(response.data));
      }
      return response;
    } catch (err) {
      dispatch(editDeviceFail(err));
      return err?.response;
    }
  };

const editDeviceRequest = () => ({
  type: EDIT_DEVICE_REQUEST,
});

const editDeviceSuccess = (device) => ({
  type: EDIT_DEVICE_SUCCESS,
  payload: device,
});

const restoreDevice = (device) => ({
  type: RESTORE_DEVICE,
  payload: device,
});

const editDeviceFail = (error) => ({
  type: EDIT_DEVICE_FAIL,
  payload: error,
});

export const getDeviceDataFromSocketSuccess = (currentData) => ({
  type: GET_DEVICE_DATA_FROM_SOCKET_SUCCESS,
  payload: currentData,
});

export const leaveDeviceSocketRoom = (device) => ({
  type: LEAVE_DEVICE_SOCKET_ROOM,
  payload: device,
});

export const getDevicesEnergyPrice = (userId) => async (dispatch) => {
  dispatch(getDevicesEnergyPriceRequest());
  try {
    const response = await axios.get(`/device/energyPrices?userId=${userId}`);
    dispatch(getDevicesEnergyPriceSuccess(response.data));
  } catch (err) {
    console.log(err);
    dispatch(getDevicesEnergyPriceFail(err));
  }
};

const getDevicesEnergyPriceRequest = () => ({
  type: GET_DEVICES_ENERGY_PRICE_REQUEST,
});

const getDevicesEnergyPriceSuccess = (prices) => ({
  type: GET_DEVICES_ENERGY_PRICE_SUCCESS,
  payload: prices,
});

const getDevicesEnergyPriceFail = (error) => ({
  type: GET_DEVICES_ENERGY_PRICE_FAIL,
  payload: error,
});

export const socketDeviceStatusesUpdate = (deviceId, body) => ({
  type: SOCKET_DEVICE_UPDATE,
  payload: { deviceId, body },
});

const initialState = {
  devices: [],
  devicesEnergyPrice: [],
  paginationMetadata: null,
  currentDevice: null,
  currentDeviceChangingData: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICE_BY_ID_REQUEST:
    case CREATE_DEVICE_REQUEST:
    case GET_DEVICES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_DEVICE_FAIL:
    case GET_DEVICES_FAIL:
    case EDIT_DEVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_DEVICE_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        currentDevice: null,
        loading: false,
      };
    case GET_DEVICE_BY_ID_SUCCESS:
      return {
        ...state,
        currentDevice: action.payload,
        loading: false,
      };
    case GET_DEVICE_DATA_FROM_SOCKET_SUCCESS:
      return {
        ...state,
        currentDeviceChangingData: action.payload,
        loading: false,
      };
    case LEAVE_DEVICE_SOCKET_ROOM:
      return {
        ...state,
        currentDeviceChangingData: null,
        loading: false,
      };
    case CREATE_DEVICE_SUCCESS:
      return {
        ...state,
        devices: [...state.devices, action.payload],
        paginationMetadata: {
          ...state.paginationMetadata,
          totalCount: state.paginationMetadata.totalCount + 1,
        },
        loading: false,
      };
    case GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload,
        paginationMetadata: action.paginationMetadata,
        loading: false,
      };
    case EDIT_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentDevice: action.payload,
        devices:
          action.payload?.deleted === true
            ? state.devices.filter((dev) => dev._id !== action.payload._id)
            : state.devices.map((dev) => (dev._id === action.payload._id ? action.payload : dev)),
        paginationMetadata: {
          ...state.paginationMetadata,
          totalCount: action.payload?.deleted === true ? state.paginationMetadata?.totalCount - 1 : state.paginationMetadata?.totalCount,
        },
      };
    case RESTORE_DEVICE:
      return {
        ...state,
        devices: [...state.devices, action.payload],
        loading: false,
        error: null,
      };
    case GET_DEVICES_ENERGY_PRICE_REQUEST:
      return {
        ...state,
      };
    case GET_DEVICES_ENERGY_PRICE_SUCCESS:
      return {
        ...state,
        devicesEnergyPrice: action.payload,
      };
    case GET_DEVICES_ENERGY_PRICE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case SOCKET_DEVICE_UPDATE:
      return {
        ...state,
        currentDevice:
          state?.currentDevice?.identifier === action.payload.deviceId ? { ...state.currentDevice, ...action.payload.body } : state.currentDevice,
        devices: state.devices.map((dev) => (dev._id === action.payload._id ? { ...dev, ...action.payload.body } : dev)),
      };
    default:
      return state;
  }
};
