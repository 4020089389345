import { useRef } from 'react';
import styled from 'styled-components';
import Button from '../../shared/Button';
import Input from '../../shared/Input';
import DataPaper from '../../shared/DataPaper';
import ChartPaper from '../../shared/ChartPaper';
import { useTranslation } from 'react-i18next';
import Table from '../../shared/Table';
import { theme } from '../../shared/theme';
import {
  Collapse,
  TableCell,
  TableRow,
  Checkbox,
  ClickAwayListener,
  Grow,
  Popper,
  MenuItem,
  IconButton,
  MenuList,
  TablePagination,
} from '@material-ui/core';
import Paper from '../../shared/Paper';
import StatusPill from '../../shared/StatusPill';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LaunchIcon from '@material-ui/icons/Launch';
import FilterListIcon from '@material-ui/icons/FilterList';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from 'react-router-dom';
import Select from '../../shared/Select';
import { dateFilterOptions, phaseFilterOptions } from '../../shared/consts';
import { ReactComponent as Placeholder } from '../../shared/assets/dashboard-nodata.svg';
import { calculateCo2Emission, calculateMoneySaved } from '../../utils/objectsFormating';
import { ReactComponent as ProductionIcon } from '../../shared/assets/production.svg';
import { ReactComponent as UsageIcon } from '../../shared/assets/usage.svg';
import { ReactComponent as SavingsIcon } from '../../shared/assets/savings.svg';
import { ReactComponent as CO2Icon } from '../../shared/assets/co2.svg';
import { ReactComponent as UsersIcon } from '../../shared/assets/users.svg';
import { ReactComponent as DevicesIcon } from '../../shared/assets/devices.svg';
import dayjs from 'dayjs';

const DashboardView = ({
  user,
  filters,
  handleFiltersChange,
  isFiltersCollapsed,
  checkedItems,
  handleFiltersToggle,
  handleCheckAll,
  handleCheck,
  devices,
  chartData,
  isExportOverlayOpen,
  setIsExportOverlayOpen,
  handleExportPDF,
  tableCollapsedItems,
  handleTableCollapse,
  usersList,
  tableSearchTerm,
  debouncedTableSearchTerm,
  setTableSearchTerm,
  pagiCount,
  pagiPage,
  setPagiPage,
  pagiRowsPerPage,
  setPagiRowsPerPage,
  checkedIds,
  usersLoading,
  devicesLoading,
  handleExportCSV,
  history,
  boxesData,
  filterErrors,
  devicesEnergyProduction,
  usersEnergyProduction,
  devicesEnergyPrice,
  previousValues,
  devicesWeatherData,
  selfConsumption,
}) => {
  const { t } = useTranslation();
  const printRef = useRef();
  return (
    <Wrapper>
      <PageTitle>{t('dashboardPage.title')}</PageTitle>
      {devices?.length === 0 && usersList.length === 0 && debouncedTableSearchTerm === '' && !devicesLoading && !usersLoading ? (
        <></>
      ) : (
        <>
          <Actions>
            <ButtonWrapper>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<StyledLaunchIcon />}
                endIcon={<StyledArrowDropDownIcon $active={isExportOverlayOpen} $iconColor={theme.primary} />}
                onClick={() => {
                  setIsExportOverlayOpen(true);
                }}
              >
                {t('btns.export')}
              </Button>
              <StyledPopper open={isExportOverlayOpen} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={() => setIsExportOverlayOpen(false)}>
                        <MenuList autoFocusItem={open} id="menu-list-grow">
                          <StyledMenuItem
                            onClick={() => {
                              setIsExportOverlayOpen(false);
                              handleExportPDF(printRef.current);
                            }}
                          >
                            {t('btns.exportOptions.asPdf')}
                          </StyledMenuItem>
                          <StyledMenuItem
                            onClick={() => {
                              setIsExportOverlayOpen(false);
                              handleExportCSV();
                            }}
                          >
                            {t('btns.exportOptions.asCsv')}
                          </StyledMenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </StyledPopper>
            </ButtonWrapper>
            <Button
              color="secondary"
              onClick={handleFiltersToggle}
              startIcon={<StyledFilterListIcon />}
              endIcon={<StyledArrowDropDownIcon $active={isFiltersCollapsed} />}
            >
              {t('btns.filters')}
            </Button>
          </Actions>
          <Collapse in={isFiltersCollapsed}>
            <Filters>
              <Select
                name="dateRange"
                options={dateFilterOptions}
                label={t('formLabels.dateRange')}
                value={filters.dateRange}
                onChange={(e) => handleFiltersChange('dateRange', e.target.value)}
              />
              {filters.dateRange === 'custom' && (
                <>
                  {' '}
                  <Input
                    name="dateFrom"
                    label={t('formLabels.dateFrom')}
                    type="date"
                    onChange={(e) => handleFiltersChange('dateFrom', e.target.value)}
                    value={dayjs(filters.dateFrom).format('YYYY-MM-DD')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Input
                    name="dateTo"
                    label={t('formLabels.dateTo')}
                    type="date"
                    onChange={(e) => handleFiltersChange('dateTo', e.target.value)}
                    value={dayjs(filters.dateTo).format('YYYY-MM-DD')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorMsg>{filterErrors}</ErrorMsg>
                </>
              )}
              <Select
                name="phase"
                options={phaseFilterOptions}
                label={t('formLabels.phase')}
                value={filters.phase}
                onChange={(e) => handleFiltersChange('phase', e.target.value)}
              />
            </Filters>
          </Collapse>
        </>
      )}
      <div ref={printRef}>
        {devices?.length === 0 && usersList.length === 0 && debouncedTableSearchTerm === '' && !devicesLoading && !usersLoading ? (
          <></>
        ) : (
          <DataGrid>
            <DataPaper
              title={t('dataBoxes.producedEnergy.title')}
              icon={<ProductionIcon />}
              color={theme.primary}
              unit="kWh"
              value={boxesData.totalProduction ?? 0}
              maxValue={boxesData.totalProduction > previousValues.production ? boxesData.totalProduction : previousValues.production}
              dateRange={filters?.dateRange}
              previousValue={previousValues.production}
              popupContent={() => (
                <div dangerouslySetInnerHTML={{ __html: t('dataBoxes.producedEnergy.tooltip', { interpolation: { escapeValue: false } }) }} />
              )}
            />
            <DataPaper
              title={t('dataBoxes.usedEnergy.title')}
              icon={<UsageIcon />}
              color={theme.thirdary}
              unit="kWh"
              value={boxesData.totalUsage ?? 0}
              maxValue={boxesData.totalUsage > previousValues.usage ? boxesData.totalUsage : previousValues.usage}
              dateRange={filters?.dateRange}
              previousValue={previousValues.usage}
              selfConsumption={selfConsumption.total ?? 0}
              popupContent={() => (
                <div dangerouslySetInnerHTML={{ __html: t('dataBoxes.usedEnergy.tooltip', { interpolation: { escapeValue: false } }) }} />
              )}
            />
            <DataPaper
              title={t('dataBoxes.savedMoney.title')}
              progressBar={false}
              icon={<SavingsIcon />}
              color={theme.fourth}
              unit="zł"
              roundTo={2}
              value={calculateMoneySaved(devicesEnergyProduction, devicesEnergyPrice)}
              dateRange={filters?.dateRange}
              popupContent={() => (
                <div dangerouslySetInnerHTML={{ __html: t('dataBoxes.savedMoney.tooltip', { interpolation: { escapeValue: false } }) }} />
              )}
            />
            <DataPaper
              title={t('dataBoxes.savedEmission.title')}
              icon={<CO2Icon />}
              progressBar={false}
              color={theme.secondary}
              unit="kg"
              value={calculateCo2Emission(boxesData.totalProduction)}
              dateRange={filters?.dateRange}
              popupContent={() => (
                <div dangerouslySetInnerHTML={{ __html: t('dataBoxes.savedEmission.tooltip', { interpolation: { escapeValue: false } }) }} />
              )}
            />
          </DataGrid>
        )}
        {devices?.length === 0 && usersList.length === 0 && debouncedTableSearchTerm === '' && !devicesLoading && !usersLoading ? (
          <></>
        ) : (
          <ChartPaper data={chartData} loadingState={usersLoading} selfConsumption={selfConsumption.chart} />
        )}
        {user?.role === 'admin' ? (
          usersList.length === 0 && debouncedTableSearchTerm === '' && !usersLoading ? (
            <TableNoDevicesPlaceholder>
              <StyledPlaceholder />
              <p>
                {t('noDataLabels.noAddedUsers')},
                <br /> {t('noDataLabels.youCanAddUsers')}.
              </p>
              <Button onClick={() => history.push('/users')} color="primary">
                {t('dashboardPage.goToUsersBtn')}
              </Button>
            </TableNoDevicesPlaceholder>
          ) : (
            <StyledPaper title={t('tableLabels.users')} checkedCounter={checkedIds.users.length} icon={() => <UsersIcon />}>
              <TableSearchWrapper>
                <Input
                  background="transparent"
                  name="tableSearch"
                  label={t('tableLabels.searchUser')}
                  value={tableSearchTerm ?? ''}
                  onChange={(e) => setTableSearchTerm(e.target.value)}
                />
              </TableSearchWrapper>
              <Table
                checkedItemsValues={Object.values(checkedItems).map(({ checked }) => checked)}
                items={usersList}
                onCheckAll={() => handleCheckAll('all', usersList)}
                noItemsPlaceholder={() => <p>{t('noDataLabels.noUsers')}</p>}
                noSearchItemsPlaceholder={t('noDataLabels.noMatchingUsers')}
                searchTerm={debouncedTableSearchTerm}
                itemsLength={usersList.length}
                loadingState={usersLoading}
                columns={[
                  {
                    label: t('tableLabels.nameAndSurname'),
                  },
                  {
                    label: t('tableLabels.email'),
                  },
                  {
                    label: t('tableLabels.deviceNumber'),
                  },
                  {
                    label: t('tableLabels.totalEnergyProduction'),
                  },
                  {
                    label: t('tableLabels.totalSavedEmission'),
                  },
                  {
                    label: '',
                  },
                ]}
              >
                {usersList?.map(({ _id: userId, name, surname, email, devices }) => {
                  const notDeletedDevices = devices.filter(({ deleted }) => deleted === false);
                  return (
                    <>
                      <TableRow key={userId}>
                        <TableCell>
                          <StyledCheckbox
                            color="primary"
                            checked={checkedItems?.[userId]?.checked ?? false}
                            indeterminate={
                              checkedItems?.[userId]?.checked &&
                              !notDeletedDevices.every(({ identifier }) => checkedItems?.[userId]?.devices?.[identifier] === true)
                            }
                            onChange={() => handleCheck(userId, 'checked')}
                            name={`check_${userId}`}
                          />
                        </TableCell>
                        <TableCell>
                          <StyledLink to={`/users/${userId}`}>
                            {name} {surname} <StyledOpenInNewIcon />
                          </StyledLink>
                        </TableCell>
                        <TableCell>{email}</TableCell>
                        <TableCell>{notDeletedDevices?.length}</TableCell>
                        <TableCell>{usersEnergyProduction[userId] ? usersEnergyProduction[userId]?.toFixed(3) : 0} kWh</TableCell>
                        <TableCell>{calculateCo2Emission(usersEnergyProduction[userId])} kg</TableCell>
                        <TableCell>
                          {notDeletedDevices?.length > 0 && (
                            <IconButton onClick={() => handleTableCollapse(userId)}>
                              <StyledKeyboardArrowDownIcon $active={tableCollapsedItems[userId]} />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                      {notDeletedDevices?.length > 0 && (
                        <UserDevicesTableRow key={`devices_${userId}`}>
                          <LinesTableCell>
                            <YLine active={tableCollapsedItems[userId]} itemsCount={notDeletedDevices?.length} />
                            {notDeletedDevices?.map(({ _id }, i) => (
                              <XLine key={_id} index={i} />
                            ))}
                          </LinesTableCell>
                          <UserDevicesTableCell colSpan={9}>
                            <Collapse in={tableCollapsedItems[userId]}>
                              <Table
                                rowCheckbox={false}
                                checkboxPlaceholderCell={true}
                                columns={[
                                  {
                                    label: t('tableLabels.name'),
                                  },
                                  {
                                    label: t('tableLabels.status'),
                                  },
                                  {
                                    label: t('tableLabels.energyProduction'),
                                  },
                                  {
                                    label: t('tableLabels.savedEmission'),
                                  },
                                  {
                                    label: t('tableLabels.atmosphericConditions'),
                                  },
                                ]}
                              >
                                {notDeletedDevices.map(({ _id, identifier, name, status, dataSource = 'custom', address: { postalCode } = {} }) => (
                                  <TableRow key={_id}>
                                    <TableCell>
                                      <StyledCheckbox
                                        color="primary"
                                        checked={checkedItems?.[userId]?.devices[identifier] ?? false}
                                        onChange={() => handleCheck(userId, identifier)}
                                        name={`check_${_id}`}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <StyledLink to={`/users/${userId}/${_id}`}>
                                        {`${name} (${identifier})`} <StyledOpenInNewIcon />
                                      </StyledLink>
                                    </TableCell>
                                    <TableCell>
                                      <StatusPill color={dataSource === 'mae' ? (status === 'ON' ? 'success' : 'danger') : 'disabled'}>
                                        {dataSource === 'mae'
                                          ? status === 'ON'
                                            ? t('noDataLabels.noData')
                                            : t('statusPill.connected')
                                          : t('statusPill.disconnected')}
                                      </StatusPill>
                                    </TableCell>
                                    <TableCell>
                                      {devicesEnergyProduction[identifier] ? devicesEnergyProduction[identifier]?.toFixed(3) : 0} kWh
                                    </TableCell>
                                    <TableCell>{calculateCo2Emission(devicesEnergyProduction[identifier])} kg</TableCell>
                                    <TableCell>
                                      {' '}
                                      {devicesWeatherData[postalCode]
                                        ? `${devicesWeatherData[postalCode]?.temperature ? `${devicesWeatherData[postalCode]?.temperature}°C` : ''}${
                                            devicesWeatherData[postalCode]?.humidity
                                              ? `${devicesWeatherData[postalCode]?.temperature ? ' / ' : ''}${
                                                  devicesWeatherData[postalCode]?.humidity
                                                }%`
                                              : ''
                                          } (${dayjs(devicesWeatherData[postalCode]?.time).format('DD.MM.YYYY HH:mm')})`
                                        : t('noDataLabels.noData')}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </Table>
                            </Collapse>
                          </UserDevicesTableCell>
                        </UserDevicesTableRow>
                      )}
                    </>
                  );
                })}
              </Table>
              <StyledTablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={pagiCount}
                labelRowsPerPage={`${t('tableLabels.rows')}:`}
                rowsPerPage={pagiRowsPerPage}
                page={pagiPage}
                onChangePage={(event, newPage) => setPagiPage(newPage)}
                onChangeRowsPerPage={(event) => setPagiRowsPerPage(parseInt(event.target.value, 10))}
              />
            </StyledPaper>
          )
        ) : devices?.length === 0 && debouncedTableSearchTerm === '' && !devicesLoading ? (
          <TableNoDevicesPlaceholder>
            <StyledPlaceholder />
            <p>
              {t('noDataLabels.noAddedDevices')},
              <br /> {t('noDataLabels.youCanAddDevices')}.
            </p>
            <Button onClick={() => history.push('/devices')} color="primary">
              {t('dashboardPage.goToDevicesBtn')}
            </Button>
          </TableNoDevicesPlaceholder>
        ) : (
          <StyledPaper title={t('tableLabels.devices')} checkedCounter={checkedIds.devices.length} icon={() => <DevicesIcon />}>
            <TableSearchWrapper>
              <Input
                background="transparent"
                name="tableSearch"
                label={t('tableLabels.searchDevice')}
                value={tableSearchTerm ?? ''}
                onChange={(e) => setTableSearchTerm(e.target.value)}
              />
            </TableSearchWrapper>
            <Table
              checkedItemsValues={Object.values(checkedItems?.[user?._id]?.devices ?? {}) ?? []}
              items={devices}
              onCheckAll={() => handleCheckAll(user?._id, devices)}
              noItemsPlaceholder={() => <p>{t('noDataLabels.noAddedDevices')}</p>}
              noSearchItemsPlaceholder={t('noDataLabels.noMatchingDevices')}
              searchTerm={debouncedTableSearchTerm}
              itemsLength={devices.length}
              loadingState={devicesLoading}
              columns={[
                {
                  label: t('tableLabels.name'),
                },
                {
                  label: t('tableLabels.status'),
                },
                {
                  label: t('tableLabels.energyProduction'),
                },
                {
                  label: t('tableLabels.savedEmission'),
                },
                {
                  label: t('tableLabels.atmosphericConditions'),
                },
              ]}
            >
              {devices?.map(({ _id, identifier, name, status, dataSource = 'custom', address: { postalCode } = {} }) => (
                <TableRow key={_id}>
                  <TableCell>
                    <StyledCheckbox
                      color="primary"
                      checked={checkedItems?.[user?._id]?.devices[identifier] ?? false}
                      onChange={() => handleCheck(user?._id, identifier)}
                      name={`check_${_id}`}
                    />
                  </TableCell>
                  <TableCell>
                    {' '}
                    <StyledLink to={`/devices/${_id}`}>
                      {`${name} (${identifier})`} <StyledOpenInNewIcon />
                    </StyledLink>
                  </TableCell>
                  <TableCell>
                    <StatusPill color={dataSource === 'mae' ? (status === 'ON' ? 'success' : 'danger') : 'disabled'}>
                      {dataSource === 'mae' ? (status === 'ON' ? t('statusPill.connected') : t('statusPill.disconnected')) : t('noDataLabels.noData')}
                    </StatusPill>
                  </TableCell>
                  <TableCell>{devicesEnergyProduction[identifier] ? devicesEnergyProduction[identifier]?.toFixed(3) : 0} kWh</TableCell>
                  <TableCell>{calculateCo2Emission(devicesEnergyProduction[identifier])} kg</TableCell>
                  <TableCell>
                    {devicesWeatherData[postalCode]
                      ? `${devicesWeatherData[postalCode]?.temperature ? `${devicesWeatherData[postalCode]?.temperature}°C` : ''}${
                          devicesWeatherData[postalCode]?.humidity
                            ? `${devicesWeatherData[postalCode]?.temperature ? ' / ' : ''}${devicesWeatherData[postalCode]?.humidity}%`
                            : ''
                        } (${dayjs(devicesWeatherData[postalCode]?.time).format('DD.MM.YYYY HH:mm')})`
                      : t('noDataLabels.noData')}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
            <StyledTablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              labelRowsPerPage={`${t('tableLabels.rows')}:`}
              count={pagiCount}
              rowsPerPage={pagiRowsPerPage}
              page={pagiPage}
              onChangePage={(event, newPage) => setPagiPage(newPage)}
              onChangeRowsPerPage={(event) => setPagiRowsPerPage(parseInt(event.target.value, 10))}
            />
          </StyledPaper>
        )}
      </div>
    </Wrapper>
  );
};

export default DashboardView;

const Wrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  ${({ theme }) => `${theme.mq.tablet}{
      padding: 45px 0 80px;
  }`}
`;

const PageTitle = styled.h1``;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  > button {
    margin-left: 15px;
  }
  ${({ theme }) => `${theme.mq.tablet}{
      margin-top: -20px;
  }`}
`;

const Filters = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  > div {
    width: calc(50% - 5px);
    &:nth-child(2) {
      margin-right: 10px;
    }
    &:first-child,
    &:last-child {
      width: 100%;
    }
  }
  margin-top: 25px;
  ${({ theme }) => `${theme.mq.tablet}{
      margin-top: 10px;
      >div{
        width: calc(25% - 7.5px);
        &:first-child, &:last-child {
          width: calc(25% - 7.5px);
        }
        &:not(:last-child){
          margin-right:10px;
        }
      }
  }`}
`;

const DataGrid = styled.div`
  display: grid;
  margin: 15px 0 35px;
  grid-template-columns: 1fr;
  gap: 10px;
  ${({ theme }) => `${theme.mq.small}{
      grid-template-columns: 1fr 1fr;
  }`}
  ${({ theme }) => `${theme.mq.tablet}{
      gap: 30px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
  }`}
`;

const StyledPaper = styled(Paper)`
  margin: 35px 0;
  padding: 100px 15px 15px;
  ${({ theme }) => `${theme.mq.small}{
      padding: 65px 15px 15px;
  }`}
`;

const StyledLaunchIcon = styled(LaunchIcon)`
  width: 20px;
  margin-right: 5px;
  path {
    fill: ${({ theme }) => theme.primary};
  }
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  width: 20px;
  path {
    fill: ${({ $iconColor }) => ($iconColor ? $iconColor : '#fff')};
  }
  transition: 300ms;
  ${({ $active }) => $active && `transform: rotate(180deg);`}
`;

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
  width: 25px;
  path {
    fill: ${({ theme }) => theme.primaryText};
  }
  ${({ $active }) => $active && `transform: rotate(180deg);`}
`;

const StyledFilterListIcon = styled(FilterListIcon)`
  width: 20px;
  margin-right: 5px;
`;

const ButtonWrapper = styled.div`
  position: relative;
`;

const StyledPopper = styled(Popper)`
  position: absolute !important;
  right: 0;
  top: 50px !important;
  z-index: 9;
  > div {
    padding: 10px 15px;
  }
  li:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.background};
  }
`;

const UserDevicesTableRow = styled(TableRow)`
  padding: 0 !important;
  border-bottom: 2px solid #eee !important;
  border-color: rgba(0, 0, 0, 0.05) !important;
  > td {
    padding: 0 !important;
  }
`;

const UserDevicesTableCell = styled(TableCell)`
  padding: 0 !important;
`;

const XLine = styled.div`
  position: absolute;
  top: ${({ index }) => 24 + (index + 1) * 51.5}px;
  margin: auto;
  right: 10px;
  width: 28px;
  height: 2px;
  background-color: #ccc;
`;

const YLine = styled.div`
  background-color: #ccc;
  width: 2px;
  height: ${({ itemsCount }) => 24 + itemsCount * 51.5}px;
  position: absolute;
  left: 0;
  top: ${({ active }) => (active ? 0 : '10px')};
  transition: 300ms;
  right: 0;
  margin: auto;
`;

const LinesTableCell = styled(TableCell)`
  position: relative;
  overflow: hidden;
`;

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  height: 15px;
  width: 15px;
  path {
    fill: ${({ theme }) => theme.primary};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.lightPrimaryText};
  white-space: nowrap;
  transition: 300ms;
  font-weight: 500;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const TableSearchWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 40px;
  right: 15px;
  ${({ theme }) => `${theme.mq.small}{
      top: 5px;
  }`}
`;

const StyledCheckbox = styled(Checkbox)`
  path {
    fill: ${({ theme }) => (theme.paperBackground === '#fff' ? theme.primary : theme.primaryText)};
  }
`;

const StyledTablePagination = styled(TablePagination)`
  color: ${({ theme }) => theme.primaryText};
  .MuiTablePagination-selectRoot {
    margin-right: 10px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.primaryText};
`;

const TableNoDevicesPlaceholder = styled.div`
  text-align: center;
  p {
    font-size: 18px;
    span {
      color: ${({ theme }) => theme.primary};
    }
  }
  button {
    margin-top: 10px;
  }
`;

const StyledPlaceholder = styled(Placeholder)`
  max-width: 600px;
  height: 100%;
  width: 100%;
  margin: 10px auto;
  display: block;
  ${({ theme }) => `${theme.mq.tablet}{
      height: 550px;
      margin: 0 auto;
  }`}
`;

const ErrorMsg = styled.p`
  position: absolute;
  color: ${({ theme }) => theme.danger};
  font-size: 13px;
  bottom: 0;
`;
