import { useState, useEffect, memo } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Modal = ({ show, className, exitButton, onCancel, title, children }) => {
  const [isShown, setShown] = useState(show);

  useEffect(() => {
    if (isShown !== show) {
      setShown(show);
      if (show) {
        document.querySelector('body').classList = 'freeze';
      } else {
        document.querySelector('body').classList = '';
      }
    }
  }, [show]);

  return isShown
    ? ReactDOM.createPortal(
        <ModalBackground>
          <ModalContainer className={className}>
            {title && <Title>{title}</Title>}
            {exitButton && (
              <ExitButton
                data-cy="close-modal-btn"
                onClick={() => {
                  onCancel(show);
                  setShown(show);
                }}
              />
            )}
            {children}
          </ModalContainer>
        </ModalBackground>,
        document.getElementById('modal-root'),
      )
    : null;
};

export default memo(Modal);

const ModalBackground = styled.div`
  background-color: ${({ theme }) => (theme.paperBackground === '#fff' ? `rgba(0,0,0,0.1)` : `rgba(0, 0, 0, 0.3)`)};
  width: 100%;
  height: 100vh;
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  z-index: 999;
`;

const ModalContainer = styled.div`
  overflow: auto;
  ${({ theme }) => `${theme.mq.small}{
      overflow: visible;
  }`}
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 2rem;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.paperBackground};
  border: 2px solid ${({ theme }) => theme.paperBackground}90;
  border-radius: 6px;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.14);
  max-height: 90%;
  ${({ theme }) => `${theme.mq.phone} {
   padding: 2rem;
}`}
`;

const ExitButton = styled.button`
  border: none;
  background: none;
  padding: 12px;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  &::before,
  &::after {
    content: '';
    height: 20px;
    width: 2px;
    background-color: #b5b5b5;
    position: absolute;
    top: 0;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const Title = styled.p`
  font-weight: bold;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 19px;
  z-index: 1;
  background-color: ${({ theme }) => theme.paperBackground};
`;
