import Modal from '../../../shared/Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Button from '../../../shared/Button';
import Input from '../../../shared/Input';
import Checkbox from '../../../shared/Checkbox';
import Spinner from '../../../shared/Spinner';
import { useSnackbar } from 'notistack';
import ReCaptcha from '../../../shared/ReCaptcha';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('validationTexts.firstnameRequired')),
  surname: Yup.string().required(i18n.t('validationTexts.surnameRequired')),
  email: Yup.string().email(i18n.t('validationTexts.wrongEmail')).required(i18n.t('validationTexts.emailRequired')),
  phoneNumber: Yup.string().min(9, i18n.t('validationTexts.phoneTooShort')).required(i18n.t('validationTexts.phoneRequired')),
  recaptchaToken: Yup.string().required(i18n.t('validationTexts.recaptchaRequired')),
  password: Yup.string().required(i18n.t('validationTexts.passwordRequired')),
  consents: Yup.object().shape({
    terms: Yup.boolean().isTrue('Akceptacja regulaminu jest wymagana'),
    dataAnalysis: Yup.boolean().isTrue('Zgoda wymagana do prawidłowego działania serwisu'),
    emails: Yup.boolean(),
  }),
});

const RegisterModal = ({ isRegisterModal, setIsRegisterModal, createUserSelf, userCreateLoading }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    name: '',
    surname: '',
    phoneNumber: '',
    email: '',
    password: '',
    recaptchaToken: '',
    active: true,
    consents: {
      terms: false,
      emails: false,
      dataAnalysis: false,
    },
  };
  return (
    <Modal
      title={t('registerModal.title')}
      show={isRegisterModal}
      exitButton={true}
      onCancel={() => {
        setIsRegisterModal(false);
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setFieldError }) => {
          const res = await createUserSelf(values);
          if (res?.status === 200) {
            enqueueSnackbar(t('registerModal.successText'), { variant: 'success' });
            setIsRegisterModal(false);
          } else if (res?.status === 500 || res?.status === 300) {
            setFieldError('email', `Email ${values.email} jest już zajęty`);
          } else {
            enqueueSnackbar(t('registerModal.errorDuringAdding'), { variant: 'error' });
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
          return userCreateLoading ? (
            <Spinner component="div" padding="150px" />
          ) : (
            <Form onSubmit={handleSubmit}>
              <InputRow colsTemplate="1fr 1fr">
                <Input
                  name="name"
                  label={t('formLabels.firstName')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  error={touched.name && errors.name}
                />
                <Input
                  name="surname"
                  label={t('formLabels.surname')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.surname}
                  error={touched.surname && errors.surname}
                />
              </InputRow>
              <InputRow colsTemplate="1fr 1fr">
                <Input
                  name="phoneNumber"
                  label={t('formLabels.phoneNumber')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                  error={touched.phoneNumber && errors.phoneNumber}
                />
                <Input
                  name="email"
                  label={t('formLabels.email')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && errors.email}
                />
              </InputRow>
              <InputRow>
                <Input
                  name="password"
                  label={t('formLabels.password')}
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={touched.password && errors.password}
                />
              </InputRow>
              <Checkbox
                value={values?.consents?.terms}
                onChange={() => setFieldValue('consents.terms', !values?.consents?.terms)}
                name="consents.terms"
                error={errors?.consents?.terms}
                touched={touched?.consents?.terms}
              >
                {t('registerModal.accept')}{' '}
                <a target="_blank" href="#/terms">
                  {t('registerModal.regulations')}
                </a>{' '}
                {t('registerModal.service')}
              </Checkbox>
              <Checkbox
                value={values?.consents?.dataAnalysis}
                onChange={() => setFieldValue('consents.dataAnalysis', !values?.consents?.dataAnalysis)}
                name="consents.dataAnalysis"
                error={errors?.consents?.dataAnalysis}
                touched={touched?.consents?.dataAnalysis}
              >
                {t('registerModal.analizeConsent')}
              </Checkbox>
              <Checkbox
                value={values?.consents?.emails}
                onChange={() => setFieldValue('consents.emails', !values?.consents?.emails)}
                name="consents.emails"
                color="primary"
                hideError
              >
                {t('registerModal.marketingConsent')}
              </Checkbox>
              <ReCaptcha setFieldValue={setFieldValue} error={touched.recaptchaToken && errors.recaptchaToken} align="left" />
              <Button fullWidth type="submit">
                {t('registerModal.createAccountBtn')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default RegisterModal;

const Form = styled.form`
  width: 80vw;
  padding: 30px 0;
  ${({ theme }) => `${theme.mq.desktop}{
    width: 500px;
  }`}
`;

const InputRow = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  ${({ theme, colsTemplate }) => `${theme.mq.small}{
    grid-template-columns: ${colsTemplate ? colsTemplate : '1fr'};
  }`}
`;
