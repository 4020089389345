import { useState, useEffect } from 'react';
import Modal from '../../../shared/Modal';
import WarningModal from '../../../shared/WarningModal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Button from '../../../shared/Button';
import Select from '../../../shared/Select';
import Input from '../../../shared/Input';
import { useSnackbar } from 'notistack';
import { FormControlLabel, Checkbox, Switch } from '@material-ui/core';
import { useGus } from '../../../utils/hooks';
import { capitalizeText } from '../../../utils/other';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t('validationTexts.firstnameRequired')),
  surname: Yup.string().required(i18n.t('validationTexts.surnameRequired')),
  email: Yup.string().email(i18n.t('validationTexts.wrongEmail')).required(i18n.t('validationTexts.emailRequired')),
  phoneNumber: Yup.string().min(9, i18n.t('validationTexts.phoneTooShort')),
  address: Yup.object().shape({
    postalCode: Yup.string().test('pattern', i18n.t('validationTexts.wrongPostalCode'), (value) =>
      value ? value.match(/^[0-9]{2}-[0-9]{3}$/) : true,
    ),
  }),
  password: Yup.string().required(i18n.t('validationTexts.passwordRequired')),
});

const AddUserModal = ({ isAddUserModal, setIsAddUserModal, createUser, createAdmin, editUser }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [userRole, setUserRole] = useState(false);
  const [warningModal, setWarningModal] = useState({ shown: false, targetId: null });
  const { voivodeships, districts, communes, cities, handleCurrentsChange, handleSearchTermChange } = useGus();
  const initialValues = {
    name: '',
    surname: '',
    phoneNumber: '',
    address: {
      street: '',
      houseNumber: '',
      apartmentNumber: '',
      district: '',
      voivodeship: '',
      commune: '',
      city: '',
      postalCode: '',
    },
    email: '',
    password: '',
    active: true,
  };

  const voivodeshipsOptions = voivodeships.map(({ name, id }) => ({ label: name, value: id }));
  const districtsOptions = districts.map(({ name, id }) => ({ label: name, value: id }));
  const communesOptions = communes.map(({ name, id }) => ({ label: name, value: id }));
  const citiesOptions = cities.map(({ name, id }) => ({ label: name, value: id }));

  useEffect(() => setUserRole(false), [isAddUserModal]);

  return (
    <Modal
      title={t('addUserModal.title')}
      show={isAddUserModal}
      exitButton={true}
      onCancel={() => {
        setIsAddUserModal(false);
      }}
    >
      <WarningModal
        modalState={warningModal}
        setModalState={setWarningModal}
        text={t('addUserModal.userAlreadyExistButDeleted')}
        onSuccess={() => {
          editUser(warningModal.targetId, { deleted: false }, true);
          setWarningModal({ shown: false, targetId: null });
          setIsAddUserModal(false);
        }}
        onDecline={() => setWarningModal({ shown: false, targetId: null })}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setFieldError }) => {
          let res;
          if (userRole) {
            res = await createAdmin(values);
          } else {
            res = await createUser(values);
          }
          if (res?.status === 200) {
            enqueueSnackbar(t('snackbarTexts.userAdded'), { variant: 'success' });
            setIsAddUserModal(false);
          } else if (res?.status === 500 && res?.data?.message === `Email ${values.email} jest zajęty`) {
            setFieldError('email', res?.data?.message);
          } else if (res?.status === 300) {
            setWarningModal({ shown: true, targetId: res?.data?.data });
          } else {
            enqueueSnackbar(t('snackbarTexts.errorDuringAdding'), { variant: 'error' });
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <SwitchWrapper>
              <SwitchLabel data-cy="user-switch" onClick={() => setUserRole(false)} active={!userRole}>
                {t('addUserModal.user')}
              </SwitchLabel>
              <Switch color="primary" onChange={() => setUserRole((prev) => !prev)} checked={userRole} />
              <SwitchLabel data-cy="admin-switch" onClick={() => setUserRole(true)} active={userRole}>
                {t('addUserModal.admin')}
              </SwitchLabel>
            </SwitchWrapper>
            <InputRow colsTemplate="1fr 1fr">
              <Input
                name="name"
                label={t('formLabels.firstName')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={touched.name && errors.name}
              />
              <Input
                name="surname"
                label={t('formLabels.surname')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.surname}
                error={touched.surname && errors.surname}
              />
            </InputRow>
            <InputRow>
              <Input
                name="phoneNumber"
                label={t('formLabels.phoneNumber')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
                error={touched.phoneNumber && errors.phoneNumber}
              />
            </InputRow>
            <InputRow colsTemplate="1fr 1fr">
              <Input
                name="email"
                label={t('formLabels.email')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={touched.email && errors.email}
              />
              <Input
                name="password"
                label={t('formLabels.password')}
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={touched.password && errors.password}
              />
            </InputRow>
            <SectionLabel>
              {t('formLabels.address')} <span>({t('formLabels.optional')})</span>
            </SectionLabel>
            <InputRow colsTemplate="1fr 1fr 1fr">
              <Input
                name="address.street"
                label={t('formLabels.street')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.address?.street}
                error={touched?.address?.street && errors?.address?.street}
              />
              <Input
                name="address.houseNumber"
                label={t('formLabels.houseNumber')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.address?.houseNumber}
                error={touched?.address?.houseNumber && errors?.address?.houseNumber}
              />
              <Input
                name="address.apartmentNumber"
                label={t('formLabels.apartmentNumber')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.address?.apartmentNumber}
                error={touched?.address?.apartmentNumber && errors?.address?.apartmentNumber}
              />
            </InputRow>
            <InputRow colsTemplate="1fr 1fr">
              <Select
                variant="react-select"
                name="address.voivodeship"
                menuPosition="fixed"
                label={t('formLabels.voivodeship')}
                onChange={({ label }) => {
                  handleCurrentsChange('voivodeship', label);
                  setFieldValue('address.voivodeship', label);
                  if (label !== values.address.voivodeship) {
                    setFieldValue('address.district', '');
                    setFieldValue('address.commune', '');
                    setFieldValue('address.city', '');
                  }
                }}
                onInputChange={(v) => handleSearchTermChange('voivodeship', v)}
                onBlur={handleBlur}
                value={{ label: capitalizeText(values?.address?.voivodeship) }}
                error={touched?.address?.voivodeship && errors?.address?.voivodeship}
                options={voivodeshipsOptions}
              />
              <Select
                variant="react-select"
                menuPosition="fixed"
                name="address.district"
                disabled={values?.address?.voivodeship === '' ?? true}
                label={t('formLabels.district')}
                onChange={({ label }) => {
                  handleCurrentsChange('district', label);
                  setFieldValue('address.district', label);
                }}
                onInputChange={(v) => handleSearchTermChange('district', v)}
                onBlur={handleBlur}
                value={{ label: values?.address?.district }}
                error={touched?.address?.district && errors?.address?.district}
                options={districtsOptions}
              />
            </InputRow>
            <InputRow colsTemplate="1fr 1fr 1fr">
              <Select
                variant="react-select"
                menuPosition="fixed"
                name="address.commune"
                disabled={values?.address?.district === '' ?? true}
                label={t('formLabels.commune')}
                onChange={({ label }) => {
                  handleCurrentsChange('commune', label);
                  setFieldValue('address.commune', label);
                }}
                onInputChange={(v) => handleSearchTermChange('commune', v)}
                onBlur={handleBlur}
                value={{ label: values?.address?.commune }}
                error={touched?.address?.commune && errors?.address?.commune}
                options={communesOptions}
              />
              <Select
                variant="react-select"
                menuPosition="fixed"
                name="address.city"
                disabled={values?.address?.commune === '' ?? true}
                label={t('formLabels.city')}
                onChange={({ label }) => {
                  handleCurrentsChange('city', label);
                  setFieldValue('address.city', label);
                }}
                onInputChange={(v) => handleSearchTermChange('city', v)}
                onBlur={handleBlur}
                value={{ label: values?.address?.city }}
                error={touched?.address?.city && errors?.address?.city}
                options={citiesOptions}
              />
              <Input
                name="address.postalCode"
                label={t('formLabels.postalCode')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.address?.postalCode}
                error={touched?.address?.postalCode && errors?.address?.postalCode}
              />
            </InputRow>
            <StyledFormControlLabel
              control={
                <StyledCheckbox checked={values.active} onChange={() => setFieldValue('active', !values.active)} name="active" color="primary" />
              }
              label={t('addUserModal.userActive')}
            />
            <Button fullWidth type="submit">
              {t('addUserModal.addBtn')}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddUserModal;

const Form = styled.form`
  width: 80vw;
  padding: 30px 0;
  ${({ theme }) => `${theme.mq.desktop}{
    width: 500px;
  }`}
`;

const StyledCheckbox = styled(Checkbox)`
  path {
    fill: ${({ theme }) => (theme.paperBackground === '#fff' ? theme.primary : theme.primaryText)};
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 20px;
  span {
    color: ${({ theme }) => theme.primaryText};
  }
`;

const InputRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${({ theme, colsTemplate }) => `${theme.mq.small}{
    gap: 10px;
    grid-template-columns: ${colsTemplate ? colsTemplate : '1fr'};
  }`}
`;

const SectionLabel = styled.p`
  font-size: 16px;
  margin: 0 0 3px 5px;
  span {
    font-size: 13px;
    color: ${({ theme }) => theme.secondaryText};
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme.primary};
  }
  .MuiSwitch-track {
    background-color: ${({ theme }) => theme.secondaryText}!important;
  }
`;

const SwitchLabel = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme, active }) => (active ? theme.primary : `${theme.secondaryText}80`)};
  transition: 300ms;
  width: 100px;
  margin: 0 5px;
  cursor: pointer;
  &:first-child {
    text-align: right;
  }
`;
