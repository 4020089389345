import styled from 'styled-components';
import Button from '../../shared/Button';
import Select from '../../shared/Select';
import AddApiKeyModal from './modals/AddApiKeyModal';
import UserConsentsModal from './modals/UserConsentsModal';
import NotificationsSettings from './modals/NotificationsSettingsModal';
import Paper from '../../shared/Paper';
import { List, ListItem, ListItemIcon, Divider, ListItemText, Switch, ListItemSecondaryAction } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import EditUserDataModal from './modals/EditUserDataModal';
import dayjs from 'dayjs';
import { formatAddress } from '../../utils/objectsFormating';
import PasswordConfirmationModal from '../../shared/PasswordConfirmationModal';
import InfoModal from '../../shared/InfoModal';
import RefreshIcon from '@material-ui/icons/Refresh';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/Info';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { handleCopyText } from '../../utils/other';
import { ReactComponent as ActiveUsersIcon } from '../../shared/assets/active_users.svg';
import { ReactComponent as DeactiveUsersIcon } from '../../shared/assets/deactive_users.svg';
import { ReactComponent as CalendarIcon } from '../../shared/assets/calendar.svg';
import { ReactComponent as ActiveDevicesIcon } from '../../shared/assets/active_devices.svg';
import { ReactComponent as DeactiveDevicesIcon } from '../../shared/assets/deactive_devices.svg';
import { ReactComponent as UserIcon } from '../../shared/assets/user.svg';
import { ReactComponent as MailIcon } from '../../shared/assets/mail.svg';
import { ReactComponent as PhoneIcon } from '../../shared/assets/phone.svg';
import { ReactComponent as LocationIcon } from '../../shared/assets/location.svg';
import { useTranslation } from 'react-i18next';
import { handleLanguageChange } from '../../utils/other';
import { languageOptions } from '../../shared/consts';

const ProfileView = ({
  isEditUserDataModal,
  setEditUserDataModal,
  user,
  editUser,
  toggleDarkMode,
  darkMode,
  devices,
  usersList,
  generateApiKey,
  isConfirmationModal,
  setConfirmationModal,
  generateApiKeyModal,
  setGenerateApiKeyModal,
  addApiKeyModal,
  setAddApiKeyModal,
  deleteUser,
  logoutUser,
  isUserConsentsModal,
  setUserConsentsModal,
  isNotificationSettingsModal,
  setNotificationSettingsModal,
  resetPassword,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const usersDevices = usersList?.map(({ devices }) => devices).flat();
  let devicesSource = user?.role === 'admin' ? usersDevices : devices;
  const currentLanguage = localStorage.getItem('language') ?? 'pl';
  return (
    <Wrapper>
      <PasswordConfirmationModal
        modalState={isConfirmationModal}
        setModalState={setConfirmationModal}
        successCallback={async (password) => {
          const key = await generateApiKey(user?._id, password);
          setGenerateApiKeyModal({ shown: true, key });
        }}
      />
      <UserConsentsModal
        modalState={isUserConsentsModal}
        setModalState={setUserConsentsModal}
        user={user}
        editUser={editUser}
        deleteUser={deleteUser}
        logoutUser={logoutUser}
        history={history}
      />
      <NotificationsSettings modalState={isNotificationSettingsModal} setModalState={setNotificationSettingsModal} user={user} editUser={editUser} />
      <InfoModal
        title={t('saveApiKeyModal.title')}
        info={t('saveApiKeyModal.text')}
        modalState={generateApiKeyModal.shown}
        btnText={t('saveApiKeyModal.keySavedBtn')}
        successCallback={() => setGenerateApiKeyModal({ shown: false, key: null })}
        closeCallback={() => setGenerateApiKeyModal({ shown: false, key: null })}
      >
        <Key data-cy="api-key-text">
          {generateApiKeyModal.key}
          <StyledFileCopyIcon
            onClick={() =>
              handleCopyText(generateApiKeyModal.key, () => enqueueSnackbar(t('saveApiKeyModal.successTooltip'), { variant: 'success' }))
            }
          />
        </Key>
      </InfoModal>
      <AddApiKeyModal modalState={addApiKeyModal} setModalState={setAddApiKeyModal} editUser={editUser} userId={user?._id} />
      <EditUserDataModal
        isEditUserDataModal={isEditUserDataModal}
        setEditUserDataModal={setEditUserDataModal}
        user={user}
        editUser={editUser}
        deleteUser={deleteUser}
        logoutUser={logoutUser}
        history={history}
        resetPassword={resetPassword}
      />
      <PageTitle>{t('profilePage.title')}</PageTitle>
      <Actions>
        <Button color="primary" startIcon={<EditIcon />} onClick={() => setEditUserDataModal(true)} data-cy="edit-user-btn">
          {t('profilePage.editUserBtn')}
        </Button>
      </Actions>
      <Content>
        <Paper title={t('profilePage.userData')} data-cy="user-data">
          <List>
            <ListItem>
              <StyledListItemIcon>
                <UserIcon />
              </StyledListItemIcon>
              <ListItemText primary={`${user?.name} ${user?.surname}`} />
            </ListItem>
            <Divider />
            <ListItem>
              <StyledListItemIcon>
                <MailIcon />
              </StyledListItemIcon>
              <StyledListItemText primary={user?.email} />
            </ListItem>
            <Divider />
            <ListItem>
              <StyledListItemIcon>
                <PhoneIcon />
              </StyledListItemIcon>
              <ListItemText primary={user?.phoneNumber || 'Brak'} />
            </ListItem>
            <Divider />
            <ListItem>
              <StyledListItemIcon>
                <LocationIcon />
              </StyledListItemIcon>
              <ListItemText primary={formatAddress(user?.address)} />
            </ListItem>
          </List>
        </Paper>
        <Paper title={t('profilePage.appSettings')} data-cy="app-settings">
          <List>
            <ListItem>
              <ListItemText primary={t('profilePage.darkMode')} />
              <ListItemSecondaryAction>
                <Switch edge="end" onChange={toggleDarkMode} checked={darkMode === 'true'} />
              </ListItemSecondaryAction>
            </ListItem>
            {user?.role === 'user' && (
              <>
                {' '}
                <Divider />
                <ListItem>
                  <ListItemLabel>
                    {t('profilePage.apiKey')}
                    <StyledInfoIcon onClick={() => history.push('/docs')} />
                  </ListItemLabel>
                  <ListItemSecondaryAction>
                    {user?.apiKey && user?.apiKey !== null ? (
                      <KeyWrapper>
                        <span>{t('profilePage.generatedBtn')}</span>
                        <RefreshIcon
                          onClick={() => setConfirmationModal(true)}
                          title={`${t('profilePage.generateBtn')} ${t('profilePage.againBtn')}`}
                        />
                      </KeyWrapper>
                    ) : (
                      <ApiButton data-cy="api-key-btn" color="secondary" type="button" onClick={() => setConfirmationModal(true)}>
                        {t('profilePage.generateBtn')}
                      </ApiButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemLabel>
                    {t('profilePage.apiKey')} SolarEdge
                    <StyledInfoIcon onClick={() => window.open('https://www.youtube.com/watch?v=iR26nmL5bXg')} />
                  </ListItemLabel>
                  <ListItemSecondaryAction>
                    {user?.solarEdgeApiKey && user?.solarEdgeApiKey !== null ? (
                      <KeyWrapper>
                        <span>{t('profilePage.addedApiKeyBtn')}</span>
                        <RefreshIcon
                          onClick={() => setAddApiKeyModal({ shown: true, target: 'solarEdgeApiKey' })}
                          title={`${t('profilePage.addApiKeyBtn')} ${t('profilePage.againBtn')}`}
                        />
                      </KeyWrapper>
                    ) : (
                      <ApiButton
                        data-cy="api-key-btn"
                        color="secondary"
                        type="button"
                        onClick={() => setAddApiKeyModal({ shown: true, target: 'solarEdgeApiKey' })}
                      >
                        {t('profilePage.addApiKeyBtn')}
                      </ApiButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemLabel>{t('profilePage.userConsents')}</ListItemLabel>
                  <ListItemSecondaryAction>
                    <ApiButton data-cy="api-key-btn" color="secondary" type="button" onClick={() => setUserConsentsModal(true)}>
                      {t('profilePage.manage')}
                    </ApiButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemLabel>{t('profilePage.notifications')}</ListItemLabel>
                  <ListItemSecondaryAction>
                    <ApiButton data-cy="api-key-btn" color="secondary" type="button" onClick={() => setNotificationSettingsModal(true)}>
                      {t('profilePage.manage')}
                    </ApiButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            )}
            <Divider />
            <ListItem>
              <ListItemLabel>{t('profilePage.appLanguage')}</ListItemLabel>
              <ListItemSecondaryAction style={{ margin: 5 }}>
                <LanguageSelect
                  hideError
                  menuPosition="fixed"
                  name="languageSelect"
                  onChange={({ target: { value } }) => handleLanguageChange(value)}
                  value={currentLanguage}
                  options={languageOptions}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>
        <StatsPaper title={t('profilePage.userStats.title')} role={user?.role} data-cy="statistics">
          {user?.role === 'admin' && (
            <>
              <StatsItem>
                <ActiveUsersIcon />
                <StyledStatsValue>{usersList?.filter(({ active }) => active)?.length}</StyledStatsValue>
                <StatsDescription>{t('profilePage.userStats.activeUsers')}</StatsDescription>
              </StatsItem>
              <StatsItem>
                <DeactiveUsersIcon />
                <StyledStatsValue>{usersList?.filter(({ active }) => !active)?.length}</StyledStatsValue>
                <StatsDescription>{t('profilePage.userStats.inactiveUsers')}</StatsDescription>
              </StatsItem>
            </>
          )}
          <StatsItem>
            <ActiveDevicesIcon />
            <StyledStatsValue>{devicesSource?.filter(({ status }) => status === 'ON')?.length}</StyledStatsValue>
            <StatsDescription>{t('profilePage.userStats.activeDevices')}</StatsDescription>
          </StatsItem>
          <StatsItem>
            <DeactiveDevicesIcon />
            <StyledStatsValue>{devicesSource?.filter(({ status }) => status === 'OFF')?.length}</StyledStatsValue>
            <StatsDescription>{t('profilePage.userStats.inactiveDevices')}</StatsDescription>
          </StatsItem>
          <StatsItem>
            <CalendarIcon />
            <StyledStatsValue>{user && dayjs(new Date()).diff(user.createdDate, 'days')}</StyledStatsValue>
            <StatsDescription>{t('profilePage.userStats.daysTogether')}</StatsDescription>
          </StatsItem>
        </StatsPaper>
      </Content>
    </Wrapper>
  );
};

export default ProfileView;

const Wrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  ${({ theme }) => `${theme.mq.tablet}{
      padding: 45px 0 80px;
  }`}
`;

const PageTitle = styled.h1``;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  flex-wrap: wrap;
  button {
    margin-left: 15px;
    margin-bottom: 10px;
  }
  ${({ theme }) => `${theme.mq.tablet}{
      margin-top: -50px;
  }`}
`;

const ApiButton = styled(Button)`
  margin: 5px 0;
  max-height: 26px;
  align-self: center;
  padding: 3px 7.5px;
  font-size: 12px;
  ${({ theme }) => `${theme.mq.tablet}{
    position:relative;
  }`}
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin: 15px 0 35px;
  ${({ theme }) => `${theme.mq.desktop}{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
  }`}
`;

const StatsItem = styled.div`
  margin: 5px;
  text-align: center;
  ${({ theme }) => `${theme.mq.desktop}{
  text-align: auto;
  }`}
  >svg {
    transform: translateY(7px);
    width: 50px;
    height: 50px;
    path {
      fill: ${({ theme }) => theme.primaryText};
    }
  }
`;

const StatsValue = styled.span`
  font-size: 35px;
  vertical-align: bottom;
  margin-left: 5px;
`;

const StatsDescription = styled.p`
  margin-top: 10px;
`;

const StatsPaper = styled(Paper)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  svg path,
  .active-devices .fil1 {
    fill: ${({ theme }) => theme.defaultIcon};
  }
  ${({ theme }) => `${theme.mq.desktop}{
  height: 200px;
  }`}
  ${({ role, theme }) =>
    role === 'admin' &&
    `
    margin-bottom: 20px;
    height: auto;
    min-height: 200px;
    ${theme.mq.desktop}{
      margin-bottom: 0;
      grid-column-start: 1;
      grid-column-end: 3;
    }
  `}
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 40px;
  ${({ theme }) => `${theme.mq.small}{
      min-width:56px;
  }`}
  svg {
    width: 40px;
    height: 40px;
  }
  .fil1 {
    fill: ${({ theme }) => theme.defaultIcon};
  }
`;

const StyledStatsValue = styled(StatsValue)`
  color: ${({ theme }) => theme.primaryText};
`;

const KeyWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: 5px;
  }
  svg {
    cursor: pointer;
    transition: 300ms;
    &:hover {
      opacity: 0.8;
    }
    path {
      fill: ${({ theme }) => theme.defaultIcon};
    }
  }
`;

const Key = styled.div`
  position: relative;
  display: flex;
  padding: 15px 10px;
  background-color: ${({ theme }) => theme.background};
  margin: 0 0 20px;
  color: ${({ theme }) => theme.primaryText};
  text-align: center;
  justify-content: center;
`;

const StyledFileCopyIcon = styled(FileCopyIcon)`
  position: absolute;
  height: 22px;
  width: 22px;
  right: 10px;
  cursor: pointer;
  transition: 300ms;
  &:hover {
    opacity: 0.8;
  }
  path {
    fill: #757575;
  }
`;

const ListItemLabel = styled.span`
  display: flex;
  align-items: center;
  height: 33px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  transition: 300ms;
  margin-left: 5px;
  path {
    fill: ${({ theme }) => theme.defaultIcon};
  }
  &:hover {
    opacity: 0.8;
  }
`;

const StyledListItemText = styled(ListItemText)`
  word-break: break-all;
`;

const LanguageSelect = styled(Select)`
  width: 125px;
  height: 25px;
  margin: 5px;
  padding: 0 5px;
  background: ${({ theme }) => theme.background};
`;
