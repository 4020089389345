import { useState, useEffect } from 'react';
import NavigationView from './NavigationView';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/modules/auth';
import { toggleSidebar, getNotifications, markAsReadNotifications } from '../../redux/modules/navigation';

const NavigationContainer = ({
  location,
  history,
  user,
  logoutUser,
  sidebarState,
  toggleSidebar,
  getNotifications,
  notifications,
  markAsReadNotifications,
}) => {
  const [isProfileOverlayOpen, setIsProfileOverlayOpen] = useState(false);
  const [isNotificationsOverlayOpen, setIsNotificationsOverlayOpen] = useState(false);

  useEffect(() => {
    toggleSidebar(false);
    handleOverlaysOutsideClick();
  }, [location]);

  useEffect(() => {
    if (user) {
      getNotifications(user?._id);
    }
  }, [user]);

  const handleOverlaysOutsideClick = () => {
    if (isProfileOverlayOpen) {
      setIsProfileOverlayOpen(false);
    }
    if (isNotificationsOverlayOpen) {
      setIsNotificationsOverlayOpen(false);
    }
  };
  const handleLogout = async () => {
    if (location.pathname !== '/') {
      history.push('/');
    }
    await logoutUser();
  };

  return (
    <NavigationView
      sidebarState={sidebarState}
      toggleSidebar={toggleSidebar}
      user={user}
      isProfileOverlayOpen={isProfileOverlayOpen}
      setIsProfileOverlayOpen={setIsProfileOverlayOpen}
      handleLogout={handleLogout}
      isNotificationsOverlayOpen={isNotificationsOverlayOpen}
      setIsNotificationsOverlayOpen={setIsNotificationsOverlayOpen}
      handleOverlaysOutsideClick={handleOverlaysOutsideClick}
      notifications={notifications}
      markAsReadNotifications={markAsReadNotifications}
      pathname={location.pathname}
    />
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  sidebarState: state.navigation.sidebarState,
  notifications: state.navigation.notifications,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    toggleSidebar: (target) => dispatch(toggleSidebar(target)),
    getNotifications: (userId) => dispatch(getNotifications(userId)),
    markAsReadNotifications: (userId, notifId) => dispatch(markAsReadNotifications(userId, notifId)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationContainer));
