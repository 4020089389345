import styled from 'styled-components';
import Paper from '../../shared/Paper';
import Input from '../../shared/Input';
import FileUpload from '../../shared/FileUpload';
import Button from '../../shared/Button';
import Gallery from '../../shared/Gallery';
import Spinner from '../../shared/Spinner';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as MessageSendedImg } from '../../shared/assets/message-sended.svg';
import { useTranslation } from 'react-i18next';

const ReportErrorView = ({
  //  reportError,
  reportLoading,
  handleSend,
  reportSended,
  setReportSended,
}) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    text: Yup.string().required(t('validationTexts.descriptionRequired')).min(3, t('validationTexts.descriptionMin3')),
    title: Yup.string().required(t('validationTexts.titleRequired')),
  });
  return (
    <Wrapper>
      <PageTitle>{t('reportErrorPage.title')}</PageTitle>
      <StyledPaper>
        {reportLoading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : reportSended ? (
          <SuccessWrapper>
            <StyledMessageSendedImg />
            <SuccessMessage>{t('reportErrorPage.successText')}</SuccessMessage>
            <Button type="button" onClick={() => setReportSended(false)}>
              {t('reportErrorPage.createNextReportBtn')}
            </Button>
          </SuccessWrapper>
        ) : (
          <Formik
            initialValues={{
              files: [],
              title: '',
              text: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSend(values);
            }}
          >
            {({ handleChange, setFieldValue, handleSubmit, values, errors }) => (
              <Form onSubmit={handleSubmit}>
                <Input label={t('formLabels.title')} name="title" onChange={handleChange} value={values.title} error={errors.title} />
                <Input
                  multiline
                  rows={5}
                  name="text"
                  label={t('formLabels.problemDescription')}
                  onChange={handleChange}
                  value={values.text}
                  error={errors.text}
                />
                <FileUpload
                  onDrop={(files) => {
                    const filesWithPreview = files.map((file) =>
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                      }),
                    );
                    setFieldValue('files', [...values.files, ...filesWithPreview]);
                  }}
                  placeholder={t('formLabels.attachFiles')}
                />
                {values.files.length > 0 && (
                  <>
                    <Label>{t('formLabels.attachedFiles')}</Label>
                    <Gallery
                      images={values.files?.map(({ preview, originalName }, index) => ({
                        name: originalName,
                        src: preview,
                        _id: index,
                      }))}
                      showSmallDeleteBtns={true}
                      variant="dashboard"
                      deleteModalHandler={(index) => {
                        setFieldValue(
                          'files',
                          values.files?.filter((_, i) => i !== index),
                        );
                      }}
                    />
                  </>
                )}
                <CenterBox>
                  <Button type="submit">{t('reportErrorPage.sendApplication')}</Button>
                </CenterBox>
              </Form>
            )}
          </Formik>
        )}
      </StyledPaper>
    </Wrapper>
  );
};

export default ReportErrorView;

const Wrapper = styled.div`
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
`;

const PageTitle = styled.h1`
  margin-top: 10px;
  ${({ theme }) => `${theme.mq.desktop}{
    margin-top: 45px;
  }`}
`;

const StyledPaper = styled(Paper)`
  margin: 10px auto;
  max-width: 500px;
`;

const Form = styled.form`
  padding: 20px;
`;

const CenterBox = styled.div`
  text-align: center;
`;

const Label = styled.p`
  margin-left: 2px;
  display: block;
  color: #484848;
  font-size: 14px;
`;

const SuccessMessage = styled.h1`
  color: #737373;
  text-align: center;
  margin: 40px 0 50px;
`;

const StyledMessageSendedImg = styled(MessageSendedImg)`
  width: 100%;
  height: 250px;
`;

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
