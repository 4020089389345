import styled, { keyframes } from 'styled-components';

const Spinner = ({ size = '10em', component = 'td', colSpan, padding = '50px 0 30px 0', height = '100%', className }) => {
  return component === 'td' ? (
    <SpinnerWrapperTd className={className} data-cy="spinner" padding={padding} colSpan={colSpan}>
      <SpinnerItem size={size} />
    </SpinnerWrapperTd>
  ) : (
    <SpinnerWrapperDiv className={className} data-cy="spinner" padding={padding} colSpan={colSpan} height={height}>
      <SpinnerItem size={size} />
    </SpinnerWrapperDiv>
  );
};

export default Spinner;

const rotate = keyframes`
 from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerWrapperTd = styled.td`
  width: 100%;
  height: 100%;
  padding: ${({ padding }) => padding};
`;

const SpinnerWrapperDiv = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding};
`;

const SpinnerItem = styled.div`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border: ${({ size }) => (size === '2.5em' ? `5px` : '1em')} solid rgba(0, 0, 0, 0.05);
  border-left: ${({ size }) => (size === '2.5em' ? `5px` : '1em')} solid ${({ theme }) => theme.primary};
  transform: translateZ(0);
  animation: ${rotate} 1.1s infinite linear;
  border-radius: 50%;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  &:after {
    border-radius: 50%;
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`;
