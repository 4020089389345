import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Table, Paper, Spinner } from '../../shared';
import { Add, Edit, Check, Close } from '@material-ui/icons';
import { TablePagination, TableRow, TableCell, IconButton } from '@material-ui/core';
import MessageModal from './modals/MessageModal';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const MessageView = ({ page, count, rowsPerPage, handleChangePage, handleChangeRowsPerPage, sort, setSort }) => {
  const [messageModal, setMessageModal] = useState({ shown: false, target: null });
  const { t } = useTranslation();
  const { user } = useSelector(({ auth }) => auth);
  const { loading, messages } = useSelector(({ message }) => message);
  return (
    <Wrapper>
      <MessageModal modalState={messageModal} setModalState={setMessageModal} />
      <PageTitle>{t('messagesPage.title')}</PageTitle>
      {user?.role === 'admin' && (
        <Actions>
          <Button data-cy="add-user-btn" color="primary" onClick={() => setMessageModal({ shown: true, target: 'new' })} startIcon={<Add />}>
            {t('messagesPage.addMessBtn')}
          </Button>
        </Actions>
      )}
      <StyledPaper>
        {loading ? (
          <Spinner component="div" />
        ) : (
          <>
            <Table
              rowCheckbox={false}
              sort={sort}
              setSort={setSort}
              columns={[
                {
                  label: t('tableLabels.title'),
                  width: '60%',
                },
                {
                  label: t('tableLabels.createdDate'),
                  sorting: true,
                  name: 'createdDate',
                  width: '20%',
                },
                {
                  label: t('tableLabels.seen'),
                  width: '20%',
                },
                {
                  width: 100,
                  label: '',
                },
              ]}
            >
              {messages?.length > 0 ? (
                messages?.map(({ _id, createdDate, title, to }) => (
                  <TableRow key={_id} style={{ height: 53 }}>
                    <TableCell>
                      <Title>
                        <Link to={`/messages/${_id}`}>{title}</Link>
                      </Title>
                    </TableCell>
                    <TableCell>{dayjs(createdDate).format('DD.MM.YYYY HH:mm')}</TableCell>
                    <TableCell>
                      {user?.role === 'admin'
                        ? `${to?.filter(({ seen }) => seen)?.length}/${to?.length}`
                        : (() => {
                            const seen = to?.find((el) => el?.user?._id === user?._id)?.seen;
                            return seen ? <Check /> : <Close />;
                          })()}
                    </TableCell>
                    <TableCell>
                      {user?.role === 'admin' && (
                        <IconButton onClick={() => setMessageModal({ shown: true, target: _id })}>
                          <Edit fontSize="small" />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <NoDataTableCell colSpan={4}>{t('noDataLabels.noMessages')}</NoDataTableCell>
                </TableRow>
              )}
            </Table>
            <StyledTablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              labelRowsPerPage={`${t('tableLabels.rows')}:`}
              page={page}
              count={count}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        )}
      </StyledPaper>
    </Wrapper>
  );
};

export default MessageView;

const PageTitle = styled.h1``;

const Wrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  ${({ theme }) => `${theme.mq.tablet}{
      padding: 45px 0 80px;
  }`}
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  > button {
    margin-left: 15px;
  }
  ${({ theme }) => `${theme.mq.tablet}{
      margin-top: -20px;
  }`}
`;

const StyledPaper = styled(Paper)`
  margin: 15px 0 35px;
`;

const StyledTablePagination = styled(TablePagination)`
  color: ${({ theme }) => theme.primaryText};
  .MuiTablePagination-selectRoot {
    margin-right: 10px;
  }
`;

const Title = styled.span`
  a {
    text-decoration: none;
    transition: 300ms;
    color: ${({ theme }) => theme.primaryText};
    &:hover {
      color: ${({ theme }) => theme.primary};
      text-decoration: underline;
    }
  }
`;

const NoDataTableCell = styled(TableCell)`
  text-align: center;
  height: 80px;
  font-size: 18px;
`;
// const UserSeenPill = styled.div`
//   display: inline-block;
//   color: ${({ theme }) => theme.primaryText};
//   font-size: 14px;
//   margin: 3px;
//   padding: 4px 7px;
//   border-radius: 4px;
//   cursor: default;
//   user-select: none;
//   box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 10%);
//   ${({ seen, theme }) =>
//     seen
//       ? `
//     background-color: ${theme.primary}
//   `
//       : `
//     background-color: ${theme.background}

//       `}
// `;
