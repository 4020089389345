import io from 'socket.io-client';
// import userEventsHandler from './user/eventsHandler';
import { getNotifications } from '../redux/modules/navigation';
import { socketDeviceStatusesUpdate } from '../redux/modules/device';

export const socketMiddleware = (url) => {
  return ({ getState, dispatch }) => {
    const socket = io.connect(url, {
      // 'force new connection': true,
      // reconnectionAttempts: 'Infinity',
      timeout: 10000,
      transports: ['websocket'],
    });
    // userEventsHandler(socket, storeAPI);
    socket.on('connect', () => {});
    socket.on('notification-add', (users) => {
      const {
        auth: { user },
      } = getState();
      if (users?.length > 0) {
        if (users?.includes(user?._id)) {
          dispatch(getNotifications(user?._id));
        }
      }
    });
    socket.on('device-notification-add', ({ userId, body: { deviceId, ...body } }) => {
      const {
        auth: { user },
      } = getState();
      if (user?._id === userId) {
        dispatch(getNotifications(user?._id));
      }
      if (body) {
        const finalBody = {};
        if (body?.isFire) {
          finalBody.isFire = body.isFire;
        }
        if (body?.isFlooded) {
          finalBody.isFlooded = body.isFlooded;
        }
        if (body?.isDamaged) {
          finalBody.damage = { isDamaged: body.isDamaged, description: body.damageDescription ?? '' };
        }
        dispatch(socketDeviceStatusesUpdate(deviceId, body));
      }
    });
    //Middleware
    return (next) => (action) => {
      switch (action.type) {
        default: {
          return next(action);
        }
        //user event emitters
        // case 'LOGOUT_USER_SUCCESS': {
        //   return next(action);
        // }
        // case 'GET_DEVICE_BY_ID_SUCCESS': {
        //   socket.emit('device-join', `device-${action.payload.identifier}`);
        //   return next(action);
        // }
        // case 'LEAVE_DEVICE_SOCKET_ROOM': {
        //   const currentDeviceIdentifier = storeAPI.getState()?.device?.currentDevice?.identifier;
        //   socket.emit('device-leave', `device-${currentDeviceIdentifier}`);
        //   return next(action);
        // }
      }
    };
  };
};
